import { GRAPHQL_AUTH_MODE, GraphQLQuery } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { Languages } from "../constants/enums";
import { createChoice, updateChoice } from "../graphql/mutations";
import { choiceByConceptID, getChoice, listChoices } from "../graphql/queries";
import { onCreateChoice } from "../graphql/subscriptions";
import { Choice } from "../models";
import {
  ChoiceBulkTrashVariables,
  GetVariables,
  ListingByConceptVariables,
  Option,
} from "../models/app";
import { HeadCell } from "../models/dataTable";
import {
  setAllListing,
  setLastIndex,
  setListing,
  setNextToken,
  setPagination,
} from "../store/ducks/choice";
import { CreateChoiceInput, UpdateChoiceInput } from "./../models/GQL_API";
import useApp from "./useApp";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();
  const language = useSelector((state: any) => state.accounts.language);

  const session = useSelector((state: any) => state.app.session);
  const nextToken = useSelector((state: any) => state.choices.nextToken);
  const lastIndex = useSelector((state: any) => state.choices.lastIndex);
  const paginationListing = useSelector(
    (state: any) => state.choices.pagination
  );
  const choicesAllListing = useSelector(
    (state: any) => state.choices.allListing
  );
  const choicesListing = useSelector((state: any) => state.choices.allListing);
  const selectedConcept = useSelector((state: any) => state.concepts.selected);
  const choiceListing = useSelector((state: any) => state.choices.listing);
  async function fetch(params: ListingByConceptVariables) {
    try {
      const { conceptID, searchText, limit, startIndex } = params;
      const filter: any = {
        conceptID: { eq: conceptID ? conceptID : selectedConcept },
        deleted: { eq: "0" },
      };
      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      const dataList: any = await API.graphql<GraphQLQuery<Choice>>({
        query: listChoices,
        variables: { filter, limit, nextToken },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      const currentNextToken = dataList.data.listChoices.nextToken;
      const responseListing = dataList.data.listChoices.items;

      let listing = [...choiceListing, ...responseListing];
      // console.log({ listing });
      dispatch(setListing(listing));
      dispatch(setNextToken(currentNextToken));
      return listing;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchAll(params: any) {
    try {
      const { conceptID, searchText, limit, conceptsList } = params;
      const filter: any = {
        deleted: { eq: "0" },
      };
      if (conceptsList) {
        filter.or = [];
        for (let concept of conceptsList) {
          filter.or.push({ conceptID: { eq: concept } });
        }
        if (filter.or.length === 0) {
          delete filter.or;
        }
      }
      if (conceptID) {
        filter.conceptID = { eq: conceptID };
      }
      if (searchText && searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      if (
        choicesAllListing.length === 0 ||
        (conceptID && conceptID !== choicesAllListing[0].conceptID)
      ) {
        const firstlisting: any = await API.graphql<GraphQLQuery<Choice>>({
          query: listChoices,
          variables: { filter, limit: limit ?? 1000 },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });

        let nextToken = firstlisting.data.listChoices.nextToken;
        let allChoices = firstlisting.data.listChoices.items;

        while (nextToken && nextToken.length > 0) {
          const listing: any = await API.graphql<GraphQLQuery<Choice>>({
            query: listChoices,
            variables: { filter, limit: limit ?? 10000, nextToken },
            authMode: session
              ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
              : GRAPHQL_AUTH_MODE.AWS_IAM,
          });

          allChoices = allChoices.concat(listing.data.listChoices.items);
          nextToken = listing.data.listChoices.nextToken;
        }
        return allChoices;
      } else {
        return choicesAllListing;
      }
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchChoiceByConceptID(params: ListingByConceptVariables) {
    try {
      const { conceptID, searchText, limit, forceRefresh } = params;
      if (!conceptID) return [];
      const filter: any = {
        deleted: { eq: "0" },
      };
      if (searchText && searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      if (
        choicesAllListing.length === 0 ||
        conceptID !== choicesAllListing[0].conceptID ||
        forceRefresh
      ) {
        const listing: any = await API.graphql<GraphQLQuery<Choice>>({
          query: choiceByConceptID,
          variables: { filter, conceptID: conceptID, limit: limit ?? 10000 },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        let nextToken = listing.data.choiceByConceptID.nextToken;
        let allItems = listing.data.choiceByConceptID.items;

        while (nextToken && nextToken.length > 0) {
          const newListing: any = await API.graphql<GraphQLQuery<Choice>>({
            query: choiceByConceptID,
            variables: {
              filter,
              conceptID: conceptID,
              limit: limit ?? 10000,
              nextToken,
            },
            authMode: session
              ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
              : GRAPHQL_AUTH_MODE.AWS_IAM,
          });

          allItems = allItems.concat(newListing.data.choiceByConceptID.items);
          nextToken = newListing.data.choiceByConceptID.nextToken;
        }
        return allItems;
      } else {
        return choicesAllListing;
      }
    } catch (err) {
      console.log(err);
      showError(err);
    }
  }

  async function fetchByChoiceGroups(params: any) {
    try {
      const { conceptID, ChoiceGroups, limit } = params;
      const filter: any = {
        conceptID: { eq: conceptID },
      };

      if (ChoiceGroups.length > 0) {
        filter.or = [];
        for (let i = 0; i < ChoiceGroups.length; i++) {
          filter.or.push({ choiceGroupID: { eq: ChoiceGroups[i].id } });
        }
      }

      const listing: any = await API.graphql<GraphQLQuery<Choice>>({
        query: listChoices,
        variables: { filter, limit: limit ?? 1000 },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return listing.data.listChoices.items;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function fetchMultipleByID(params: any) {
    try {
      const { choisesIDs, limit } = params;
      const filter: any = {};
      if (choisesIDs) {
        filter.or = [];
        for (let choiceID of choisesIDs) {
          filter.or.push({ id: { eq: choiceID } });
        }
        if (filter.or.length === 0) {
          delete filter.or;
        }
      }

      const listing: any = await API.graphql<GraphQLQuery<Choice>>({
        query: listChoices,
        variables: { filter, limit: limit ?? 1000 },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return listing.data.listChoices.items;
    } catch (err: Error | any) {
      console.log(err);
      showError(err.message || err);
      return [];
    }
  }

  async function get(params: any) {
    const { id } = params;

    try {
      const choice: any = await API.graphql({
        query: getChoice,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return choice.data.getChoice;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: any, session = true) {
    let { userID, userName, data } = params;

    if (!selectedConcept) throw new Error("You must select a concept first.");

    let multiLanguages: any = [[`${Languages.ENGLISH}-name`, data.name]];
    multiLanguages = JSON.stringify(multiLanguages);

    try {
      const createInput: CreateChoiceInput = {
        name: data.name.toLowerCase(),
        price: data.price ? Number(data.price) : 0,
        precedence: data.precedence ? data.precedence : "0",
        conceptID: selectedConcept,
        choiceGroupID: data.choiceGroupID,
        symphonyID: data.symphonyID ? data.symphonyID : "0",
        multiLanguages: multiLanguages,
        deleted: "0",
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
      };

      const Choice: any = await API.graphql<GraphQLQuery<Choice>>({
        query: createChoice,
        variables: { input: createInput },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));

      let newListing = [...choicesListing, Choice.data.createChoice];
      dispatch(setAllListing(newListing));

      showConfirm(`New ${singleName} has been created successfully`);

      return Choice;
    } catch (err: any) {
      console.log(err);
      showError(err?.message ?? "Something went wrong.");
    }
  }

  async function update(params: any, session: any) {
    try {
      const { data } = params;
      let original = await get(params);

      let multiLanguages: any = [];
      if (original.multiLanguages) {
        multiLanguages = new Map(JSON.parse(original.multiLanguages));
        if (data.name) {
          multiLanguages.set(`${language}-name`, data.name);
        }
        multiLanguages = JSON.stringify(Array.from(multiLanguages.entries()));
      } else {
        if (data.name) {
          multiLanguages.push([`${language}-name`, data.name]);
        }
        multiLanguages = JSON.stringify(multiLanguages);
      }

      const updateInput: UpdateChoiceInput = {
        id: original.id,
        name:
          data.name && language === Languages.ENGLISH
            ? data.name.toLowerCase()
            : original!.name,
        price: Number(data.price),
        precedence: data.precedence ? data.precedence : original.precedence,
        choiceGroupID: data.choiceGroupID
          ? data.choiceGroupID
          : original.choiceGroupID,
        symphonyID: data.symphonyID ? data.symphonyID : original.symphonyID,
        multiLanguages: multiLanguages,
        _version: original._version,
      };

      const Choice: any = await API.graphql<GraphQLQuery<Choice>>({
        query: updateChoice,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`${singleName} has been updated successfully`);
      dispatch(setLastIndex(0));
      dispatch(setNextToken(null));
      dispatch(setPagination([]));

      let newListing: any = [];
      choicesListing.map((item: any) => {
        if (item.id === Choice.data.updateChoice.id) {
          newListing.push(Choice.data.updateChoice);
        } else {
          newListing.push(item);
        }
      });
      dispatch(setAllListing(newListing));

      return Choice.data.updateChoice;
    } catch (err) {
      showError(err);
    }
  }

  async function trash(params: GetVariables) {
    try {
      const original = await get(params);

      const updateInput: UpdateChoiceInput = {
        id: original.id,
        deleted: "1",

        _version: original._version,
      };

      await API.graphql<GraphQLQuery<Choice>>({
        query: updateChoice,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const newChoicesListing = choicesListing.filter(
        (choice: any) => choice.id !== original.id
      );
      dispatch(setAllListing(newChoicesListing));
      showConfirm(`${singleName} has been moved to trash successfully`);
    } catch (err) {
      showError(err);
    }
  }

  async function bulkTrash(params: ChoiceBulkTrashVariables) {
    const { ids, listing } = params;

    ids.forEach(async (id: any) => {
      try {
        await trash(id);
      } catch (err: Error | any) {
        throw err;
      }
    });

    dispatch(setAllListing(listing.filter((model: any) => !ids.has(model.id))));

    showConfirm(`${ids.size} ${listingName} items has been moved to trash`);
  }

  function options(listing: Choice[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    {
      id: "precedence",
      numeric: false,
      disablePadding: false,
      label: "Precedence",
    },
    {
      id: "price",
      numeric: false,
      disablePadding: false,
      label: "Price",
    },
    // {
    //   id: "symphonyID",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "simphony ID",
    // },
    {
      id: "createdBy",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];
  const dataCells: readonly string[] = [
    "name",
    "price",
    "symphonyID",
    "precedence",
  ];

  const api: any = {};

  api[`${listingName}Model`] = Choice as any;
  api[`${listingName}CreateSubscription`] = onCreateChoice;

  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Options`] = options;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}FetchAll`] = fetchAll;
  api[`${listingName}FetchByChoiceGroups`] = fetchByChoiceGroups;
  api[`${listingName}FetchMultipleByID`] = fetchMultipleByID;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}BulkTrash`] = bulkTrash;
  api[`${listingName}FetchByConceptID`] = fetchChoiceByConceptID;
  api[`${listingName}ChangeListing`] = (listing: Choice[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeAllListing`] = (listing: Choice[]) =>
    dispatch(setAllListing(listing));
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = choiceListing;
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  return api;
};

export default useResource;
