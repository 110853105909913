import { useDispatch, useSelector } from "react-redux";
import useApp from "./useApp";
import { GRAPHQL_AUTH_MODE, GraphQLQuery } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api";
import { API } from "aws-amplify";
import * as XLSX from "xlsx";
import {
  listReviews,
  getReview,
  listConcepts,
  syncConcepts,
} from "../../src/graphql/queries";
//import { addReview } from "../store/ducks/review";
import { ReviewListingVariables, GetVariables } from "../models/app";
import { Review } from "./../models/GQL_API";
import dayjs from "dayjs";
// import { Review } from "../API";
import {
  setListing,
  setNextToken,
  setPrevNextToken,
} from "../store/ducks/review";
import { Concept } from "../API";
import useParentConcept from "./useParentConcept";
import { capitalizeSentence } from "../helpers/utils";

const useReviews = (listingName: string, singleName: string) => {
  const accountsSelected = useSelector((state: any) => state.accounts.selected);
  const conceptsSelected = useSelector((state: any) => state.concepts.selected);
  const session = useSelector((state: any) => state.app.session);
  const parentconceptsSelected = useSelector(
    (state: any) => state.parentconcepts.selected
  );
  const dispatch = useDispatch();
  const { showConfirm, showError } = useApp();
  const parentconceptsListing = useSelector(
    (state: any) => state.parentconcepts.listing
  );
  const { parentconceptsGetName } = useParentConcept(
    "parentconcepts",
    "parentconcept"
  );
  const ReviewsListing: Review[] = useSelector(
    (state: any) => state.reviews.listing
  );

  async function fetch(params: ReviewListingVariables) {
    const {
      conceptID,
      parentConceptID,
      nextToken,
      searchText,
      dateRange,
      limit,
    } = params;

    if (!conceptID) {
      console.log("No conceptID provided, skipping fetch.");
      return;
    }

    // Clear data if the conceptID changes
    if (conceptID !== conceptsSelected) {
      dispatch(setListing([])); // Clear previous data
      dispatch(setPrevNextToken(null)); // Clear previous tokens
      dispatch(setNextToken(null)); // Clear next tokens
    }

    console.log("Fetching reviews for conceptID:", conceptID);
    dispatch({ type: "FETCH_REVIEWS_LOADING", payload: true });

    try {
      const filter: any = { deleted: { eq: "0" }, and: [], or: [] };

      if (searchText?.length > 0) {
        filter.or.push(
          { orderID: { contains: searchText } },
          { phoneUser: { eq: searchText } }
        );
      }
      if (conceptID) {
        filter.and.push({ conceptID: { eq: conceptID } });
      }
      if (parentConceptID) {
        filter.and.push({ parentConceptID: { eq: parentConceptID } });
      }
      if (dateRange?.[0]?.startDate && dateRange?.[0]?.endDate) {
        const formattedStartDate = dayjs(dateRange[0].startDate).format(
          "YYYY-MM-DD"
        );
        const formattedEndDate = dayjs(dateRange[0].endDate).format(
          "YYYY-MM-DD"
        );

        if (formattedStartDate === formattedEndDate) {
          filter.and.push({ createdAt: { contains: formattedStartDate } });
        } else {
          filter.and.push({
            createdAt: { between: [formattedStartDate, formattedEndDate] },
          });
        }
      }

      if (filter.and.length === 0) delete filter.and;
      if (filter.or.length === 0) delete filter.or;

      const reviewData: any = await API.graphql({
        query: listReviews,
        variables: { filter, limit: limit || 1000, nextToken },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      const currentNextToken = reviewData.data.listReviews.nextToken;
      const responseListing = reviewData.data.listReviews.items;

      dispatch(setListing(responseListing)); // Update with fetched data
      dispatch(setPrevNextToken(nextToken)); // Save current token
      dispatch(setNextToken(currentNextToken)); // Save next token

      // fetchwithLowestRates(params);
      return responseListing;
    } catch (err: any) {
      console.error("Error fetching reviews:", err);
      showError(err?.message || "An error occurred while fetching reviews.");
    } finally {
      dispatch({ type: "FETCH_REVIEWS_LOADING", payload: false });
    }
  }

  const fetchAll = async (params: ReviewListingVariables) => {
    const { limit, conceptID, searchText, dateRange, parentConceptID } = params;

    console.log({ params });

    try {
      const filter: any = {
        deleted: { eq: "0" },
      };

      filter.or = [];
      filter.and = [];

      if (searchText && searchText.length > 0) {
        filter.or.push({ orderID: { contains: searchText } });
      }
      if (parentConceptID && parentConceptID !== "") {
        filter.and.push({ parentConceptID: { eq: parentConceptID } });
      }
      if (conceptID && conceptID !== "") {
        filter.and.push({ conceptID: { eq: conceptID } });
      }

      console.log({ dateRange });

      if (dateRange && dateRange[0]?.startDate && dateRange[0]?.endDate) {
        const formattedStartDate = dayjs(dateRange[0].startDate)
          .startOf("day")
          .format("YYYY-MM-DD");
        const formattedEndDate = dayjs(dateRange[0].endDate)
          .endOf("day")
          .format("YYYY-MM-DD");
        console.log(
          `Start Date: ${formattedStartDate}, End Date: ${formattedEndDate}`
        );

        if (formattedStartDate === formattedEndDate) {
          filter.and.push({
            createdAt: { contains: formattedStartDate },
          });
        } else {
          filter.and.push({
            createdAt: { between: [formattedStartDate, formattedEndDate] },
          });
        }
      }

      if (filter.and && filter.and.length === 0) {
        delete filter.and;
      }
      if (filter.or && filter.or.length === 0) {
        delete filter.or;
      }

      let myVariables = {
        // conceptID,
        filter,
        limit: 10000,
      };

      const res: any = await API.graphql({
        // query: reviewsByConceptID,
        variables: myVariables,
        query: listReviews,
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      let currentNextToken = res.data.listReviews.nextToken;
      let listing = res.data.listReviews.items;

      while (currentNextToken && listing.length < limit) {
        const allreviews: any = await API.graphql({
          // query: reviewsByConceptID,
          query: listReviews,
          variables: { ...myVariables, nextToken: currentNextToken },
          authMode: session
            ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            : GRAPHQL_AUTH_MODE.AWS_IAM,
        });
        listing = [...listing, ...allreviews.data.listReviews.items];
        currentNextToken = allreviews.data.listReviews.nextToken;
      }
      console.log("listing;", listing);
      // fetchwithLowestRates(params);
      return listing;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
      console.log(err);
    }
  };
  async function getReviewByID(params: GetVariables) {
    const { id } = params;
    try {
      const result: GraphQLResult<any> = await API.graphql({
        query: getReview,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });
      return result?.data?.getReview || null;
    } catch (err: Error | any) {
      showError(err.message || "Error fetching review");
    }
  }
  // async function fetchwithLowestRates(): Promise<{
  //   requireAction: { conceptID: string; averageRating: number }[];
  //   bestPerformance: { conceptID: string; averageRating: number }[];
  // }> {
  //   console.log("Fetching Concepts");

  //   try {
  //     const conceptData: any = await API.graphql({
  //       query: listConcepts,
  //       variables: {
  //         limit: 1000,
  //       },
  //       authMode: session
  //         ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
  //         : GRAPHQL_AUTH_MODE.AWS_IAM,
  //     });
  //     // const conceptData: any = await API.graphql({
  //     //   query: syncConcepts,
  //     //   variables: {
  //     //     filter: { deleted: { eq: "0" } },
  //     //     limit: 1000,
  //     //   },
  //     // });
  //     const concepts = conceptData.data.syncConcepts.items || [];
  //     console.log("conceptDatasaaaa", concepts);

  //     const requireAction: { conceptID: string; averageRating: number }[] = [];
  //     const bestPerformance: { conceptID: string; averageRating: number }[] =
  //       [];

  //     concepts.forEach((concept: any) => {
  //       const averageRating = Number(concept.averageRating);

  //       // Validate the averageRating is a number
  //       // if (isNaN(averageRating)) return;
  //       console.log("averageRating1233", concept.averageRating);

  //       if (averageRating <= 3) {
  //         requireAction.push({ conceptID: concept.id, averageRating });
  //       } else if (averageRating > 3) {
  //         bestPerformance.push({ conceptID: concept.id, averageRating });
  //       }
  //     });

  //     console.log("Best Performance:", bestPerformance);
  //     console.log("Require Action:", requireAction);

  //     return { requireAction, bestPerformance };
  //   } catch (err: Error | any) {
  //     showError(err.message);
  //     return { requireAction: [], bestPerformance: [] };
  //   }
  // }

  async function fetchwithLowestRates(params: ReviewListingVariables): Promise<{
    requireAction: { conceptID: string; reviews: ReviewListingVariables[] }[];
    bestPerformance: { conceptID: string; averageRating: number }[];
    lowRatingsCount: { [key: string]: number };
  }> {
    console.log("Fetch Review");
    const { limit, nextToken } = params;

    try {
      let reviews: any[] = [];
      // parentConceptID: { eq: selec }
      const filter: any = {
        deleted: { eq: "0" },
      };

      if (parentconceptsSelected)
        filter.parentConceptID = { eq: parentconceptsSelected };
      // else return;

      const reviewData: any = await API.graphql({
        query: listReviews,
        variables: {
          filter,
          limit: limit || 1000,
          nextToken,
        },
        // authMode: session
        // ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        // : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      reviews = reviewData?.data?.listReviews?.items || [];
      const groupedReviews: { [key: string]: ReviewListingVariables[] } = {};
      const lowRatingsCount: { [key: string]: number } = {};

      reviews.forEach((review) => {
        const { conceptID } = review;
        console.log("review;", review);
        if (!groupedReviews[conceptID]) {
          groupedReviews[conceptID] = [];
        }
        groupedReviews[conceptID].push(review);
        console.log("llcyy", conceptID);
        // Count low ratings (<= 3)
        if (Number(review.rate) <= 3) {
          lowRatingsCount[conceptID] = (lowRatingsCount[conceptID] || 0) + 1;
        }
      });

      const requireAction: {
        conceptID: string;
        reviews: ReviewListingVariables[];
      }[] = [];
      const bestPerformance: { conceptID: string; averageRating: number }[] =
        [];

      for (const conceptID in groupedReviews) {
        const reviewsGroup = groupedReviews[conceptID];
        console.log("reviewsGroup", reviewsGroup);
        // Ensure reviewsGroup is not empty
        if (reviewsGroup.length === 0) continue;

        const totalRate = reviewsGroup.reduce((sum, review) => {
          const rate = Number(review.rate);
          return !isNaN(rate) ? sum + rate : sum; // Only add valid numeric values
        }, 0);

        // Avoid division by zero, and ensure valid average rating
        const averageRating =
          reviewsGroup.length > 0 ? totalRate / reviewsGroup.length : 0;

        const lowRatingReviews = reviewsGroup.filter(
          (review) => Number(review.rate) <= 3
        );
        console.log("fffffff", averageRating, lowRatingReviews);
        if (
          averageRating <= 3 &&
          conceptID &&
          conceptID !== "0" &&
          conceptID !== "null"
        ) {
          requireAction.push({ conceptID, reviews: lowRatingReviews });
        } else if (conceptID && conceptID !== "0" && conceptID !== "null") {
          bestPerformance.push({ conceptID, averageRating });
        }
      }

      console.log("Best Performance Count:", bestPerformance);
      console.log("Require Action Count:", requireAction);
      // console.log("Low Ratings Count:", lowRatingsCount);
      localStorage.setItem("requireAction", JSON.stringify(requireAction));
      localStorage.setItem("bestPerformance", JSON.stringify(bestPerformance));
      localStorage.setItem("lowRatingsCount", JSON.stringify(lowRatingsCount));
      return { requireAction, bestPerformance, lowRatingsCount };
    } catch (err: Error | any) {
      showError(err.message);
      return { requireAction: [], bestPerformance: [], lowRatingsCount: {} };
    }
  }

  async function exportReviewsData(params: ReviewListingVariables) {
    const allReviews = await fetchAll(params);

    console.log({ allReviews });
    if (!allReviews || allReviews.length === 0) {
      console.error("No data available to export.");
      return;
    }

    const excelData = allReviews.map((review: Review) => ({
      createdByName: capitalizeSentence(review.createdByName),
      phoneUser: review.phoneUser,
      concept: review.conceptName,
      // parentConcept: parentconceptsGetName({
      //   id: parentconceptsListing,
      //   listing: review.parentConceptID,
      // }),
      rate: review.rate,
      feedback: review.feedback || "N/A",
      createdAt: new Date(review.createdAt).toLocaleString(),
    }));

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Reviews");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;",
    });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "ReviewsData.xlsx";
    link.click();
  }

  const api: any = {};
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Export`] = exportReviewsData;
  api[`${listingName}FetchwithLowestRates`] = fetchwithLowestRates;
  api[`${listingName}Get`] = getReviewByID;

  return api;
};
export default useReviews;
