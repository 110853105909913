/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../models/GQL_API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateUser = /* GraphQL */ `subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
  onCreateUser(filter: $filter) {
    id
    accountID
    username
    preferred_username
    email
    email_verified
    phone_number
    phone_number_verified
    name
    nickname
    given_name
    middle_name
    family_name
    address
    birthdate
    gender
    locale
    picture
    pictureCrop
    website
    zoneinfo
    verification_code
    facebook
    instagram
    group
    phones
    flags
    flagsName
    interests
    interestsName
    status
    stats
    spending
    prevOrderDate
    lastOrderDate
    promocodes {
      name
      promocodeId
      canBeUsed
      discountVal
      discountLimit
      isDisplayed
      __typename
    }
    mostOrderedItems
    points
    deleted
    createdAt
    createdByID
    createdByName
    cognitoUsername
    cognitoSub
    autoGeneratedPassword
    updated
    sub
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserSubscriptionVariables,
  APITypes.OnCreateUserSubscription
>;
export const onUpdateUser = /* GraphQL */ `subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
  onUpdateUser(filter: $filter) {
    id
    accountID
    username
    preferred_username
    email
    email_verified
    phone_number
    phone_number_verified
    name
    nickname
    given_name
    middle_name
    family_name
    address
    birthdate
    gender
    locale
    picture
    pictureCrop
    website
    zoneinfo
    verification_code
    facebook
    instagram
    group
    phones
    flags
    flagsName
    interests
    interestsName
    status
    stats
    spending
    prevOrderDate
    lastOrderDate
    promocodes {
      name
      promocodeId
      canBeUsed
      discountVal
      discountLimit
      isDisplayed
      __typename
    }
    mostOrderedItems
    points
    deleted
    createdAt
    createdByID
    createdByName
    cognitoUsername
    cognitoSub
    autoGeneratedPassword
    updated
    sub
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserSubscriptionVariables,
  APITypes.OnUpdateUserSubscription
>;
export const onDeleteUser = /* GraphQL */ `subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
  onDeleteUser(filter: $filter) {
    id
    accountID
    username
    preferred_username
    email
    email_verified
    phone_number
    phone_number_verified
    name
    nickname
    given_name
    middle_name
    family_name
    address
    birthdate
    gender
    locale
    picture
    pictureCrop
    website
    zoneinfo
    verification_code
    facebook
    instagram
    group
    phones
    flags
    flagsName
    interests
    interestsName
    status
    stats
    spending
    prevOrderDate
    lastOrderDate
    promocodes {
      name
      promocodeId
      canBeUsed
      discountVal
      discountLimit
      isDisplayed
      __typename
    }
    mostOrderedItems
    points
    deleted
    createdAt
    createdByID
    createdByName
    cognitoUsername
    cognitoSub
    autoGeneratedPassword
    updated
    sub
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserSubscriptionVariables,
  APITypes.OnDeleteUserSubscription
>;
export const onCreateReview = /* GraphQL */ `subscription OnCreateReview($filter: ModelSubscriptionReviewFilterInput) {
  onCreateReview(filter: $filter) {
    id
    conceptID
    conceptName
    accountID
    bookingID
    parentConceptID
    guestName
    guestId
    reason
    value
    userID
    orderID
    rate
    feedback
    satisfaction
    thoughts
    itemReviews {
      itemID
      rate
      satisfaction
      feedback
      multiLanguages
      __typename
    }
    photos
    multiLanguages
    deleted
    createdAt
    createdByID
    createdByName
    phoneUser
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateReviewSubscriptionVariables,
  APITypes.OnCreateReviewSubscription
>;
export const onUpdateReview = /* GraphQL */ `subscription OnUpdateReview($filter: ModelSubscriptionReviewFilterInput) {
  onUpdateReview(filter: $filter) {
    id
    conceptID
    conceptName
    accountID
    bookingID
    parentConceptID
    guestName
    guestId
    reason
    value
    userID
    orderID
    rate
    feedback
    satisfaction
    thoughts
    itemReviews {
      itemID
      rate
      satisfaction
      feedback
      multiLanguages
      __typename
    }
    photos
    multiLanguages
    deleted
    createdAt
    createdByID
    createdByName
    phoneUser
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateReviewSubscriptionVariables,
  APITypes.OnUpdateReviewSubscription
>;
export const onDeleteReview = /* GraphQL */ `subscription OnDeleteReview($filter: ModelSubscriptionReviewFilterInput) {
  onDeleteReview(filter: $filter) {
    id
    conceptID
    conceptName
    accountID
    bookingID
    parentConceptID
    guestName
    guestId
    reason
    value
    userID
    orderID
    rate
    feedback
    satisfaction
    thoughts
    itemReviews {
      itemID
      rate
      satisfaction
      feedback
      multiLanguages
      __typename
    }
    photos
    multiLanguages
    deleted
    createdAt
    createdByID
    createdByName
    phoneUser
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteReviewSubscriptionVariables,
  APITypes.OnDeleteReviewSubscription
>;
export const onCreateAccount = /* GraphQL */ `subscription OnCreateAccount($filter: ModelSubscriptionAccountFilterInput) {
  onCreateAccount(filter: $filter) {
    id
    logo
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    callCenterEmployeesIds
    kpiReportResetFrequency
    agentBarResetFrequency
    dynamicStatus
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAccountSubscriptionVariables,
  APITypes.OnCreateAccountSubscription
>;
export const onUpdateAccount = /* GraphQL */ `subscription OnUpdateAccount($filter: ModelSubscriptionAccountFilterInput) {
  onUpdateAccount(filter: $filter) {
    id
    logo
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    callCenterEmployeesIds
    kpiReportResetFrequency
    agentBarResetFrequency
    dynamicStatus
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAccountSubscriptionVariables,
  APITypes.OnUpdateAccountSubscription
>;
export const onDeleteAccount = /* GraphQL */ `subscription OnDeleteAccount($filter: ModelSubscriptionAccountFilterInput) {
  onDeleteAccount(filter: $filter) {
    id
    logo
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    callCenterEmployeesIds
    kpiReportResetFrequency
    agentBarResetFrequency
    dynamicStatus
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAccountSubscriptionVariables,
  APITypes.OnDeleteAccountSubscription
>;
export const onCreateFeature = /* GraphQL */ `subscription OnCreateFeature($filter: ModelSubscriptionFeatureFilterInput) {
  onCreateFeature(filter: $filter) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateFeatureSubscriptionVariables,
  APITypes.OnCreateFeatureSubscription
>;
export const onUpdateFeature = /* GraphQL */ `subscription OnUpdateFeature($filter: ModelSubscriptionFeatureFilterInput) {
  onUpdateFeature(filter: $filter) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateFeatureSubscriptionVariables,
  APITypes.OnUpdateFeatureSubscription
>;
export const onDeleteFeature = /* GraphQL */ `subscription OnDeleteFeature($filter: ModelSubscriptionFeatureFilterInput) {
  onDeleteFeature(filter: $filter) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteFeatureSubscriptionVariables,
  APITypes.OnDeleteFeatureSubscription
>;
export const onCreateMedia = /* GraphQL */ `subscription OnCreateMedia($filter: ModelSubscriptionMediaFilterInput) {
  onCreateMedia(filter: $filter) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMediaSubscriptionVariables,
  APITypes.OnCreateMediaSubscription
>;
export const onUpdateMedia = /* GraphQL */ `subscription OnUpdateMedia($filter: ModelSubscriptionMediaFilterInput) {
  onUpdateMedia(filter: $filter) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMediaSubscriptionVariables,
  APITypes.OnUpdateMediaSubscription
>;
export const onDeleteMedia = /* GraphQL */ `subscription OnDeleteMedia($filter: ModelSubscriptionMediaFilterInput) {
  onDeleteMedia(filter: $filter) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMediaSubscriptionVariables,
  APITypes.OnDeleteMediaSubscription
>;
export const onCreateConcept = /* GraphQL */ `subscription OnCreateConcept($filter: ModelSubscriptionConceptFilterInput) {
  onCreateConcept(filter: $filter) {
    id
    accountID
    name
    kiosks
    merchantID
    merchantUsername
    merchantPassword
    merchantIntegrationNumber
    merchantAPIKey
    paymentSharePercentage
    simphonyServerBaseURL
    simphonyShortOrgName
    simphonyLocRef
    simphonyToken
    revenueCenterID
    employeeID
    tenderID
    tenders {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptTendersId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    orderType {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptOrderTypeId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    discountID
    useServiceCharge
    serviceChargeId
    specialRequestID
    addressID
    ratingCount
    averageRating
    feesback
    simphonyAuthClientId
    simphonyAuthUsername
    simphonyAuthPassword
    simphonyAuthBaseURL
    orderTypeDineIn
    orderTypeTakeAway
    orderTypeDelivery
    simphonyTakeAwayRvc
    simphonyDeliveryRvc
    exportInterval
    deleted
    createdAt
    createdByID
    createdByName
    foodicsToken
    enableTableFoodics
    image
    logo
    album
    type
    rating
    location
    description
    areas
    seatTypes
    needAssistance
    spaBooking
    showerBooking
    cigarMenu
    precedence
    isPinRequired
    viewOnly
    openTime
    closeTime
    varifyForAmount
    varifyFirstOrder
    varifyNewAddress
    sliderImages
    receiveWaiters
    WaiterNotificationOption
    applyLoyalty
    rewardPoints
    moneySpent
    afterVat
    afterDeliveryFee
    beforePromoCode
    pointsCalcWithPromoCode
    isBusy
    allowOnlineOrdering
    minOrderVal
    vatPercentage
    serviceChargePercentage
    addDeliveryToVat
    categoryTagIDs
    parentConceptID
    containerColor
    avgOrderCompletionEstimate
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateConceptSubscriptionVariables,
  APITypes.OnCreateConceptSubscription
>;
export const onUpdateConcept = /* GraphQL */ `subscription OnUpdateConcept($filter: ModelSubscriptionConceptFilterInput) {
  onUpdateConcept(filter: $filter) {
    id
    accountID
    name
    kiosks
    merchantID
    merchantUsername
    merchantPassword
    merchantIntegrationNumber
    merchantAPIKey
    paymentSharePercentage
    simphonyServerBaseURL
    simphonyShortOrgName
    simphonyLocRef
    simphonyToken
    revenueCenterID
    employeeID
    tenderID
    tenders {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptTendersId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    orderType {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptOrderTypeId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    discountID
    useServiceCharge
    serviceChargeId
    specialRequestID
    addressID
    ratingCount
    averageRating
    feesback
    simphonyAuthClientId
    simphonyAuthUsername
    simphonyAuthPassword
    simphonyAuthBaseURL
    orderTypeDineIn
    orderTypeTakeAway
    orderTypeDelivery
    simphonyTakeAwayRvc
    simphonyDeliveryRvc
    exportInterval
    deleted
    createdAt
    createdByID
    createdByName
    foodicsToken
    enableTableFoodics
    image
    logo
    album
    type
    rating
    location
    description
    areas
    seatTypes
    needAssistance
    spaBooking
    showerBooking
    cigarMenu
    precedence
    isPinRequired
    viewOnly
    openTime
    closeTime
    varifyForAmount
    varifyFirstOrder
    varifyNewAddress
    sliderImages
    receiveWaiters
    WaiterNotificationOption
    applyLoyalty
    rewardPoints
    moneySpent
    afterVat
    afterDeliveryFee
    beforePromoCode
    pointsCalcWithPromoCode
    isBusy
    allowOnlineOrdering
    minOrderVal
    vatPercentage
    serviceChargePercentage
    addDeliveryToVat
    categoryTagIDs
    parentConceptID
    containerColor
    avgOrderCompletionEstimate
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateConceptSubscriptionVariables,
  APITypes.OnUpdateConceptSubscription
>;
export const onDeleteConcept = /* GraphQL */ `subscription OnDeleteConcept($filter: ModelSubscriptionConceptFilterInput) {
  onDeleteConcept(filter: $filter) {
    id
    accountID
    name
    kiosks
    merchantID
    merchantUsername
    merchantPassword
    merchantIntegrationNumber
    merchantAPIKey
    paymentSharePercentage
    simphonyServerBaseURL
    simphonyShortOrgName
    simphonyLocRef
    simphonyToken
    revenueCenterID
    employeeID
    tenderID
    tenders {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptTendersId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    orderType {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptOrderTypeId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    discountID
    useServiceCharge
    serviceChargeId
    specialRequestID
    addressID
    ratingCount
    averageRating
    feesback
    simphonyAuthClientId
    simphonyAuthUsername
    simphonyAuthPassword
    simphonyAuthBaseURL
    orderTypeDineIn
    orderTypeTakeAway
    orderTypeDelivery
    simphonyTakeAwayRvc
    simphonyDeliveryRvc
    exportInterval
    deleted
    createdAt
    createdByID
    createdByName
    foodicsToken
    enableTableFoodics
    image
    logo
    album
    type
    rating
    location
    description
    areas
    seatTypes
    needAssistance
    spaBooking
    showerBooking
    cigarMenu
    precedence
    isPinRequired
    viewOnly
    openTime
    closeTime
    varifyForAmount
    varifyFirstOrder
    varifyNewAddress
    sliderImages
    receiveWaiters
    WaiterNotificationOption
    applyLoyalty
    rewardPoints
    moneySpent
    afterVat
    afterDeliveryFee
    beforePromoCode
    pointsCalcWithPromoCode
    isBusy
    allowOnlineOrdering
    minOrderVal
    vatPercentage
    serviceChargePercentage
    addDeliveryToVat
    categoryTagIDs
    parentConceptID
    containerColor
    avgOrderCompletionEstimate
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteConceptSubscriptionVariables,
  APITypes.OnDeleteConceptSubscription
>;
export const onCreateCategoryTag = /* GraphQL */ `subscription OnCreateCategoryTag(
  $filter: ModelSubscriptionCategoryTagFilterInput
) {
  onCreateCategoryTag(filter: $filter) {
    id
    parentConceptID
    name
    image
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCategoryTagSubscriptionVariables,
  APITypes.OnCreateCategoryTagSubscription
>;
export const onUpdateCategoryTag = /* GraphQL */ `subscription OnUpdateCategoryTag(
  $filter: ModelSubscriptionCategoryTagFilterInput
) {
  onUpdateCategoryTag(filter: $filter) {
    id
    parentConceptID
    name
    image
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCategoryTagSubscriptionVariables,
  APITypes.OnUpdateCategoryTagSubscription
>;
export const onDeleteCategoryTag = /* GraphQL */ `subscription OnDeleteCategoryTag(
  $filter: ModelSubscriptionCategoryTagFilterInput
) {
  onDeleteCategoryTag(filter: $filter) {
    id
    parentConceptID
    name
    image
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCategoryTagSubscriptionVariables,
  APITypes.OnDeleteCategoryTagSubscription
>;
export const onCreateTender = /* GraphQL */ `subscription OnCreateTender($filter: ModelSubscriptionTenderFilterInput) {
  onCreateTender(filter: $filter) {
    id
    name
    simphonyID
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conceptTendersId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTenderSubscriptionVariables,
  APITypes.OnCreateTenderSubscription
>;
export const onUpdateTender = /* GraphQL */ `subscription OnUpdateTender($filter: ModelSubscriptionTenderFilterInput) {
  onUpdateTender(filter: $filter) {
    id
    name
    simphonyID
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conceptTendersId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTenderSubscriptionVariables,
  APITypes.OnUpdateTenderSubscription
>;
export const onDeleteTender = /* GraphQL */ `subscription OnDeleteTender($filter: ModelSubscriptionTenderFilterInput) {
  onDeleteTender(filter: $filter) {
    id
    name
    simphonyID
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conceptTendersId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTenderSubscriptionVariables,
  APITypes.OnDeleteTenderSubscription
>;
export const onCreateOrderType = /* GraphQL */ `subscription OnCreateOrderType($filter: ModelSubscriptionOrderTypeFilterInput) {
  onCreateOrderType(filter: $filter) {
    id
    name
    simphonyID
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conceptOrderTypeId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderTypeSubscriptionVariables,
  APITypes.OnCreateOrderTypeSubscription
>;
export const onUpdateOrderType = /* GraphQL */ `subscription OnUpdateOrderType($filter: ModelSubscriptionOrderTypeFilterInput) {
  onUpdateOrderType(filter: $filter) {
    id
    name
    simphonyID
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conceptOrderTypeId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderTypeSubscriptionVariables,
  APITypes.OnUpdateOrderTypeSubscription
>;
export const onDeleteOrderType = /* GraphQL */ `subscription OnDeleteOrderType($filter: ModelSubscriptionOrderTypeFilterInput) {
  onDeleteOrderType(filter: $filter) {
    id
    name
    simphonyID
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conceptOrderTypeId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderTypeSubscriptionVariables,
  APITypes.OnDeleteOrderTypeSubscription
>;
export const onCreateParentConcept = /* GraphQL */ `subscription OnCreateParentConcept(
  $filter: ModelSubscriptionParentConceptFilterInput
) {
  onCreateParentConcept(filter: $filter) {
    id
    deleted
    createdAt
    createdByID
    createdByName
    image
    name
    logo
    type
    venues {
      items {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        paymentSharePercentage
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          nextToken
          startedAt
          __typename
        }
        orderType {
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        ratingCount
        averageRating
        feesback
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        categoryTagIDs
        parentConceptID
        containerColor
        avgOrderCompletionEstimate
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    index
    categoryTags {
      items {
        id
        parentConceptID
        name
        image
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateParentConceptSubscriptionVariables,
  APITypes.OnCreateParentConceptSubscription
>;
export const onUpdateParentConcept = /* GraphQL */ `subscription OnUpdateParentConcept(
  $filter: ModelSubscriptionParentConceptFilterInput
) {
  onUpdateParentConcept(filter: $filter) {
    id
    deleted
    createdAt
    createdByID
    createdByName
    image
    name
    logo
    type
    venues {
      items {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        paymentSharePercentage
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          nextToken
          startedAt
          __typename
        }
        orderType {
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        ratingCount
        averageRating
        feesback
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        categoryTagIDs
        parentConceptID
        containerColor
        avgOrderCompletionEstimate
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    index
    categoryTags {
      items {
        id
        parentConceptID
        name
        image
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateParentConceptSubscriptionVariables,
  APITypes.OnUpdateParentConceptSubscription
>;
export const onDeleteParentConcept = /* GraphQL */ `subscription OnDeleteParentConcept(
  $filter: ModelSubscriptionParentConceptFilterInput
) {
  onDeleteParentConcept(filter: $filter) {
    id
    deleted
    createdAt
    createdByID
    createdByName
    image
    name
    logo
    type
    venues {
      items {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        paymentSharePercentage
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          nextToken
          startedAt
          __typename
        }
        orderType {
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        ratingCount
        averageRating
        feesback
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        categoryTagIDs
        parentConceptID
        containerColor
        avgOrderCompletionEstimate
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    index
    categoryTags {
      items {
        id
        parentConceptID
        name
        image
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteParentConceptSubscriptionVariables,
  APITypes.OnDeleteParentConceptSubscription
>;
export const onCreateTimeline = /* GraphQL */ `subscription OnCreateTimeline($filter: ModelSubscriptionTimelineFilterInput) {
  onCreateTimeline(filter: $filter) {
    id
    actionName
    oldStatus
    newStatus
    bookingId
    transactionId
    customerId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTimelineSubscriptionVariables,
  APITypes.OnCreateTimelineSubscription
>;
export const onUpdateTimeline = /* GraphQL */ `subscription OnUpdateTimeline($filter: ModelSubscriptionTimelineFilterInput) {
  onUpdateTimeline(filter: $filter) {
    id
    actionName
    oldStatus
    newStatus
    bookingId
    transactionId
    customerId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTimelineSubscriptionVariables,
  APITypes.OnUpdateTimelineSubscription
>;
export const onDeleteTimeline = /* GraphQL */ `subscription OnDeleteTimeline($filter: ModelSubscriptionTimelineFilterInput) {
  onDeleteTimeline(filter: $filter) {
    id
    actionName
    oldStatus
    newStatus
    bookingId
    transactionId
    customerId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTimelineSubscriptionVariables,
  APITypes.OnDeleteTimelineSubscription
>;
export const onCreateCall = /* GraphQL */ `subscription OnCreateCall($filter: ModelSubscriptionCallFilterInput) {
  onCreateCall(filter: $filter) {
    id
    phone_number
    customer {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    createdByID
    createdByName
    endedAt
    duration
    date
    employeeId
    updatedAt
    _version
    _deleted
    _lastChangedAt
    callCustomerId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCallSubscriptionVariables,
  APITypes.OnCreateCallSubscription
>;
export const onUpdateCall = /* GraphQL */ `subscription OnUpdateCall($filter: ModelSubscriptionCallFilterInput) {
  onUpdateCall(filter: $filter) {
    id
    phone_number
    customer {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    createdByID
    createdByName
    endedAt
    duration
    date
    employeeId
    updatedAt
    _version
    _deleted
    _lastChangedAt
    callCustomerId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCallSubscriptionVariables,
  APITypes.OnUpdateCallSubscription
>;
export const onDeleteCall = /* GraphQL */ `subscription OnDeleteCall($filter: ModelSubscriptionCallFilterInput) {
  onDeleteCall(filter: $filter) {
    id
    phone_number
    customer {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    createdByID
    createdByName
    endedAt
    duration
    date
    employeeId
    updatedAt
    _version
    _deleted
    _lastChangedAt
    callCustomerId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCallSubscriptionVariables,
  APITypes.OnDeleteCallSubscription
>;
export const onCreateAdminRole = /* GraphQL */ `subscription OnCreateAdminRole($filter: ModelSubscriptionAdminRoleFilterInput) {
  onCreateAdminRole(filter: $filter) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAdminRoleSubscriptionVariables,
  APITypes.OnCreateAdminRoleSubscription
>;
export const onUpdateAdminRole = /* GraphQL */ `subscription OnUpdateAdminRole($filter: ModelSubscriptionAdminRoleFilterInput) {
  onUpdateAdminRole(filter: $filter) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAdminRoleSubscriptionVariables,
  APITypes.OnUpdateAdminRoleSubscription
>;
export const onDeleteAdminRole = /* GraphQL */ `subscription OnDeleteAdminRole($filter: ModelSubscriptionAdminRoleFilterInput) {
  onDeleteAdminRole(filter: $filter) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAdminRoleSubscriptionVariables,
  APITypes.OnDeleteAdminRoleSubscription
>;
export const onCreateAdminGroup = /* GraphQL */ `subscription OnCreateAdminGroup(
  $filter: ModelSubscriptionAdminGroupFilterInput
) {
  onCreateAdminGroup(filter: $filter) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAdminGroupSubscriptionVariables,
  APITypes.OnCreateAdminGroupSubscription
>;
export const onUpdateAdminGroup = /* GraphQL */ `subscription OnUpdateAdminGroup(
  $filter: ModelSubscriptionAdminGroupFilterInput
) {
  onUpdateAdminGroup(filter: $filter) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAdminGroupSubscriptionVariables,
  APITypes.OnUpdateAdminGroupSubscription
>;
export const onDeleteAdminGroup = /* GraphQL */ `subscription OnDeleteAdminGroup(
  $filter: ModelSubscriptionAdminGroupFilterInput
) {
  onDeleteAdminGroup(filter: $filter) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAdminGroupSubscriptionVariables,
  APITypes.OnDeleteAdminGroupSubscription
>;
export const onCreateUserConcepts = /* GraphQL */ `subscription OnCreateUserConcepts(
  $filter: ModelSubscriptionUserConceptsFilterInput
) {
  onCreateUserConcepts(filter: $filter) {
    id
    defaultConcept
    concepts
    parentConcepts
    conceptsRoles
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserConceptsSubscriptionVariables,
  APITypes.OnCreateUserConceptsSubscription
>;
export const onUpdateUserConcepts = /* GraphQL */ `subscription OnUpdateUserConcepts(
  $filter: ModelSubscriptionUserConceptsFilterInput
) {
  onUpdateUserConcepts(filter: $filter) {
    id
    defaultConcept
    concepts
    parentConcepts
    conceptsRoles
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserConceptsSubscriptionVariables,
  APITypes.OnUpdateUserConceptsSubscription
>;
export const onDeleteUserConcepts = /* GraphQL */ `subscription OnDeleteUserConcepts(
  $filter: ModelSubscriptionUserConceptsFilterInput
) {
  onDeleteUserConcepts(filter: $filter) {
    id
    defaultConcept
    concepts
    parentConcepts
    conceptsRoles
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserConceptsSubscriptionVariables,
  APITypes.OnDeleteUserConceptsSubscription
>;
export const onCreateTransaction = /* GraphQL */ `subscription OnCreateTransaction(
  $filter: ModelSubscriptionTransactionFilterInput
) {
  onCreateTransaction(filter: $filter) {
    id
    transactionID
    date
    guestName
    guestPhone
    guestID
    guestGroup
    guestsNames
    bookingID
    bookingDate
    amount_cents
    status
    failureReason
    type
    timeSlots
    tables
    conceptID
    currency
    refund
    reference_orderID
    ownerID
    refunded_amount_cents
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateTransactionSubscriptionVariables,
  APITypes.OnCreateTransactionSubscription
>;
export const onUpdateTransaction = /* GraphQL */ `subscription OnUpdateTransaction(
  $filter: ModelSubscriptionTransactionFilterInput
) {
  onUpdateTransaction(filter: $filter) {
    id
    transactionID
    date
    guestName
    guestPhone
    guestID
    guestGroup
    guestsNames
    bookingID
    bookingDate
    amount_cents
    status
    failureReason
    type
    timeSlots
    tables
    conceptID
    currency
    refund
    reference_orderID
    ownerID
    refunded_amount_cents
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateTransactionSubscriptionVariables,
  APITypes.OnUpdateTransactionSubscription
>;
export const onDeleteTransaction = /* GraphQL */ `subscription OnDeleteTransaction(
  $filter: ModelSubscriptionTransactionFilterInput
) {
  onDeleteTransaction(filter: $filter) {
    id
    transactionID
    date
    guestName
    guestPhone
    guestID
    guestGroup
    guestsNames
    bookingID
    bookingDate
    amount_cents
    status
    failureReason
    type
    timeSlots
    tables
    conceptID
    currency
    refund
    reference_orderID
    ownerID
    refunded_amount_cents
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteTransactionSubscriptionVariables,
  APITypes.OnDeleteTransactionSubscription
>;
export const onCreatePaymentStat = /* GraphQL */ `subscription OnCreatePaymentStat(
  $filter: ModelSubscriptionPaymentStatFilterInput
) {
  onCreatePaymentStat(filter: $filter) {
    id
    accountID
    conceptID
    year
    month
    monthName
    earning
    refund
    earningTrxCount
    refundTrxCount
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePaymentStatSubscriptionVariables,
  APITypes.OnCreatePaymentStatSubscription
>;
export const onUpdatePaymentStat = /* GraphQL */ `subscription OnUpdatePaymentStat(
  $filter: ModelSubscriptionPaymentStatFilterInput
) {
  onUpdatePaymentStat(filter: $filter) {
    id
    accountID
    conceptID
    year
    month
    monthName
    earning
    refund
    earningTrxCount
    refundTrxCount
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePaymentStatSubscriptionVariables,
  APITypes.OnUpdatePaymentStatSubscription
>;
export const onDeletePaymentStat = /* GraphQL */ `subscription OnDeletePaymentStat(
  $filter: ModelSubscriptionPaymentStatFilterInput
) {
  onDeletePaymentStat(filter: $filter) {
    id
    accountID
    conceptID
    year
    month
    monthName
    earning
    refund
    earningTrxCount
    refundTrxCount
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePaymentStatSubscriptionVariables,
  APITypes.OnDeletePaymentStatSubscription
>;
export const onCreateCategory = /* GraphQL */ `subscription OnCreateCategory($filter: ModelSubscriptionCategoryFilterInput) {
  onCreateCategory(filter: $filter) {
    id
    conceptID
    parentConceptID
    preparationAreaID
    subCategory
    name
    guestView
    guestOrder
    staffOrder
    image
    darkImage
    menuItems {
      items {
        id
        conceptID
        kioskID
        name
        description
        image
        precedence
        categoryID
        categoryName
        outOfStock
        requiredPoints
        symphonyID
        comboItemID
        ratings
        prices
        choiceGroups
        pricesItems {
          id
          conceptID
          parentConceptID
          name
          multiLanguages
          price
          symphonyID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsItems {
          id
          conceptID
          parentConceptID
          name
          required
          maxNumberOfChoices
          minNumberOfChoices
          precedence
          type
          choices
          deleted
          createdAt
          createdByID
          createdByName
          multiLanguages
          simphonyID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsRules {
          id
          minNumberOfChoices
          maxNumberOfChoices
          __typename
        }
        ExtraChoiceGroups
        enabled
        hasLimitedQuantity
        quantity
        autoRestock
        restockQuantity
        deleted
        createdAt
        createdByID
        createdByName
        quantityOrdered
        performancePerDay
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    symphonyID
    isHidden
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCategorySubscriptionVariables,
  APITypes.OnCreateCategorySubscription
>;
export const onUpdateCategory = /* GraphQL */ `subscription OnUpdateCategory($filter: ModelSubscriptionCategoryFilterInput) {
  onUpdateCategory(filter: $filter) {
    id
    conceptID
    parentConceptID
    preparationAreaID
    subCategory
    name
    guestView
    guestOrder
    staffOrder
    image
    darkImage
    menuItems {
      items {
        id
        conceptID
        kioskID
        name
        description
        image
        precedence
        categoryID
        categoryName
        outOfStock
        requiredPoints
        symphonyID
        comboItemID
        ratings
        prices
        choiceGroups
        pricesItems {
          id
          conceptID
          parentConceptID
          name
          multiLanguages
          price
          symphonyID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsItems {
          id
          conceptID
          parentConceptID
          name
          required
          maxNumberOfChoices
          minNumberOfChoices
          precedence
          type
          choices
          deleted
          createdAt
          createdByID
          createdByName
          multiLanguages
          simphonyID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsRules {
          id
          minNumberOfChoices
          maxNumberOfChoices
          __typename
        }
        ExtraChoiceGroups
        enabled
        hasLimitedQuantity
        quantity
        autoRestock
        restockQuantity
        deleted
        createdAt
        createdByID
        createdByName
        quantityOrdered
        performancePerDay
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    symphonyID
    isHidden
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCategorySubscriptionVariables,
  APITypes.OnUpdateCategorySubscription
>;
export const onDeleteCategory = /* GraphQL */ `subscription OnDeleteCategory($filter: ModelSubscriptionCategoryFilterInput) {
  onDeleteCategory(filter: $filter) {
    id
    conceptID
    parentConceptID
    preparationAreaID
    subCategory
    name
    guestView
    guestOrder
    staffOrder
    image
    darkImage
    menuItems {
      items {
        id
        conceptID
        kioskID
        name
        description
        image
        precedence
        categoryID
        categoryName
        outOfStock
        requiredPoints
        symphonyID
        comboItemID
        ratings
        prices
        choiceGroups
        pricesItems {
          id
          conceptID
          parentConceptID
          name
          multiLanguages
          price
          symphonyID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsItems {
          id
          conceptID
          parentConceptID
          name
          required
          maxNumberOfChoices
          minNumberOfChoices
          precedence
          type
          choices
          deleted
          createdAt
          createdByID
          createdByName
          multiLanguages
          simphonyID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsRules {
          id
          minNumberOfChoices
          maxNumberOfChoices
          __typename
        }
        ExtraChoiceGroups
        enabled
        hasLimitedQuantity
        quantity
        autoRestock
        restockQuantity
        deleted
        createdAt
        createdByID
        createdByName
        quantityOrdered
        performancePerDay
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    symphonyID
    isHidden
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCategorySubscriptionVariables,
  APITypes.OnDeleteCategorySubscription
>;
export const onCreateMenuItem = /* GraphQL */ `subscription OnCreateMenuItem($filter: ModelSubscriptionMenuItemFilterInput) {
  onCreateMenuItem(filter: $filter) {
    id
    conceptID
    kioskID
    name
    description
    image
    precedence
    categoryID
    categoryName
    outOfStock
    requiredPoints
    symphonyID
    comboItemID
    ratings
    prices
    choiceGroups
    pricesItems {
      id
      conceptID
      parentConceptID
      name
      multiLanguages
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    choiceGroupsItems {
      id
      conceptID
      parentConceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      choiceItems {
        items {
          id
          conceptID
          name
          price
          precedence
          choiceGroupID
          choiceGroupName
          deleted
          createdAt
          createdByID
          createdByName
          symphonyID
          multiLanguages
          definitionNumber
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      simphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    choiceGroupsRules {
      id
      minNumberOfChoices
      maxNumberOfChoices
      __typename
    }
    ExtraChoiceGroups
    enabled
    hasLimitedQuantity
    quantity
    autoRestock
    restockQuantity
    deleted
    createdAt
    createdByID
    createdByName
    quantityOrdered
    performancePerDay
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMenuItemSubscriptionVariables,
  APITypes.OnCreateMenuItemSubscription
>;
export const onUpdateMenuItem = /* GraphQL */ `subscription OnUpdateMenuItem($filter: ModelSubscriptionMenuItemFilterInput) {
  onUpdateMenuItem(filter: $filter) {
    id
    conceptID
    kioskID
    name
    description
    image
    precedence
    categoryID
    categoryName
    outOfStock
    requiredPoints
    symphonyID
    comboItemID
    ratings
    prices
    choiceGroups
    pricesItems {
      id
      conceptID
      parentConceptID
      name
      multiLanguages
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    choiceGroupsItems {
      id
      conceptID
      parentConceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      choiceItems {
        items {
          id
          conceptID
          name
          price
          precedence
          choiceGroupID
          choiceGroupName
          deleted
          createdAt
          createdByID
          createdByName
          symphonyID
          multiLanguages
          definitionNumber
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      simphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    choiceGroupsRules {
      id
      minNumberOfChoices
      maxNumberOfChoices
      __typename
    }
    ExtraChoiceGroups
    enabled
    hasLimitedQuantity
    quantity
    autoRestock
    restockQuantity
    deleted
    createdAt
    createdByID
    createdByName
    quantityOrdered
    performancePerDay
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMenuItemSubscriptionVariables,
  APITypes.OnUpdateMenuItemSubscription
>;
export const onDeleteMenuItem = /* GraphQL */ `subscription OnDeleteMenuItem($filter: ModelSubscriptionMenuItemFilterInput) {
  onDeleteMenuItem(filter: $filter) {
    id
    conceptID
    kioskID
    name
    description
    image
    precedence
    categoryID
    categoryName
    outOfStock
    requiredPoints
    symphonyID
    comboItemID
    ratings
    prices
    choiceGroups
    pricesItems {
      id
      conceptID
      parentConceptID
      name
      multiLanguages
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    choiceGroupsItems {
      id
      conceptID
      parentConceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      choiceItems {
        items {
          id
          conceptID
          name
          price
          precedence
          choiceGroupID
          choiceGroupName
          deleted
          createdAt
          createdByID
          createdByName
          symphonyID
          multiLanguages
          definitionNumber
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      simphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    choiceGroupsRules {
      id
      minNumberOfChoices
      maxNumberOfChoices
      __typename
    }
    ExtraChoiceGroups
    enabled
    hasLimitedQuantity
    quantity
    autoRestock
    restockQuantity
    deleted
    createdAt
    createdByID
    createdByName
    quantityOrdered
    performancePerDay
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMenuItemSubscriptionVariables,
  APITypes.OnDeleteMenuItemSubscription
>;
export const onCreatePrice = /* GraphQL */ `subscription OnCreatePrice($filter: ModelSubscriptionPriceFilterInput) {
  onCreatePrice(filter: $filter) {
    id
    conceptID
    parentConceptID
    name
    multiLanguages
    price
    symphonyID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePriceSubscriptionVariables,
  APITypes.OnCreatePriceSubscription
>;
export const onUpdatePrice = /* GraphQL */ `subscription OnUpdatePrice($filter: ModelSubscriptionPriceFilterInput) {
  onUpdatePrice(filter: $filter) {
    id
    conceptID
    parentConceptID
    name
    multiLanguages
    price
    symphonyID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePriceSubscriptionVariables,
  APITypes.OnUpdatePriceSubscription
>;
export const onDeletePrice = /* GraphQL */ `subscription OnDeletePrice($filter: ModelSubscriptionPriceFilterInput) {
  onDeletePrice(filter: $filter) {
    id
    conceptID
    parentConceptID
    name
    multiLanguages
    price
    symphonyID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePriceSubscriptionVariables,
  APITypes.OnDeletePriceSubscription
>;
export const onCreateChoiceGroups = /* GraphQL */ `subscription OnCreateChoiceGroups(
  $filter: ModelSubscriptionChoiceGroupsFilterInput
) {
  onCreateChoiceGroups(filter: $filter) {
    id
    conceptID
    parentConceptID
    name
    required
    maxNumberOfChoices
    minNumberOfChoices
    precedence
    type
    choices
    choiceItems {
      items {
        id
        conceptID
        name
        price
        precedence
        choiceGroupID
        choiceGroupName
        deleted
        createdAt
        createdByID
        createdByName
        symphonyID
        multiLanguages
        definitionNumber
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    simphonyID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateChoiceGroupsSubscriptionVariables,
  APITypes.OnCreateChoiceGroupsSubscription
>;
export const onUpdateChoiceGroups = /* GraphQL */ `subscription OnUpdateChoiceGroups(
  $filter: ModelSubscriptionChoiceGroupsFilterInput
) {
  onUpdateChoiceGroups(filter: $filter) {
    id
    conceptID
    parentConceptID
    name
    required
    maxNumberOfChoices
    minNumberOfChoices
    precedence
    type
    choices
    choiceItems {
      items {
        id
        conceptID
        name
        price
        precedence
        choiceGroupID
        choiceGroupName
        deleted
        createdAt
        createdByID
        createdByName
        symphonyID
        multiLanguages
        definitionNumber
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    simphonyID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateChoiceGroupsSubscriptionVariables,
  APITypes.OnUpdateChoiceGroupsSubscription
>;
export const onDeleteChoiceGroups = /* GraphQL */ `subscription OnDeleteChoiceGroups(
  $filter: ModelSubscriptionChoiceGroupsFilterInput
) {
  onDeleteChoiceGroups(filter: $filter) {
    id
    conceptID
    parentConceptID
    name
    required
    maxNumberOfChoices
    minNumberOfChoices
    precedence
    type
    choices
    choiceItems {
      items {
        id
        conceptID
        name
        price
        precedence
        choiceGroupID
        choiceGroupName
        deleted
        createdAt
        createdByID
        createdByName
        symphonyID
        multiLanguages
        definitionNumber
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    simphonyID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteChoiceGroupsSubscriptionVariables,
  APITypes.OnDeleteChoiceGroupsSubscription
>;
export const onCreateChoice = /* GraphQL */ `subscription OnCreateChoice($filter: ModelSubscriptionChoiceFilterInput) {
  onCreateChoice(filter: $filter) {
    id
    conceptID
    name
    price
    precedence
    choiceGroupID
    choiceGroupName
    deleted
    createdAt
    createdByID
    createdByName
    symphonyID
    multiLanguages
    definitionNumber
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateChoiceSubscriptionVariables,
  APITypes.OnCreateChoiceSubscription
>;
export const onUpdateChoice = /* GraphQL */ `subscription OnUpdateChoice($filter: ModelSubscriptionChoiceFilterInput) {
  onUpdateChoice(filter: $filter) {
    id
    conceptID
    name
    price
    precedence
    choiceGroupID
    choiceGroupName
    deleted
    createdAt
    createdByID
    createdByName
    symphonyID
    multiLanguages
    definitionNumber
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateChoiceSubscriptionVariables,
  APITypes.OnUpdateChoiceSubscription
>;
export const onDeleteChoice = /* GraphQL */ `subscription OnDeleteChoice($filter: ModelSubscriptionChoiceFilterInput) {
  onDeleteChoice(filter: $filter) {
    id
    conceptID
    name
    price
    precedence
    choiceGroupID
    choiceGroupName
    deleted
    createdAt
    createdByID
    createdByName
    symphonyID
    multiLanguages
    definitionNumber
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteChoiceSubscriptionVariables,
  APITypes.OnDeleteChoiceSubscription
>;
export const onCreateCart = /* GraphQL */ `subscription OnCreateCart($filter: ModelSubscriptionCartFilterInput) {
  onCreateCart(filter: $filter) {
    id
    conceptID
    userID
    orderedItems {
      menuItemID
      categoryID
      name
      quantity
      selectedChoiceGroups {
        id
        name
        selectedChoices {
          id
          name
          price
          __typename
        }
        precedence
        __typename
      }
      price
      priceName
      priceID
      priceMultiLanguages
      multiLanguages
      notes
      image
      type
      __typename
    }
    specialRequest
    deliveryFee
    subTotal
    subTaxTotal
    totalPrice
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateCartSubscriptionVariables,
  APITypes.OnCreateCartSubscription
>;
export const onUpdateCart = /* GraphQL */ `subscription OnUpdateCart($filter: ModelSubscriptionCartFilterInput) {
  onUpdateCart(filter: $filter) {
    id
    conceptID
    userID
    orderedItems {
      menuItemID
      categoryID
      name
      quantity
      selectedChoiceGroups {
        id
        name
        selectedChoices {
          id
          name
          price
          __typename
        }
        precedence
        __typename
      }
      price
      priceName
      priceID
      priceMultiLanguages
      multiLanguages
      notes
      image
      type
      __typename
    }
    specialRequest
    deliveryFee
    subTotal
    subTaxTotal
    totalPrice
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateCartSubscriptionVariables,
  APITypes.OnUpdateCartSubscription
>;
export const onDeleteCart = /* GraphQL */ `subscription OnDeleteCart($filter: ModelSubscriptionCartFilterInput) {
  onDeleteCart(filter: $filter) {
    id
    conceptID
    userID
    orderedItems {
      menuItemID
      categoryID
      name
      quantity
      selectedChoiceGroups {
        id
        name
        selectedChoices {
          id
          name
          price
          __typename
        }
        precedence
        __typename
      }
      price
      priceName
      priceID
      priceMultiLanguages
      multiLanguages
      notes
      image
      type
      __typename
    }
    specialRequest
    deliveryFee
    subTotal
    subTaxTotal
    totalPrice
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteCartSubscriptionVariables,
  APITypes.OnDeleteCartSubscription
>;
export const onCreateAdminStatus = /* GraphQL */ `subscription OnCreateAdminStatus(
  $filter: ModelSubscriptionAdminStatusFilterInput
) {
  onCreateAdminStatus(filter: $filter) {
    id
    sub
    accountID
    name
    averageCallTime
    numberOfCalls
    idelDuration
    activeDuration
    awayDuration
    lastCallTime
    agentBarAverageCallTime
    agentBarNumberOfCalls
    type
    waiterStatus
    totalAverageRatio
    performancePerDay
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAdminStatusSubscriptionVariables,
  APITypes.OnCreateAdminStatusSubscription
>;
export const onUpdateAdminStatus = /* GraphQL */ `subscription OnUpdateAdminStatus(
  $filter: ModelSubscriptionAdminStatusFilterInput
) {
  onUpdateAdminStatus(filter: $filter) {
    id
    sub
    accountID
    name
    averageCallTime
    numberOfCalls
    idelDuration
    activeDuration
    awayDuration
    lastCallTime
    agentBarAverageCallTime
    agentBarNumberOfCalls
    type
    waiterStatus
    totalAverageRatio
    performancePerDay
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAdminStatusSubscriptionVariables,
  APITypes.OnUpdateAdminStatusSubscription
>;
export const onDeleteAdminStatus = /* GraphQL */ `subscription OnDeleteAdminStatus(
  $filter: ModelSubscriptionAdminStatusFilterInput
) {
  onDeleteAdminStatus(filter: $filter) {
    id
    sub
    accountID
    name
    averageCallTime
    numberOfCalls
    idelDuration
    activeDuration
    awayDuration
    lastCallTime
    agentBarAverageCallTime
    agentBarNumberOfCalls
    type
    waiterStatus
    totalAverageRatio
    performancePerDay
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAdminStatusSubscriptionVariables,
  APITypes.OnDeleteAdminStatusSubscription
>;
export const onCreateOnlineOrder = /* GraphQL */ `subscription OnCreateOnlineOrder(
  $filter: ModelSubscriptionOnlineOrderFilterInput
) {
  onCreateOnlineOrder(filter: $filter) {
    id
    conceptID
    statusID
    status
    reason
    orderType
    channel
    userID
    userName
    userPhoneNumber
    dispatcherID
    dispatcherName
    totalAmount
    discountedValue
    subTotal
    subTaxTotal
    tax
    deliveryFee
    orderTime
    executionArn
    specialRequest
    orderAddress
    orderedItems {
      menuItemID
      categoryID
      name
      quantity
      selectedChoiceGroups {
        id
        name
        selectedChoices {
          id
          name
          price
          __typename
        }
        precedence
        __typename
      }
      price
      priceName
      priceID
      priceMultiLanguages
      multiLanguages
      notes
      image
      type
      __typename
    }
    discountID
    amount_cents
    transactionID
    paymentStatus
    paymentType
    referenceId
    cardHolderNumber
    cardHolderName
    cardSchemeName
    deleted
    createdAt
    sentToKitchenAt
    readyAt
    outForDeliveryAt
    deliveredAt
    promoCodeID
    promoCodeName
    promoCodeAppliedTo
    rating
    isReview
    reviewID
    review {
      id
      conceptID
      conceptName
      accountID
      bookingID
      parentConceptID
      guestName
      guestId
      reason
      value
      userID
      orderID
      rate
      feedback
      satisfaction
      thoughts
      itemReviews {
        itemID
        rate
        satisfaction
        feedback
        multiLanguages
        __typename
      }
      photos
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      phoneUser
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdByID
    createdByName
    tmpField
    otp
    avgOrderCompletionEstimate
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOnlineOrderSubscriptionVariables,
  APITypes.OnCreateOnlineOrderSubscription
>;
export const onUpdateOnlineOrder = /* GraphQL */ `subscription OnUpdateOnlineOrder(
  $filter: ModelSubscriptionOnlineOrderFilterInput
) {
  onUpdateOnlineOrder(filter: $filter) {
    id
    conceptID
    statusID
    status
    reason
    orderType
    channel
    userID
    userName
    userPhoneNumber
    dispatcherID
    dispatcherName
    totalAmount
    discountedValue
    subTotal
    subTaxTotal
    tax
    deliveryFee
    orderTime
    executionArn
    specialRequest
    orderAddress
    orderedItems {
      menuItemID
      categoryID
      name
      quantity
      selectedChoiceGroups {
        id
        name
        selectedChoices {
          id
          name
          price
          __typename
        }
        precedence
        __typename
      }
      price
      priceName
      priceID
      priceMultiLanguages
      multiLanguages
      notes
      image
      type
      __typename
    }
    discountID
    amount_cents
    transactionID
    paymentStatus
    paymentType
    referenceId
    cardHolderNumber
    cardHolderName
    cardSchemeName
    deleted
    createdAt
    sentToKitchenAt
    readyAt
    outForDeliveryAt
    deliveredAt
    promoCodeID
    promoCodeName
    promoCodeAppliedTo
    rating
    isReview
    reviewID
    review {
      id
      conceptID
      conceptName
      accountID
      bookingID
      parentConceptID
      guestName
      guestId
      reason
      value
      userID
      orderID
      rate
      feedback
      satisfaction
      thoughts
      itemReviews {
        itemID
        rate
        satisfaction
        feedback
        multiLanguages
        __typename
      }
      photos
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      phoneUser
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdByID
    createdByName
    tmpField
    otp
    avgOrderCompletionEstimate
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOnlineOrderSubscriptionVariables,
  APITypes.OnUpdateOnlineOrderSubscription
>;
export const onDeleteOnlineOrder = /* GraphQL */ `subscription OnDeleteOnlineOrder(
  $filter: ModelSubscriptionOnlineOrderFilterInput
) {
  onDeleteOnlineOrder(filter: $filter) {
    id
    conceptID
    statusID
    status
    reason
    orderType
    channel
    userID
    userName
    userPhoneNumber
    dispatcherID
    dispatcherName
    totalAmount
    discountedValue
    subTotal
    subTaxTotal
    tax
    deliveryFee
    orderTime
    executionArn
    specialRequest
    orderAddress
    orderedItems {
      menuItemID
      categoryID
      name
      quantity
      selectedChoiceGroups {
        id
        name
        selectedChoices {
          id
          name
          price
          __typename
        }
        precedence
        __typename
      }
      price
      priceName
      priceID
      priceMultiLanguages
      multiLanguages
      notes
      image
      type
      __typename
    }
    discountID
    amount_cents
    transactionID
    paymentStatus
    paymentType
    referenceId
    cardHolderNumber
    cardHolderName
    cardSchemeName
    deleted
    createdAt
    sentToKitchenAt
    readyAt
    outForDeliveryAt
    deliveredAt
    promoCodeID
    promoCodeName
    promoCodeAppliedTo
    rating
    isReview
    reviewID
    review {
      id
      conceptID
      conceptName
      accountID
      bookingID
      parentConceptID
      guestName
      guestId
      reason
      value
      userID
      orderID
      rate
      feedback
      satisfaction
      thoughts
      itemReviews {
        itemID
        rate
        satisfaction
        feedback
        multiLanguages
        __typename
      }
      photos
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      phoneUser
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdByID
    createdByName
    tmpField
    otp
    avgOrderCompletionEstimate
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOnlineOrderSubscriptionVariables,
  APITypes.OnDeleteOnlineOrderSubscription
>;
export const onCreateOrderStatus = /* GraphQL */ `subscription OnCreateOrderStatus(
  $filter: ModelSubscriptionOrderStatusFilterInput
) {
  onCreateOrderStatus(filter: $filter) {
    id
    conceptID
    name
    color
    precedence
    accessibleStatus
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOrderStatusSubscriptionVariables,
  APITypes.OnCreateOrderStatusSubscription
>;
export const onUpdateOrderStatus = /* GraphQL */ `subscription OnUpdateOrderStatus(
  $filter: ModelSubscriptionOrderStatusFilterInput
) {
  onUpdateOrderStatus(filter: $filter) {
    id
    conceptID
    name
    color
    precedence
    accessibleStatus
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOrderStatusSubscriptionVariables,
  APITypes.OnUpdateOrderStatusSubscription
>;
export const onDeleteOrderStatus = /* GraphQL */ `subscription OnDeleteOrderStatus(
  $filter: ModelSubscriptionOrderStatusFilterInput
) {
  onDeleteOrderStatus(filter: $filter) {
    id
    conceptID
    name
    color
    precedence
    accessibleStatus
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOrderStatusSubscriptionVariables,
  APITypes.OnDeleteOrderStatusSubscription
>;
export const onCreateDiscountCoupon = /* GraphQL */ `subscription OnCreateDiscountCoupon(
  $filter: ModelSubscriptionDiscountCouponFilterInput
) {
  onCreateDiscountCoupon(filter: $filter) {
    id
    discountPercent
    usedBy
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDiscountCouponSubscriptionVariables,
  APITypes.OnCreateDiscountCouponSubscription
>;
export const onUpdateDiscountCoupon = /* GraphQL */ `subscription OnUpdateDiscountCoupon(
  $filter: ModelSubscriptionDiscountCouponFilterInput
) {
  onUpdateDiscountCoupon(filter: $filter) {
    id
    discountPercent
    usedBy
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDiscountCouponSubscriptionVariables,
  APITypes.OnUpdateDiscountCouponSubscription
>;
export const onDeleteDiscountCoupon = /* GraphQL */ `subscription OnDeleteDiscountCoupon(
  $filter: ModelSubscriptionDiscountCouponFilterInput
) {
  onDeleteDiscountCoupon(filter: $filter) {
    id
    discountPercent
    usedBy
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDiscountCouponSubscriptionVariables,
  APITypes.OnDeleteDiscountCouponSubscription
>;
export const onCreateZone = /* GraphQL */ `subscription OnCreateZone($filter: ModelSubscriptionZoneFilterInput) {
  onCreateZone(filter: $filter) {
    id
    conceptID
    location
    deliveryFee
    concepts {
      id
      deliveryFee
      __typename
    }
    polygon
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateZoneSubscriptionVariables,
  APITypes.OnCreateZoneSubscription
>;
export const onUpdateZone = /* GraphQL */ `subscription OnUpdateZone($filter: ModelSubscriptionZoneFilterInput) {
  onUpdateZone(filter: $filter) {
    id
    conceptID
    location
    deliveryFee
    concepts {
      id
      deliveryFee
      __typename
    }
    polygon
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateZoneSubscriptionVariables,
  APITypes.OnUpdateZoneSubscription
>;
export const onDeleteZone = /* GraphQL */ `subscription OnDeleteZone($filter: ModelSubscriptionZoneFilterInput) {
  onDeleteZone(filter: $filter) {
    id
    conceptID
    location
    deliveryFee
    concepts {
      id
      deliveryFee
      __typename
    }
    polygon
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteZoneSubscriptionVariables,
  APITypes.OnDeleteZoneSubscription
>;
export const onCreateUserIP = /* GraphQL */ `subscription OnCreateUserIP($filter: ModelSubscriptionUserIPFilterInput) {
  onCreateUserIP(filter: $filter) {
    id
    ip
    date
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserIPSubscriptionVariables,
  APITypes.OnCreateUserIPSubscription
>;
export const onUpdateUserIP = /* GraphQL */ `subscription OnUpdateUserIP($filter: ModelSubscriptionUserIPFilterInput) {
  onUpdateUserIP(filter: $filter) {
    id
    ip
    date
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserIPSubscriptionVariables,
  APITypes.OnUpdateUserIPSubscription
>;
export const onDeleteUserIP = /* GraphQL */ `subscription OnDeleteUserIP($filter: ModelSubscriptionUserIPFilterInput) {
  onDeleteUserIP(filter: $filter) {
    id
    ip
    date
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserIPSubscriptionVariables,
  APITypes.OnDeleteUserIPSubscription
>;
export const onCreateMainCategory = /* GraphQL */ `subscription OnCreateMainCategory(
  $filter: ModelSubscriptionMainCategoryFilterInput
) {
  onCreateMainCategory(filter: $filter) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    guestView
    guestOrder
    staffOrder
    image
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMainCategorySubscriptionVariables,
  APITypes.OnCreateMainCategorySubscription
>;
export const onUpdateMainCategory = /* GraphQL */ `subscription OnUpdateMainCategory(
  $filter: ModelSubscriptionMainCategoryFilterInput
) {
  onUpdateMainCategory(filter: $filter) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    guestView
    guestOrder
    staffOrder
    image
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMainCategorySubscriptionVariables,
  APITypes.OnUpdateMainCategorySubscription
>;
export const onDeleteMainCategory = /* GraphQL */ `subscription OnDeleteMainCategory(
  $filter: ModelSubscriptionMainCategoryFilterInput
) {
  onDeleteMainCategory(filter: $filter) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    guestView
    guestOrder
    staffOrder
    image
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMainCategorySubscriptionVariables,
  APITypes.OnDeleteMainCategorySubscription
>;
export const onCreateSubCategory = /* GraphQL */ `subscription OnCreateSubCategory(
  $filter: ModelSubscriptionSubCategoryFilterInput
) {
  onCreateSubCategory(filter: $filter) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    mainCategoryID
    guestView
    guestOrder
    staffOrder
    image
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateSubCategorySubscriptionVariables,
  APITypes.OnCreateSubCategorySubscription
>;
export const onUpdateSubCategory = /* GraphQL */ `subscription OnUpdateSubCategory(
  $filter: ModelSubscriptionSubCategoryFilterInput
) {
  onUpdateSubCategory(filter: $filter) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    mainCategoryID
    guestView
    guestOrder
    staffOrder
    image
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateSubCategorySubscriptionVariables,
  APITypes.OnUpdateSubCategorySubscription
>;
export const onDeleteSubCategory = /* GraphQL */ `subscription OnDeleteSubCategory(
  $filter: ModelSubscriptionSubCategoryFilterInput
) {
  onDeleteSubCategory(filter: $filter) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    mainCategoryID
    guestView
    guestOrder
    staffOrder
    image
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteSubCategorySubscriptionVariables,
  APITypes.OnDeleteSubCategorySubscription
>;
export const onCreateChannel = /* GraphQL */ `subscription OnCreateChannel($filter: ModelSubscriptionChannelFilterInput) {
  onCreateChannel(filter: $filter) {
    id
    name
    description
    numberOfOrders
    isActive
    multiLanguages
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateChannelSubscriptionVariables,
  APITypes.OnCreateChannelSubscription
>;
export const onUpdateChannel = /* GraphQL */ `subscription OnUpdateChannel($filter: ModelSubscriptionChannelFilterInput) {
  onUpdateChannel(filter: $filter) {
    id
    name
    description
    numberOfOrders
    isActive
    multiLanguages
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateChannelSubscriptionVariables,
  APITypes.OnUpdateChannelSubscription
>;
export const onDeleteChannel = /* GraphQL */ `subscription OnDeleteChannel($filter: ModelSubscriptionChannelFilterInput) {
  onDeleteChannel(filter: $filter) {
    id
    name
    description
    numberOfOrders
    isActive
    multiLanguages
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteChannelSubscriptionVariables,
  APITypes.OnDeleteChannelSubscription
>;
export const onCreatePromocode = /* GraphQL */ `subscription OnCreatePromocode($filter: ModelSubscriptionPromocodeFilterInput) {
  onCreatePromocode(filter: $filter) {
    id
    name
    redemptions
    status
    startDate
    endDate
    appliesTo
    minOrderReq
    minOrderVal
    minOrderQty
    tenderReq
    discountVal
    discountLimit
    quota
    quotaUsed
    totalRevenue
    appliedDiscountedVal
    usePerCustomer
    channelReq
    customerEligibility
    customerReq
    spendingExceeds
    spendingBelow
    orderSinceDate
    orderFromDate
    orderToDate
    isDisplayed
    deleted
    createdAt
    createdByID
    createdByName
    parentConceptIDs
    conceptIDs
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePromocodeSubscriptionVariables,
  APITypes.OnCreatePromocodeSubscription
>;
export const onUpdatePromocode = /* GraphQL */ `subscription OnUpdatePromocode($filter: ModelSubscriptionPromocodeFilterInput) {
  onUpdatePromocode(filter: $filter) {
    id
    name
    redemptions
    status
    startDate
    endDate
    appliesTo
    minOrderReq
    minOrderVal
    minOrderQty
    tenderReq
    discountVal
    discountLimit
    quota
    quotaUsed
    totalRevenue
    appliedDiscountedVal
    usePerCustomer
    channelReq
    customerEligibility
    customerReq
    spendingExceeds
    spendingBelow
    orderSinceDate
    orderFromDate
    orderToDate
    isDisplayed
    deleted
    createdAt
    createdByID
    createdByName
    parentConceptIDs
    conceptIDs
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePromocodeSubscriptionVariables,
  APITypes.OnUpdatePromocodeSubscription
>;
export const onDeletePromocode = /* GraphQL */ `subscription OnDeletePromocode($filter: ModelSubscriptionPromocodeFilterInput) {
  onDeletePromocode(filter: $filter) {
    id
    name
    redemptions
    status
    startDate
    endDate
    appliesTo
    minOrderReq
    minOrderVal
    minOrderQty
    tenderReq
    discountVal
    discountLimit
    quota
    quotaUsed
    totalRevenue
    appliedDiscountedVal
    usePerCustomer
    channelReq
    customerEligibility
    customerReq
    spendingExceeds
    spendingBelow
    orderSinceDate
    orderFromDate
    orderToDate
    isDisplayed
    deleted
    createdAt
    createdByID
    createdByName
    parentConceptIDs
    conceptIDs
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePromocodeSubscriptionVariables,
  APITypes.OnDeletePromocodeSubscription
>;
export const onCreatePromocodeDashboard = /* GraphQL */ `subscription OnCreatePromocodeDashboard(
  $filter: ModelSubscriptionPromocodeDashboardFilterInput
) {
  onCreatePromocodeDashboard(filter: $filter) {
    id
    conceptID
    totalRevenue
    totalRedemptions
    totalDiscount
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePromocodeDashboardSubscriptionVariables,
  APITypes.OnCreatePromocodeDashboardSubscription
>;
export const onUpdatePromocodeDashboard = /* GraphQL */ `subscription OnUpdatePromocodeDashboard(
  $filter: ModelSubscriptionPromocodeDashboardFilterInput
) {
  onUpdatePromocodeDashboard(filter: $filter) {
    id
    conceptID
    totalRevenue
    totalRedemptions
    totalDiscount
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePromocodeDashboardSubscriptionVariables,
  APITypes.OnUpdatePromocodeDashboardSubscription
>;
export const onDeletePromocodeDashboard = /* GraphQL */ `subscription OnDeletePromocodeDashboard(
  $filter: ModelSubscriptionPromocodeDashboardFilterInput
) {
  onDeletePromocodeDashboard(filter: $filter) {
    id
    conceptID
    totalRevenue
    totalRedemptions
    totalDiscount
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePromocodeDashboardSubscriptionVariables,
  APITypes.OnDeletePromocodeDashboardSubscription
>;
export const onCreateConceptRevenue = /* GraphQL */ `subscription OnCreateConceptRevenue(
  $filter: ModelSubscriptionConceptRevenueFilterInput
) {
  onCreateConceptRevenue(filter: $filter) {
    id
    conceptID
    date
    driveThruRevenue
    pickupRevenue
    deliveryRevenue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateConceptRevenueSubscriptionVariables,
  APITypes.OnCreateConceptRevenueSubscription
>;
export const onUpdateConceptRevenue = /* GraphQL */ `subscription OnUpdateConceptRevenue(
  $filter: ModelSubscriptionConceptRevenueFilterInput
) {
  onUpdateConceptRevenue(filter: $filter) {
    id
    conceptID
    date
    driveThruRevenue
    pickupRevenue
    deliveryRevenue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateConceptRevenueSubscriptionVariables,
  APITypes.OnUpdateConceptRevenueSubscription
>;
export const onDeleteConceptRevenue = /* GraphQL */ `subscription OnDeleteConceptRevenue(
  $filter: ModelSubscriptionConceptRevenueFilterInput
) {
  onDeleteConceptRevenue(filter: $filter) {
    id
    conceptID
    date
    driveThruRevenue
    pickupRevenue
    deliveryRevenue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteConceptRevenueSubscriptionVariables,
  APITypes.OnDeleteConceptRevenueSubscription
>;
export const onCreateLoyaltyItem = /* GraphQL */ `subscription OnCreateLoyaltyItem(
  $filter: ModelSubscriptionLoyaltyItemFilterInput
) {
  onCreateLoyaltyItem(filter: $filter) {
    id
    name
    conceptID
    parentConceptID
    description
    startDate
    endDate
    options
    points
    redemptionPerUser
    image
    simphonyID
    multiLanguages
    isActive
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateLoyaltyItemSubscriptionVariables,
  APITypes.OnCreateLoyaltyItemSubscription
>;
export const onUpdateLoyaltyItem = /* GraphQL */ `subscription OnUpdateLoyaltyItem(
  $filter: ModelSubscriptionLoyaltyItemFilterInput
) {
  onUpdateLoyaltyItem(filter: $filter) {
    id
    name
    conceptID
    parentConceptID
    description
    startDate
    endDate
    options
    points
    redemptionPerUser
    image
    simphonyID
    multiLanguages
    isActive
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateLoyaltyItemSubscriptionVariables,
  APITypes.OnUpdateLoyaltyItemSubscription
>;
export const onDeleteLoyaltyItem = /* GraphQL */ `subscription OnDeleteLoyaltyItem(
  $filter: ModelSubscriptionLoyaltyItemFilterInput
) {
  onDeleteLoyaltyItem(filter: $filter) {
    id
    name
    conceptID
    parentConceptID
    description
    startDate
    endDate
    options
    points
    redemptionPerUser
    image
    simphonyID
    multiLanguages
    isActive
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteLoyaltyItemSubscriptionVariables,
  APITypes.OnDeleteLoyaltyItemSubscription
>;
export const onCreateOTP = /* GraphQL */ `subscription OnCreateOTP($filter: ModelSubscriptionOTPFilterInput) {
  onCreateOTP(filter: $filter) {
    id
    OTP
    redeemed
    deleted
    userID
    conceptID
    loyaltyItemID
    loyaltyItem {
      id
      name
      conceptID
      parentConceptID
      description
      startDate
      endDate
      options
      points
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    user {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    concept {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      paymentSharePercentage
      simphonyServerBaseURL
      simphonyShortOrgName
      simphonyLocRef
      simphonyToken
      revenueCenterID
      employeeID
      tenderID
      tenders {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptTendersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderType {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptOrderTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      discountID
      useServiceCharge
      serviceChargeId
      specialRequestID
      addressID
      ratingCount
      averageRating
      feesback
      simphonyAuthClientId
      simphonyAuthUsername
      simphonyAuthPassword
      simphonyAuthBaseURL
      orderTypeDineIn
      orderTypeTakeAway
      orderTypeDelivery
      simphonyTakeAwayRvc
      simphonyDeliveryRvc
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      openTime
      closeTime
      varifyForAmount
      varifyFirstOrder
      varifyNewAddress
      sliderImages
      receiveWaiters
      WaiterNotificationOption
      applyLoyalty
      rewardPoints
      moneySpent
      afterVat
      afterDeliveryFee
      beforePromoCode
      pointsCalcWithPromoCode
      isBusy
      allowOnlineOrdering
      minOrderVal
      vatPercentage
      serviceChargePercentage
      addDeliveryToVat
      categoryTagIDs
      parentConceptID
      containerColor
      avgOrderCompletionEstimate
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateOTPSubscriptionVariables,
  APITypes.OnCreateOTPSubscription
>;
export const onUpdateOTP = /* GraphQL */ `subscription OnUpdateOTP($filter: ModelSubscriptionOTPFilterInput) {
  onUpdateOTP(filter: $filter) {
    id
    OTP
    redeemed
    deleted
    userID
    conceptID
    loyaltyItemID
    loyaltyItem {
      id
      name
      conceptID
      parentConceptID
      description
      startDate
      endDate
      options
      points
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    user {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    concept {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      paymentSharePercentage
      simphonyServerBaseURL
      simphonyShortOrgName
      simphonyLocRef
      simphonyToken
      revenueCenterID
      employeeID
      tenderID
      tenders {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptTendersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderType {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptOrderTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      discountID
      useServiceCharge
      serviceChargeId
      specialRequestID
      addressID
      ratingCount
      averageRating
      feesback
      simphonyAuthClientId
      simphonyAuthUsername
      simphonyAuthPassword
      simphonyAuthBaseURL
      orderTypeDineIn
      orderTypeTakeAway
      orderTypeDelivery
      simphonyTakeAwayRvc
      simphonyDeliveryRvc
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      openTime
      closeTime
      varifyForAmount
      varifyFirstOrder
      varifyNewAddress
      sliderImages
      receiveWaiters
      WaiterNotificationOption
      applyLoyalty
      rewardPoints
      moneySpent
      afterVat
      afterDeliveryFee
      beforePromoCode
      pointsCalcWithPromoCode
      isBusy
      allowOnlineOrdering
      minOrderVal
      vatPercentage
      serviceChargePercentage
      addDeliveryToVat
      categoryTagIDs
      parentConceptID
      containerColor
      avgOrderCompletionEstimate
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateOTPSubscriptionVariables,
  APITypes.OnUpdateOTPSubscription
>;
export const onDeleteOTP = /* GraphQL */ `subscription OnDeleteOTP($filter: ModelSubscriptionOTPFilterInput) {
  onDeleteOTP(filter: $filter) {
    id
    OTP
    redeemed
    deleted
    userID
    conceptID
    loyaltyItemID
    loyaltyItem {
      id
      name
      conceptID
      parentConceptID
      description
      startDate
      endDate
      options
      points
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    user {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    concept {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      paymentSharePercentage
      simphonyServerBaseURL
      simphonyShortOrgName
      simphonyLocRef
      simphonyToken
      revenueCenterID
      employeeID
      tenderID
      tenders {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptTendersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderType {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptOrderTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      discountID
      useServiceCharge
      serviceChargeId
      specialRequestID
      addressID
      ratingCount
      averageRating
      feesback
      simphonyAuthClientId
      simphonyAuthUsername
      simphonyAuthPassword
      simphonyAuthBaseURL
      orderTypeDineIn
      orderTypeTakeAway
      orderTypeDelivery
      simphonyTakeAwayRvc
      simphonyDeliveryRvc
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      openTime
      closeTime
      varifyForAmount
      varifyFirstOrder
      varifyNewAddress
      sliderImages
      receiveWaiters
      WaiterNotificationOption
      applyLoyalty
      rewardPoints
      moneySpent
      afterVat
      afterDeliveryFee
      beforePromoCode
      pointsCalcWithPromoCode
      isBusy
      allowOnlineOrdering
      minOrderVal
      vatPercentage
      serviceChargePercentage
      addDeliveryToVat
      categoryTagIDs
      parentConceptID
      containerColor
      avgOrderCompletionEstimate
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteOTPSubscriptionVariables,
  APITypes.OnDeleteOTPSubscription
>;
export const onCreateMostOrderedItems = /* GraphQL */ `subscription OnCreateMostOrderedItems(
  $filter: ModelSubscriptionMostOrderedItemsFilterInput
) {
  onCreateMostOrderedItems(filter: $filter) {
    id
    conceptID
    menuItemID
    menuItemName
    menuItemImage
    quantity
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMostOrderedItemsSubscriptionVariables,
  APITypes.OnCreateMostOrderedItemsSubscription
>;
export const onUpdateMostOrderedItems = /* GraphQL */ `subscription OnUpdateMostOrderedItems(
  $filter: ModelSubscriptionMostOrderedItemsFilterInput
) {
  onUpdateMostOrderedItems(filter: $filter) {
    id
    conceptID
    menuItemID
    menuItemName
    menuItemImage
    quantity
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMostOrderedItemsSubscriptionVariables,
  APITypes.OnUpdateMostOrderedItemsSubscription
>;
export const onDeleteMostOrderedItems = /* GraphQL */ `subscription OnDeleteMostOrderedItems(
  $filter: ModelSubscriptionMostOrderedItemsFilterInput
) {
  onDeleteMostOrderedItems(filter: $filter) {
    id
    conceptID
    menuItemID
    menuItemName
    menuItemImage
    quantity
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMostOrderedItemsSubscriptionVariables,
  APITypes.OnDeleteMostOrderedItemsSubscription
>;
