/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../models/GQL_API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    accountID
    username
    preferred_username
    email
    email_verified
    phone_number
    phone_number_verified
    name
    nickname
    given_name
    middle_name
    family_name
    address
    birthdate
    gender
    locale
    picture
    pictureCrop
    website
    zoneinfo
    verification_code
    facebook
    instagram
    group
    phones
    flags
    flagsName
    interests
    interestsName
    status
    stats
    spending
    prevOrderDate
    lastOrderDate
    promocodes {
      name
      promocodeId
      canBeUsed
      discountVal
      discountLimit
      isDisplayed
      __typename
    }
    mostOrderedItems
    points
    deleted
    createdAt
    createdByID
    createdByName
    cognitoUsername
    cognitoSub
    autoGeneratedPassword
    updated
    sub
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    scannedCount
    count
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const syncUsers = /* GraphQL */ `query SyncUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    scannedCount
    count
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncUsersQueryVariables, APITypes.SyncUsersQuery>;
export const ByPhoneNumber = /* GraphQL */ `query ByPhoneNumber(
  $phone_number: String!
  $deletedNameCreatedAt: ModelUserUsersByPhoneNumberCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  ByPhoneNumber(
    phone_number: $phone_number
    deletedNameCreatedAt: $deletedNameCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    scannedCount
    count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ByPhoneNumberQueryVariables,
  APITypes.ByPhoneNumberQuery
>;
export const getReview = /* GraphQL */ `query GetReview($id: ID!) {
  getReview(id: $id) {
    id
    conceptID
    conceptName
    accountID
    bookingID
    parentConceptID
    guestName
    guestId
    reason
    value
    userID
    orderID
    rate
    feedback
    satisfaction
    thoughts
    itemReviews {
      itemID
      rate
      satisfaction
      feedback
      multiLanguages
      __typename
    }
    photos
    multiLanguages
    deleted
    createdAt
    createdByID
    createdByName
    phoneUser
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetReviewQueryVariables, APITypes.GetReviewQuery>;
export const listReviews = /* GraphQL */ `query ListReviews(
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      conceptName
      accountID
      bookingID
      parentConceptID
      guestName
      guestId
      reason
      value
      userID
      orderID
      rate
      feedback
      satisfaction
      thoughts
      itemReviews {
        itemID
        rate
        satisfaction
        feedback
        multiLanguages
        __typename
      }
      photos
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      phoneUser
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReviewsQueryVariables,
  APITypes.ListReviewsQuery
>;
export const syncReviews = /* GraphQL */ `query SyncReviews(
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncReviews(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      conceptName
      accountID
      bookingID
      parentConceptID
      guestName
      guestId
      reason
      value
      userID
      orderID
      rate
      feedback
      satisfaction
      thoughts
      itemReviews {
        itemID
        rate
        satisfaction
        feedback
        multiLanguages
        __typename
      }
      photos
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      phoneUser
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncReviewsQueryVariables,
  APITypes.SyncReviewsQuery
>;
export const reviewsByConceptID = /* GraphQL */ `query ReviewsByConceptID(
  $conceptID: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelReviewFilterInput
  $limit: Int
  $nextToken: String
) {
  reviewsByConceptID(
    conceptID: $conceptID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      conceptName
      accountID
      bookingID
      parentConceptID
      guestName
      guestId
      reason
      value
      userID
      orderID
      rate
      feedback
      satisfaction
      thoughts
      itemReviews {
        itemID
        rate
        satisfaction
        feedback
        multiLanguages
        __typename
      }
      photos
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      phoneUser
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReviewsByConceptIDQueryVariables,
  APITypes.ReviewsByConceptIDQuery
>;
export const getAccount = /* GraphQL */ `query GetAccount($id: ID!) {
  getAccount(id: $id) {
    id
    logo
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    callCenterEmployeesIds
    kpiReportResetFrequency
    agentBarResetFrequency
    dynamicStatus
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAccountQueryVariables,
  APITypes.GetAccountQuery
>;
export const listAccounts = /* GraphQL */ `query ListAccounts(
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
) {
  listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      logo
      domain
      siteTitle
      guestsCount
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      deleted
      createdAt
      createdByID
      createdByName
      callCenterEmployeesIds
      kpiReportResetFrequency
      agentBarResetFrequency
      dynamicStatus
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAccountsQueryVariables,
  APITypes.ListAccountsQuery
>;
export const syncAccounts = /* GraphQL */ `query SyncAccounts(
  $filter: ModelAccountFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAccounts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      logo
      domain
      siteTitle
      guestsCount
      tagline
      description
      siteAddress
      defaultLanguage
      languages
      features
      status
      socialLinks
      deleted
      createdAt
      createdByID
      createdByName
      callCenterEmployeesIds
      kpiReportResetFrequency
      agentBarResetFrequency
      dynamicStatus
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAccountsQueryVariables,
  APITypes.SyncAccountsQuery
>;
export const getFeature = /* GraphQL */ `query GetFeature($id: ID!) {
  getFeature(id: $id) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFeatureQueryVariables,
  APITypes.GetFeatureQuery
>;
export const listFeatures = /* GraphQL */ `query ListFeatures(
  $filter: ModelFeatureFilterInput
  $limit: Int
  $nextToken: String
) {
  listFeatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFeaturesQueryVariables,
  APITypes.ListFeaturesQuery
>;
export const syncFeatures = /* GraphQL */ `query SyncFeatures(
  $filter: ModelFeatureFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFeatures(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      icon
      slug
      precedence
      parent
      private
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFeaturesQueryVariables,
  APITypes.SyncFeaturesQuery
>;
export const getMedia = /* GraphQL */ `query GetMedia($id: ID!) {
  getMedia(id: $id) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetMediaQueryVariables, APITypes.GetMediaQuery>;
export const listMedia = /* GraphQL */ `query ListMedia(
  $filter: ModelMediaFilterInput
  $limit: Int
  $nextToken: String
) {
  listMedia(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      attachments
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListMediaQueryVariables, APITypes.ListMediaQuery>;
export const syncMedia = /* GraphQL */ `query SyncMedia(
  $filter: ModelMediaFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMedia(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      accountID
      fileUrl
      filename
      filetype
      fileSize
      alternativeText
      caption
      description
      attachments
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncMediaQueryVariables, APITypes.SyncMediaQuery>;
export const getConcept = /* GraphQL */ `query GetConcept($id: ID!) {
  getConcept(id: $id) {
    id
    accountID
    name
    kiosks
    merchantID
    merchantUsername
    merchantPassword
    merchantIntegrationNumber
    merchantAPIKey
    paymentSharePercentage
    simphonyServerBaseURL
    simphonyShortOrgName
    simphonyLocRef
    simphonyToken
    revenueCenterID
    employeeID
    tenderID
    tenders {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptTendersId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    orderType {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptOrderTypeId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    discountID
    useServiceCharge
    serviceChargeId
    specialRequestID
    addressID
    ratingCount
    averageRating
    feesback
    simphonyAuthClientId
    simphonyAuthUsername
    simphonyAuthPassword
    simphonyAuthBaseURL
    orderTypeDineIn
    orderTypeTakeAway
    orderTypeDelivery
    simphonyTakeAwayRvc
    simphonyDeliveryRvc
    exportInterval
    deleted
    createdAt
    createdByID
    createdByName
    foodicsToken
    enableTableFoodics
    image
    logo
    album
    type
    rating
    location
    description
    areas
    seatTypes
    needAssistance
    spaBooking
    showerBooking
    cigarMenu
    precedence
    isPinRequired
    viewOnly
    openTime
    closeTime
    varifyForAmount
    varifyFirstOrder
    varifyNewAddress
    sliderImages
    receiveWaiters
    WaiterNotificationOption
    applyLoyalty
    rewardPoints
    moneySpent
    afterVat
    afterDeliveryFee
    beforePromoCode
    pointsCalcWithPromoCode
    isBusy
    allowOnlineOrdering
    minOrderVal
    vatPercentage
    serviceChargePercentage
    addDeliveryToVat
    categoryTagIDs
    parentConceptID
    containerColor
    avgOrderCompletionEstimate
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConceptQueryVariables,
  APITypes.GetConceptQuery
>;
export const listConcepts = /* GraphQL */ `query ListConcepts(
  $filter: ModelConceptFilterInput
  $limit: Int
  $nextToken: String
) {
  listConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      paymentSharePercentage
      simphonyServerBaseURL
      simphonyShortOrgName
      simphonyLocRef
      simphonyToken
      revenueCenterID
      employeeID
      tenderID
      tenders {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptTendersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderType {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptOrderTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      discountID
      useServiceCharge
      serviceChargeId
      specialRequestID
      addressID
      ratingCount
      averageRating
      feesback
      simphonyAuthClientId
      simphonyAuthUsername
      simphonyAuthPassword
      simphonyAuthBaseURL
      orderTypeDineIn
      orderTypeTakeAway
      orderTypeDelivery
      simphonyTakeAwayRvc
      simphonyDeliveryRvc
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      openTime
      closeTime
      varifyForAmount
      varifyFirstOrder
      varifyNewAddress
      sliderImages
      receiveWaiters
      WaiterNotificationOption
      applyLoyalty
      rewardPoints
      moneySpent
      afterVat
      afterDeliveryFee
      beforePromoCode
      pointsCalcWithPromoCode
      isBusy
      allowOnlineOrdering
      minOrderVal
      vatPercentage
      serviceChargePercentage
      addDeliveryToVat
      categoryTagIDs
      parentConceptID
      containerColor
      avgOrderCompletionEstimate
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListConceptsQueryVariables,
  APITypes.ListConceptsQuery
>;
export const syncConcepts = /* GraphQL */ `query SyncConcepts(
  $filter: ModelConceptFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncConcepts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      paymentSharePercentage
      simphonyServerBaseURL
      simphonyShortOrgName
      simphonyLocRef
      simphonyToken
      revenueCenterID
      employeeID
      tenderID
      tenders {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptTendersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderType {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptOrderTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      discountID
      useServiceCharge
      serviceChargeId
      specialRequestID
      addressID
      ratingCount
      averageRating
      feesback
      simphonyAuthClientId
      simphonyAuthUsername
      simphonyAuthPassword
      simphonyAuthBaseURL
      orderTypeDineIn
      orderTypeTakeAway
      orderTypeDelivery
      simphonyTakeAwayRvc
      simphonyDeliveryRvc
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      openTime
      closeTime
      varifyForAmount
      varifyFirstOrder
      varifyNewAddress
      sliderImages
      receiveWaiters
      WaiterNotificationOption
      applyLoyalty
      rewardPoints
      moneySpent
      afterVat
      afterDeliveryFee
      beforePromoCode
      pointsCalcWithPromoCode
      isBusy
      allowOnlineOrdering
      minOrderVal
      vatPercentage
      serviceChargePercentage
      addDeliveryToVat
      categoryTagIDs
      parentConceptID
      containerColor
      avgOrderCompletionEstimate
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncConceptsQueryVariables,
  APITypes.SyncConceptsQuery
>;
export const conceptsByParentConceptIDAndPrecedence = /* GraphQL */ `query ConceptsByParentConceptIDAndPrecedence(
  $parentConceptID: ID!
  $precedence: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelConceptFilterInput
  $limit: Int
  $nextToken: String
) {
  conceptsByParentConceptIDAndPrecedence(
    parentConceptID: $parentConceptID
    precedence: $precedence
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      paymentSharePercentage
      simphonyServerBaseURL
      simphonyShortOrgName
      simphonyLocRef
      simphonyToken
      revenueCenterID
      employeeID
      tenderID
      tenders {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptTendersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderType {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptOrderTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      discountID
      useServiceCharge
      serviceChargeId
      specialRequestID
      addressID
      ratingCount
      averageRating
      feesback
      simphonyAuthClientId
      simphonyAuthUsername
      simphonyAuthPassword
      simphonyAuthBaseURL
      orderTypeDineIn
      orderTypeTakeAway
      orderTypeDelivery
      simphonyTakeAwayRvc
      simphonyDeliveryRvc
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      openTime
      closeTime
      varifyForAmount
      varifyFirstOrder
      varifyNewAddress
      sliderImages
      receiveWaiters
      WaiterNotificationOption
      applyLoyalty
      rewardPoints
      moneySpent
      afterVat
      afterDeliveryFee
      beforePromoCode
      pointsCalcWithPromoCode
      isBusy
      allowOnlineOrdering
      minOrderVal
      vatPercentage
      serviceChargePercentage
      addDeliveryToVat
      categoryTagIDs
      parentConceptID
      containerColor
      avgOrderCompletionEstimate
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ConceptsByParentConceptIDAndPrecedenceQueryVariables,
  APITypes.ConceptsByParentConceptIDAndPrecedenceQuery
>;
export const getCategoryTag = /* GraphQL */ `query GetCategoryTag($id: ID!) {
  getCategoryTag(id: $id) {
    id
    parentConceptID
    name
    image
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCategoryTagQueryVariables,
  APITypes.GetCategoryTagQuery
>;
export const listCategoryTags = /* GraphQL */ `query ListCategoryTags(
  $filter: ModelCategoryTagFilterInput
  $limit: Int
  $nextToken: String
) {
  listCategoryTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      parentConceptID
      name
      image
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCategoryTagsQueryVariables,
  APITypes.ListCategoryTagsQuery
>;
export const syncCategoryTags = /* GraphQL */ `query SyncCategoryTags(
  $filter: ModelCategoryTagFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCategoryTags(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      parentConceptID
      name
      image
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCategoryTagsQueryVariables,
  APITypes.SyncCategoryTagsQuery
>;
export const categoryTagsByParentConceptIDAndPrecedence = /* GraphQL */ `query CategoryTagsByParentConceptIDAndPrecedence(
  $parentConceptID: ID!
  $precedence: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCategoryTagFilterInput
  $limit: Int
  $nextToken: String
) {
  categoryTagsByParentConceptIDAndPrecedence(
    parentConceptID: $parentConceptID
    precedence: $precedence
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      parentConceptID
      name
      image
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CategoryTagsByParentConceptIDAndPrecedenceQueryVariables,
  APITypes.CategoryTagsByParentConceptIDAndPrecedenceQuery
>;
export const getTender = /* GraphQL */ `query GetTender($id: ID!) {
  getTender(id: $id) {
    id
    name
    simphonyID
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conceptTendersId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTenderQueryVariables, APITypes.GetTenderQuery>;
export const listTenders = /* GraphQL */ `query ListTenders(
  $id: ID
  $filter: ModelTenderFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTenders(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      simphonyID
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conceptTendersId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTendersQueryVariables,
  APITypes.ListTendersQuery
>;
export const syncTenders = /* GraphQL */ `query SyncTenders(
  $filter: ModelTenderFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTenders(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      simphonyID
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conceptTendersId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTendersQueryVariables,
  APITypes.SyncTendersQuery
>;
export const getOrderType = /* GraphQL */ `query GetOrderType($id: ID!) {
  getOrderType(id: $id) {
    id
    name
    simphonyID
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conceptOrderTypeId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrderTypeQueryVariables,
  APITypes.GetOrderTypeQuery
>;
export const listOrderTypes = /* GraphQL */ `query ListOrderTypes(
  $id: ID
  $filter: ModelOrderTypeFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listOrderTypes(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      name
      simphonyID
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conceptOrderTypeId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderTypesQueryVariables,
  APITypes.ListOrderTypesQuery
>;
export const syncOrderTypes = /* GraphQL */ `query SyncOrderTypes(
  $filter: ModelOrderTypeFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOrderTypes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      simphonyID
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      conceptOrderTypeId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOrderTypesQueryVariables,
  APITypes.SyncOrderTypesQuery
>;
export const getParentConcept = /* GraphQL */ `query GetParentConcept($id: ID!) {
  getParentConcept(id: $id) {
    id
    deleted
    createdAt
    createdByID
    createdByName
    image
    name
    logo
    type
    venues {
      items {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        paymentSharePercentage
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          nextToken
          startedAt
          __typename
        }
        orderType {
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        ratingCount
        averageRating
        feesback
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        categoryTagIDs
        parentConceptID
        containerColor
        avgOrderCompletionEstimate
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    index
    categoryTags {
      items {
        id
        parentConceptID
        name
        image
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetParentConceptQueryVariables,
  APITypes.GetParentConceptQuery
>;
export const listParentConcepts = /* GraphQL */ `query ListParentConcepts(
  $filter: ModelParentConceptFilterInput
  $limit: Int
  $nextToken: String
) {
  listParentConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      deleted
      createdAt
      createdByID
      createdByName
      image
      name
      logo
      type
      venues {
        items {
          id
          accountID
          name
          kiosks
          merchantID
          merchantUsername
          merchantPassword
          merchantIntegrationNumber
          merchantAPIKey
          paymentSharePercentage
          simphonyServerBaseURL
          simphonyShortOrgName
          simphonyLocRef
          simphonyToken
          revenueCenterID
          employeeID
          tenderID
          discountID
          useServiceCharge
          serviceChargeId
          specialRequestID
          addressID
          ratingCount
          averageRating
          feesback
          simphonyAuthClientId
          simphonyAuthUsername
          simphonyAuthPassword
          simphonyAuthBaseURL
          orderTypeDineIn
          orderTypeTakeAway
          orderTypeDelivery
          simphonyTakeAwayRvc
          simphonyDeliveryRvc
          exportInterval
          deleted
          createdAt
          createdByID
          createdByName
          foodicsToken
          enableTableFoodics
          image
          logo
          album
          type
          rating
          location
          description
          areas
          seatTypes
          needAssistance
          spaBooking
          showerBooking
          cigarMenu
          precedence
          isPinRequired
          viewOnly
          openTime
          closeTime
          varifyForAmount
          varifyFirstOrder
          varifyNewAddress
          sliderImages
          receiveWaiters
          WaiterNotificationOption
          applyLoyalty
          rewardPoints
          moneySpent
          afterVat
          afterDeliveryFee
          beforePromoCode
          pointsCalcWithPromoCode
          isBusy
          allowOnlineOrdering
          minOrderVal
          vatPercentage
          serviceChargePercentage
          addDeliveryToVat
          categoryTagIDs
          parentConceptID
          containerColor
          avgOrderCompletionEstimate
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      index
      categoryTags {
        items {
          id
          parentConceptID
          name
          image
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListParentConceptsQueryVariables,
  APITypes.ListParentConceptsQuery
>;
export const syncParentConcepts = /* GraphQL */ `query SyncParentConcepts(
  $filter: ModelParentConceptFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncParentConcepts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      deleted
      createdAt
      createdByID
      createdByName
      image
      name
      logo
      type
      venues {
        items {
          id
          accountID
          name
          kiosks
          merchantID
          merchantUsername
          merchantPassword
          merchantIntegrationNumber
          merchantAPIKey
          paymentSharePercentage
          simphonyServerBaseURL
          simphonyShortOrgName
          simphonyLocRef
          simphonyToken
          revenueCenterID
          employeeID
          tenderID
          discountID
          useServiceCharge
          serviceChargeId
          specialRequestID
          addressID
          ratingCount
          averageRating
          feesback
          simphonyAuthClientId
          simphonyAuthUsername
          simphonyAuthPassword
          simphonyAuthBaseURL
          orderTypeDineIn
          orderTypeTakeAway
          orderTypeDelivery
          simphonyTakeAwayRvc
          simphonyDeliveryRvc
          exportInterval
          deleted
          createdAt
          createdByID
          createdByName
          foodicsToken
          enableTableFoodics
          image
          logo
          album
          type
          rating
          location
          description
          areas
          seatTypes
          needAssistance
          spaBooking
          showerBooking
          cigarMenu
          precedence
          isPinRequired
          viewOnly
          openTime
          closeTime
          varifyForAmount
          varifyFirstOrder
          varifyNewAddress
          sliderImages
          receiveWaiters
          WaiterNotificationOption
          applyLoyalty
          rewardPoints
          moneySpent
          afterVat
          afterDeliveryFee
          beforePromoCode
          pointsCalcWithPromoCode
          isBusy
          allowOnlineOrdering
          minOrderVal
          vatPercentage
          serviceChargePercentage
          addDeliveryToVat
          categoryTagIDs
          parentConceptID
          containerColor
          avgOrderCompletionEstimate
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      index
      categoryTags {
        items {
          id
          parentConceptID
          name
          image
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncParentConceptsQueryVariables,
  APITypes.SyncParentConceptsQuery
>;
export const getTimeline = /* GraphQL */ `query GetTimeline($id: ID!) {
  getTimeline(id: $id) {
    id
    actionName
    oldStatus
    newStatus
    bookingId
    transactionId
    customerId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTimelineQueryVariables,
  APITypes.GetTimelineQuery
>;
export const listTimelines = /* GraphQL */ `query ListTimelines(
  $filter: ModelTimelineFilterInput
  $limit: Int
  $nextToken: String
) {
  listTimelines(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      transactionId
      customerId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTimelinesQueryVariables,
  APITypes.ListTimelinesQuery
>;
export const syncTimelines = /* GraphQL */ `query SyncTimelines(
  $filter: ModelTimelineFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTimelines(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      transactionId
      customerId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTimelinesQueryVariables,
  APITypes.SyncTimelinesQuery
>;
export const timelineByTransactionID = /* GraphQL */ `query TimelineByTransactionID(
  $transactionId: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTimelineFilterInput
  $limit: Int
  $nextToken: String
) {
  timelineByTransactionID(
    transactionId: $transactionId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      transactionId
      customerId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TimelineByTransactionIDQueryVariables,
  APITypes.TimelineByTransactionIDQuery
>;
export const timelineByCustomerID = /* GraphQL */ `query TimelineByCustomerID(
  $customerId: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTimelineFilterInput
  $limit: Int
  $nextToken: String
) {
  timelineByCustomerID(
    customerId: $customerId
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      actionName
      oldStatus
      newStatus
      bookingId
      transactionId
      customerId
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TimelineByCustomerIDQueryVariables,
  APITypes.TimelineByCustomerIDQuery
>;
export const getCall = /* GraphQL */ `query GetCall($id: ID!) {
  getCall(id: $id) {
    id
    phone_number
    customer {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    createdByID
    createdByName
    endedAt
    duration
    date
    employeeId
    updatedAt
    _version
    _deleted
    _lastChangedAt
    callCustomerId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCallQueryVariables, APITypes.GetCallQuery>;
export const listCalls = /* GraphQL */ `query ListCalls(
  $filter: ModelCallFilterInput
  $limit: Int
  $nextToken: String
) {
  listCalls(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      phone_number
      customer {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        autoGeneratedPassword
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      createdByID
      createdByName
      endedAt
      duration
      date
      employeeId
      updatedAt
      _version
      _deleted
      _lastChangedAt
      callCustomerId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListCallsQueryVariables, APITypes.ListCallsQuery>;
export const syncCalls = /* GraphQL */ `query SyncCalls(
  $filter: ModelCallFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCalls(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      phone_number
      customer {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        autoGeneratedPassword
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      createdByID
      createdByName
      endedAt
      duration
      date
      employeeId
      updatedAt
      _version
      _deleted
      _lastChangedAt
      callCustomerId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncCallsQueryVariables, APITypes.SyncCallsQuery>;
export const getAdminRole = /* GraphQL */ `query GetAdminRole($id: ID!) {
  getAdminRole(id: $id) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdminRoleQueryVariables,
  APITypes.GetAdminRoleQuery
>;
export const listAdminRoles = /* GraphQL */ `query ListAdminRoles(
  $filter: ModelAdminRoleFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdminRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminRolesQueryVariables,
  APITypes.ListAdminRolesQuery
>;
export const syncAdminRoles = /* GraphQL */ `query SyncAdminRoles(
  $filter: ModelAdminRoleFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAdminRoles(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      description
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAdminRolesQueryVariables,
  APITypes.SyncAdminRolesQuery
>;
export const getAdminGroup = /* GraphQL */ `query GetAdminGroup($id: ID!) {
  getAdminGroup(id: $id) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdminGroupQueryVariables,
  APITypes.GetAdminGroupQuery
>;
export const listAdminGroups = /* GraphQL */ `query ListAdminGroups(
  $filter: ModelAdminGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdminGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminGroupsQueryVariables,
  APITypes.ListAdminGroupsQuery
>;
export const syncAdminGroups = /* GraphQL */ `query SyncAdminGroups(
  $filter: ModelAdminGroupFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAdminGroups(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      description
      roles
      users
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAdminGroupsQueryVariables,
  APITypes.SyncAdminGroupsQuery
>;
export const getUserConcepts = /* GraphQL */ `query GetUserConcepts($id: ID!) {
  getUserConcepts(id: $id) {
    id
    defaultConcept
    concepts
    parentConcepts
    conceptsRoles
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserConceptsQueryVariables,
  APITypes.GetUserConceptsQuery
>;
export const listUserConcepts = /* GraphQL */ `query ListUserConcepts(
  $filter: ModelUserConceptsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserConcepts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      defaultConcept
      concepts
      parentConcepts
      conceptsRoles
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserConceptsQueryVariables,
  APITypes.ListUserConceptsQuery
>;
export const syncUserConcepts = /* GraphQL */ `query SyncUserConcepts(
  $filter: ModelUserConceptsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserConcepts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      defaultConcept
      concepts
      parentConcepts
      conceptsRoles
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserConceptsQueryVariables,
  APITypes.SyncUserConceptsQuery
>;
export const getTransaction = /* GraphQL */ `query GetTransaction($id: ID!) {
  getTransaction(id: $id) {
    id
    transactionID
    date
    guestName
    guestPhone
    guestID
    guestGroup
    guestsNames
    bookingID
    bookingDate
    amount_cents
    status
    failureReason
    type
    timeSlots
    tables
    conceptID
    currency
    refund
    reference_orderID
    ownerID
    refunded_amount_cents
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTransactionQueryVariables,
  APITypes.GetTransactionQuery
>;
export const listTransactions = /* GraphQL */ `query ListTransactions(
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      transactionID
      date
      guestName
      guestPhone
      guestID
      guestGroup
      guestsNames
      bookingID
      bookingDate
      amount_cents
      status
      failureReason
      type
      timeSlots
      tables
      conceptID
      currency
      refund
      reference_orderID
      ownerID
      refunded_amount_cents
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTransactionsQueryVariables,
  APITypes.ListTransactionsQuery
>;
export const syncTransactions = /* GraphQL */ `query SyncTransactions(
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTransactions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      transactionID
      date
      guestName
      guestPhone
      guestID
      guestGroup
      guestsNames
      bookingID
      bookingDate
      amount_cents
      status
      failureReason
      type
      timeSlots
      tables
      conceptID
      currency
      refund
      reference_orderID
      ownerID
      refunded_amount_cents
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTransactionsQueryVariables,
  APITypes.SyncTransactionsQuery
>;
export const transactionsByDeleted = /* GraphQL */ `query TransactionsByDeleted(
  $deleted: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  transactionsByDeleted(
    deleted: $deleted
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      transactionID
      date
      guestName
      guestPhone
      guestID
      guestGroup
      guestsNames
      bookingID
      bookingDate
      amount_cents
      status
      failureReason
      type
      timeSlots
      tables
      conceptID
      currency
      refund
      reference_orderID
      ownerID
      refunded_amount_cents
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TransactionsByDeletedQueryVariables,
  APITypes.TransactionsByDeletedQuery
>;
export const getPaymentStat = /* GraphQL */ `query GetPaymentStat($id: ID!) {
  getPaymentStat(id: $id) {
    id
    accountID
    conceptID
    year
    month
    monthName
    earning
    refund
    earningTrxCount
    refundTrxCount
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPaymentStatQueryVariables,
  APITypes.GetPaymentStatQuery
>;
export const listPaymentStats = /* GraphQL */ `query ListPaymentStats(
  $filter: ModelPaymentStatFilterInput
  $limit: Int
  $nextToken: String
) {
  listPaymentStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      accountID
      conceptID
      year
      month
      monthName
      earning
      refund
      earningTrxCount
      refundTrxCount
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPaymentStatsQueryVariables,
  APITypes.ListPaymentStatsQuery
>;
export const syncPaymentStats = /* GraphQL */ `query SyncPaymentStats(
  $filter: ModelPaymentStatFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPaymentStats(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      accountID
      conceptID
      year
      month
      monthName
      earning
      refund
      earningTrxCount
      refundTrxCount
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPaymentStatsQueryVariables,
  APITypes.SyncPaymentStatsQuery
>;
export const getCategory = /* GraphQL */ `query GetCategory($id: ID!) {
  getCategory(id: $id) {
    id
    conceptID
    parentConceptID
    preparationAreaID
    subCategory
    name
    guestView
    guestOrder
    staffOrder
    image
    darkImage
    menuItems {
      items {
        id
        conceptID
        kioskID
        name
        description
        image
        precedence
        categoryID
        categoryName
        outOfStock
        requiredPoints
        symphonyID
        comboItemID
        ratings
        prices
        choiceGroups
        pricesItems {
          id
          conceptID
          parentConceptID
          name
          multiLanguages
          price
          symphonyID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsItems {
          id
          conceptID
          parentConceptID
          name
          required
          maxNumberOfChoices
          minNumberOfChoices
          precedence
          type
          choices
          deleted
          createdAt
          createdByID
          createdByName
          multiLanguages
          simphonyID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsRules {
          id
          minNumberOfChoices
          maxNumberOfChoices
          __typename
        }
        ExtraChoiceGroups
        enabled
        hasLimitedQuantity
        quantity
        autoRestock
        restockQuantity
        deleted
        createdAt
        createdByID
        createdByName
        quantityOrdered
        performancePerDay
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    symphonyID
    isHidden
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCategoryQueryVariables,
  APITypes.GetCategoryQuery
>;
export const listCategories = /* GraphQL */ `query ListCategories(
  $id: ID
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCategories(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      conceptID
      parentConceptID
      preparationAreaID
      subCategory
      name
      guestView
      guestOrder
      staffOrder
      image
      darkImage
      menuItems {
        items {
          id
          conceptID
          kioskID
          name
          description
          image
          precedence
          categoryID
          categoryName
          outOfStock
          requiredPoints
          symphonyID
          comboItemID
          ratings
          prices
          choiceGroups
          ExtraChoiceGroups
          enabled
          hasLimitedQuantity
          quantity
          autoRestock
          restockQuantity
          deleted
          createdAt
          createdByID
          createdByName
          quantityOrdered
          performancePerDay
          multiLanguages
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      symphonyID
      isHidden
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCategoriesQueryVariables,
  APITypes.ListCategoriesQuery
>;
export const syncCategories = /* GraphQL */ `query SyncCategories(
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCategories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      parentConceptID
      preparationAreaID
      subCategory
      name
      guestView
      guestOrder
      staffOrder
      image
      darkImage
      menuItems {
        items {
          id
          conceptID
          kioskID
          name
          description
          image
          precedence
          categoryID
          categoryName
          outOfStock
          requiredPoints
          symphonyID
          comboItemID
          ratings
          prices
          choiceGroups
          ExtraChoiceGroups
          enabled
          hasLimitedQuantity
          quantity
          autoRestock
          restockQuantity
          deleted
          createdAt
          createdByID
          createdByName
          quantityOrdered
          performancePerDay
          multiLanguages
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      symphonyID
      isHidden
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCategoriesQueryVariables,
  APITypes.SyncCategoriesQuery
>;
export const categoryByConceptID = /* GraphQL */ `query CategoryByConceptID(
  $conceptID: ID!
  $precedence: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  categoryByConceptID(
    conceptID: $conceptID
    precedence: $precedence
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      parentConceptID
      preparationAreaID
      subCategory
      name
      guestView
      guestOrder
      staffOrder
      image
      darkImage
      menuItems {
        items {
          id
          conceptID
          kioskID
          name
          description
          image
          precedence
          categoryID
          categoryName
          outOfStock
          requiredPoints
          symphonyID
          comboItemID
          ratings
          prices
          choiceGroups
          ExtraChoiceGroups
          enabled
          hasLimitedQuantity
          quantity
          autoRestock
          restockQuantity
          deleted
          createdAt
          createdByID
          createdByName
          quantityOrdered
          performancePerDay
          multiLanguages
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      symphonyID
      isHidden
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CategoryByConceptIDQueryVariables,
  APITypes.CategoryByConceptIDQuery
>;
export const categoryByParentConceptID = /* GraphQL */ `query CategoryByParentConceptID(
  $parentConceptID: ID!
  $precedence: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  categoryByParentConceptID(
    parentConceptID: $parentConceptID
    precedence: $precedence
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      parentConceptID
      preparationAreaID
      subCategory
      name
      guestView
      guestOrder
      staffOrder
      image
      darkImage
      menuItems {
        items {
          id
          conceptID
          kioskID
          name
          description
          image
          precedence
          categoryID
          categoryName
          outOfStock
          requiredPoints
          symphonyID
          comboItemID
          ratings
          prices
          choiceGroups
          ExtraChoiceGroups
          enabled
          hasLimitedQuantity
          quantity
          autoRestock
          restockQuantity
          deleted
          createdAt
          createdByID
          createdByName
          quantityOrdered
          performancePerDay
          multiLanguages
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      precedence
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      symphonyID
      isHidden
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CategoryByParentConceptIDQueryVariables,
  APITypes.CategoryByParentConceptIDQuery
>;
export const getMenuItem = /* GraphQL */ `query GetMenuItem($id: ID!) {
  getMenuItem(id: $id) {
    id
    conceptID
    kioskID
    name
    description
    image
    precedence
    categoryID
    categoryName
    outOfStock
    requiredPoints
    symphonyID
    comboItemID
    ratings
    prices
    choiceGroups
    pricesItems {
      id
      conceptID
      parentConceptID
      name
      multiLanguages
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    choiceGroupsItems {
      id
      conceptID
      parentConceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      choiceItems {
        items {
          id
          conceptID
          name
          price
          precedence
          choiceGroupID
          choiceGroupName
          deleted
          createdAt
          createdByID
          createdByName
          symphonyID
          multiLanguages
          definitionNumber
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      simphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    choiceGroupsRules {
      id
      minNumberOfChoices
      maxNumberOfChoices
      __typename
    }
    ExtraChoiceGroups
    enabled
    hasLimitedQuantity
    quantity
    autoRestock
    restockQuantity
    deleted
    createdAt
    createdByID
    createdByName
    quantityOrdered
    performancePerDay
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMenuItemQueryVariables,
  APITypes.GetMenuItemQuery
>;
export const listMenuItems = /* GraphQL */ `query ListMenuItems(
  $id: ID
  $filter: ModelMenuItemFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMenuItems(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      conceptID
      kioskID
      name
      description
      image
      precedence
      categoryID
      categoryName
      outOfStock
      requiredPoints
      symphonyID
      comboItemID
      ratings
      prices
      choiceGroups
      pricesItems {
        id
        conceptID
        parentConceptID
        name
        multiLanguages
        price
        symphonyID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      choiceGroupsItems {
        id
        conceptID
        parentConceptID
        name
        required
        maxNumberOfChoices
        minNumberOfChoices
        precedence
        type
        choices
        choiceItems {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        multiLanguages
        simphonyID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      choiceGroupsRules {
        id
        minNumberOfChoices
        maxNumberOfChoices
        __typename
      }
      ExtraChoiceGroups
      enabled
      hasLimitedQuantity
      quantity
      autoRestock
      restockQuantity
      deleted
      createdAt
      createdByID
      createdByName
      quantityOrdered
      performancePerDay
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMenuItemsQueryVariables,
  APITypes.ListMenuItemsQuery
>;
export const syncMenuItems = /* GraphQL */ `query SyncMenuItems(
  $filter: ModelMenuItemFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMenuItems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      kioskID
      name
      description
      image
      precedence
      categoryID
      categoryName
      outOfStock
      requiredPoints
      symphonyID
      comboItemID
      ratings
      prices
      choiceGroups
      pricesItems {
        id
        conceptID
        parentConceptID
        name
        multiLanguages
        price
        symphonyID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      choiceGroupsItems {
        id
        conceptID
        parentConceptID
        name
        required
        maxNumberOfChoices
        minNumberOfChoices
        precedence
        type
        choices
        choiceItems {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        multiLanguages
        simphonyID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      choiceGroupsRules {
        id
        minNumberOfChoices
        maxNumberOfChoices
        __typename
      }
      ExtraChoiceGroups
      enabled
      hasLimitedQuantity
      quantity
      autoRestock
      restockQuantity
      deleted
      createdAt
      createdByID
      createdByName
      quantityOrdered
      performancePerDay
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMenuItemsQueryVariables,
  APITypes.SyncMenuItemsQuery
>;
export const menuItemByConceptID = /* GraphQL */ `query MenuItemByConceptID(
  $conceptID: ID!
  $name: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMenuItemFilterInput
  $limit: Int
  $nextToken: String
) {
  menuItemByConceptID(
    conceptID: $conceptID
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      kioskID
      name
      description
      image
      precedence
      categoryID
      categoryName
      outOfStock
      requiredPoints
      symphonyID
      comboItemID
      ratings
      prices
      choiceGroups
      pricesItems {
        id
        conceptID
        parentConceptID
        name
        multiLanguages
        price
        symphonyID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      choiceGroupsItems {
        id
        conceptID
        parentConceptID
        name
        required
        maxNumberOfChoices
        minNumberOfChoices
        precedence
        type
        choices
        choiceItems {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        multiLanguages
        simphonyID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      choiceGroupsRules {
        id
        minNumberOfChoices
        maxNumberOfChoices
        __typename
      }
      ExtraChoiceGroups
      enabled
      hasLimitedQuantity
      quantity
      autoRestock
      restockQuantity
      deleted
      createdAt
      createdByID
      createdByName
      quantityOrdered
      performancePerDay
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MenuItemByConceptIDQueryVariables,
  APITypes.MenuItemByConceptIDQuery
>;
export const menuItemsByCategoryIDAndPrecedence = /* GraphQL */ `query MenuItemsByCategoryIDAndPrecedence(
  $categoryID: ID!
  $precedence: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMenuItemFilterInput
  $limit: Int
  $nextToken: String
) {
  menuItemsByCategoryIDAndPrecedence(
    categoryID: $categoryID
    precedence: $precedence
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      kioskID
      name
      description
      image
      precedence
      categoryID
      categoryName
      outOfStock
      requiredPoints
      symphonyID
      comboItemID
      ratings
      prices
      choiceGroups
      pricesItems {
        id
        conceptID
        parentConceptID
        name
        multiLanguages
        price
        symphonyID
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      choiceGroupsItems {
        id
        conceptID
        parentConceptID
        name
        required
        maxNumberOfChoices
        minNumberOfChoices
        precedence
        type
        choices
        choiceItems {
          nextToken
          startedAt
          __typename
        }
        deleted
        createdAt
        createdByID
        createdByName
        multiLanguages
        simphonyID
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      choiceGroupsRules {
        id
        minNumberOfChoices
        maxNumberOfChoices
        __typename
      }
      ExtraChoiceGroups
      enabled
      hasLimitedQuantity
      quantity
      autoRestock
      restockQuantity
      deleted
      createdAt
      createdByID
      createdByName
      quantityOrdered
      performancePerDay
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MenuItemsByCategoryIDAndPrecedenceQueryVariables,
  APITypes.MenuItemsByCategoryIDAndPrecedenceQuery
>;
export const getPrice = /* GraphQL */ `query GetPrice($id: ID!) {
  getPrice(id: $id) {
    id
    conceptID
    parentConceptID
    name
    multiLanguages
    price
    symphonyID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPriceQueryVariables, APITypes.GetPriceQuery>;
export const listPrices = /* GraphQL */ `query ListPrices(
  $filter: ModelPriceFilterInput
  $limit: Int
  $nextToken: String
) {
  listPrices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      parentConceptID
      name
      multiLanguages
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPricesQueryVariables,
  APITypes.ListPricesQuery
>;
export const syncPrices = /* GraphQL */ `query SyncPrices(
  $filter: ModelPriceFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPrices(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      parentConceptID
      name
      multiLanguages
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPricesQueryVariables,
  APITypes.SyncPricesQuery
>;
export const priceByConceptID = /* GraphQL */ `query PriceByConceptID(
  $conceptID: ID!
  $name: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPriceFilterInput
  $limit: Int
  $nextToken: String
) {
  priceByConceptID(
    conceptID: $conceptID
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      parentConceptID
      name
      multiLanguages
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PriceByConceptIDQueryVariables,
  APITypes.PriceByConceptIDQuery
>;
export const priceByParentConceptID = /* GraphQL */ `query PriceByParentConceptID(
  $parentConceptID: ID!
  $name: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPriceFilterInput
  $limit: Int
  $nextToken: String
) {
  priceByParentConceptID(
    parentConceptID: $parentConceptID
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      parentConceptID
      name
      multiLanguages
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PriceByParentConceptIDQueryVariables,
  APITypes.PriceByParentConceptIDQuery
>;
export const getChoiceGroups = /* GraphQL */ `query GetChoiceGroups($id: ID!) {
  getChoiceGroups(id: $id) {
    id
    conceptID
    parentConceptID
    name
    required
    maxNumberOfChoices
    minNumberOfChoices
    precedence
    type
    choices
    choiceItems {
      items {
        id
        conceptID
        name
        price
        precedence
        choiceGroupID
        choiceGroupName
        deleted
        createdAt
        createdByID
        createdByName
        symphonyID
        multiLanguages
        definitionNumber
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    simphonyID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetChoiceGroupsQueryVariables,
  APITypes.GetChoiceGroupsQuery
>;
export const listChoiceGroups = /* GraphQL */ `query ListChoiceGroups(
  $filter: ModelChoiceGroupsFilterInput
  $limit: Int
  $nextToken: String
) {
  listChoiceGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      parentConceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      choiceItems {
        items {
          id
          conceptID
          name
          price
          precedence
          choiceGroupID
          choiceGroupName
          deleted
          createdAt
          createdByID
          createdByName
          symphonyID
          multiLanguages
          definitionNumber
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      simphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChoiceGroupsQueryVariables,
  APITypes.ListChoiceGroupsQuery
>;
export const syncChoiceGroups = /* GraphQL */ `query SyncChoiceGroups(
  $filter: ModelChoiceGroupsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncChoiceGroups(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      parentConceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      choiceItems {
        items {
          id
          conceptID
          name
          price
          precedence
          choiceGroupID
          choiceGroupName
          deleted
          createdAt
          createdByID
          createdByName
          symphonyID
          multiLanguages
          definitionNumber
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      simphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncChoiceGroupsQueryVariables,
  APITypes.SyncChoiceGroupsQuery
>;
export const choiceGroupsByConceptID = /* GraphQL */ `query ChoiceGroupsByConceptID(
  $conceptID: ID!
  $precedence: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelChoiceGroupsFilterInput
  $limit: Int
  $nextToken: String
) {
  choiceGroupsByConceptID(
    conceptID: $conceptID
    precedence: $precedence
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      parentConceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      choiceItems {
        items {
          id
          conceptID
          name
          price
          precedence
          choiceGroupID
          choiceGroupName
          deleted
          createdAt
          createdByID
          createdByName
          symphonyID
          multiLanguages
          definitionNumber
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      simphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ChoiceGroupsByConceptIDQueryVariables,
  APITypes.ChoiceGroupsByConceptIDQuery
>;
export const choiceGroupsByParentConceptID = /* GraphQL */ `query ChoiceGroupsByParentConceptID(
  $parentConceptID: ID!
  $precedence: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelChoiceGroupsFilterInput
  $limit: Int
  $nextToken: String
) {
  choiceGroupsByParentConceptID(
    parentConceptID: $parentConceptID
    precedence: $precedence
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      parentConceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      choiceItems {
        items {
          id
          conceptID
          name
          price
          precedence
          choiceGroupID
          choiceGroupName
          deleted
          createdAt
          createdByID
          createdByName
          symphonyID
          multiLanguages
          definitionNumber
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      simphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ChoiceGroupsByParentConceptIDQueryVariables,
  APITypes.ChoiceGroupsByParentConceptIDQuery
>;
export const getChoice = /* GraphQL */ `query GetChoice($id: ID!) {
  getChoice(id: $id) {
    id
    conceptID
    name
    price
    precedence
    choiceGroupID
    choiceGroupName
    deleted
    createdAt
    createdByID
    createdByName
    symphonyID
    multiLanguages
    definitionNumber
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetChoiceQueryVariables, APITypes.GetChoiceQuery>;
export const listChoices = /* GraphQL */ `query ListChoices(
  $filter: ModelChoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  listChoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      name
      price
      precedence
      choiceGroupID
      choiceGroupName
      deleted
      createdAt
      createdByID
      createdByName
      symphonyID
      multiLanguages
      definitionNumber
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChoicesQueryVariables,
  APITypes.ListChoicesQuery
>;
export const syncChoices = /* GraphQL */ `query SyncChoices(
  $filter: ModelChoiceFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncChoices(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      name
      price
      precedence
      choiceGroupID
      choiceGroupName
      deleted
      createdAt
      createdByID
      createdByName
      symphonyID
      multiLanguages
      definitionNumber
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncChoicesQueryVariables,
  APITypes.SyncChoicesQuery
>;
export const choiceByConceptID = /* GraphQL */ `query ChoiceByConceptID(
  $conceptID: ID!
  $name: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelChoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  choiceByConceptID(
    conceptID: $conceptID
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      name
      price
      precedence
      choiceGroupID
      choiceGroupName
      deleted
      createdAt
      createdByID
      createdByName
      symphonyID
      multiLanguages
      definitionNumber
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ChoiceByConceptIDQueryVariables,
  APITypes.ChoiceByConceptIDQuery
>;
export const choicesByChoiceGroupIDAndPrecedence = /* GraphQL */ `query ChoicesByChoiceGroupIDAndPrecedence(
  $choiceGroupID: ID!
  $precedence: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelChoiceFilterInput
  $limit: Int
  $nextToken: String
) {
  choicesByChoiceGroupIDAndPrecedence(
    choiceGroupID: $choiceGroupID
    precedence: $precedence
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      name
      price
      precedence
      choiceGroupID
      choiceGroupName
      deleted
      createdAt
      createdByID
      createdByName
      symphonyID
      multiLanguages
      definitionNumber
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ChoicesByChoiceGroupIDAndPrecedenceQueryVariables,
  APITypes.ChoicesByChoiceGroupIDAndPrecedenceQuery
>;
export const getCart = /* GraphQL */ `query GetCart($id: ID!) {
  getCart(id: $id) {
    id
    conceptID
    userID
    orderedItems {
      menuItemID
      categoryID
      name
      quantity
      selectedChoiceGroups {
        id
        name
        selectedChoices {
          id
          name
          price
          __typename
        }
        precedence
        __typename
      }
      price
      priceName
      priceID
      priceMultiLanguages
      multiLanguages
      notes
      image
      type
      __typename
    }
    specialRequest
    deliveryFee
    subTotal
    subTaxTotal
    totalPrice
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCartQueryVariables, APITypes.GetCartQuery>;
export const listCarts = /* GraphQL */ `query ListCarts(
  $filter: ModelCartFilterInput
  $limit: Int
  $nextToken: String
) {
  listCarts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      userID
      orderedItems {
        menuItemID
        categoryID
        name
        quantity
        selectedChoiceGroups {
          id
          name
          precedence
          __typename
        }
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        type
        __typename
      }
      specialRequest
      deliveryFee
      subTotal
      subTaxTotal
      totalPrice
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListCartsQueryVariables, APITypes.ListCartsQuery>;
export const syncCarts = /* GraphQL */ `query SyncCarts(
  $filter: ModelCartFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCarts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      userID
      orderedItems {
        menuItemID
        categoryID
        name
        quantity
        selectedChoiceGroups {
          id
          name
          precedence
          __typename
        }
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        type
        __typename
      }
      specialRequest
      deliveryFee
      subTotal
      subTaxTotal
      totalPrice
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncCartsQueryVariables, APITypes.SyncCartsQuery>;
export const cartByUserID = /* GraphQL */ `query CartByUserID(
  $userID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCartFilterInput
  $limit: Int
  $nextToken: String
) {
  cartByUserID(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      userID
      orderedItems {
        menuItemID
        categoryID
        name
        quantity
        selectedChoiceGroups {
          id
          name
          precedence
          __typename
        }
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        type
        __typename
      }
      specialRequest
      deliveryFee
      subTotal
      subTaxTotal
      totalPrice
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CartByUserIDQueryVariables,
  APITypes.CartByUserIDQuery
>;
export const getAdminStatus = /* GraphQL */ `query GetAdminStatus($id: ID!) {
  getAdminStatus(id: $id) {
    id
    sub
    accountID
    name
    averageCallTime
    numberOfCalls
    idelDuration
    activeDuration
    awayDuration
    lastCallTime
    agentBarAverageCallTime
    agentBarNumberOfCalls
    type
    waiterStatus
    totalAverageRatio
    performancePerDay
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAdminStatusQueryVariables,
  APITypes.GetAdminStatusQuery
>;
export const listAdminStatuses = /* GraphQL */ `query ListAdminStatuses(
  $filter: ModelAdminStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  listAdminStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      sub
      accountID
      name
      averageCallTime
      numberOfCalls
      idelDuration
      activeDuration
      awayDuration
      lastCallTime
      agentBarAverageCallTime
      agentBarNumberOfCalls
      type
      waiterStatus
      totalAverageRatio
      performancePerDay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAdminStatusesQueryVariables,
  APITypes.ListAdminStatusesQuery
>;
export const syncAdminStatuses = /* GraphQL */ `query SyncAdminStatuses(
  $filter: ModelAdminStatusFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAdminStatuses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      sub
      accountID
      name
      averageCallTime
      numberOfCalls
      idelDuration
      activeDuration
      awayDuration
      lastCallTime
      agentBarAverageCallTime
      agentBarNumberOfCalls
      type
      waiterStatus
      totalAverageRatio
      performancePerDay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAdminStatusesQueryVariables,
  APITypes.SyncAdminStatusesQuery
>;
export const ByTotalAverageRatio = /* GraphQL */ `query ByTotalAverageRatio(
  $name: String!
  $totalAverageRatio: ModelFloatKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAdminStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  ByTotalAverageRatio(
    name: $name
    totalAverageRatio: $totalAverageRatio
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      sub
      accountID
      name
      averageCallTime
      numberOfCalls
      idelDuration
      activeDuration
      awayDuration
      lastCallTime
      agentBarAverageCallTime
      agentBarNumberOfCalls
      type
      waiterStatus
      totalAverageRatio
      performancePerDay
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ByTotalAverageRatioQueryVariables,
  APITypes.ByTotalAverageRatioQuery
>;
export const getOnlineOrder = /* GraphQL */ `query GetOnlineOrder($id: ID!) {
  getOnlineOrder(id: $id) {
    id
    conceptID
    statusID
    status
    reason
    orderType
    channel
    userID
    userName
    userPhoneNumber
    dispatcherID
    dispatcherName
    totalAmount
    discountedValue
    subTotal
    subTaxTotal
    tax
    deliveryFee
    orderTime
    executionArn
    specialRequest
    orderAddress
    orderedItems {
      menuItemID
      categoryID
      name
      quantity
      selectedChoiceGroups {
        id
        name
        selectedChoices {
          id
          name
          price
          __typename
        }
        precedence
        __typename
      }
      price
      priceName
      priceID
      priceMultiLanguages
      multiLanguages
      notes
      image
      type
      __typename
    }
    discountID
    amount_cents
    transactionID
    paymentStatus
    paymentType
    referenceId
    cardHolderNumber
    cardHolderName
    cardSchemeName
    deleted
    createdAt
    sentToKitchenAt
    readyAt
    outForDeliveryAt
    deliveredAt
    promoCodeID
    promoCodeName
    promoCodeAppliedTo
    rating
    isReview
    reviewID
    review {
      id
      conceptID
      conceptName
      accountID
      bookingID
      parentConceptID
      guestName
      guestId
      reason
      value
      userID
      orderID
      rate
      feedback
      satisfaction
      thoughts
      itemReviews {
        itemID
        rate
        satisfaction
        feedback
        multiLanguages
        __typename
      }
      photos
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      phoneUser
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdByID
    createdByName
    tmpField
    otp
    avgOrderCompletionEstimate
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOnlineOrderQueryVariables,
  APITypes.GetOnlineOrderQuery
>;
export const listOnlineOrders = /* GraphQL */ `query ListOnlineOrders(
  $filter: ModelOnlineOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOnlineOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      statusID
      status
      reason
      orderType
      channel
      userID
      userName
      userPhoneNumber
      dispatcherID
      dispatcherName
      totalAmount
      discountedValue
      subTotal
      subTaxTotal
      tax
      deliveryFee
      orderTime
      executionArn
      specialRequest
      orderAddress
      orderedItems {
        menuItemID
        categoryID
        name
        quantity
        selectedChoiceGroups {
          id
          name
          precedence
          __typename
        }
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        type
        __typename
      }
      discountID
      amount_cents
      transactionID
      paymentStatus
      paymentType
      referenceId
      cardHolderNumber
      cardHolderName
      cardSchemeName
      deleted
      createdAt
      sentToKitchenAt
      readyAt
      outForDeliveryAt
      deliveredAt
      promoCodeID
      promoCodeName
      promoCodeAppliedTo
      rating
      isReview
      reviewID
      review {
        id
        conceptID
        conceptName
        accountID
        bookingID
        parentConceptID
        guestName
        guestId
        reason
        value
        userID
        orderID
        rate
        feedback
        satisfaction
        thoughts
        itemReviews {
          itemID
          rate
          satisfaction
          feedback
          multiLanguages
          __typename
        }
        photos
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        phoneUser
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdByID
      createdByName
      tmpField
      otp
      avgOrderCompletionEstimate
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOnlineOrdersQueryVariables,
  APITypes.ListOnlineOrdersQuery
>;
export const syncOnlineOrders = /* GraphQL */ `query SyncOnlineOrders(
  $filter: ModelOnlineOrderFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOnlineOrders(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      statusID
      status
      reason
      orderType
      channel
      userID
      userName
      userPhoneNumber
      dispatcherID
      dispatcherName
      totalAmount
      discountedValue
      subTotal
      subTaxTotal
      tax
      deliveryFee
      orderTime
      executionArn
      specialRequest
      orderAddress
      orderedItems {
        menuItemID
        categoryID
        name
        quantity
        selectedChoiceGroups {
          id
          name
          precedence
          __typename
        }
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        type
        __typename
      }
      discountID
      amount_cents
      transactionID
      paymentStatus
      paymentType
      referenceId
      cardHolderNumber
      cardHolderName
      cardSchemeName
      deleted
      createdAt
      sentToKitchenAt
      readyAt
      outForDeliveryAt
      deliveredAt
      promoCodeID
      promoCodeName
      promoCodeAppliedTo
      rating
      isReview
      reviewID
      review {
        id
        conceptID
        conceptName
        accountID
        bookingID
        parentConceptID
        guestName
        guestId
        reason
        value
        userID
        orderID
        rate
        feedback
        satisfaction
        thoughts
        itemReviews {
          itemID
          rate
          satisfaction
          feedback
          multiLanguages
          __typename
        }
        photos
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        phoneUser
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdByID
      createdByName
      tmpField
      otp
      avgOrderCompletionEstimate
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOnlineOrdersQueryVariables,
  APITypes.SyncOnlineOrdersQuery
>;
export const orderByConceptID = /* GraphQL */ `query OrderByConceptID(
  $conceptID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOnlineOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  orderByConceptID(
    conceptID: $conceptID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      statusID
      status
      reason
      orderType
      channel
      userID
      userName
      userPhoneNumber
      dispatcherID
      dispatcherName
      totalAmount
      discountedValue
      subTotal
      subTaxTotal
      tax
      deliveryFee
      orderTime
      executionArn
      specialRequest
      orderAddress
      orderedItems {
        menuItemID
        categoryID
        name
        quantity
        selectedChoiceGroups {
          id
          name
          precedence
          __typename
        }
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        type
        __typename
      }
      discountID
      amount_cents
      transactionID
      paymentStatus
      paymentType
      referenceId
      cardHolderNumber
      cardHolderName
      cardSchemeName
      deleted
      createdAt
      sentToKitchenAt
      readyAt
      outForDeliveryAt
      deliveredAt
      promoCodeID
      promoCodeName
      promoCodeAppliedTo
      rating
      isReview
      reviewID
      review {
        id
        conceptID
        conceptName
        accountID
        bookingID
        parentConceptID
        guestName
        guestId
        reason
        value
        userID
        orderID
        rate
        feedback
        satisfaction
        thoughts
        itemReviews {
          itemID
          rate
          satisfaction
          feedback
          multiLanguages
          __typename
        }
        photos
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        phoneUser
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdByID
      createdByName
      tmpField
      otp
      avgOrderCompletionEstimate
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OrderByConceptIDQueryVariables,
  APITypes.OrderByConceptIDQuery
>;
export const orderByStatus = /* GraphQL */ `query OrderByStatus(
  $status: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOnlineOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  orderByStatus(
    status: $status
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      statusID
      status
      reason
      orderType
      channel
      userID
      userName
      userPhoneNumber
      dispatcherID
      dispatcherName
      totalAmount
      discountedValue
      subTotal
      subTaxTotal
      tax
      deliveryFee
      orderTime
      executionArn
      specialRequest
      orderAddress
      orderedItems {
        menuItemID
        categoryID
        name
        quantity
        selectedChoiceGroups {
          id
          name
          precedence
          __typename
        }
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        type
        __typename
      }
      discountID
      amount_cents
      transactionID
      paymentStatus
      paymentType
      referenceId
      cardHolderNumber
      cardHolderName
      cardSchemeName
      deleted
      createdAt
      sentToKitchenAt
      readyAt
      outForDeliveryAt
      deliveredAt
      promoCodeID
      promoCodeName
      promoCodeAppliedTo
      rating
      isReview
      reviewID
      review {
        id
        conceptID
        conceptName
        accountID
        bookingID
        parentConceptID
        guestName
        guestId
        reason
        value
        userID
        orderID
        rate
        feedback
        satisfaction
        thoughts
        itemReviews {
          itemID
          rate
          satisfaction
          feedback
          multiLanguages
          __typename
        }
        photos
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        phoneUser
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdByID
      createdByName
      tmpField
      otp
      avgOrderCompletionEstimate
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OrderByStatusQueryVariables,
  APITypes.OrderByStatusQuery
>;
export const ordersByUserID = /* GraphQL */ `query OrdersByUserID(
  $userID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOnlineOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  ordersByUserID(
    userID: $userID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      statusID
      status
      reason
      orderType
      channel
      userID
      userName
      userPhoneNumber
      dispatcherID
      dispatcherName
      totalAmount
      discountedValue
      subTotal
      subTaxTotal
      tax
      deliveryFee
      orderTime
      executionArn
      specialRequest
      orderAddress
      orderedItems {
        menuItemID
        categoryID
        name
        quantity
        selectedChoiceGroups {
          id
          name
          precedence
          __typename
        }
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        type
        __typename
      }
      discountID
      amount_cents
      transactionID
      paymentStatus
      paymentType
      referenceId
      cardHolderNumber
      cardHolderName
      cardSchemeName
      deleted
      createdAt
      sentToKitchenAt
      readyAt
      outForDeliveryAt
      deliveredAt
      promoCodeID
      promoCodeName
      promoCodeAppliedTo
      rating
      isReview
      reviewID
      review {
        id
        conceptID
        conceptName
        accountID
        bookingID
        parentConceptID
        guestName
        guestId
        reason
        value
        userID
        orderID
        rate
        feedback
        satisfaction
        thoughts
        itemReviews {
          itemID
          rate
          satisfaction
          feedback
          multiLanguages
          __typename
        }
        photos
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        phoneUser
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdByID
      createdByName
      tmpField
      otp
      avgOrderCompletionEstimate
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OrdersByUserIDQueryVariables,
  APITypes.OrdersByUserIDQuery
>;
export const orderByDeleted = /* GraphQL */ `query OrderByDeleted(
  $deleted: String!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOnlineOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  orderByDeleted(
    deleted: $deleted
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      statusID
      status
      reason
      orderType
      channel
      userID
      userName
      userPhoneNumber
      dispatcherID
      dispatcherName
      totalAmount
      discountedValue
      subTotal
      subTaxTotal
      tax
      deliveryFee
      orderTime
      executionArn
      specialRequest
      orderAddress
      orderedItems {
        menuItemID
        categoryID
        name
        quantity
        selectedChoiceGroups {
          id
          name
          precedence
          __typename
        }
        price
        priceName
        priceID
        priceMultiLanguages
        multiLanguages
        notes
        image
        type
        __typename
      }
      discountID
      amount_cents
      transactionID
      paymentStatus
      paymentType
      referenceId
      cardHolderNumber
      cardHolderName
      cardSchemeName
      deleted
      createdAt
      sentToKitchenAt
      readyAt
      outForDeliveryAt
      deliveredAt
      promoCodeID
      promoCodeName
      promoCodeAppliedTo
      rating
      isReview
      reviewID
      review {
        id
        conceptID
        conceptName
        accountID
        bookingID
        parentConceptID
        guestName
        guestId
        reason
        value
        userID
        orderID
        rate
        feedback
        satisfaction
        thoughts
        itemReviews {
          itemID
          rate
          satisfaction
          feedback
          multiLanguages
          __typename
        }
        photos
        multiLanguages
        deleted
        createdAt
        createdByID
        createdByName
        phoneUser
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdByID
      createdByName
      tmpField
      otp
      avgOrderCompletionEstimate
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OrderByDeletedQueryVariables,
  APITypes.OrderByDeletedQuery
>;
export const getOrderStatus = /* GraphQL */ `query GetOrderStatus($id: ID!) {
  getOrderStatus(id: $id) {
    id
    conceptID
    name
    color
    precedence
    accessibleStatus
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrderStatusQueryVariables,
  APITypes.GetOrderStatusQuery
>;
export const listOrderStatuses = /* GraphQL */ `query ListOrderStatuses(
  $filter: ModelOrderStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      name
      color
      precedence
      accessibleStatus
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderStatusesQueryVariables,
  APITypes.ListOrderStatusesQuery
>;
export const syncOrderStatuses = /* GraphQL */ `query SyncOrderStatuses(
  $filter: ModelOrderStatusFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOrderStatuses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      name
      color
      precedence
      accessibleStatus
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOrderStatusesQueryVariables,
  APITypes.SyncOrderStatusesQuery
>;
export const getDiscountCoupon = /* GraphQL */ `query GetDiscountCoupon($id: ID!) {
  getDiscountCoupon(id: $id) {
    id
    discountPercent
    usedBy
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDiscountCouponQueryVariables,
  APITypes.GetDiscountCouponQuery
>;
export const listDiscountCoupons = /* GraphQL */ `query ListDiscountCoupons(
  $filter: ModelDiscountCouponFilterInput
  $limit: Int
  $nextToken: String
) {
  listDiscountCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      discountPercent
      usedBy
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDiscountCouponsQueryVariables,
  APITypes.ListDiscountCouponsQuery
>;
export const syncDiscountCoupons = /* GraphQL */ `query SyncDiscountCoupons(
  $filter: ModelDiscountCouponFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDiscountCoupons(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      discountPercent
      usedBy
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDiscountCouponsQueryVariables,
  APITypes.SyncDiscountCouponsQuery
>;
export const getZone = /* GraphQL */ `query GetZone($id: ID!) {
  getZone(id: $id) {
    id
    conceptID
    location
    deliveryFee
    concepts {
      id
      deliveryFee
      __typename
    }
    polygon
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetZoneQueryVariables, APITypes.GetZoneQuery>;
export const listZones = /* GraphQL */ `query ListZones(
  $filter: ModelZoneFilterInput
  $limit: Int
  $nextToken: String
) {
  listZones(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      location
      deliveryFee
      concepts {
        id
        deliveryFee
        __typename
      }
      polygon
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListZonesQueryVariables, APITypes.ListZonesQuery>;
export const syncZones = /* GraphQL */ `query SyncZones(
  $filter: ModelZoneFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncZones(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      location
      deliveryFee
      concepts {
        id
        deliveryFee
        __typename
      }
      polygon
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncZonesQueryVariables, APITypes.SyncZonesQuery>;
export const zoneByConceptID = /* GraphQL */ `query ZoneByConceptID(
  $conceptID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelZoneFilterInput
  $limit: Int
  $nextToken: String
) {
  zoneByConceptID(
    conceptID: $conceptID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      location
      deliveryFee
      concepts {
        id
        deliveryFee
        __typename
      }
      polygon
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ZoneByConceptIDQueryVariables,
  APITypes.ZoneByConceptIDQuery
>;
export const zoneByConceptIDSortedByLocationName = /* GraphQL */ `query ZoneByConceptIDSortedByLocationName(
  $conceptID: ID!
  $location: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelZoneFilterInput
  $limit: Int
  $nextToken: String
) {
  zoneByConceptIDSortedByLocationName(
    conceptID: $conceptID
    location: $location
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      location
      deliveryFee
      concepts {
        id
        deliveryFee
        __typename
      }
      polygon
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ZoneByConceptIDSortedByLocationNameQueryVariables,
  APITypes.ZoneByConceptIDSortedByLocationNameQuery
>;
export const getUserIP = /* GraphQL */ `query GetUserIP($id: ID!) {
  getUserIP(id: $id) {
    id
    ip
    date
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserIPQueryVariables, APITypes.GetUserIPQuery>;
export const listUserIPS = /* GraphQL */ `query ListUserIPS(
  $filter: ModelUserIPFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserIPS(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      ip
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserIPSQueryVariables,
  APITypes.ListUserIPSQuery
>;
export const syncUserIPS = /* GraphQL */ `query SyncUserIPS(
  $filter: ModelUserIPFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserIPS(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      ip
      date
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserIPSQueryVariables,
  APITypes.SyncUserIPSQuery
>;
export const getMainCategory = /* GraphQL */ `query GetMainCategory($id: ID!) {
  getMainCategory(id: $id) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    guestView
    guestOrder
    staffOrder
    image
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMainCategoryQueryVariables,
  APITypes.GetMainCategoryQuery
>;
export const listMainCategories = /* GraphQL */ `query ListMainCategories(
  $filter: ModelMainCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listMainCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMainCategoriesQueryVariables,
  APITypes.ListMainCategoriesQuery
>;
export const syncMainCategories = /* GraphQL */ `query SyncMainCategories(
  $filter: ModelMainCategoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMainCategories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMainCategoriesQueryVariables,
  APITypes.SyncMainCategoriesQuery
>;
export const mainCategoryByConceptID = /* GraphQL */ `query MainCategoryByConceptID(
  $conceptID: ID!
  $precedence: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMainCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  mainCategoryByConceptID(
    conceptID: $conceptID
    precedence: $precedence
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MainCategoryByConceptIDQueryVariables,
  APITypes.MainCategoryByConceptIDQuery
>;
export const getSubCategory = /* GraphQL */ `query GetSubCategory($id: ID!) {
  getSubCategory(id: $id) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    mainCategoryID
    guestView
    guestOrder
    staffOrder
    image
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSubCategoryQueryVariables,
  APITypes.GetSubCategoryQuery
>;
export const listSubCategories = /* GraphQL */ `query ListSubCategories(
  $filter: ModelSubCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listSubCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      mainCategoryID
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSubCategoriesQueryVariables,
  APITypes.ListSubCategoriesQuery
>;
export const syncSubCategories = /* GraphQL */ `query SyncSubCategories(
  $filter: ModelSubCategoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncSubCategories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      mainCategoryID
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncSubCategoriesQueryVariables,
  APITypes.SyncSubCategoriesQuery
>;
export const subCategoryByConceptID = /* GraphQL */ `query SubCategoryByConceptID(
  $conceptID: ID!
  $precedence: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSubCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  subCategoryByConceptID(
    conceptID: $conceptID
    precedence: $precedence
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      name
      precedence
      openTime
      closeTime
      mainCategoryID
      guestView
      guestOrder
      staffOrder
      image
      menuPDF
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SubCategoryByConceptIDQueryVariables,
  APITypes.SubCategoryByConceptIDQuery
>;
export const getChannel = /* GraphQL */ `query GetChannel($id: ID!) {
  getChannel(id: $id) {
    id
    name
    description
    numberOfOrders
    isActive
    multiLanguages
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetChannelQueryVariables,
  APITypes.GetChannelQuery
>;
export const listChannels = /* GraphQL */ `query ListChannels(
  $filter: ModelChannelFilterInput
  $limit: Int
  $nextToken: String
) {
  listChannels(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      numberOfOrders
      isActive
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListChannelsQueryVariables,
  APITypes.ListChannelsQuery
>;
export const syncChannels = /* GraphQL */ `query SyncChannels(
  $filter: ModelChannelFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncChannels(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      description
      numberOfOrders
      isActive
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncChannelsQueryVariables,
  APITypes.SyncChannelsQuery
>;
export const getPromocode = /* GraphQL */ `query GetPromocode($id: ID!) {
  getPromocode(id: $id) {
    id
    name
    redemptions
    status
    startDate
    endDate
    appliesTo
    minOrderReq
    minOrderVal
    minOrderQty
    tenderReq
    discountVal
    discountLimit
    quota
    quotaUsed
    totalRevenue
    appliedDiscountedVal
    usePerCustomer
    channelReq
    customerEligibility
    customerReq
    spendingExceeds
    spendingBelow
    orderSinceDate
    orderFromDate
    orderToDate
    isDisplayed
    deleted
    createdAt
    createdByID
    createdByName
    parentConceptIDs
    conceptIDs
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPromocodeQueryVariables,
  APITypes.GetPromocodeQuery
>;
export const listPromocodes = /* GraphQL */ `query ListPromocodes(
  $filter: ModelPromocodeFilterInput
  $limit: Int
  $nextToken: String
) {
  listPromocodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      redemptions
      status
      startDate
      endDate
      appliesTo
      minOrderReq
      minOrderVal
      minOrderQty
      tenderReq
      discountVal
      discountLimit
      quota
      quotaUsed
      totalRevenue
      appliedDiscountedVal
      usePerCustomer
      channelReq
      customerEligibility
      customerReq
      spendingExceeds
      spendingBelow
      orderSinceDate
      orderFromDate
      orderToDate
      isDisplayed
      deleted
      createdAt
      createdByID
      createdByName
      parentConceptIDs
      conceptIDs
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPromocodesQueryVariables,
  APITypes.ListPromocodesQuery
>;
export const syncPromocodes = /* GraphQL */ `query SyncPromocodes(
  $filter: ModelPromocodeFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPromocodes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      redemptions
      status
      startDate
      endDate
      appliesTo
      minOrderReq
      minOrderVal
      minOrderQty
      tenderReq
      discountVal
      discountLimit
      quota
      quotaUsed
      totalRevenue
      appliedDiscountedVal
      usePerCustomer
      channelReq
      customerEligibility
      customerReq
      spendingExceeds
      spendingBelow
      orderSinceDate
      orderFromDate
      orderToDate
      isDisplayed
      deleted
      createdAt
      createdByID
      createdByName
      parentConceptIDs
      conceptIDs
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPromocodesQueryVariables,
  APITypes.SyncPromocodesQuery
>;
export const promoCodeByName = /* GraphQL */ `query PromoCodeByName(
  $name: String!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPromocodeFilterInput
  $limit: Int
  $nextToken: String
) {
  promoCodeByName(
    name: $name
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      redemptions
      status
      startDate
      endDate
      appliesTo
      minOrderReq
      minOrderVal
      minOrderQty
      tenderReq
      discountVal
      discountLimit
      quota
      quotaUsed
      totalRevenue
      appliedDiscountedVal
      usePerCustomer
      channelReq
      customerEligibility
      customerReq
      spendingExceeds
      spendingBelow
      orderSinceDate
      orderFromDate
      orderToDate
      isDisplayed
      deleted
      createdAt
      createdByID
      createdByName
      parentConceptIDs
      conceptIDs
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PromoCodeByNameQueryVariables,
  APITypes.PromoCodeByNameQuery
>;
export const promoCodeByDeleted = /* GraphQL */ `query PromoCodeByDeleted(
  $deleted: String!
  $totalRevenue: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPromocodeFilterInput
  $limit: Int
  $nextToken: String
) {
  promoCodeByDeleted(
    deleted: $deleted
    totalRevenue: $totalRevenue
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      redemptions
      status
      startDate
      endDate
      appliesTo
      minOrderReq
      minOrderVal
      minOrderQty
      tenderReq
      discountVal
      discountLimit
      quota
      quotaUsed
      totalRevenue
      appliedDiscountedVal
      usePerCustomer
      channelReq
      customerEligibility
      customerReq
      spendingExceeds
      spendingBelow
      orderSinceDate
      orderFromDate
      orderToDate
      isDisplayed
      deleted
      createdAt
      createdByID
      createdByName
      parentConceptIDs
      conceptIDs
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PromoCodeByDeletedQueryVariables,
  APITypes.PromoCodeByDeletedQuery
>;
export const getPromocodeDashboard = /* GraphQL */ `query GetPromocodeDashboard($id: ID!) {
  getPromocodeDashboard(id: $id) {
    id
    conceptID
    totalRevenue
    totalRedemptions
    totalDiscount
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPromocodeDashboardQueryVariables,
  APITypes.GetPromocodeDashboardQuery
>;
export const listPromocodeDashboards = /* GraphQL */ `query ListPromocodeDashboards(
  $filter: ModelPromocodeDashboardFilterInput
  $limit: Int
  $nextToken: String
) {
  listPromocodeDashboards(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      totalRevenue
      totalRedemptions
      totalDiscount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPromocodeDashboardsQueryVariables,
  APITypes.ListPromocodeDashboardsQuery
>;
export const syncPromocodeDashboards = /* GraphQL */ `query SyncPromocodeDashboards(
  $filter: ModelPromocodeDashboardFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPromocodeDashboards(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      totalRevenue
      totalRedemptions
      totalDiscount
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncPromocodeDashboardsQueryVariables,
  APITypes.SyncPromocodeDashboardsQuery
>;
export const getConceptRevenue = /* GraphQL */ `query GetConceptRevenue($id: ID!) {
  getConceptRevenue(id: $id) {
    id
    conceptID
    date
    driveThruRevenue
    pickupRevenue
    deliveryRevenue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConceptRevenueQueryVariables,
  APITypes.GetConceptRevenueQuery
>;
export const listConceptRevenues = /* GraphQL */ `query ListConceptRevenues(
  $filter: ModelConceptRevenueFilterInput
  $limit: Int
  $nextToken: String
) {
  listConceptRevenues(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      date
      driveThruRevenue
      pickupRevenue
      deliveryRevenue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListConceptRevenuesQueryVariables,
  APITypes.ListConceptRevenuesQuery
>;
export const syncConceptRevenues = /* GraphQL */ `query SyncConceptRevenues(
  $filter: ModelConceptRevenueFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncConceptRevenues(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      date
      driveThruRevenue
      pickupRevenue
      deliveryRevenue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncConceptRevenuesQueryVariables,
  APITypes.SyncConceptRevenuesQuery
>;
export const RevenueByConceptID = /* GraphQL */ `query RevenueByConceptID(
  $conceptID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelConceptRevenueFilterInput
  $limit: Int
  $nextToken: String
) {
  RevenueByConceptID(
    conceptID: $conceptID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      date
      driveThruRevenue
      pickupRevenue
      deliveryRevenue
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RevenueByConceptIDQueryVariables,
  APITypes.RevenueByConceptIDQuery
>;
export const getLoyaltyItem = /* GraphQL */ `query GetLoyaltyItem($id: ID!) {
  getLoyaltyItem(id: $id) {
    id
    name
    conceptID
    parentConceptID
    description
    startDate
    endDate
    options
    points
    redemptionPerUser
    image
    simphonyID
    multiLanguages
    isActive
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLoyaltyItemQueryVariables,
  APITypes.GetLoyaltyItemQuery
>;
export const listLoyaltyItems = /* GraphQL */ `query ListLoyaltyItems(
  $filter: ModelLoyaltyItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listLoyaltyItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      conceptID
      parentConceptID
      description
      startDate
      endDate
      options
      points
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLoyaltyItemsQueryVariables,
  APITypes.ListLoyaltyItemsQuery
>;
export const syncLoyaltyItems = /* GraphQL */ `query SyncLoyaltyItems(
  $filter: ModelLoyaltyItemFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncLoyaltyItems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      conceptID
      parentConceptID
      description
      startDate
      endDate
      options
      points
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncLoyaltyItemsQueryVariables,
  APITypes.SyncLoyaltyItemsQuery
>;
export const LoyaltyItemByConceptID = /* GraphQL */ `query LoyaltyItemByConceptID(
  $conceptID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelLoyaltyItemFilterInput
  $limit: Int
  $nextToken: String
) {
  LoyaltyItemByConceptID(
    conceptID: $conceptID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      conceptID
      parentConceptID
      description
      startDate
      endDate
      options
      points
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LoyaltyItemByConceptIDQueryVariables,
  APITypes.LoyaltyItemByConceptIDQuery
>;
export const LoyaltyItemByParentConceptID = /* GraphQL */ `query LoyaltyItemByParentConceptID(
  $parentConceptID: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelLoyaltyItemFilterInput
  $limit: Int
  $nextToken: String
) {
  LoyaltyItemByParentConceptID(
    parentConceptID: $parentConceptID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      conceptID
      parentConceptID
      description
      startDate
      endDate
      options
      points
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LoyaltyItemByParentConceptIDQueryVariables,
  APITypes.LoyaltyItemByParentConceptIDQuery
>;
export const getOTP = /* GraphQL */ `query GetOTP($id: ID!) {
  getOTP(id: $id) {
    id
    OTP
    redeemed
    deleted
    userID
    conceptID
    loyaltyItemID
    loyaltyItem {
      id
      name
      conceptID
      parentConceptID
      description
      startDate
      endDate
      options
      points
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    user {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    concept {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      paymentSharePercentage
      simphonyServerBaseURL
      simphonyShortOrgName
      simphonyLocRef
      simphonyToken
      revenueCenterID
      employeeID
      tenderID
      tenders {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptTendersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderType {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptOrderTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      discountID
      useServiceCharge
      serviceChargeId
      specialRequestID
      addressID
      ratingCount
      averageRating
      feesback
      simphonyAuthClientId
      simphonyAuthUsername
      simphonyAuthPassword
      simphonyAuthBaseURL
      orderTypeDineIn
      orderTypeTakeAway
      orderTypeDelivery
      simphonyTakeAwayRvc
      simphonyDeliveryRvc
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      openTime
      closeTime
      varifyForAmount
      varifyFirstOrder
      varifyNewAddress
      sliderImages
      receiveWaiters
      WaiterNotificationOption
      applyLoyalty
      rewardPoints
      moneySpent
      afterVat
      afterDeliveryFee
      beforePromoCode
      pointsCalcWithPromoCode
      isBusy
      allowOnlineOrdering
      minOrderVal
      vatPercentage
      serviceChargePercentage
      addDeliveryToVat
      categoryTagIDs
      parentConceptID
      containerColor
      avgOrderCompletionEstimate
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOTPQueryVariables, APITypes.GetOTPQuery>;
export const listOTPS = /* GraphQL */ `query ListOTPS($filter: ModelOTPFilterInput, $limit: Int, $nextToken: String) {
  listOTPS(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      OTP
      redeemed
      deleted
      userID
      conceptID
      loyaltyItemID
      loyaltyItem {
        id
        name
        conceptID
        parentConceptID
        description
        startDate
        endDate
        options
        points
        redemptionPerUser
        image
        simphonyID
        multiLanguages
        isActive
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        autoGeneratedPassword
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      concept {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        paymentSharePercentage
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          nextToken
          startedAt
          __typename
        }
        orderType {
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        ratingCount
        averageRating
        feesback
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        categoryTagIDs
        parentConceptID
        containerColor
        avgOrderCompletionEstimate
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListOTPSQueryVariables, APITypes.ListOTPSQuery>;
export const syncOTPS = /* GraphQL */ `query SyncOTPS(
  $filter: ModelOTPFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOTPS(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      OTP
      redeemed
      deleted
      userID
      conceptID
      loyaltyItemID
      loyaltyItem {
        id
        name
        conceptID
        parentConceptID
        description
        startDate
        endDate
        options
        points
        redemptionPerUser
        image
        simphonyID
        multiLanguages
        isActive
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        autoGeneratedPassword
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      concept {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        paymentSharePercentage
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          nextToken
          startedAt
          __typename
        }
        orderType {
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        ratingCount
        averageRating
        feesback
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        categoryTagIDs
        parentConceptID
        containerColor
        avgOrderCompletionEstimate
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncOTPSQueryVariables, APITypes.SyncOTPSQuery>;
export const ByOTP = /* GraphQL */ `query ByOTP(
  $OTP: String!
  $sortDirection: ModelSortDirection
  $filter: ModelOTPFilterInput
  $limit: Int
  $nextToken: String
) {
  ByOTP(
    OTP: $OTP
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      OTP
      redeemed
      deleted
      userID
      conceptID
      loyaltyItemID
      loyaltyItem {
        id
        name
        conceptID
        parentConceptID
        description
        startDate
        endDate
        options
        points
        redemptionPerUser
        image
        simphonyID
        multiLanguages
        isActive
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        autoGeneratedPassword
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      concept {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        paymentSharePercentage
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          nextToken
          startedAt
          __typename
        }
        orderType {
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        ratingCount
        averageRating
        feesback
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        categoryTagIDs
        parentConceptID
        containerColor
        avgOrderCompletionEstimate
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ByOTPQueryVariables, APITypes.ByOTPQuery>;
export const otpByUserID = /* GraphQL */ `query OtpByUserID(
  $userID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelOTPFilterInput
  $limit: Int
  $nextToken: String
) {
  otpByUserID(
    userID: $userID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      OTP
      redeemed
      deleted
      userID
      conceptID
      loyaltyItemID
      loyaltyItem {
        id
        name
        conceptID
        parentConceptID
        description
        startDate
        endDate
        options
        points
        redemptionPerUser
        image
        simphonyID
        multiLanguages
        isActive
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      user {
        id
        accountID
        username
        preferred_username
        email
        email_verified
        phone_number
        phone_number_verified
        name
        nickname
        given_name
        middle_name
        family_name
        address
        birthdate
        gender
        locale
        picture
        pictureCrop
        website
        zoneinfo
        verification_code
        facebook
        instagram
        group
        phones
        flags
        flagsName
        interests
        interestsName
        status
        stats
        spending
        prevOrderDate
        lastOrderDate
        promocodes {
          name
          promocodeId
          canBeUsed
          discountVal
          discountLimit
          isDisplayed
          __typename
        }
        mostOrderedItems
        points
        deleted
        createdAt
        createdByID
        createdByName
        cognitoUsername
        cognitoSub
        autoGeneratedPassword
        updated
        sub
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      concept {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        paymentSharePercentage
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          nextToken
          startedAt
          __typename
        }
        orderType {
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        ratingCount
        averageRating
        feesback
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        categoryTagIDs
        parentConceptID
        containerColor
        avgOrderCompletionEstimate
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OtpByUserIDQueryVariables,
  APITypes.OtpByUserIDQuery
>;
export const getMostOrderedItems = /* GraphQL */ `query GetMostOrderedItems($id: ID!) {
  getMostOrderedItems(id: $id) {
    id
    conceptID
    menuItemID
    menuItemName
    menuItemImage
    quantity
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMostOrderedItemsQueryVariables,
  APITypes.GetMostOrderedItemsQuery
>;
export const listMostOrderedItems = /* GraphQL */ `query ListMostOrderedItems(
  $filter: ModelMostOrderedItemsFilterInput
  $limit: Int
  $nextToken: String
) {
  listMostOrderedItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      conceptID
      menuItemID
      menuItemName
      menuItemImage
      quantity
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMostOrderedItemsQueryVariables,
  APITypes.ListMostOrderedItemsQuery
>;
export const syncMostOrderedItems = /* GraphQL */ `query SyncMostOrderedItems(
  $filter: ModelMostOrderedItemsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMostOrderedItems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      conceptID
      menuItemID
      menuItemName
      menuItemImage
      quantity
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMostOrderedItemsQueryVariables,
  APITypes.SyncMostOrderedItemsQuery
>;
export const mostOrderedItemsByConceptID = /* GraphQL */ `query MostOrderedItemsByConceptID(
  $conceptID: String!
  $quantity: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMostOrderedItemsFilterInput
  $limit: Int
  $nextToken: String
) {
  mostOrderedItemsByConceptID(
    conceptID: $conceptID
    quantity: $quantity
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      conceptID
      menuItemID
      menuItemName
      menuItemImage
      quantity
      deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MostOrderedItemsByConceptIDQueryVariables,
  APITypes.MostOrderedItemsByConceptIDQuery
>;
