import { FC, ReactElement, useEffect } from "react";

import { Pages } from "../../constants/enums";
import useApp from "../../hooks/useApp";
import useAdmin from "../../hooks/useAdmin";
import { useSelector } from "react-redux";
import { AdminPermissions } from "../../models/app";
import { User } from "../../models";
interface Props {
  children: ReactElement;
}

const PublicRoute: FC<Props> = ({ children }) => {
  const session = useSelector((state: any) => state.app.session);
  const { navigateTo } = useApp();
  const { isAdmin } = useAdmin("admins", "admin");
  const userPermissions: AdminPermissions = useSelector(
    (state: any) => state.app.userPermissions
  );
  useEffect(() => {
    if (session) {
      if (isAdmin(session)) {
        
        if (userPermissions && userPermissions.can_see_reports) {
          navigateTo(`/dashboard/${Pages.KPI_REPORT}`);
        } else if (userPermissions && userPermissions.kitchen) {
          navigateTo(`/dashboard/${Pages.KDS}`);
        } else {
          navigateTo(`/dashboard/${Pages.WAREHOUSE}`);
        }
      } else if (
        session["cognito:groups"] &&
        session["cognito:groups"][0] === "staff"
      ) {
        navigateTo(`/dashboard/${Pages.WAITER_ORDER}`);
      } else {
        navigateTo(`/dashboard/${Pages.ADMINS}/${session["cognito:username"]}`);
      }
    }

    // eslint-disable-next-line
  }, [session]);

  return <>{children}</>;
};

export default PublicRoute;
