/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
  id?: string | null,
  accountID: string,
  username?: string | null,
  preferred_username?: string | null,
  email?: string | null,
  email_verified?: boolean | null,
  phone_number?: string | null,
  phone_number_verified?: boolean | null,
  name?: string | null,
  nickname?: string | null,
  given_name?: string | null,
  middle_name?: string | null,
  family_name?: string | null,
  address?: string | null,
  birthdate?: string | null,
  gender?: string | null,
  locale?: string | null,
  picture?: string | null,
  pictureCrop?: string | null,
  website?: string | null,
  zoneinfo?: string | null,
  verification_code?: string | null,
  facebook?: string | null,
  instagram?: string | null,
  group?: string | null,
  phones?: Array< string | null > | null,
  flags?: Array< string | null > | null,
  flagsName?: Array< string | null > | null,
  interests?: Array< string | null > | null,
  interestsName?: Array< string | null > | null,
  status?: string | null,
  stats?: Array< string | null > | null,
  spending?: number | null,
  prevOrderDate?: string | null,
  lastOrderDate?: string | null,
  promocodes?: Array< PromocodeItemInput | null > | null,
  mostOrderedItems?: string | null,
  points?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  cognitoUsername?: string | null,
  cognitoSub?: string | null,
  autoGeneratedPassword: boolean,
  updated?: string | null,
  sub?: string | null,
  _version?: number | null,
};

export type PromocodeItemInput = {
  name: string,
  promocodeId: string,
  canBeUsed: number,
  discountVal: number,
  discountLimit?: number | null,
  isDisplayed?: boolean | null,
};

export type ModelUserConditionInput = {
  accountID?: ModelStringInput | null,
  username?: ModelStringInput | null,
  preferred_username?: ModelStringInput | null,
  email?: ModelStringInput | null,
  email_verified?: ModelBooleanInput | null,
  phone_number?: ModelStringInput | null,
  phone_number_verified?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  given_name?: ModelStringInput | null,
  middle_name?: ModelStringInput | null,
  family_name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  birthdate?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  locale?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  pictureCrop?: ModelStringInput | null,
  website?: ModelStringInput | null,
  zoneinfo?: ModelStringInput | null,
  verification_code?: ModelStringInput | null,
  facebook?: ModelStringInput | null,
  instagram?: ModelStringInput | null,
  group?: ModelStringInput | null,
  phones?: ModelStringInput | null,
  flags?: ModelStringInput | null,
  flagsName?: ModelStringInput | null,
  interests?: ModelStringInput | null,
  interestsName?: ModelStringInput | null,
  status?: ModelStringInput | null,
  stats?: ModelIDInput | null,
  spending?: ModelIntInput | null,
  prevOrderDate?: ModelStringInput | null,
  lastOrderDate?: ModelStringInput | null,
  mostOrderedItems?: ModelStringInput | null,
  points?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  cognitoUsername?: ModelStringInput | null,
  cognitoSub?: ModelStringInput | null,
  autoGeneratedPassword?: ModelBooleanInput | null,
  updated?: ModelStringInput | null,
  sub?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type User = {
  __typename: "User",
  id: string,
  accountID: string,
  username?: string | null,
  preferred_username?: string | null,
  email?: string | null,
  email_verified?: boolean | null,
  phone_number?: string | null,
  phone_number_verified?: boolean | null,
  name?: string | null,
  nickname?: string | null,
  given_name?: string | null,
  middle_name?: string | null,
  family_name?: string | null,
  address?: string | null,
  birthdate?: string | null,
  gender?: string | null,
  locale?: string | null,
  picture?: string | null,
  pictureCrop?: string | null,
  website?: string | null,
  zoneinfo?: string | null,
  verification_code?: string | null,
  facebook?: string | null,
  instagram?: string | null,
  group?: string | null,
  phones?: Array< string | null > | null,
  flags?: Array< string | null > | null,
  flagsName?: Array< string | null > | null,
  interests?: Array< string | null > | null,
  interestsName?: Array< string | null > | null,
  status?: string | null,
  stats?: Array< string | null > | null,
  spending?: number | null,
  prevOrderDate?: string | null,
  lastOrderDate?: string | null,
  promocodes?:  Array<PromocodeItem | null > | null,
  mostOrderedItems?: string | null,
  points?: number | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  cognitoUsername?: string | null,
  cognitoSub?: string | null,
  autoGeneratedPassword: boolean,
  updated?: string | null,
  sub?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type PromocodeItem = {
  __typename: "PromocodeItem",
  name: string,
  promocodeId: string,
  canBeUsed: number,
  discountVal: number,
  discountLimit?: number | null,
  isDisplayed?: boolean | null,
};

export type UpdateUserInput = {
  id: string,
  accountID?: string | null,
  username?: string | null,
  preferred_username?: string | null,
  email?: string | null,
  email_verified?: boolean | null,
  phone_number?: string | null,
  phone_number_verified?: boolean | null,
  name?: string | null,
  nickname?: string | null,
  given_name?: string | null,
  middle_name?: string | null,
  family_name?: string | null,
  address?: string | null,
  birthdate?: string | null,
  gender?: string | null,
  locale?: string | null,
  picture?: string | null,
  pictureCrop?: string | null,
  website?: string | null,
  zoneinfo?: string | null,
  verification_code?: string | null,
  facebook?: string | null,
  instagram?: string | null,
  group?: string | null,
  phones?: Array< string | null > | null,
  flags?: Array< string | null > | null,
  flagsName?: Array< string | null > | null,
  interests?: Array< string | null > | null,
  interestsName?: Array< string | null > | null,
  status?: string | null,
  stats?: Array< string | null > | null,
  spending?: number | null,
  prevOrderDate?: string | null,
  lastOrderDate?: string | null,
  promocodes?: Array< PromocodeItemInput | null > | null,
  mostOrderedItems?: string | null,
  points?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  cognitoUsername?: string | null,
  cognitoSub?: string | null,
  autoGeneratedPassword?: boolean | null,
  updated?: string | null,
  sub?: string | null,
  _version?: number | null,
};

export type DeleteUserInput = {
  id: string,
  _version?: number | null,
};

export type CreateReviewInput = {
  id?: string | null,
  conceptID?: string | null,
  conceptName?: string | null,
  accountID?: string | null,
  bookingID?: string | null,
  parentConceptID?: string | null,
  guestName?: string | null,
  guestId?: string | null,
  reason?: Array< string | null > | null,
  value?: string | null,
  userID?: string | null,
  orderID?: string | null,
  rate?: number | null,
  feedback?: string | null,
  satisfaction?: string | null,
  thoughts?: string | null,
  itemReviews?: Array< ItemReviewInput | null > | null,
  photos?: Array< string | null > | null,
  multiLanguages?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  phoneUser?: string | null,
  _version?: number | null,
};

export type ItemReviewInput = {
  itemID: string,
  rate?: number | null,
  satisfaction?: string | null,
  feedback?: string | null,
  multiLanguages?: string | null,
};

export type ModelReviewConditionInput = {
  conceptID?: ModelStringInput | null,
  conceptName?: ModelStringInput | null,
  accountID?: ModelStringInput | null,
  bookingID?: ModelStringInput | null,
  parentConceptID?: ModelStringInput | null,
  guestName?: ModelStringInput | null,
  guestId?: ModelStringInput | null,
  reason?: ModelStringInput | null,
  value?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  orderID?: ModelStringInput | null,
  rate?: ModelIntInput | null,
  feedback?: ModelStringInput | null,
  satisfaction?: ModelStringInput | null,
  thoughts?: ModelStringInput | null,
  photos?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  phoneUser?: ModelStringInput | null,
  and?: Array< ModelReviewConditionInput | null > | null,
  or?: Array< ModelReviewConditionInput | null > | null,
  not?: ModelReviewConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Review = {
  __typename: "Review",
  id: string,
  conceptID?: string | null,
  conceptName?: string | null,
  accountID?: string | null,
  bookingID?: string | null,
  parentConceptID?: string | null,
  guestName?: string | null,
  guestId?: string | null,
  reason?: Array< string | null > | null,
  value?: string | null,
  userID?: string | null,
  orderID?: string | null,
  rate?: number | null,
  feedback?: string | null,
  satisfaction?: string | null,
  thoughts?: string | null,
  itemReviews?:  Array<ItemReview | null > | null,
  photos?: Array< string | null > | null,
  multiLanguages?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  phoneUser?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ItemReview = {
  __typename: "ItemReview",
  itemID: string,
  rate?: number | null,
  satisfaction?: string | null,
  feedback?: string | null,
  multiLanguages?: string | null,
};

export type UpdateReviewInput = {
  id: string,
  conceptID?: string | null,
  conceptName?: string | null,
  accountID?: string | null,
  bookingID?: string | null,
  parentConceptID?: string | null,
  guestName?: string | null,
  guestId?: string | null,
  reason?: Array< string | null > | null,
  value?: string | null,
  userID?: string | null,
  orderID?: string | null,
  rate?: number | null,
  feedback?: string | null,
  satisfaction?: string | null,
  thoughts?: string | null,
  itemReviews?: Array< ItemReviewInput | null > | null,
  photos?: Array< string | null > | null,
  multiLanguages?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  phoneUser?: string | null,
  _version?: number | null,
};

export type DeleteReviewInput = {
  id: string,
  _version?: number | null,
};

export type CreateAccountInput = {
  id?: string | null,
  logo?: string | null,
  domain: string,
  siteTitle: string,
  guestsCount?: number | null,
  tagline: string,
  description: string,
  siteAddress: string,
  defaultLanguage: string,
  languages: Array< string | null >,
  features: Array< string | null >,
  status: string,
  socialLinks?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  callCenterEmployeesIds?: Array< string | null > | null,
  kpiReportResetFrequency?: string | null,
  agentBarResetFrequency?: string | null,
  dynamicStatus?: string | null,
  _version?: number | null,
};

export type ModelAccountConditionInput = {
  logo?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  siteTitle?: ModelStringInput | null,
  guestsCount?: ModelIntInput | null,
  tagline?: ModelStringInput | null,
  description?: ModelStringInput | null,
  siteAddress?: ModelStringInput | null,
  defaultLanguage?: ModelStringInput | null,
  languages?: ModelStringInput | null,
  features?: ModelStringInput | null,
  status?: ModelStringInput | null,
  socialLinks?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  callCenterEmployeesIds?: ModelStringInput | null,
  kpiReportResetFrequency?: ModelStringInput | null,
  agentBarResetFrequency?: ModelStringInput | null,
  dynamicStatus?: ModelStringInput | null,
  and?: Array< ModelAccountConditionInput | null > | null,
  or?: Array< ModelAccountConditionInput | null > | null,
  not?: ModelAccountConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Account = {
  __typename: "Account",
  id: string,
  logo?: string | null,
  domain: string,
  siteTitle: string,
  guestsCount?: number | null,
  tagline: string,
  description: string,
  siteAddress: string,
  defaultLanguage: string,
  languages: Array< string | null >,
  features: Array< string | null >,
  status: string,
  socialLinks?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  callCenterEmployeesIds?: Array< string | null > | null,
  kpiReportResetFrequency?: string | null,
  agentBarResetFrequency?: string | null,
  dynamicStatus?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAccountInput = {
  id: string,
  logo?: string | null,
  domain?: string | null,
  siteTitle?: string | null,
  guestsCount?: number | null,
  tagline?: string | null,
  description?: string | null,
  siteAddress?: string | null,
  defaultLanguage?: string | null,
  languages?: Array< string | null > | null,
  features?: Array< string | null > | null,
  status?: string | null,
  socialLinks?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  callCenterEmployeesIds?: Array< string | null > | null,
  kpiReportResetFrequency?: string | null,
  agentBarResetFrequency?: string | null,
  dynamicStatus?: string | null,
  _version?: number | null,
};

export type DeleteAccountInput = {
  id: string,
  _version?: number | null,
};

export type CreateFeatureInput = {
  id?: string | null,
  name: string,
  icon?: string | null,
  slug: string,
  precedence: string,
  parent?: string | null,
  private?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelFeatureConditionInput = {
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  parent?: ModelStringInput | null,
  private?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelFeatureConditionInput | null > | null,
  or?: Array< ModelFeatureConditionInput | null > | null,
  not?: ModelFeatureConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Feature = {
  __typename: "Feature",
  id: string,
  name: string,
  icon?: string | null,
  slug: string,
  precedence: string,
  parent?: string | null,
  private?: boolean | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateFeatureInput = {
  id: string,
  name?: string | null,
  icon?: string | null,
  slug?: string | null,
  precedence?: string | null,
  parent?: string | null,
  private?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteFeatureInput = {
  id: string,
  _version?: number | null,
};

export type CreateMediaInput = {
  id?: string | null,
  accountID: string,
  fileUrl: string,
  filename: string,
  filetype?: string | null,
  fileSize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  attachments?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelMediaConditionInput = {
  accountID?: ModelIDInput | null,
  fileUrl?: ModelStringInput | null,
  filename?: ModelStringInput | null,
  filetype?: ModelStringInput | null,
  fileSize?: ModelIntInput | null,
  alternativeText?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  description?: ModelStringInput | null,
  attachments?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelMediaConditionInput | null > | null,
  or?: Array< ModelMediaConditionInput | null > | null,
  not?: ModelMediaConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Media = {
  __typename: "Media",
  id: string,
  accountID: string,
  fileUrl: string,
  filename: string,
  filetype?: string | null,
  fileSize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  attachments?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateMediaInput = {
  id: string,
  accountID?: string | null,
  fileUrl?: string | null,
  filename?: string | null,
  filetype?: string | null,
  fileSize?: number | null,
  alternativeText?: string | null,
  caption?: string | null,
  description?: string | null,
  attachments?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteMediaInput = {
  id: string,
  _version?: number | null,
};

export type CreateConceptInput = {
  id?: string | null,
  accountID: string,
  name: string,
  kiosks?: Array< string | null > | null,
  merchantID?: string | null,
  merchantUsername?: string | null,
  merchantPassword?: string | null,
  merchantIntegrationNumber?: string | null,
  merchantAPIKey?: string | null,
  paymentSharePercentage?: number | null,
  simphonyServerBaseURL?: string | null,
  simphonyShortOrgName?: string | null,
  simphonyLocRef?: string | null,
  simphonyToken?: string | null,
  revenueCenterID?: number | null,
  employeeID?: number | null,
  tenderID?: number | null,
  discountID?: number | null,
  useServiceCharge?: boolean | null,
  serviceChargeId?: number | null,
  specialRequestID?: number | null,
  addressID?: number | null,
  ratingCount?: number | null,
  averageRating?: number | null,
  feesback?: string | null,
  simphonyAuthClientId?: string | null,
  simphonyAuthUsername?: string | null,
  simphonyAuthPassword?: string | null,
  simphonyAuthBaseURL?: string | null,
  orderTypeDineIn?: number | null,
  orderTypeTakeAway?: number | null,
  orderTypeDelivery?: number | null,
  simphonyTakeAwayRvc?: number | null,
  simphonyDeliveryRvc?: number | null,
  exportInterval?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  foodicsToken?: string | null,
  enableTableFoodics?: string | null,
  image?: string | null,
  logo?: string | null,
  album?: Array< string | null > | null,
  type?: string | null,
  rating?: number | null,
  location?: string | null,
  description?: string | null,
  areas?: Array< string | null > | null,
  seatTypes?: Array< string | null > | null,
  needAssistance?: boolean | null,
  spaBooking?: boolean | null,
  showerBooking?: boolean | null,
  cigarMenu?: boolean | null,
  precedence?: number | null,
  isPinRequired?: boolean | null,
  viewOnly?: boolean | null,
  openTime?: string | null,
  closeTime?: string | null,
  varifyForAmount?: number | null,
  varifyFirstOrder?: boolean | null,
  varifyNewAddress?: boolean | null,
  sliderImages?: Array< string | null > | null,
  receiveWaiters?: Array< string | null > | null,
  WaiterNotificationOption?: string | null,
  applyLoyalty?: boolean | null,
  rewardPoints?: number | null,
  moneySpent?: number | null,
  afterVat?: boolean | null,
  afterDeliveryFee?: boolean | null,
  beforePromoCode?: boolean | null,
  pointsCalcWithPromoCode?: boolean | null,
  isBusy?: boolean | null,
  allowOnlineOrdering?: boolean | null,
  minOrderVal?: number | null,
  vatPercentage?: number | null,
  serviceChargePercentage?: number | null,
  addDeliveryToVat?: boolean | null,
  categoryTagIDs?: Array< string | null > | null,
  parentConceptID: string,
  containerColor?: string | null,
  avgOrderCompletionEstimate?: string | null,
  _version?: number | null,
};

export type ModelConceptConditionInput = {
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  kiosks?: ModelIDInput | null,
  merchantID?: ModelStringInput | null,
  merchantUsername?: ModelStringInput | null,
  merchantPassword?: ModelStringInput | null,
  merchantIntegrationNumber?: ModelStringInput | null,
  merchantAPIKey?: ModelStringInput | null,
  paymentSharePercentage?: ModelFloatInput | null,
  simphonyServerBaseURL?: ModelStringInput | null,
  simphonyShortOrgName?: ModelStringInput | null,
  simphonyLocRef?: ModelStringInput | null,
  simphonyToken?: ModelStringInput | null,
  revenueCenterID?: ModelIntInput | null,
  employeeID?: ModelIntInput | null,
  tenderID?: ModelIntInput | null,
  discountID?: ModelIntInput | null,
  useServiceCharge?: ModelBooleanInput | null,
  serviceChargeId?: ModelIntInput | null,
  specialRequestID?: ModelIntInput | null,
  addressID?: ModelIntInput | null,
  ratingCount?: ModelIntInput | null,
  averageRating?: ModelFloatInput | null,
  feesback?: ModelStringInput | null,
  simphonyAuthClientId?: ModelStringInput | null,
  simphonyAuthUsername?: ModelStringInput | null,
  simphonyAuthPassword?: ModelStringInput | null,
  simphonyAuthBaseURL?: ModelStringInput | null,
  orderTypeDineIn?: ModelIntInput | null,
  orderTypeTakeAway?: ModelIntInput | null,
  orderTypeDelivery?: ModelIntInput | null,
  simphonyTakeAwayRvc?: ModelIntInput | null,
  simphonyDeliveryRvc?: ModelIntInput | null,
  exportInterval?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  foodicsToken?: ModelStringInput | null,
  enableTableFoodics?: ModelStringInput | null,
  image?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  album?: ModelStringInput | null,
  type?: ModelStringInput | null,
  rating?: ModelFloatInput | null,
  location?: ModelStringInput | null,
  description?: ModelStringInput | null,
  areas?: ModelStringInput | null,
  seatTypes?: ModelStringInput | null,
  needAssistance?: ModelBooleanInput | null,
  spaBooking?: ModelBooleanInput | null,
  showerBooking?: ModelBooleanInput | null,
  cigarMenu?: ModelBooleanInput | null,
  precedence?: ModelIntInput | null,
  isPinRequired?: ModelBooleanInput | null,
  viewOnly?: ModelBooleanInput | null,
  openTime?: ModelStringInput | null,
  closeTime?: ModelStringInput | null,
  varifyForAmount?: ModelIntInput | null,
  varifyFirstOrder?: ModelBooleanInput | null,
  varifyNewAddress?: ModelBooleanInput | null,
  sliderImages?: ModelStringInput | null,
  receiveWaiters?: ModelStringInput | null,
  WaiterNotificationOption?: ModelStringInput | null,
  applyLoyalty?: ModelBooleanInput | null,
  rewardPoints?: ModelIntInput | null,
  moneySpent?: ModelIntInput | null,
  afterVat?: ModelBooleanInput | null,
  afterDeliveryFee?: ModelBooleanInput | null,
  beforePromoCode?: ModelBooleanInput | null,
  pointsCalcWithPromoCode?: ModelBooleanInput | null,
  isBusy?: ModelBooleanInput | null,
  allowOnlineOrdering?: ModelBooleanInput | null,
  minOrderVal?: ModelIntInput | null,
  vatPercentage?: ModelFloatInput | null,
  serviceChargePercentage?: ModelFloatInput | null,
  addDeliveryToVat?: ModelBooleanInput | null,
  categoryTagIDs?: ModelIDInput | null,
  parentConceptID?: ModelIDInput | null,
  containerColor?: ModelStringInput | null,
  avgOrderCompletionEstimate?: ModelStringInput | null,
  and?: Array< ModelConceptConditionInput | null > | null,
  or?: Array< ModelConceptConditionInput | null > | null,
  not?: ModelConceptConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Concept = {
  __typename: "Concept",
  id: string,
  accountID: string,
  name: string,
  kiosks?: Array< string | null > | null,
  merchantID?: string | null,
  merchantUsername?: string | null,
  merchantPassword?: string | null,
  merchantIntegrationNumber?: string | null,
  merchantAPIKey?: string | null,
  paymentSharePercentage?: number | null,
  simphonyServerBaseURL?: string | null,
  simphonyShortOrgName?: string | null,
  simphonyLocRef?: string | null,
  simphonyToken?: string | null,
  revenueCenterID?: number | null,
  employeeID?: number | null,
  tenderID?: number | null,
  tenders?: ModelTenderConnection | null,
  orderType?: ModelOrderTypeConnection | null,
  discountID?: number | null,
  useServiceCharge?: boolean | null,
  serviceChargeId?: number | null,
  specialRequestID?: number | null,
  addressID?: number | null,
  ratingCount?: number | null,
  averageRating?: number | null,
  feesback?: string | null,
  simphonyAuthClientId?: string | null,
  simphonyAuthUsername?: string | null,
  simphonyAuthPassword?: string | null,
  simphonyAuthBaseURL?: string | null,
  orderTypeDineIn?: number | null,
  orderTypeTakeAway?: number | null,
  orderTypeDelivery?: number | null,
  simphonyTakeAwayRvc?: number | null,
  simphonyDeliveryRvc?: number | null,
  exportInterval?: number | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  foodicsToken?: string | null,
  enableTableFoodics?: string | null,
  image?: string | null,
  logo?: string | null,
  album?: Array< string | null > | null,
  type?: string | null,
  rating?: number | null,
  location?: string | null,
  description?: string | null,
  areas?: Array< string | null > | null,
  seatTypes?: Array< string | null > | null,
  needAssistance?: boolean | null,
  spaBooking?: boolean | null,
  showerBooking?: boolean | null,
  cigarMenu?: boolean | null,
  precedence?: number | null,
  isPinRequired?: boolean | null,
  viewOnly?: boolean | null,
  openTime?: string | null,
  closeTime?: string | null,
  varifyForAmount?: number | null,
  varifyFirstOrder?: boolean | null,
  varifyNewAddress?: boolean | null,
  sliderImages?: Array< string | null > | null,
  receiveWaiters?: Array< string | null > | null,
  WaiterNotificationOption?: string | null,
  applyLoyalty?: boolean | null,
  rewardPoints?: number | null,
  moneySpent?: number | null,
  afterVat?: boolean | null,
  afterDeliveryFee?: boolean | null,
  beforePromoCode?: boolean | null,
  pointsCalcWithPromoCode?: boolean | null,
  isBusy?: boolean | null,
  allowOnlineOrdering?: boolean | null,
  minOrderVal?: number | null,
  vatPercentage?: number | null,
  serviceChargePercentage?: number | null,
  addDeliveryToVat?: boolean | null,
  categoryTagIDs?: Array< string | null > | null,
  parentConceptID: string,
  containerColor?: string | null,
  avgOrderCompletionEstimate?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelTenderConnection = {
  __typename: "ModelTenderConnection",
  items:  Array<Tender | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Tender = {
  __typename: "Tender",
  id: string,
  name: string,
  simphonyID?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  conceptTendersId?: string | null,
};

export type ModelOrderTypeConnection = {
  __typename: "ModelOrderTypeConnection",
  items:  Array<OrderType | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type OrderType = {
  __typename: "OrderType",
  id: string,
  name: string,
  simphonyID?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  conceptOrderTypeId?: string | null,
};

export type UpdateConceptInput = {
  id: string,
  accountID?: string | null,
  name?: string | null,
  kiosks?: Array< string | null > | null,
  merchantID?: string | null,
  merchantUsername?: string | null,
  merchantPassword?: string | null,
  merchantIntegrationNumber?: string | null,
  merchantAPIKey?: string | null,
  paymentSharePercentage?: number | null,
  simphonyServerBaseURL?: string | null,
  simphonyShortOrgName?: string | null,
  simphonyLocRef?: string | null,
  simphonyToken?: string | null,
  revenueCenterID?: number | null,
  employeeID?: number | null,
  tenderID?: number | null,
  discountID?: number | null,
  useServiceCharge?: boolean | null,
  serviceChargeId?: number | null,
  specialRequestID?: number | null,
  addressID?: number | null,
  ratingCount?: number | null,
  averageRating?: number | null,
  feesback?: string | null,
  simphonyAuthClientId?: string | null,
  simphonyAuthUsername?: string | null,
  simphonyAuthPassword?: string | null,
  simphonyAuthBaseURL?: string | null,
  orderTypeDineIn?: number | null,
  orderTypeTakeAway?: number | null,
  orderTypeDelivery?: number | null,
  simphonyTakeAwayRvc?: number | null,
  simphonyDeliveryRvc?: number | null,
  exportInterval?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  foodicsToken?: string | null,
  enableTableFoodics?: string | null,
  image?: string | null,
  logo?: string | null,
  album?: Array< string | null > | null,
  type?: string | null,
  rating?: number | null,
  location?: string | null,
  description?: string | null,
  areas?: Array< string | null > | null,
  seatTypes?: Array< string | null > | null,
  needAssistance?: boolean | null,
  spaBooking?: boolean | null,
  showerBooking?: boolean | null,
  cigarMenu?: boolean | null,
  precedence?: number | null,
  isPinRequired?: boolean | null,
  viewOnly?: boolean | null,
  openTime?: string | null,
  closeTime?: string | null,
  varifyForAmount?: number | null,
  varifyFirstOrder?: boolean | null,
  varifyNewAddress?: boolean | null,
  sliderImages?: Array< string | null > | null,
  receiveWaiters?: Array< string | null > | null,
  WaiterNotificationOption?: string | null,
  applyLoyalty?: boolean | null,
  rewardPoints?: number | null,
  moneySpent?: number | null,
  afterVat?: boolean | null,
  afterDeliveryFee?: boolean | null,
  beforePromoCode?: boolean | null,
  pointsCalcWithPromoCode?: boolean | null,
  isBusy?: boolean | null,
  allowOnlineOrdering?: boolean | null,
  minOrderVal?: number | null,
  vatPercentage?: number | null,
  serviceChargePercentage?: number | null,
  addDeliveryToVat?: boolean | null,
  categoryTagIDs?: Array< string | null > | null,
  parentConceptID?: string | null,
  containerColor?: string | null,
  avgOrderCompletionEstimate?: string | null,
  _version?: number | null,
};

export type DeleteConceptInput = {
  id: string,
  _version?: number | null,
};

export type CreateCategoryTagInput = {
  id?: string | null,
  parentConceptID: string,
  name: string,
  image?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelCategoryTagConditionInput = {
  parentConceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  image?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelCategoryTagConditionInput | null > | null,
  or?: Array< ModelCategoryTagConditionInput | null > | null,
  not?: ModelCategoryTagConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type CategoryTag = {
  __typename: "CategoryTag",
  id: string,
  parentConceptID: string,
  name: string,
  image?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateCategoryTagInput = {
  id: string,
  parentConceptID?: string | null,
  name?: string | null,
  image?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteCategoryTagInput = {
  id: string,
  _version?: number | null,
};

export type CreateTenderInput = {
  id?: string | null,
  name: string,
  simphonyID?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
  conceptTendersId?: string | null,
};

export type ModelTenderConditionInput = {
  name?: ModelStringInput | null,
  simphonyID?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelTenderConditionInput | null > | null,
  or?: Array< ModelTenderConditionInput | null > | null,
  not?: ModelTenderConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
  conceptTendersId?: ModelIDInput | null,
};

export type UpdateTenderInput = {
  id: string,
  name?: string | null,
  simphonyID?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
  conceptTendersId?: string | null,
};

export type DeleteTenderInput = {
  id: string,
  _version?: number | null,
};

export type CreateOrderTypeInput = {
  id?: string | null,
  name: string,
  simphonyID?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
  conceptOrderTypeId?: string | null,
};

export type ModelOrderTypeConditionInput = {
  name?: ModelStringInput | null,
  simphonyID?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelOrderTypeConditionInput | null > | null,
  or?: Array< ModelOrderTypeConditionInput | null > | null,
  not?: ModelOrderTypeConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
  conceptOrderTypeId?: ModelIDInput | null,
};

export type UpdateOrderTypeInput = {
  id: string,
  name?: string | null,
  simphonyID?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
  conceptOrderTypeId?: string | null,
};

export type DeleteOrderTypeInput = {
  id: string,
  _version?: number | null,
};

export type CreateParentConceptInput = {
  id?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  image?: string | null,
  name?: string | null,
  logo?: string | null,
  type?: string | null,
  index?: number | null,
  _version?: number | null,
};

export type ModelParentConceptConditionInput = {
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  image?: ModelStringInput | null,
  name?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  type?: ModelStringInput | null,
  index?: ModelIntInput | null,
  and?: Array< ModelParentConceptConditionInput | null > | null,
  or?: Array< ModelParentConceptConditionInput | null > | null,
  not?: ModelParentConceptConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ParentConcept = {
  __typename: "ParentConcept",
  id: string,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  image?: string | null,
  name?: string | null,
  logo?: string | null,
  type?: string | null,
  venues?: ModelConceptConnection | null,
  index?: number | null,
  categoryTags?: ModelCategoryTagConnection | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelConceptConnection = {
  __typename: "ModelConceptConnection",
  items:  Array<Concept | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCategoryTagConnection = {
  __typename: "ModelCategoryTagConnection",
  items:  Array<CategoryTag | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type UpdateParentConceptInput = {
  id: string,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  image?: string | null,
  name?: string | null,
  logo?: string | null,
  type?: string | null,
  index?: number | null,
  _version?: number | null,
};

export type DeleteParentConceptInput = {
  id: string,
  _version?: number | null,
};

export type CreateTimelineInput = {
  id?: string | null,
  actionName: string,
  oldStatus: string,
  newStatus: string,
  bookingId?: string | null,
  transactionId?: string | null,
  customerId?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelTimelineConditionInput = {
  actionName?: ModelStringInput | null,
  oldStatus?: ModelStringInput | null,
  newStatus?: ModelStringInput | null,
  bookingId?: ModelStringInput | null,
  transactionId?: ModelStringInput | null,
  customerId?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelTimelineConditionInput | null > | null,
  or?: Array< ModelTimelineConditionInput | null > | null,
  not?: ModelTimelineConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Timeline = {
  __typename: "Timeline",
  id: string,
  actionName: string,
  oldStatus: string,
  newStatus: string,
  bookingId?: string | null,
  transactionId?: string | null,
  customerId?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateTimelineInput = {
  id: string,
  actionName?: string | null,
  oldStatus?: string | null,
  newStatus?: string | null,
  bookingId?: string | null,
  transactionId?: string | null,
  customerId?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteTimelineInput = {
  id: string,
  _version?: number | null,
};

export type CreateCallInput = {
  id?: string | null,
  phone_number?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  endedAt?: string | null,
  duration?: string | null,
  date: string,
  employeeId: string,
  _version?: number | null,
  callCustomerId?: string | null,
};

export type ModelCallConditionInput = {
  phone_number?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  endedAt?: ModelStringInput | null,
  duration?: ModelStringInput | null,
  date?: ModelStringInput | null,
  employeeId?: ModelStringInput | null,
  and?: Array< ModelCallConditionInput | null > | null,
  or?: Array< ModelCallConditionInput | null > | null,
  not?: ModelCallConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
  callCustomerId?: ModelIDInput | null,
};

export type Call = {
  __typename: "Call",
  id: string,
  phone_number?: string | null,
  customer?: User | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  endedAt?: string | null,
  duration?: string | null,
  date: string,
  employeeId: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
  callCustomerId?: string | null,
};

export type UpdateCallInput = {
  id: string,
  phone_number?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  endedAt?: string | null,
  duration?: string | null,
  date?: string | null,
  employeeId?: string | null,
  _version?: number | null,
  callCustomerId?: string | null,
};

export type DeleteCallInput = {
  id: string,
  _version?: number | null,
};

export type CreateAdminRoleInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelAdminRoleConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAdminRoleConditionInput | null > | null,
  or?: Array< ModelAdminRoleConditionInput | null > | null,
  not?: ModelAdminRoleConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type AdminRole = {
  __typename: "AdminRole",
  id: string,
  name?: string | null,
  description?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAdminRoleInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteAdminRoleInput = {
  id: string,
  _version?: number | null,
};

export type CreateAdminGroupInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
  roles?: Array< string | null > | null,
  users?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelAdminGroupConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  roles?: ModelIDInput | null,
  users?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelAdminGroupConditionInput | null > | null,
  or?: Array< ModelAdminGroupConditionInput | null > | null,
  not?: ModelAdminGroupConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type AdminGroup = {
  __typename: "AdminGroup",
  id: string,
  name?: string | null,
  description?: string | null,
  roles?: Array< string | null > | null,
  users?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAdminGroupInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  roles?: Array< string | null > | null,
  users?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteAdminGroupInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserConceptsInput = {
  id?: string | null,
  defaultConcept?: string | null,
  concepts?: Array< string | null > | null,
  parentConcepts?: Array< string | null > | null,
  conceptsRoles?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelUserConceptsConditionInput = {
  defaultConcept?: ModelIDInput | null,
  concepts?: ModelIDInput | null,
  parentConcepts?: ModelIDInput | null,
  conceptsRoles?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelUserConceptsConditionInput | null > | null,
  or?: Array< ModelUserConceptsConditionInput | null > | null,
  not?: ModelUserConceptsConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UserConcepts = {
  __typename: "UserConcepts",
  id: string,
  defaultConcept?: string | null,
  concepts?: Array< string | null > | null,
  parentConcepts?: Array< string | null > | null,
  conceptsRoles?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateUserConceptsInput = {
  id: string,
  defaultConcept?: string | null,
  concepts?: Array< string | null > | null,
  parentConcepts?: Array< string | null > | null,
  conceptsRoles?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteUserConceptsInput = {
  id: string,
  _version?: number | null,
};

export type CreateTransactionInput = {
  id?: string | null,
  transactionID: string,
  date: string,
  guestName: string,
  guestPhone: string,
  guestID: string,
  guestGroup: string,
  guestsNames?: Array< string | null > | null,
  bookingID?: string | null,
  bookingDate?: string | null,
  amount_cents: string,
  status: boolean,
  failureReason?: string | null,
  type: string,
  timeSlots?: Array< string | null > | null,
  tables?: Array< string | null > | null,
  conceptID: string,
  currency: string,
  refund: boolean,
  reference_orderID?: string | null,
  ownerID: string,
  refunded_amount_cents: string,
  deleted?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type ModelTransactionConditionInput = {
  transactionID?: ModelStringInput | null,
  date?: ModelStringInput | null,
  guestName?: ModelStringInput | null,
  guestPhone?: ModelStringInput | null,
  guestID?: ModelStringInput | null,
  guestGroup?: ModelStringInput | null,
  guestsNames?: ModelStringInput | null,
  bookingID?: ModelStringInput | null,
  bookingDate?: ModelStringInput | null,
  amount_cents?: ModelStringInput | null,
  status?: ModelBooleanInput | null,
  failureReason?: ModelStringInput | null,
  type?: ModelStringInput | null,
  timeSlots?: ModelStringInput | null,
  tables?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  refund?: ModelBooleanInput | null,
  reference_orderID?: ModelStringInput | null,
  ownerID?: ModelStringInput | null,
  refunded_amount_cents?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelTransactionConditionInput | null > | null,
  or?: Array< ModelTransactionConditionInput | null > | null,
  not?: ModelTransactionConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Transaction = {
  __typename: "Transaction",
  id: string,
  transactionID: string,
  date: string,
  guestName: string,
  guestPhone: string,
  guestID: string,
  guestGroup: string,
  guestsNames?: Array< string | null > | null,
  bookingID?: string | null,
  bookingDate?: string | null,
  amount_cents: string,
  status: boolean,
  failureReason?: string | null,
  type: string,
  timeSlots?: Array< string | null > | null,
  tables?: Array< string | null > | null,
  conceptID: string,
  currency: string,
  refund: boolean,
  reference_orderID?: string | null,
  ownerID: string,
  refunded_amount_cents: string,
  deleted?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateTransactionInput = {
  id: string,
  transactionID?: string | null,
  date?: string | null,
  guestName?: string | null,
  guestPhone?: string | null,
  guestID?: string | null,
  guestGroup?: string | null,
  guestsNames?: Array< string | null > | null,
  bookingID?: string | null,
  bookingDate?: string | null,
  amount_cents?: string | null,
  status?: boolean | null,
  failureReason?: string | null,
  type?: string | null,
  timeSlots?: Array< string | null > | null,
  tables?: Array< string | null > | null,
  conceptID?: string | null,
  currency?: string | null,
  refund?: boolean | null,
  reference_orderID?: string | null,
  ownerID?: string | null,
  refunded_amount_cents?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteTransactionInput = {
  id: string,
  _version?: number | null,
};

export type CreatePaymentStatInput = {
  id?: string | null,
  accountID?: string | null,
  conceptID: string,
  year: number,
  month: number,
  monthName: string,
  earning: number,
  refund: number,
  earningTrxCount: number,
  refundTrxCount: number,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type ModelPaymentStatConditionInput = {
  accountID?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  year?: ModelIntInput | null,
  month?: ModelIntInput | null,
  monthName?: ModelStringInput | null,
  earning?: ModelFloatInput | null,
  refund?: ModelFloatInput | null,
  earningTrxCount?: ModelIntInput | null,
  refundTrxCount?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelPaymentStatConditionInput | null > | null,
  or?: Array< ModelPaymentStatConditionInput | null > | null,
  not?: ModelPaymentStatConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type PaymentStat = {
  __typename: "PaymentStat",
  id: string,
  accountID?: string | null,
  conceptID: string,
  year: number,
  month: number,
  monthName: string,
  earning: number,
  refund: number,
  earningTrxCount: number,
  refundTrxCount: number,
  deleted?: string | null,
  createdAt: string,
  createdByID?: string | null,
  createdByName?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdatePaymentStatInput = {
  id: string,
  accountID?: string | null,
  conceptID?: string | null,
  year?: number | null,
  month?: number | null,
  monthName?: string | null,
  earning?: number | null,
  refund?: number | null,
  earningTrxCount?: number | null,
  refundTrxCount?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeletePaymentStatInput = {
  id: string,
  _version?: number | null,
};

export type CreateCategoryInput = {
  id?: string | null,
  conceptID: string,
  parentConceptID: string,
  preparationAreaID?: string | null,
  subCategory?: string | null,
  name: string,
  guestView?: boolean | null,
  guestOrder?: boolean | null,
  staffOrder?: boolean | null,
  image?: string | null,
  darkImage?: string | null,
  precedence: string,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  multiLanguages?: string | null,
  symphonyID?: string | null,
  isHidden?: boolean | null,
  _version?: number | null,
};

export type ModelCategoryConditionInput = {
  conceptID?: ModelIDInput | null,
  parentConceptID?: ModelIDInput | null,
  preparationAreaID?: ModelIDInput | null,
  subCategory?: ModelIDInput | null,
  name?: ModelStringInput | null,
  guestView?: ModelBooleanInput | null,
  guestOrder?: ModelBooleanInput | null,
  staffOrder?: ModelBooleanInput | null,
  image?: ModelIDInput | null,
  darkImage?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  symphonyID?: ModelStringInput | null,
  isHidden?: ModelBooleanInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Category = {
  __typename: "Category",
  id: string,
  conceptID: string,
  parentConceptID: string,
  preparationAreaID?: string | null,
  subCategory?: string | null,
  name: string,
  guestView?: boolean | null,
  guestOrder?: boolean | null,
  staffOrder?: boolean | null,
  image?: string | null,
  darkImage?: string | null,
  menuItems?: ModelMenuItemConnection | null,
  precedence: string,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  multiLanguages?: string | null,
  symphonyID?: string | null,
  isHidden?: boolean | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelMenuItemConnection = {
  __typename: "ModelMenuItemConnection",
  items:  Array<MenuItem | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type MenuItem = {
  __typename: "MenuItem",
  id: string,
  conceptID: string,
  kioskID?: string | null,
  name: string,
  description?: string | null,
  image?: string | null,
  precedence?: string | null,
  categoryID: string,
  categoryName?: string | null,
  outOfStock?: boolean | null,
  requiredPoints?: number | null,
  symphonyID?: string | null,
  comboItemID?: string | null,
  ratings?: Array< string | null > | null,
  prices?: Array< string | null > | null,
  choiceGroups?: Array< string | null > | null,
  pricesItems?:  Array<Price | null > | null,
  choiceGroupsItems?:  Array<ChoiceGroups | null > | null,
  choiceGroupsRules?:  Array<ChoiceGroupRules | null > | null,
  ExtraChoiceGroups?: Array< string | null > | null,
  enabled?: boolean | null,
  hasLimitedQuantity?: boolean | null,
  quantity?: number | null,
  autoRestock?: boolean | null,
  restockQuantity?: number | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  quantityOrdered?: number | null,
  performancePerDay?: Array< string | null > | null,
  multiLanguages?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type Price = {
  __typename: "Price",
  id: string,
  conceptID: string,
  parentConceptID: string,
  name: string,
  multiLanguages?: string | null,
  price: number,
  symphonyID: string,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ChoiceGroups = {
  __typename: "ChoiceGroups",
  id: string,
  conceptID: string,
  parentConceptID: string,
  name: string,
  required?: boolean | null,
  maxNumberOfChoices?: number | null,
  minNumberOfChoices?: number | null,
  precedence?: string | null,
  type?: string | null,
  choices?: Array< string | null > | null,
  choiceItems?: ModelChoiceConnection | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  multiLanguages?: string | null,
  simphonyID?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ModelChoiceConnection = {
  __typename: "ModelChoiceConnection",
  items:  Array<Choice | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type Choice = {
  __typename: "Choice",
  id: string,
  conceptID: string,
  name: string,
  price: number,
  precedence?: string | null,
  choiceGroupID: string,
  choiceGroupName?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  symphonyID?: string | null,
  multiLanguages?: string | null,
  definitionNumber?: number | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ChoiceGroupRules = {
  __typename: "ChoiceGroupRules",
  id: string,
  minNumberOfChoices?: number | null,
  maxNumberOfChoices?: number | null,
};

export type UpdateCategoryInput = {
  id: string,
  conceptID?: string | null,
  parentConceptID?: string | null,
  preparationAreaID?: string | null,
  subCategory?: string | null,
  name?: string | null,
  guestView?: boolean | null,
  guestOrder?: boolean | null,
  staffOrder?: boolean | null,
  image?: string | null,
  darkImage?: string | null,
  precedence?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  multiLanguages?: string | null,
  symphonyID?: string | null,
  isHidden?: boolean | null,
  _version?: number | null,
};

export type DeleteCategoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateMenuItemInput = {
  id?: string | null,
  conceptID: string,
  kioskID?: string | null,
  name: string,
  description?: string | null,
  image?: string | null,
  precedence?: string | null,
  categoryID: string,
  categoryName?: string | null,
  outOfStock?: boolean | null,
  requiredPoints?: number | null,
  symphonyID?: string | null,
  comboItemID?: string | null,
  ratings?: Array< string | null > | null,
  prices?: Array< string | null > | null,
  choiceGroups?: Array< string | null > | null,
  choiceGroupsRules?: Array< ChoiceGroupRulesInput | null > | null,
  ExtraChoiceGroups?: Array< string | null > | null,
  enabled?: boolean | null,
  hasLimitedQuantity?: boolean | null,
  quantity?: number | null,
  autoRestock?: boolean | null,
  restockQuantity?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  quantityOrdered?: number | null,
  performancePerDay?: Array< string | null > | null,
  multiLanguages?: string | null,
  _version?: number | null,
};

export type ChoiceGroupRulesInput = {
  id: string,
  minNumberOfChoices?: number | null,
  maxNumberOfChoices?: number | null,
};

export type ModelMenuItemConditionInput = {
  conceptID?: ModelIDInput | null,
  kioskID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  image?: ModelIDInput | null,
  precedence?: ModelStringInput | null,
  categoryID?: ModelIDInput | null,
  categoryName?: ModelStringInput | null,
  outOfStock?: ModelBooleanInput | null,
  requiredPoints?: ModelIntInput | null,
  symphonyID?: ModelStringInput | null,
  comboItemID?: ModelStringInput | null,
  ratings?: ModelIDInput | null,
  prices?: ModelIDInput | null,
  choiceGroups?: ModelIDInput | null,
  ExtraChoiceGroups?: ModelIDInput | null,
  enabled?: ModelBooleanInput | null,
  hasLimitedQuantity?: ModelBooleanInput | null,
  quantity?: ModelIntInput | null,
  autoRestock?: ModelBooleanInput | null,
  restockQuantity?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  quantityOrdered?: ModelIntInput | null,
  performancePerDay?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  and?: Array< ModelMenuItemConditionInput | null > | null,
  or?: Array< ModelMenuItemConditionInput | null > | null,
  not?: ModelMenuItemConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateMenuItemInput = {
  id: string,
  conceptID?: string | null,
  kioskID?: string | null,
  name?: string | null,
  description?: string | null,
  image?: string | null,
  precedence?: string | null,
  categoryID?: string | null,
  categoryName?: string | null,
  outOfStock?: boolean | null,
  requiredPoints?: number | null,
  symphonyID?: string | null,
  comboItemID?: string | null,
  ratings?: Array< string | null > | null,
  prices?: Array< string | null > | null,
  choiceGroups?: Array< string | null > | null,
  choiceGroupsRules?: Array< ChoiceGroupRulesInput | null > | null,
  ExtraChoiceGroups?: Array< string | null > | null,
  enabled?: boolean | null,
  hasLimitedQuantity?: boolean | null,
  quantity?: number | null,
  autoRestock?: boolean | null,
  restockQuantity?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  quantityOrdered?: number | null,
  performancePerDay?: Array< string | null > | null,
  multiLanguages?: string | null,
  _version?: number | null,
};

export type DeleteMenuItemInput = {
  id: string,
  _version?: number | null,
};

export type CreatePriceInput = {
  id?: string | null,
  conceptID: string,
  parentConceptID: string,
  name: string,
  multiLanguages?: string | null,
  price: number,
  symphonyID: string,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelPriceConditionInput = {
  conceptID?: ModelIDInput | null,
  parentConceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  symphonyID?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelPriceConditionInput | null > | null,
  or?: Array< ModelPriceConditionInput | null > | null,
  not?: ModelPriceConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdatePriceInput = {
  id: string,
  conceptID?: string | null,
  parentConceptID?: string | null,
  name?: string | null,
  multiLanguages?: string | null,
  price?: number | null,
  symphonyID?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeletePriceInput = {
  id: string,
  _version?: number | null,
};

export type CreateChoiceGroupsInput = {
  id?: string | null,
  conceptID: string,
  parentConceptID: string,
  name: string,
  required?: boolean | null,
  maxNumberOfChoices?: number | null,
  minNumberOfChoices?: number | null,
  precedence?: string | null,
  type?: string | null,
  choices?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  multiLanguages?: string | null,
  simphonyID?: string | null,
  _version?: number | null,
};

export type ModelChoiceGroupsConditionInput = {
  conceptID?: ModelIDInput | null,
  parentConceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  required?: ModelBooleanInput | null,
  maxNumberOfChoices?: ModelIntInput | null,
  minNumberOfChoices?: ModelIntInput | null,
  precedence?: ModelStringInput | null,
  type?: ModelStringInput | null,
  choices?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  simphonyID?: ModelStringInput | null,
  and?: Array< ModelChoiceGroupsConditionInput | null > | null,
  or?: Array< ModelChoiceGroupsConditionInput | null > | null,
  not?: ModelChoiceGroupsConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateChoiceGroupsInput = {
  id: string,
  conceptID?: string | null,
  parentConceptID?: string | null,
  name?: string | null,
  required?: boolean | null,
  maxNumberOfChoices?: number | null,
  minNumberOfChoices?: number | null,
  precedence?: string | null,
  type?: string | null,
  choices?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  multiLanguages?: string | null,
  simphonyID?: string | null,
  _version?: number | null,
};

export type DeleteChoiceGroupsInput = {
  id: string,
  _version?: number | null,
};

export type CreateChoiceInput = {
  id?: string | null,
  conceptID: string,
  name: string,
  price: number,
  precedence?: string | null,
  choiceGroupID: string,
  choiceGroupName?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  symphonyID?: string | null,
  multiLanguages?: string | null,
  definitionNumber?: number | null,
  _version?: number | null,
};

export type ModelChoiceConditionInput = {
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  precedence?: ModelStringInput | null,
  choiceGroupID?: ModelIDInput | null,
  choiceGroupName?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  symphonyID?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  definitionNumber?: ModelIntInput | null,
  and?: Array< ModelChoiceConditionInput | null > | null,
  or?: Array< ModelChoiceConditionInput | null > | null,
  not?: ModelChoiceConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UpdateChoiceInput = {
  id: string,
  conceptID?: string | null,
  name?: string | null,
  price?: number | null,
  precedence?: string | null,
  choiceGroupID?: string | null,
  choiceGroupName?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  symphonyID?: string | null,
  multiLanguages?: string | null,
  definitionNumber?: number | null,
  _version?: number | null,
};

export type DeleteChoiceInput = {
  id: string,
  _version?: number | null,
};

export type CreateCartInput = {
  id?: string | null,
  conceptID: string,
  userID: string,
  orderedItems?: Array< CartItemInput | null > | null,
  specialRequest?: string | null,
  deliveryFee?: number | null,
  subTotal?: number | null,
  subTaxTotal?: number | null,
  totalPrice?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type CartItemInput = {
  menuItemID: string,
  categoryID: string,
  name?: string | null,
  quantity: number,
  selectedChoiceGroups?: Array< CartChoiceGroupInput | null > | null,
  price: number,
  priceName?: string | null,
  priceID?: string | null,
  priceMultiLanguages?: string | null,
  multiLanguages?: string | null,
  notes?: string | null,
  image?: string | null,
  type?: string | null,
};

export type CartChoiceGroupInput = {
  id: string,
  name?: string | null,
  selectedChoices?: Array< CartChoiceInput | null > | null,
  precedence?: string | null,
};

export type CartChoiceInput = {
  id: string,
  name?: string | null,
  price?: number | null,
};

export type ModelCartConditionInput = {
  conceptID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  specialRequest?: ModelStringInput | null,
  deliveryFee?: ModelFloatInput | null,
  subTotal?: ModelFloatInput | null,
  subTaxTotal?: ModelFloatInput | null,
  totalPrice?: ModelFloatInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelCartConditionInput | null > | null,
  or?: Array< ModelCartConditionInput | null > | null,
  not?: ModelCartConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Cart = {
  __typename: "Cart",
  id: string,
  conceptID: string,
  userID: string,
  orderedItems?:  Array<CartItem | null > | null,
  specialRequest?: string | null,
  deliveryFee?: number | null,
  subTotal?: number | null,
  subTaxTotal?: number | null,
  totalPrice?: number | null,
  deleted?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type CartItem = {
  __typename: "CartItem",
  menuItemID: string,
  categoryID: string,
  name?: string | null,
  quantity: number,
  selectedChoiceGroups?:  Array<CartChoiceGroup | null > | null,
  price: number,
  priceName?: string | null,
  priceID?: string | null,
  priceMultiLanguages?: string | null,
  multiLanguages?: string | null,
  notes?: string | null,
  image?: string | null,
  type?: string | null,
};

export type CartChoiceGroup = {
  __typename: "CartChoiceGroup",
  id: string,
  name?: string | null,
  selectedChoices?:  Array<CartChoice | null > | null,
  precedence?: string | null,
};

export type CartChoice = {
  __typename: "CartChoice",
  id: string,
  name?: string | null,
  price?: number | null,
};

export type UpdateCartInput = {
  id: string,
  conceptID?: string | null,
  userID?: string | null,
  orderedItems?: Array< CartItemInput | null > | null,
  specialRequest?: string | null,
  deliveryFee?: number | null,
  subTotal?: number | null,
  subTaxTotal?: number | null,
  totalPrice?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteCartInput = {
  id: string,
  _version?: number | null,
};

export type CreateAdminStatusInput = {
  id?: string | null,
  sub: string,
  accountID: string,
  name?: string | null,
  averageCallTime?: number | null,
  numberOfCalls?: number | null,
  idelDuration?: number | null,
  activeDuration?: number | null,
  awayDuration?: number | null,
  lastCallTime?: number | null,
  agentBarAverageCallTime?: number | null,
  agentBarNumberOfCalls?: number | null,
  type?: string | null,
  waiterStatus?: string | null,
  totalAverageRatio?: number | null,
  performancePerDay?: Array< string | null > | null,
  _version?: number | null,
};

export type ModelAdminStatusConditionInput = {
  sub?: ModelStringInput | null,
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  averageCallTime?: ModelFloatInput | null,
  numberOfCalls?: ModelFloatInput | null,
  idelDuration?: ModelFloatInput | null,
  activeDuration?: ModelFloatInput | null,
  awayDuration?: ModelFloatInput | null,
  lastCallTime?: ModelFloatInput | null,
  agentBarAverageCallTime?: ModelFloatInput | null,
  agentBarNumberOfCalls?: ModelFloatInput | null,
  type?: ModelStringInput | null,
  waiterStatus?: ModelStringInput | null,
  totalAverageRatio?: ModelFloatInput | null,
  performancePerDay?: ModelStringInput | null,
  and?: Array< ModelAdminStatusConditionInput | null > | null,
  or?: Array< ModelAdminStatusConditionInput | null > | null,
  not?: ModelAdminStatusConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type AdminStatus = {
  __typename: "AdminStatus",
  id: string,
  sub: string,
  accountID: string,
  name?: string | null,
  averageCallTime?: number | null,
  numberOfCalls?: number | null,
  idelDuration?: number | null,
  activeDuration?: number | null,
  awayDuration?: number | null,
  lastCallTime?: number | null,
  agentBarAverageCallTime?: number | null,
  agentBarNumberOfCalls?: number | null,
  type?: string | null,
  waiterStatus?: string | null,
  totalAverageRatio?: number | null,
  performancePerDay?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateAdminStatusInput = {
  id: string,
  sub?: string | null,
  accountID?: string | null,
  name?: string | null,
  averageCallTime?: number | null,
  numberOfCalls?: number | null,
  idelDuration?: number | null,
  activeDuration?: number | null,
  awayDuration?: number | null,
  lastCallTime?: number | null,
  agentBarAverageCallTime?: number | null,
  agentBarNumberOfCalls?: number | null,
  type?: string | null,
  waiterStatus?: string | null,
  totalAverageRatio?: number | null,
  performancePerDay?: Array< string | null > | null,
  _version?: number | null,
};

export type DeleteAdminStatusInput = {
  id: string,
  _version?: number | null,
};

export type CreateOnlineOrderInput = {
  id?: string | null,
  conceptID: string,
  statusID?: string | null,
  status?: string | null,
  reason?: string | null,
  orderType?: string | null,
  channel?: string | null,
  userID: string,
  userName?: string | null,
  userPhoneNumber?: string | null,
  dispatcherID?: string | null,
  dispatcherName?: string | null,
  totalAmount?: number | null,
  discountedValue?: number | null,
  subTotal?: number | null,
  subTaxTotal?: number | null,
  tax?: number | null,
  deliveryFee?: number | null,
  orderTime?: string | null,
  executionArn?: string | null,
  specialRequest?: string | null,
  orderAddress?: string | null,
  orderedItems?: Array< CartItemInput | null > | null,
  discountID?: number | null,
  amount_cents?: string | null,
  transactionID?: string | null,
  paymentStatus?: boolean | null,
  paymentType?: string | null,
  referenceId?: string | null,
  cardHolderNumber?: string | null,
  cardHolderName?: string | null,
  cardSchemeName?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  sentToKitchenAt?: string | null,
  readyAt?: string | null,
  outForDeliveryAt?: string | null,
  deliveredAt?: string | null,
  promoCodeID?: string | null,
  promoCodeName?: string | null,
  promoCodeAppliedTo?: string | null,
  rating?: number | null,
  isReview?: boolean | null,
  reviewID?: string | null,
  createdByID: string,
  createdByName: string,
  tmpField?: string | null,
  otp?: string | null,
  avgOrderCompletionEstimate?: string | null,
  _version?: number | null,
};

export type ModelOnlineOrderConditionInput = {
  conceptID?: ModelIDInput | null,
  statusID?: ModelIDInput | null,
  status?: ModelStringInput | null,
  reason?: ModelStringInput | null,
  orderType?: ModelStringInput | null,
  channel?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  userName?: ModelStringInput | null,
  userPhoneNumber?: ModelStringInput | null,
  dispatcherID?: ModelStringInput | null,
  dispatcherName?: ModelStringInput | null,
  totalAmount?: ModelFloatInput | null,
  discountedValue?: ModelFloatInput | null,
  subTotal?: ModelFloatInput | null,
  subTaxTotal?: ModelFloatInput | null,
  tax?: ModelFloatInput | null,
  deliveryFee?: ModelFloatInput | null,
  orderTime?: ModelStringInput | null,
  executionArn?: ModelStringInput | null,
  specialRequest?: ModelStringInput | null,
  orderAddress?: ModelStringInput | null,
  discountID?: ModelIntInput | null,
  amount_cents?: ModelStringInput | null,
  transactionID?: ModelStringInput | null,
  paymentStatus?: ModelBooleanInput | null,
  paymentType?: ModelStringInput | null,
  referenceId?: ModelStringInput | null,
  cardHolderNumber?: ModelStringInput | null,
  cardHolderName?: ModelStringInput | null,
  cardSchemeName?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  sentToKitchenAt?: ModelStringInput | null,
  readyAt?: ModelStringInput | null,
  outForDeliveryAt?: ModelStringInput | null,
  deliveredAt?: ModelStringInput | null,
  promoCodeID?: ModelIDInput | null,
  promoCodeName?: ModelStringInput | null,
  promoCodeAppliedTo?: ModelStringInput | null,
  rating?: ModelIntInput | null,
  isReview?: ModelBooleanInput | null,
  reviewID?: ModelIDInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  tmpField?: ModelStringInput | null,
  otp?: ModelStringInput | null,
  avgOrderCompletionEstimate?: ModelStringInput | null,
  and?: Array< ModelOnlineOrderConditionInput | null > | null,
  or?: Array< ModelOnlineOrderConditionInput | null > | null,
  not?: ModelOnlineOrderConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type OnlineOrder = {
  __typename: "OnlineOrder",
  id: string,
  conceptID: string,
  statusID?: string | null,
  status?: string | null,
  reason?: string | null,
  orderType?: string | null,
  channel?: string | null,
  userID: string,
  userName?: string | null,
  userPhoneNumber?: string | null,
  dispatcherID?: string | null,
  dispatcherName?: string | null,
  totalAmount?: number | null,
  discountedValue?: number | null,
  subTotal?: number | null,
  subTaxTotal?: number | null,
  tax?: number | null,
  deliveryFee?: number | null,
  orderTime?: string | null,
  executionArn?: string | null,
  specialRequest?: string | null,
  orderAddress?: string | null,
  orderedItems?:  Array<CartItem | null > | null,
  discountID?: number | null,
  amount_cents?: string | null,
  transactionID?: string | null,
  paymentStatus?: boolean | null,
  paymentType?: string | null,
  referenceId?: string | null,
  cardHolderNumber?: string | null,
  cardHolderName?: string | null,
  cardSchemeName?: string | null,
  deleted?: string | null,
  createdAt: string,
  sentToKitchenAt?: string | null,
  readyAt?: string | null,
  outForDeliveryAt?: string | null,
  deliveredAt?: string | null,
  promoCodeID?: string | null,
  promoCodeName?: string | null,
  promoCodeAppliedTo?: string | null,
  rating?: number | null,
  isReview?: boolean | null,
  reviewID?: string | null,
  review?: Review | null,
  createdByID: string,
  createdByName: string,
  tmpField?: string | null,
  otp?: string | null,
  avgOrderCompletionEstimate?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateOnlineOrderInput = {
  id: string,
  conceptID?: string | null,
  statusID?: string | null,
  status?: string | null,
  reason?: string | null,
  orderType?: string | null,
  channel?: string | null,
  userID?: string | null,
  userName?: string | null,
  userPhoneNumber?: string | null,
  dispatcherID?: string | null,
  dispatcherName?: string | null,
  totalAmount?: number | null,
  discountedValue?: number | null,
  subTotal?: number | null,
  subTaxTotal?: number | null,
  tax?: number | null,
  deliveryFee?: number | null,
  orderTime?: string | null,
  executionArn?: string | null,
  specialRequest?: string | null,
  orderAddress?: string | null,
  orderedItems?: Array< CartItemInput | null > | null,
  discountID?: number | null,
  amount_cents?: string | null,
  transactionID?: string | null,
  paymentStatus?: boolean | null,
  paymentType?: string | null,
  referenceId?: string | null,
  cardHolderNumber?: string | null,
  cardHolderName?: string | null,
  cardSchemeName?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  sentToKitchenAt?: string | null,
  readyAt?: string | null,
  outForDeliveryAt?: string | null,
  deliveredAt?: string | null,
  promoCodeID?: string | null,
  promoCodeName?: string | null,
  promoCodeAppliedTo?: string | null,
  rating?: number | null,
  isReview?: boolean | null,
  reviewID?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  tmpField?: string | null,
  otp?: string | null,
  avgOrderCompletionEstimate?: string | null,
  _version?: number | null,
};

export type DeleteOnlineOrderInput = {
  id: string,
  _version?: number | null,
};

export type CreateOrderStatusInput = {
  id?: string | null,
  conceptID: string,
  name: string,
  color?: string | null,
  precedence?: number | null,
  accessibleStatus?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelOrderStatusConditionInput = {
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  color?: ModelStringInput | null,
  precedence?: ModelIntInput | null,
  accessibleStatus?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelOrderStatusConditionInput | null > | null,
  or?: Array< ModelOrderStatusConditionInput | null > | null,
  not?: ModelOrderStatusConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type OrderStatus = {
  __typename: "OrderStatus",
  id: string,
  conceptID: string,
  name: string,
  color?: string | null,
  precedence?: number | null,
  accessibleStatus?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateOrderStatusInput = {
  id: string,
  conceptID?: string | null,
  name?: string | null,
  color?: string | null,
  precedence?: number | null,
  accessibleStatus?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteOrderStatusInput = {
  id: string,
  _version?: number | null,
};

export type CreateDiscountCouponInput = {
  id?: string | null,
  discountPercent: string,
  usedBy?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelDiscountCouponConditionInput = {
  discountPercent?: ModelStringInput | null,
  usedBy?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelDiscountCouponConditionInput | null > | null,
  or?: Array< ModelDiscountCouponConditionInput | null > | null,
  not?: ModelDiscountCouponConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type DiscountCoupon = {
  __typename: "DiscountCoupon",
  id: string,
  discountPercent: string,
  usedBy?: Array< string | null > | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateDiscountCouponInput = {
  id: string,
  discountPercent?: string | null,
  usedBy?: Array< string | null > | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteDiscountCouponInput = {
  id: string,
  _version?: number | null,
};

export type CreateZoneInput = {
  id?: string | null,
  conceptID: string,
  location: string,
  deliveryFee: number,
  concepts?: Array< ZoneConceptInput | null > | null,
  polygon?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ZoneConceptInput = {
  id: string,
  deliveryFee: number,
};

export type ModelZoneConditionInput = {
  conceptID?: ModelIDInput | null,
  location?: ModelStringInput | null,
  deliveryFee?: ModelIntInput | null,
  polygon?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelZoneConditionInput | null > | null,
  or?: Array< ModelZoneConditionInput | null > | null,
  not?: ModelZoneConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Zone = {
  __typename: "Zone",
  id: string,
  conceptID: string,
  location: string,
  deliveryFee: number,
  concepts?:  Array<ZoneConcept | null > | null,
  polygon?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type ZoneConcept = {
  __typename: "ZoneConcept",
  id: string,
  deliveryFee: number,
};

export type UpdateZoneInput = {
  id: string,
  conceptID?: string | null,
  location?: string | null,
  deliveryFee?: number | null,
  concepts?: Array< ZoneConceptInput | null > | null,
  polygon?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteZoneInput = {
  id: string,
  _version?: number | null,
};

export type CreateUserIPInput = {
  id?: string | null,
  ip?: string | null,
  date?: string | null,
  _version?: number | null,
};

export type ModelUserIPConditionInput = {
  ip?: ModelStringInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelUserIPConditionInput | null > | null,
  or?: Array< ModelUserIPConditionInput | null > | null,
  not?: ModelUserIPConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type UserIP = {
  __typename: "UserIP",
  id: string,
  ip?: string | null,
  date?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateUserIPInput = {
  id: string,
  ip?: string | null,
  date?: string | null,
  _version?: number | null,
};

export type DeleteUserIPInput = {
  id: string,
  _version?: number | null,
};

export type CreateMainCategoryInput = {
  id?: string | null,
  conceptID: string,
  name: string,
  precedence: string,
  openTime?: string | null,
  closeTime?: string | null,
  guestView?: boolean | null,
  guestOrder?: boolean | null,
  staffOrder?: boolean | null,
  image?: string | null,
  menuPDF?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  multiLanguages?: string | null,
  _version?: number | null,
};

export type ModelMainCategoryConditionInput = {
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  openTime?: ModelStringInput | null,
  closeTime?: ModelStringInput | null,
  guestView?: ModelBooleanInput | null,
  guestOrder?: ModelBooleanInput | null,
  staffOrder?: ModelBooleanInput | null,
  image?: ModelStringInput | null,
  menuPDF?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  and?: Array< ModelMainCategoryConditionInput | null > | null,
  or?: Array< ModelMainCategoryConditionInput | null > | null,
  not?: ModelMainCategoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type MainCategory = {
  __typename: "MainCategory",
  id: string,
  conceptID: string,
  name: string,
  precedence: string,
  openTime?: string | null,
  closeTime?: string | null,
  guestView?: boolean | null,
  guestOrder?: boolean | null,
  staffOrder?: boolean | null,
  image?: string | null,
  menuPDF?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  multiLanguages?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateMainCategoryInput = {
  id: string,
  conceptID?: string | null,
  name?: string | null,
  precedence?: string | null,
  openTime?: string | null,
  closeTime?: string | null,
  guestView?: boolean | null,
  guestOrder?: boolean | null,
  staffOrder?: boolean | null,
  image?: string | null,
  menuPDF?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  multiLanguages?: string | null,
  _version?: number | null,
};

export type DeleteMainCategoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateSubCategoryInput = {
  id?: string | null,
  conceptID: string,
  name: string,
  precedence: string,
  openTime?: string | null,
  closeTime?: string | null,
  mainCategoryID?: string | null,
  guestView?: boolean | null,
  guestOrder?: boolean | null,
  staffOrder?: boolean | null,
  image?: string | null,
  menuPDF?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  multiLanguages?: string | null,
  _version?: number | null,
};

export type ModelSubCategoryConditionInput = {
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  openTime?: ModelStringInput | null,
  closeTime?: ModelStringInput | null,
  mainCategoryID?: ModelIDInput | null,
  guestView?: ModelBooleanInput | null,
  guestOrder?: ModelBooleanInput | null,
  staffOrder?: ModelBooleanInput | null,
  image?: ModelStringInput | null,
  menuPDF?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  and?: Array< ModelSubCategoryConditionInput | null > | null,
  or?: Array< ModelSubCategoryConditionInput | null > | null,
  not?: ModelSubCategoryConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type SubCategory = {
  __typename: "SubCategory",
  id: string,
  conceptID: string,
  name: string,
  precedence: string,
  openTime?: string | null,
  closeTime?: string | null,
  mainCategoryID?: string | null,
  guestView?: boolean | null,
  guestOrder?: boolean | null,
  staffOrder?: boolean | null,
  image?: string | null,
  menuPDF?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  multiLanguages?: string | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateSubCategoryInput = {
  id: string,
  conceptID?: string | null,
  name?: string | null,
  precedence?: string | null,
  openTime?: string | null,
  closeTime?: string | null,
  mainCategoryID?: string | null,
  guestView?: boolean | null,
  guestOrder?: boolean | null,
  staffOrder?: boolean | null,
  image?: string | null,
  menuPDF?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  multiLanguages?: string | null,
  _version?: number | null,
};

export type DeleteSubCategoryInput = {
  id: string,
  _version?: number | null,
};

export type CreateChannelInput = {
  id?: string | null,
  name: string,
  description?: string | null,
  numberOfOrders?: number | null,
  isActive?: boolean | null,
  multiLanguages?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelChannelConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  numberOfOrders?: ModelIntInput | null,
  isActive?: ModelBooleanInput | null,
  multiLanguages?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelChannelConditionInput | null > | null,
  or?: Array< ModelChannelConditionInput | null > | null,
  not?: ModelChannelConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Channel = {
  __typename: "Channel",
  id: string,
  name: string,
  description?: string | null,
  numberOfOrders?: number | null,
  isActive?: boolean | null,
  multiLanguages?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateChannelInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  numberOfOrders?: number | null,
  isActive?: boolean | null,
  multiLanguages?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteChannelInput = {
  id: string,
  _version?: number | null,
};

export type CreatePromocodeInput = {
  id?: string | null,
  name: string,
  redemptions: number,
  status?: string | null,
  startDate: string,
  endDate: string,
  appliesTo: string,
  minOrderReq: string,
  minOrderVal?: number | null,
  minOrderQty?: number | null,
  tenderReq: Array< string | null >,
  discountVal: number,
  discountLimit?: number | null,
  quota?: number | null,
  quotaUsed?: number | null,
  totalRevenue?: number | null,
  appliedDiscountedVal?: number | null,
  usePerCustomer?: number | null,
  channelReq: Array< string | null >,
  customerEligibility: string,
  customerReq?: Array< string | null > | null,
  spendingExceeds?: number | null,
  spendingBelow?: number | null,
  orderSinceDate?: string | null,
  orderFromDate?: string | null,
  orderToDate?: string | null,
  isDisplayed?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  parentConceptIDs?: Array< string | null > | null,
  conceptIDs?: Array< string | null > | null,
  _version?: number | null,
};

export type ModelPromocodeConditionInput = {
  name?: ModelStringInput | null,
  redemptions?: ModelIntInput | null,
  status?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  appliesTo?: ModelStringInput | null,
  minOrderReq?: ModelStringInput | null,
  minOrderVal?: ModelIntInput | null,
  minOrderQty?: ModelIntInput | null,
  tenderReq?: ModelStringInput | null,
  discountVal?: ModelIntInput | null,
  discountLimit?: ModelIntInput | null,
  quota?: ModelIntInput | null,
  quotaUsed?: ModelIntInput | null,
  totalRevenue?: ModelIntInput | null,
  appliedDiscountedVal?: ModelIntInput | null,
  usePerCustomer?: ModelIntInput | null,
  channelReq?: ModelStringInput | null,
  customerEligibility?: ModelStringInput | null,
  customerReq?: ModelStringInput | null,
  spendingExceeds?: ModelIntInput | null,
  spendingBelow?: ModelIntInput | null,
  orderSinceDate?: ModelStringInput | null,
  orderFromDate?: ModelStringInput | null,
  orderToDate?: ModelStringInput | null,
  isDisplayed?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  parentConceptIDs?: ModelIDInput | null,
  conceptIDs?: ModelIDInput | null,
  and?: Array< ModelPromocodeConditionInput | null > | null,
  or?: Array< ModelPromocodeConditionInput | null > | null,
  not?: ModelPromocodeConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type Promocode = {
  __typename: "Promocode",
  id: string,
  name: string,
  redemptions: number,
  status?: string | null,
  startDate: string,
  endDate: string,
  appliesTo: string,
  minOrderReq: string,
  minOrderVal?: number | null,
  minOrderQty?: number | null,
  tenderReq: Array< string | null >,
  discountVal: number,
  discountLimit?: number | null,
  quota?: number | null,
  quotaUsed?: number | null,
  totalRevenue?: number | null,
  appliedDiscountedVal?: number | null,
  usePerCustomer?: number | null,
  channelReq: Array< string | null >,
  customerEligibility: string,
  customerReq?: Array< string | null > | null,
  spendingExceeds?: number | null,
  spendingBelow?: number | null,
  orderSinceDate?: string | null,
  orderFromDate?: string | null,
  orderToDate?: string | null,
  isDisplayed?: boolean | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  parentConceptIDs?: Array< string | null > | null,
  conceptIDs?: Array< string | null > | null,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdatePromocodeInput = {
  id: string,
  name?: string | null,
  redemptions?: number | null,
  status?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  appliesTo?: string | null,
  minOrderReq?: string | null,
  minOrderVal?: number | null,
  minOrderQty?: number | null,
  tenderReq?: Array< string | null > | null,
  discountVal?: number | null,
  discountLimit?: number | null,
  quota?: number | null,
  quotaUsed?: number | null,
  totalRevenue?: number | null,
  appliedDiscountedVal?: number | null,
  usePerCustomer?: number | null,
  channelReq?: Array< string | null > | null,
  customerEligibility?: string | null,
  customerReq?: Array< string | null > | null,
  spendingExceeds?: number | null,
  spendingBelow?: number | null,
  orderSinceDate?: string | null,
  orderFromDate?: string | null,
  orderToDate?: string | null,
  isDisplayed?: boolean | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  parentConceptIDs?: Array< string | null > | null,
  conceptIDs?: Array< string | null > | null,
  _version?: number | null,
};

export type DeletePromocodeInput = {
  id: string,
  _version?: number | null,
};

export type CreatePromocodeDashboardInput = {
  id?: string | null,
  conceptID: string,
  totalRevenue?: number | null,
  totalRedemptions?: number | null,
  totalDiscount?: number | null,
  _version?: number | null,
};

export type ModelPromocodeDashboardConditionInput = {
  conceptID?: ModelIDInput | null,
  totalRevenue?: ModelIntInput | null,
  totalRedemptions?: ModelIntInput | null,
  totalDiscount?: ModelIntInput | null,
  and?: Array< ModelPromocodeDashboardConditionInput | null > | null,
  or?: Array< ModelPromocodeDashboardConditionInput | null > | null,
  not?: ModelPromocodeDashboardConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type PromocodeDashboard = {
  __typename: "PromocodeDashboard",
  id: string,
  conceptID: string,
  totalRevenue?: number | null,
  totalRedemptions?: number | null,
  totalDiscount?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdatePromocodeDashboardInput = {
  id: string,
  conceptID?: string | null,
  totalRevenue?: number | null,
  totalRedemptions?: number | null,
  totalDiscount?: number | null,
  _version?: number | null,
};

export type DeletePromocodeDashboardInput = {
  id: string,
  _version?: number | null,
};

export type CreateConceptRevenueInput = {
  id?: string | null,
  conceptID: string,
  date: string,
  driveThruRevenue?: number | null,
  pickupRevenue?: number | null,
  deliveryRevenue?: number | null,
  _version?: number | null,
};

export type ModelConceptRevenueConditionInput = {
  conceptID?: ModelIDInput | null,
  date?: ModelStringInput | null,
  driveThruRevenue?: ModelFloatInput | null,
  pickupRevenue?: ModelFloatInput | null,
  deliveryRevenue?: ModelFloatInput | null,
  and?: Array< ModelConceptRevenueConditionInput | null > | null,
  or?: Array< ModelConceptRevenueConditionInput | null > | null,
  not?: ModelConceptRevenueConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
};

export type ConceptRevenue = {
  __typename: "ConceptRevenue",
  id: string,
  conceptID: string,
  date: string,
  driveThruRevenue?: number | null,
  pickupRevenue?: number | null,
  deliveryRevenue?: number | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateConceptRevenueInput = {
  id: string,
  conceptID?: string | null,
  date?: string | null,
  driveThruRevenue?: number | null,
  pickupRevenue?: number | null,
  deliveryRevenue?: number | null,
  _version?: number | null,
};

export type DeleteConceptRevenueInput = {
  id: string,
  _version?: number | null,
};

export type CreateLoyaltyItemInput = {
  id?: string | null,
  name: string,
  conceptID: string,
  parentConceptID: string,
  description?: string | null,
  startDate: string,
  endDate?: string | null,
  options?: Array< string | null > | null,
  points: number,
  redemptionPerUser?: number | null,
  image?: string | null,
  simphonyID?: string | null,
  multiLanguages?: string | null,
  isActive?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID: string,
  createdByName: string,
  _version?: number | null,
};

export type ModelLoyaltyItemConditionInput = {
  name?: ModelStringInput | null,
  conceptID?: ModelIDInput | null,
  parentConceptID?: ModelIDInput | null,
  description?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  options?: ModelStringInput | null,
  points?: ModelIntInput | null,
  redemptionPerUser?: ModelIntInput | null,
  image?: ModelStringInput | null,
  simphonyID?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  isActive?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  and?: Array< ModelLoyaltyItemConditionInput | null > | null,
  or?: Array< ModelLoyaltyItemConditionInput | null > | null,
  not?: ModelLoyaltyItemConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type LoyaltyItem = {
  __typename: "LoyaltyItem",
  id: string,
  name: string,
  conceptID: string,
  parentConceptID: string,
  description?: string | null,
  startDate: string,
  endDate?: string | null,
  options?: Array< string | null > | null,
  points: number,
  redemptionPerUser?: number | null,
  image?: string | null,
  simphonyID?: string | null,
  multiLanguages?: string | null,
  isActive?: string | null,
  deleted?: string | null,
  createdAt: string,
  createdByID: string,
  createdByName: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateLoyaltyItemInput = {
  id: string,
  name?: string | null,
  conceptID?: string | null,
  parentConceptID?: string | null,
  description?: string | null,
  startDate?: string | null,
  endDate?: string | null,
  options?: Array< string | null > | null,
  points?: number | null,
  redemptionPerUser?: number | null,
  image?: string | null,
  simphonyID?: string | null,
  multiLanguages?: string | null,
  isActive?: string | null,
  deleted?: string | null,
  createdAt?: string | null,
  createdByID?: string | null,
  createdByName?: string | null,
  _version?: number | null,
};

export type DeleteLoyaltyItemInput = {
  id: string,
  _version?: number | null,
};

export type CreateOTPInput = {
  id?: string | null,
  OTP: string,
  redeemed?: boolean | null,
  deleted?: string | null,
  userID: string,
  conceptID?: string | null,
  loyaltyItemID?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type ModelOTPConditionInput = {
  OTP?: ModelStringInput | null,
  redeemed?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  loyaltyItemID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelOTPConditionInput | null > | null,
  or?: Array< ModelOTPConditionInput | null > | null,
  not?: ModelOTPConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type OTP = {
  __typename: "OTP",
  id: string,
  OTP: string,
  redeemed?: boolean | null,
  deleted?: string | null,
  userID: string,
  conceptID?: string | null,
  loyaltyItemID?: string | null,
  loyaltyItem?: LoyaltyItem | null,
  user?: User | null,
  concept?: Concept | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateOTPInput = {
  id: string,
  OTP?: string | null,
  redeemed?: boolean | null,
  deleted?: string | null,
  userID?: string | null,
  conceptID?: string | null,
  loyaltyItemID?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteOTPInput = {
  id: string,
  _version?: number | null,
};

export type CreateMostOrderedItemsInput = {
  id?: string | null,
  conceptID?: string | null,
  menuItemID: string,
  menuItemName?: string | null,
  menuItemImage?: string | null,
  quantity?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type ModelMostOrderedItemsConditionInput = {
  conceptID?: ModelStringInput | null,
  menuItemID?: ModelStringInput | null,
  menuItemName?: ModelStringInput | null,
  menuItemImage?: ModelStringInput | null,
  quantity?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelMostOrderedItemsConditionInput | null > | null,
  or?: Array< ModelMostOrderedItemsConditionInput | null > | null,
  not?: ModelMostOrderedItemsConditionInput | null,
  _deleted?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
};

export type MostOrderedItems = {
  __typename: "MostOrderedItems",
  id: string,
  conceptID?: string | null,
  menuItemID: string,
  menuItemName?: string | null,
  menuItemImage?: string | null,
  quantity?: number | null,
  deleted?: string | null,
  createdAt: string,
  updatedAt: string,
  _version: number,
  _deleted?: boolean | null,
  _lastChangedAt: number,
};

export type UpdateMostOrderedItemsInput = {
  id: string,
  conceptID?: string | null,
  menuItemID?: string | null,
  menuItemName?: string | null,
  menuItemImage?: string | null,
  quantity?: number | null,
  deleted?: string | null,
  createdAt?: string | null,
  _version?: number | null,
};

export type DeleteMostOrderedItemsInput = {
  id: string,
  _version?: number | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelStringInput | null,
  username?: ModelStringInput | null,
  preferred_username?: ModelStringInput | null,
  email?: ModelStringInput | null,
  email_verified?: ModelBooleanInput | null,
  phone_number?: ModelStringInput | null,
  phone_number_verified?: ModelBooleanInput | null,
  name?: ModelStringInput | null,
  nickname?: ModelStringInput | null,
  given_name?: ModelStringInput | null,
  middle_name?: ModelStringInput | null,
  family_name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  birthdate?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  locale?: ModelStringInput | null,
  picture?: ModelStringInput | null,
  pictureCrop?: ModelStringInput | null,
  website?: ModelStringInput | null,
  zoneinfo?: ModelStringInput | null,
  verification_code?: ModelStringInput | null,
  facebook?: ModelStringInput | null,
  instagram?: ModelStringInput | null,
  group?: ModelStringInput | null,
  phones?: ModelStringInput | null,
  flags?: ModelStringInput | null,
  flagsName?: ModelStringInput | null,
  interests?: ModelStringInput | null,
  interestsName?: ModelStringInput | null,
  status?: ModelStringInput | null,
  stats?: ModelIDInput | null,
  spending?: ModelIntInput | null,
  prevOrderDate?: ModelStringInput | null,
  lastOrderDate?: ModelStringInput | null,
  mostOrderedItems?: ModelStringInput | null,
  points?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  cognitoUsername?: ModelStringInput | null,
  cognitoSub?: ModelStringInput | null,
  autoGeneratedPassword?: ModelBooleanInput | null,
  updated?: ModelStringInput | null,
  sub?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
  startedAt?: number | null,
  scannedCount?: number | null,
  count?: number | null,
};

export type ModelUserUsersByPhoneNumberCompositeKeyConditionInput = {
  eq?: ModelUserUsersByPhoneNumberCompositeKeyInput | null,
  le?: ModelUserUsersByPhoneNumberCompositeKeyInput | null,
  lt?: ModelUserUsersByPhoneNumberCompositeKeyInput | null,
  ge?: ModelUserUsersByPhoneNumberCompositeKeyInput | null,
  gt?: ModelUserUsersByPhoneNumberCompositeKeyInput | null,
  between?: Array< ModelUserUsersByPhoneNumberCompositeKeyInput | null > | null,
  beginsWith?: ModelUserUsersByPhoneNumberCompositeKeyInput | null,
};

export type ModelUserUsersByPhoneNumberCompositeKeyInput = {
  deleted?: string | null,
  name?: string | null,
  createdAt?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelReviewFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelStringInput | null,
  conceptName?: ModelStringInput | null,
  accountID?: ModelStringInput | null,
  bookingID?: ModelStringInput | null,
  parentConceptID?: ModelStringInput | null,
  guestName?: ModelStringInput | null,
  guestId?: ModelStringInput | null,
  reason?: ModelStringInput | null,
  value?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  orderID?: ModelStringInput | null,
  rate?: ModelIntInput | null,
  feedback?: ModelStringInput | null,
  satisfaction?: ModelStringInput | null,
  thoughts?: ModelStringInput | null,
  photos?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  phoneUser?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelReviewFilterInput | null > | null,
  or?: Array< ModelReviewFilterInput | null > | null,
  not?: ModelReviewFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelReviewConnection = {
  __typename: "ModelReviewConnection",
  items:  Array<Review | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelAccountFilterInput = {
  id?: ModelIDInput | null,
  logo?: ModelStringInput | null,
  domain?: ModelStringInput | null,
  siteTitle?: ModelStringInput | null,
  guestsCount?: ModelIntInput | null,
  tagline?: ModelStringInput | null,
  description?: ModelStringInput | null,
  siteAddress?: ModelStringInput | null,
  defaultLanguage?: ModelStringInput | null,
  languages?: ModelStringInput | null,
  features?: ModelStringInput | null,
  status?: ModelStringInput | null,
  socialLinks?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  callCenterEmployeesIds?: ModelStringInput | null,
  kpiReportResetFrequency?: ModelStringInput | null,
  agentBarResetFrequency?: ModelStringInput | null,
  dynamicStatus?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAccountFilterInput | null > | null,
  or?: Array< ModelAccountFilterInput | null > | null,
  not?: ModelAccountFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAccountConnection = {
  __typename: "ModelAccountConnection",
  items:  Array<Account | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFeatureFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  icon?: ModelStringInput | null,
  slug?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  parent?: ModelStringInput | null,
  private?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelFeatureFilterInput | null > | null,
  or?: Array< ModelFeatureFilterInput | null > | null,
  not?: ModelFeatureFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelFeatureConnection = {
  __typename: "ModelFeatureConnection",
  items:  Array<Feature | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMediaFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelIDInput | null,
  fileUrl?: ModelStringInput | null,
  filename?: ModelStringInput | null,
  filetype?: ModelStringInput | null,
  fileSize?: ModelIntInput | null,
  alternativeText?: ModelStringInput | null,
  caption?: ModelStringInput | null,
  description?: ModelStringInput | null,
  attachments?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMediaFilterInput | null > | null,
  or?: Array< ModelMediaFilterInput | null > | null,
  not?: ModelMediaFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelMediaConnection = {
  __typename: "ModelMediaConnection",
  items:  Array<Media | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelConceptFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  kiosks?: ModelIDInput | null,
  merchantID?: ModelStringInput | null,
  merchantUsername?: ModelStringInput | null,
  merchantPassword?: ModelStringInput | null,
  merchantIntegrationNumber?: ModelStringInput | null,
  merchantAPIKey?: ModelStringInput | null,
  paymentSharePercentage?: ModelFloatInput | null,
  simphonyServerBaseURL?: ModelStringInput | null,
  simphonyShortOrgName?: ModelStringInput | null,
  simphonyLocRef?: ModelStringInput | null,
  simphonyToken?: ModelStringInput | null,
  revenueCenterID?: ModelIntInput | null,
  employeeID?: ModelIntInput | null,
  tenderID?: ModelIntInput | null,
  discountID?: ModelIntInput | null,
  useServiceCharge?: ModelBooleanInput | null,
  serviceChargeId?: ModelIntInput | null,
  specialRequestID?: ModelIntInput | null,
  addressID?: ModelIntInput | null,
  ratingCount?: ModelIntInput | null,
  averageRating?: ModelFloatInput | null,
  feesback?: ModelStringInput | null,
  simphonyAuthClientId?: ModelStringInput | null,
  simphonyAuthUsername?: ModelStringInput | null,
  simphonyAuthPassword?: ModelStringInput | null,
  simphonyAuthBaseURL?: ModelStringInput | null,
  orderTypeDineIn?: ModelIntInput | null,
  orderTypeTakeAway?: ModelIntInput | null,
  orderTypeDelivery?: ModelIntInput | null,
  simphonyTakeAwayRvc?: ModelIntInput | null,
  simphonyDeliveryRvc?: ModelIntInput | null,
  exportInterval?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  foodicsToken?: ModelStringInput | null,
  enableTableFoodics?: ModelStringInput | null,
  image?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  album?: ModelStringInput | null,
  type?: ModelStringInput | null,
  rating?: ModelFloatInput | null,
  location?: ModelStringInput | null,
  description?: ModelStringInput | null,
  areas?: ModelStringInput | null,
  seatTypes?: ModelStringInput | null,
  needAssistance?: ModelBooleanInput | null,
  spaBooking?: ModelBooleanInput | null,
  showerBooking?: ModelBooleanInput | null,
  cigarMenu?: ModelBooleanInput | null,
  precedence?: ModelIntInput | null,
  isPinRequired?: ModelBooleanInput | null,
  viewOnly?: ModelBooleanInput | null,
  openTime?: ModelStringInput | null,
  closeTime?: ModelStringInput | null,
  varifyForAmount?: ModelIntInput | null,
  varifyFirstOrder?: ModelBooleanInput | null,
  varifyNewAddress?: ModelBooleanInput | null,
  sliderImages?: ModelStringInput | null,
  receiveWaiters?: ModelStringInput | null,
  WaiterNotificationOption?: ModelStringInput | null,
  applyLoyalty?: ModelBooleanInput | null,
  rewardPoints?: ModelIntInput | null,
  moneySpent?: ModelIntInput | null,
  afterVat?: ModelBooleanInput | null,
  afterDeliveryFee?: ModelBooleanInput | null,
  beforePromoCode?: ModelBooleanInput | null,
  pointsCalcWithPromoCode?: ModelBooleanInput | null,
  isBusy?: ModelBooleanInput | null,
  allowOnlineOrdering?: ModelBooleanInput | null,
  minOrderVal?: ModelIntInput | null,
  vatPercentage?: ModelFloatInput | null,
  serviceChargePercentage?: ModelFloatInput | null,
  addDeliveryToVat?: ModelBooleanInput | null,
  categoryTagIDs?: ModelIDInput | null,
  parentConceptID?: ModelIDInput | null,
  containerColor?: ModelStringInput | null,
  avgOrderCompletionEstimate?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelConceptFilterInput | null > | null,
  or?: Array< ModelConceptFilterInput | null > | null,
  not?: ModelConceptFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelCategoryTagFilterInput = {
  id?: ModelIDInput | null,
  parentConceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  image?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCategoryTagFilterInput | null > | null,
  or?: Array< ModelCategoryTagFilterInput | null > | null,
  not?: ModelCategoryTagFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTenderFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  simphonyID?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTenderFilterInput | null > | null,
  or?: Array< ModelTenderFilterInput | null > | null,
  not?: ModelTenderFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  conceptTendersId?: ModelIDInput | null,
};

export type ModelOrderTypeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  simphonyID?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrderTypeFilterInput | null > | null,
  or?: Array< ModelOrderTypeFilterInput | null > | null,
  not?: ModelOrderTypeFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  conceptOrderTypeId?: ModelIDInput | null,
};

export type ModelParentConceptFilterInput = {
  id?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  image?: ModelStringInput | null,
  name?: ModelStringInput | null,
  logo?: ModelStringInput | null,
  type?: ModelStringInput | null,
  index?: ModelIntInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelParentConceptFilterInput | null > | null,
  or?: Array< ModelParentConceptFilterInput | null > | null,
  not?: ModelParentConceptFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelParentConceptConnection = {
  __typename: "ModelParentConceptConnection",
  items:  Array<ParentConcept | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTimelineFilterInput = {
  id?: ModelIDInput | null,
  actionName?: ModelStringInput | null,
  oldStatus?: ModelStringInput | null,
  newStatus?: ModelStringInput | null,
  bookingId?: ModelStringInput | null,
  transactionId?: ModelStringInput | null,
  customerId?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTimelineFilterInput | null > | null,
  or?: Array< ModelTimelineFilterInput | null > | null,
  not?: ModelTimelineFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTimelineConnection = {
  __typename: "ModelTimelineConnection",
  items:  Array<Timeline | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCallFilterInput = {
  id?: ModelIDInput | null,
  phone_number?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  endedAt?: ModelStringInput | null,
  duration?: ModelStringInput | null,
  date?: ModelStringInput | null,
  employeeId?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCallFilterInput | null > | null,
  or?: Array< ModelCallFilterInput | null > | null,
  not?: ModelCallFilterInput | null,
  _deleted?: ModelBooleanInput | null,
  callCustomerId?: ModelIDInput | null,
};

export type ModelCallConnection = {
  __typename: "ModelCallConnection",
  items:  Array<Call | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAdminRoleFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAdminRoleFilterInput | null > | null,
  or?: Array< ModelAdminRoleFilterInput | null > | null,
  not?: ModelAdminRoleFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAdminRoleConnection = {
  __typename: "ModelAdminRoleConnection",
  items:  Array<AdminRole | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAdminGroupFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  roles?: ModelIDInput | null,
  users?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAdminGroupFilterInput | null > | null,
  or?: Array< ModelAdminGroupFilterInput | null > | null,
  not?: ModelAdminGroupFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAdminGroupConnection = {
  __typename: "ModelAdminGroupConnection",
  items:  Array<AdminGroup | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserConceptsFilterInput = {
  id?: ModelIDInput | null,
  defaultConcept?: ModelIDInput | null,
  concepts?: ModelIDInput | null,
  parentConcepts?: ModelIDInput | null,
  conceptsRoles?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserConceptsFilterInput | null > | null,
  or?: Array< ModelUserConceptsFilterInput | null > | null,
  not?: ModelUserConceptsFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserConceptsConnection = {
  __typename: "ModelUserConceptsConnection",
  items:  Array<UserConcepts | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelTransactionFilterInput = {
  id?: ModelIDInput | null,
  transactionID?: ModelStringInput | null,
  date?: ModelStringInput | null,
  guestName?: ModelStringInput | null,
  guestPhone?: ModelStringInput | null,
  guestID?: ModelStringInput | null,
  guestGroup?: ModelStringInput | null,
  guestsNames?: ModelStringInput | null,
  bookingID?: ModelStringInput | null,
  bookingDate?: ModelStringInput | null,
  amount_cents?: ModelStringInput | null,
  status?: ModelBooleanInput | null,
  failureReason?: ModelStringInput | null,
  type?: ModelStringInput | null,
  timeSlots?: ModelStringInput | null,
  tables?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  currency?: ModelStringInput | null,
  refund?: ModelBooleanInput | null,
  reference_orderID?: ModelStringInput | null,
  ownerID?: ModelStringInput | null,
  refunded_amount_cents?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelTransactionFilterInput | null > | null,
  or?: Array< ModelTransactionFilterInput | null > | null,
  not?: ModelTransactionFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelTransactionConnection = {
  __typename: "ModelTransactionConnection",
  items:  Array<Transaction | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPaymentStatFilterInput = {
  id?: ModelIDInput | null,
  accountID?: ModelStringInput | null,
  conceptID?: ModelStringInput | null,
  year?: ModelIntInput | null,
  month?: ModelIntInput | null,
  monthName?: ModelStringInput | null,
  earning?: ModelFloatInput | null,
  refund?: ModelFloatInput | null,
  earningTrxCount?: ModelIntInput | null,
  refundTrxCount?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPaymentStatFilterInput | null > | null,
  or?: Array< ModelPaymentStatFilterInput | null > | null,
  not?: ModelPaymentStatFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPaymentStatConnection = {
  __typename: "ModelPaymentStatConnection",
  items:  Array<PaymentStat | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  parentConceptID?: ModelIDInput | null,
  preparationAreaID?: ModelIDInput | null,
  subCategory?: ModelIDInput | null,
  name?: ModelStringInput | null,
  guestView?: ModelBooleanInput | null,
  guestOrder?: ModelBooleanInput | null,
  staffOrder?: ModelBooleanInput | null,
  image?: ModelIDInput | null,
  darkImage?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  symphonyID?: ModelStringInput | null,
  isHidden?: ModelBooleanInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items:  Array<Category | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMenuItemFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  kioskID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  image?: ModelIDInput | null,
  precedence?: ModelStringInput | null,
  categoryID?: ModelIDInput | null,
  categoryName?: ModelStringInput | null,
  outOfStock?: ModelBooleanInput | null,
  requiredPoints?: ModelIntInput | null,
  symphonyID?: ModelStringInput | null,
  comboItemID?: ModelStringInput | null,
  ratings?: ModelIDInput | null,
  prices?: ModelIDInput | null,
  choiceGroups?: ModelIDInput | null,
  ExtraChoiceGroups?: ModelIDInput | null,
  enabled?: ModelBooleanInput | null,
  hasLimitedQuantity?: ModelBooleanInput | null,
  quantity?: ModelIntInput | null,
  autoRestock?: ModelBooleanInput | null,
  restockQuantity?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  quantityOrdered?: ModelIntInput | null,
  performancePerDay?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMenuItemFilterInput | null > | null,
  or?: Array< ModelMenuItemFilterInput | null > | null,
  not?: ModelMenuItemFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPriceFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  parentConceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  symphonyID?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPriceFilterInput | null > | null,
  or?: Array< ModelPriceFilterInput | null > | null,
  not?: ModelPriceFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPriceConnection = {
  __typename: "ModelPriceConnection",
  items:  Array<Price | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelChoiceGroupsFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  parentConceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  required?: ModelBooleanInput | null,
  maxNumberOfChoices?: ModelIntInput | null,
  minNumberOfChoices?: ModelIntInput | null,
  precedence?: ModelStringInput | null,
  type?: ModelStringInput | null,
  choices?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  simphonyID?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelChoiceGroupsFilterInput | null > | null,
  or?: Array< ModelChoiceGroupsFilterInput | null > | null,
  not?: ModelChoiceGroupsFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelChoiceGroupsConnection = {
  __typename: "ModelChoiceGroupsConnection",
  items:  Array<ChoiceGroups | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelChoiceFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  price?: ModelFloatInput | null,
  precedence?: ModelStringInput | null,
  choiceGroupID?: ModelIDInput | null,
  choiceGroupName?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  symphonyID?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  definitionNumber?: ModelIntInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelChoiceFilterInput | null > | null,
  or?: Array< ModelChoiceFilterInput | null > | null,
  not?: ModelChoiceFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCartFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  specialRequest?: ModelStringInput | null,
  deliveryFee?: ModelFloatInput | null,
  subTotal?: ModelFloatInput | null,
  subTaxTotal?: ModelFloatInput | null,
  totalPrice?: ModelFloatInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelCartFilterInput | null > | null,
  or?: Array< ModelCartFilterInput | null > | null,
  not?: ModelCartFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelCartConnection = {
  __typename: "ModelCartConnection",
  items:  Array<Cart | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelAdminStatusFilterInput = {
  id?: ModelIDInput | null,
  sub?: ModelStringInput | null,
  accountID?: ModelStringInput | null,
  name?: ModelStringInput | null,
  averageCallTime?: ModelFloatInput | null,
  numberOfCalls?: ModelFloatInput | null,
  idelDuration?: ModelFloatInput | null,
  activeDuration?: ModelFloatInput | null,
  awayDuration?: ModelFloatInput | null,
  lastCallTime?: ModelFloatInput | null,
  agentBarAverageCallTime?: ModelFloatInput | null,
  agentBarNumberOfCalls?: ModelFloatInput | null,
  type?: ModelStringInput | null,
  waiterStatus?: ModelStringInput | null,
  totalAverageRatio?: ModelFloatInput | null,
  performancePerDay?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelAdminStatusFilterInput | null > | null,
  or?: Array< ModelAdminStatusFilterInput | null > | null,
  not?: ModelAdminStatusFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelAdminStatusConnection = {
  __typename: "ModelAdminStatusConnection",
  items:  Array<AdminStatus | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelFloatKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelOnlineOrderFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  statusID?: ModelIDInput | null,
  status?: ModelStringInput | null,
  reason?: ModelStringInput | null,
  orderType?: ModelStringInput | null,
  channel?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  userName?: ModelStringInput | null,
  userPhoneNumber?: ModelStringInput | null,
  dispatcherID?: ModelStringInput | null,
  dispatcherName?: ModelStringInput | null,
  totalAmount?: ModelFloatInput | null,
  discountedValue?: ModelFloatInput | null,
  subTotal?: ModelFloatInput | null,
  subTaxTotal?: ModelFloatInput | null,
  tax?: ModelFloatInput | null,
  deliveryFee?: ModelFloatInput | null,
  orderTime?: ModelStringInput | null,
  executionArn?: ModelStringInput | null,
  specialRequest?: ModelStringInput | null,
  orderAddress?: ModelStringInput | null,
  discountID?: ModelIntInput | null,
  amount_cents?: ModelStringInput | null,
  transactionID?: ModelStringInput | null,
  paymentStatus?: ModelBooleanInput | null,
  paymentType?: ModelStringInput | null,
  referenceId?: ModelStringInput | null,
  cardHolderNumber?: ModelStringInput | null,
  cardHolderName?: ModelStringInput | null,
  cardSchemeName?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  sentToKitchenAt?: ModelStringInput | null,
  readyAt?: ModelStringInput | null,
  outForDeliveryAt?: ModelStringInput | null,
  deliveredAt?: ModelStringInput | null,
  promoCodeID?: ModelIDInput | null,
  promoCodeName?: ModelStringInput | null,
  promoCodeAppliedTo?: ModelStringInput | null,
  rating?: ModelIntInput | null,
  isReview?: ModelBooleanInput | null,
  reviewID?: ModelIDInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  tmpField?: ModelStringInput | null,
  otp?: ModelStringInput | null,
  avgOrderCompletionEstimate?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOnlineOrderFilterInput | null > | null,
  or?: Array< ModelOnlineOrderFilterInput | null > | null,
  not?: ModelOnlineOrderFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelOnlineOrderConnection = {
  __typename: "ModelOnlineOrderConnection",
  items:  Array<OnlineOrder | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelOrderStatusFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  color?: ModelStringInput | null,
  precedence?: ModelIntInput | null,
  accessibleStatus?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOrderStatusFilterInput | null > | null,
  or?: Array< ModelOrderStatusFilterInput | null > | null,
  not?: ModelOrderStatusFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelOrderStatusConnection = {
  __typename: "ModelOrderStatusConnection",
  items:  Array<OrderStatus | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelDiscountCouponFilterInput = {
  id?: ModelIDInput | null,
  discountPercent?: ModelStringInput | null,
  usedBy?: ModelIDInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelDiscountCouponFilterInput | null > | null,
  or?: Array< ModelDiscountCouponFilterInput | null > | null,
  not?: ModelDiscountCouponFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelDiscountCouponConnection = {
  __typename: "ModelDiscountCouponConnection",
  items:  Array<DiscountCoupon | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelZoneFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  location?: ModelStringInput | null,
  deliveryFee?: ModelIntInput | null,
  polygon?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelZoneFilterInput | null > | null,
  or?: Array< ModelZoneFilterInput | null > | null,
  not?: ModelZoneFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelZoneConnection = {
  __typename: "ModelZoneConnection",
  items:  Array<Zone | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelUserIPFilterInput = {
  id?: ModelIDInput | null,
  ip?: ModelStringInput | null,
  date?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelUserIPFilterInput | null > | null,
  or?: Array< ModelUserIPFilterInput | null > | null,
  not?: ModelUserIPFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelUserIPConnection = {
  __typename: "ModelUserIPConnection",
  items:  Array<UserIP | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMainCategoryFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  openTime?: ModelStringInput | null,
  closeTime?: ModelStringInput | null,
  guestView?: ModelBooleanInput | null,
  guestOrder?: ModelBooleanInput | null,
  staffOrder?: ModelBooleanInput | null,
  image?: ModelStringInput | null,
  menuPDF?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMainCategoryFilterInput | null > | null,
  or?: Array< ModelMainCategoryFilterInput | null > | null,
  not?: ModelMainCategoryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelMainCategoryConnection = {
  __typename: "ModelMainCategoryConnection",
  items:  Array<MainCategory | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSubCategoryFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  precedence?: ModelStringInput | null,
  openTime?: ModelStringInput | null,
  closeTime?: ModelStringInput | null,
  mainCategoryID?: ModelIDInput | null,
  guestView?: ModelBooleanInput | null,
  guestOrder?: ModelBooleanInput | null,
  staffOrder?: ModelBooleanInput | null,
  image?: ModelStringInput | null,
  menuPDF?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelSubCategoryFilterInput | null > | null,
  or?: Array< ModelSubCategoryFilterInput | null > | null,
  not?: ModelSubCategoryFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubCategoryConnection = {
  __typename: "ModelSubCategoryConnection",
  items:  Array<SubCategory | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelChannelFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  numberOfOrders?: ModelIntInput | null,
  isActive?: ModelBooleanInput | null,
  multiLanguages?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelChannelFilterInput | null > | null,
  or?: Array< ModelChannelFilterInput | null > | null,
  not?: ModelChannelFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelChannelConnection = {
  __typename: "ModelChannelConnection",
  items:  Array<Channel | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPromocodeFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  redemptions?: ModelIntInput | null,
  status?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  appliesTo?: ModelStringInput | null,
  minOrderReq?: ModelStringInput | null,
  minOrderVal?: ModelIntInput | null,
  minOrderQty?: ModelIntInput | null,
  tenderReq?: ModelStringInput | null,
  discountVal?: ModelIntInput | null,
  discountLimit?: ModelIntInput | null,
  quota?: ModelIntInput | null,
  quotaUsed?: ModelIntInput | null,
  totalRevenue?: ModelIntInput | null,
  appliedDiscountedVal?: ModelIntInput | null,
  usePerCustomer?: ModelIntInput | null,
  channelReq?: ModelStringInput | null,
  customerEligibility?: ModelStringInput | null,
  customerReq?: ModelStringInput | null,
  spendingExceeds?: ModelIntInput | null,
  spendingBelow?: ModelIntInput | null,
  orderSinceDate?: ModelStringInput | null,
  orderFromDate?: ModelStringInput | null,
  orderToDate?: ModelStringInput | null,
  isDisplayed?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  parentConceptIDs?: ModelIDInput | null,
  conceptIDs?: ModelIDInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPromocodeFilterInput | null > | null,
  or?: Array< ModelPromocodeFilterInput | null > | null,
  not?: ModelPromocodeFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPromocodeConnection = {
  __typename: "ModelPromocodeConnection",
  items:  Array<Promocode | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelPromocodeDashboardFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  totalRevenue?: ModelIntInput | null,
  totalRedemptions?: ModelIntInput | null,
  totalDiscount?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelPromocodeDashboardFilterInput | null > | null,
  or?: Array< ModelPromocodeDashboardFilterInput | null > | null,
  not?: ModelPromocodeDashboardFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelPromocodeDashboardConnection = {
  __typename: "ModelPromocodeDashboardConnection",
  items:  Array<PromocodeDashboard | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelConceptRevenueFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  date?: ModelStringInput | null,
  driveThruRevenue?: ModelFloatInput | null,
  pickupRevenue?: ModelFloatInput | null,
  deliveryRevenue?: ModelFloatInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelConceptRevenueFilterInput | null > | null,
  or?: Array< ModelConceptRevenueFilterInput | null > | null,
  not?: ModelConceptRevenueFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelConceptRevenueConnection = {
  __typename: "ModelConceptRevenueConnection",
  items:  Array<ConceptRevenue | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelLoyaltyItemFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  conceptID?: ModelIDInput | null,
  parentConceptID?: ModelIDInput | null,
  description?: ModelStringInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  options?: ModelStringInput | null,
  points?: ModelIntInput | null,
  redemptionPerUser?: ModelIntInput | null,
  image?: ModelStringInput | null,
  simphonyID?: ModelStringInput | null,
  multiLanguages?: ModelStringInput | null,
  isActive?: ModelStringInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  createdByID?: ModelStringInput | null,
  createdByName?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelLoyaltyItemFilterInput | null > | null,
  or?: Array< ModelLoyaltyItemFilterInput | null > | null,
  not?: ModelLoyaltyItemFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelLoyaltyItemConnection = {
  __typename: "ModelLoyaltyItemConnection",
  items:  Array<LoyaltyItem | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelOTPFilterInput = {
  id?: ModelIDInput | null,
  OTP?: ModelStringInput | null,
  redeemed?: ModelBooleanInput | null,
  deleted?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  conceptID?: ModelIDInput | null,
  loyaltyItemID?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelOTPFilterInput | null > | null,
  or?: Array< ModelOTPFilterInput | null > | null,
  not?: ModelOTPFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelOTPConnection = {
  __typename: "ModelOTPConnection",
  items:  Array<OTP | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelMostOrderedItemsFilterInput = {
  id?: ModelIDInput | null,
  conceptID?: ModelStringInput | null,
  menuItemID?: ModelStringInput | null,
  menuItemName?: ModelStringInput | null,
  menuItemImage?: ModelStringInput | null,
  quantity?: ModelIntInput | null,
  deleted?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelMostOrderedItemsFilterInput | null > | null,
  or?: Array< ModelMostOrderedItemsFilterInput | null > | null,
  not?: ModelMostOrderedItemsFilterInput | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelMostOrderedItemsConnection = {
  __typename: "ModelMostOrderedItemsConnection",
  items:  Array<MostOrderedItems | null >,
  nextToken?: string | null,
  startedAt?: number | null,
};

export type ModelSubscriptionUserFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  username?: ModelSubscriptionStringInput | null,
  preferred_username?: ModelSubscriptionStringInput | null,
  email?: ModelSubscriptionStringInput | null,
  email_verified?: ModelSubscriptionBooleanInput | null,
  phone_number?: ModelSubscriptionStringInput | null,
  phone_number_verified?: ModelSubscriptionBooleanInput | null,
  name?: ModelSubscriptionStringInput | null,
  nickname?: ModelSubscriptionStringInput | null,
  given_name?: ModelSubscriptionStringInput | null,
  middle_name?: ModelSubscriptionStringInput | null,
  family_name?: ModelSubscriptionStringInput | null,
  address?: ModelSubscriptionStringInput | null,
  birthdate?: ModelSubscriptionStringInput | null,
  gender?: ModelSubscriptionStringInput | null,
  locale?: ModelSubscriptionStringInput | null,
  picture?: ModelSubscriptionStringInput | null,
  pictureCrop?: ModelSubscriptionStringInput | null,
  website?: ModelSubscriptionStringInput | null,
  zoneinfo?: ModelSubscriptionStringInput | null,
  verification_code?: ModelSubscriptionStringInput | null,
  facebook?: ModelSubscriptionStringInput | null,
  instagram?: ModelSubscriptionStringInput | null,
  group?: ModelSubscriptionStringInput | null,
  phones?: ModelSubscriptionStringInput | null,
  flags?: ModelSubscriptionStringInput | null,
  flagsName?: ModelSubscriptionStringInput | null,
  interests?: ModelSubscriptionStringInput | null,
  interestsName?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  stats?: ModelSubscriptionIDInput | null,
  spending?: ModelSubscriptionIntInput | null,
  prevOrderDate?: ModelSubscriptionStringInput | null,
  lastOrderDate?: ModelSubscriptionStringInput | null,
  mostOrderedItems?: ModelSubscriptionStringInput | null,
  points?: ModelSubscriptionIntInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  cognitoUsername?: ModelSubscriptionStringInput | null,
  cognitoSub?: ModelSubscriptionStringInput | null,
  autoGeneratedPassword?: ModelSubscriptionBooleanInput | null,
  updated?: ModelSubscriptionStringInput | null,
  sub?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionReviewFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionStringInput | null,
  conceptName?: ModelSubscriptionStringInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  bookingID?: ModelSubscriptionStringInput | null,
  parentConceptID?: ModelSubscriptionStringInput | null,
  guestName?: ModelSubscriptionStringInput | null,
  guestId?: ModelSubscriptionStringInput | null,
  reason?: ModelSubscriptionStringInput | null,
  value?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionStringInput | null,
  orderID?: ModelSubscriptionStringInput | null,
  rate?: ModelSubscriptionIntInput | null,
  feedback?: ModelSubscriptionStringInput | null,
  satisfaction?: ModelSubscriptionStringInput | null,
  thoughts?: ModelSubscriptionStringInput | null,
  photos?: ModelSubscriptionStringInput | null,
  multiLanguages?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  phoneUser?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionReviewFilterInput | null > | null,
  or?: Array< ModelSubscriptionReviewFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionAccountFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  logo?: ModelSubscriptionStringInput | null,
  domain?: ModelSubscriptionStringInput | null,
  siteTitle?: ModelSubscriptionStringInput | null,
  guestsCount?: ModelSubscriptionIntInput | null,
  tagline?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  siteAddress?: ModelSubscriptionStringInput | null,
  defaultLanguage?: ModelSubscriptionStringInput | null,
  languages?: ModelSubscriptionStringInput | null,
  features?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  socialLinks?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  callCenterEmployeesIds?: ModelSubscriptionStringInput | null,
  kpiReportResetFrequency?: ModelSubscriptionStringInput | null,
  agentBarResetFrequency?: ModelSubscriptionStringInput | null,
  dynamicStatus?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAccountFilterInput | null > | null,
  or?: Array< ModelSubscriptionAccountFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionFeatureFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  icon?: ModelSubscriptionStringInput | null,
  slug?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  parent?: ModelSubscriptionStringInput | null,
  private?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFeatureFilterInput | null > | null,
  or?: Array< ModelSubscriptionFeatureFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionMediaFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionIDInput | null,
  fileUrl?: ModelSubscriptionStringInput | null,
  filename?: ModelSubscriptionStringInput | null,
  filetype?: ModelSubscriptionStringInput | null,
  fileSize?: ModelSubscriptionIntInput | null,
  alternativeText?: ModelSubscriptionStringInput | null,
  caption?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  attachments?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMediaFilterInput | null > | null,
  or?: Array< ModelSubscriptionMediaFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionConceptFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  kiosks?: ModelSubscriptionIDInput | null,
  merchantID?: ModelSubscriptionStringInput | null,
  merchantUsername?: ModelSubscriptionStringInput | null,
  merchantPassword?: ModelSubscriptionStringInput | null,
  merchantIntegrationNumber?: ModelSubscriptionStringInput | null,
  merchantAPIKey?: ModelSubscriptionStringInput | null,
  paymentSharePercentage?: ModelSubscriptionFloatInput | null,
  simphonyServerBaseURL?: ModelSubscriptionStringInput | null,
  simphonyShortOrgName?: ModelSubscriptionStringInput | null,
  simphonyLocRef?: ModelSubscriptionStringInput | null,
  simphonyToken?: ModelSubscriptionStringInput | null,
  revenueCenterID?: ModelSubscriptionIntInput | null,
  employeeID?: ModelSubscriptionIntInput | null,
  tenderID?: ModelSubscriptionIntInput | null,
  discountID?: ModelSubscriptionIntInput | null,
  useServiceCharge?: ModelSubscriptionBooleanInput | null,
  serviceChargeId?: ModelSubscriptionIntInput | null,
  specialRequestID?: ModelSubscriptionIntInput | null,
  addressID?: ModelSubscriptionIntInput | null,
  ratingCount?: ModelSubscriptionIntInput | null,
  averageRating?: ModelSubscriptionFloatInput | null,
  feesback?: ModelSubscriptionStringInput | null,
  simphonyAuthClientId?: ModelSubscriptionStringInput | null,
  simphonyAuthUsername?: ModelSubscriptionStringInput | null,
  simphonyAuthPassword?: ModelSubscriptionStringInput | null,
  simphonyAuthBaseURL?: ModelSubscriptionStringInput | null,
  orderTypeDineIn?: ModelSubscriptionIntInput | null,
  orderTypeTakeAway?: ModelSubscriptionIntInput | null,
  orderTypeDelivery?: ModelSubscriptionIntInput | null,
  simphonyTakeAwayRvc?: ModelSubscriptionIntInput | null,
  simphonyDeliveryRvc?: ModelSubscriptionIntInput | null,
  exportInterval?: ModelSubscriptionIntInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  foodicsToken?: ModelSubscriptionStringInput | null,
  enableTableFoodics?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  logo?: ModelSubscriptionStringInput | null,
  album?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  rating?: ModelSubscriptionFloatInput | null,
  location?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  areas?: ModelSubscriptionStringInput | null,
  seatTypes?: ModelSubscriptionStringInput | null,
  needAssistance?: ModelSubscriptionBooleanInput | null,
  spaBooking?: ModelSubscriptionBooleanInput | null,
  showerBooking?: ModelSubscriptionBooleanInput | null,
  cigarMenu?: ModelSubscriptionBooleanInput | null,
  precedence?: ModelSubscriptionIntInput | null,
  isPinRequired?: ModelSubscriptionBooleanInput | null,
  viewOnly?: ModelSubscriptionBooleanInput | null,
  openTime?: ModelSubscriptionStringInput | null,
  closeTime?: ModelSubscriptionStringInput | null,
  varifyForAmount?: ModelSubscriptionIntInput | null,
  varifyFirstOrder?: ModelSubscriptionBooleanInput | null,
  varifyNewAddress?: ModelSubscriptionBooleanInput | null,
  sliderImages?: ModelSubscriptionStringInput | null,
  receiveWaiters?: ModelSubscriptionStringInput | null,
  WaiterNotificationOption?: ModelSubscriptionStringInput | null,
  applyLoyalty?: ModelSubscriptionBooleanInput | null,
  rewardPoints?: ModelSubscriptionIntInput | null,
  moneySpent?: ModelSubscriptionIntInput | null,
  afterVat?: ModelSubscriptionBooleanInput | null,
  afterDeliveryFee?: ModelSubscriptionBooleanInput | null,
  beforePromoCode?: ModelSubscriptionBooleanInput | null,
  pointsCalcWithPromoCode?: ModelSubscriptionBooleanInput | null,
  isBusy?: ModelSubscriptionBooleanInput | null,
  allowOnlineOrdering?: ModelSubscriptionBooleanInput | null,
  minOrderVal?: ModelSubscriptionIntInput | null,
  vatPercentage?: ModelSubscriptionFloatInput | null,
  serviceChargePercentage?: ModelSubscriptionFloatInput | null,
  addDeliveryToVat?: ModelSubscriptionBooleanInput | null,
  categoryTagIDs?: ModelSubscriptionIDInput | null,
  parentConceptID?: ModelSubscriptionIDInput | null,
  containerColor?: ModelSubscriptionStringInput | null,
  avgOrderCompletionEstimate?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionConceptFilterInput | null > | null,
  or?: Array< ModelSubscriptionConceptFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
  conceptTendersId?: ModelSubscriptionIDInput | null,
  conceptOrderTypeId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionCategoryTagFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  parentConceptID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCategoryTagFilterInput | null > | null,
  or?: Array< ModelSubscriptionCategoryTagFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTenderFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  simphonyID?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTenderFilterInput | null > | null,
  or?: Array< ModelSubscriptionTenderFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionOrderTypeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  simphonyID?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrderTypeFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrderTypeFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionParentConceptFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  logo?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  index?: ModelSubscriptionIntInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionParentConceptFilterInput | null > | null,
  or?: Array< ModelSubscriptionParentConceptFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTimelineFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  actionName?: ModelSubscriptionStringInput | null,
  oldStatus?: ModelSubscriptionStringInput | null,
  newStatus?: ModelSubscriptionStringInput | null,
  bookingId?: ModelSubscriptionStringInput | null,
  transactionId?: ModelSubscriptionStringInput | null,
  customerId?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTimelineFilterInput | null > | null,
  or?: Array< ModelSubscriptionTimelineFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCallFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  phone_number?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  endedAt?: ModelSubscriptionStringInput | null,
  duration?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  employeeId?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCallFilterInput | null > | null,
  or?: Array< ModelSubscriptionCallFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
  callCustomerId?: ModelSubscriptionIDInput | null,
};

export type ModelSubscriptionAdminRoleFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAdminRoleFilterInput | null > | null,
  or?: Array< ModelSubscriptionAdminRoleFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionAdminGroupFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  roles?: ModelSubscriptionIDInput | null,
  users?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAdminGroupFilterInput | null > | null,
  or?: Array< ModelSubscriptionAdminGroupFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUserConceptsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  defaultConcept?: ModelSubscriptionIDInput | null,
  concepts?: ModelSubscriptionIDInput | null,
  parentConcepts?: ModelSubscriptionIDInput | null,
  conceptsRoles?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserConceptsFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserConceptsFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionTransactionFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  transactionID?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  guestName?: ModelSubscriptionStringInput | null,
  guestPhone?: ModelSubscriptionStringInput | null,
  guestID?: ModelSubscriptionStringInput | null,
  guestGroup?: ModelSubscriptionStringInput | null,
  guestsNames?: ModelSubscriptionStringInput | null,
  bookingID?: ModelSubscriptionStringInput | null,
  bookingDate?: ModelSubscriptionStringInput | null,
  amount_cents?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionBooleanInput | null,
  failureReason?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  timeSlots?: ModelSubscriptionStringInput | null,
  tables?: ModelSubscriptionStringInput | null,
  conceptID?: ModelSubscriptionStringInput | null,
  currency?: ModelSubscriptionStringInput | null,
  refund?: ModelSubscriptionBooleanInput | null,
  reference_orderID?: ModelSubscriptionStringInput | null,
  ownerID?: ModelSubscriptionStringInput | null,
  refunded_amount_cents?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionTransactionFilterInput | null > | null,
  or?: Array< ModelSubscriptionTransactionFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPaymentStatFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  conceptID?: ModelSubscriptionStringInput | null,
  year?: ModelSubscriptionIntInput | null,
  month?: ModelSubscriptionIntInput | null,
  monthName?: ModelSubscriptionStringInput | null,
  earning?: ModelSubscriptionFloatInput | null,
  refund?: ModelSubscriptionFloatInput | null,
  earningTrxCount?: ModelSubscriptionIntInput | null,
  refundTrxCount?: ModelSubscriptionIntInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPaymentStatFilterInput | null > | null,
  or?: Array< ModelSubscriptionPaymentStatFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  parentConceptID?: ModelSubscriptionIDInput | null,
  preparationAreaID?: ModelSubscriptionIDInput | null,
  subCategory?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  guestView?: ModelSubscriptionBooleanInput | null,
  guestOrder?: ModelSubscriptionBooleanInput | null,
  staffOrder?: ModelSubscriptionBooleanInput | null,
  image?: ModelSubscriptionIDInput | null,
  darkImage?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  multiLanguages?: ModelSubscriptionStringInput | null,
  symphonyID?: ModelSubscriptionStringInput | null,
  isHidden?: ModelSubscriptionBooleanInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionCategoryFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionMenuItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  kioskID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  image?: ModelSubscriptionIDInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  categoryID?: ModelSubscriptionIDInput | null,
  categoryName?: ModelSubscriptionStringInput | null,
  outOfStock?: ModelSubscriptionBooleanInput | null,
  requiredPoints?: ModelSubscriptionIntInput | null,
  symphonyID?: ModelSubscriptionStringInput | null,
  comboItemID?: ModelSubscriptionStringInput | null,
  ratings?: ModelSubscriptionIDInput | null,
  prices?: ModelSubscriptionIDInput | null,
  choiceGroups?: ModelSubscriptionIDInput | null,
  ExtraChoiceGroups?: ModelSubscriptionIDInput | null,
  enabled?: ModelSubscriptionBooleanInput | null,
  hasLimitedQuantity?: ModelSubscriptionBooleanInput | null,
  quantity?: ModelSubscriptionIntInput | null,
  autoRestock?: ModelSubscriptionBooleanInput | null,
  restockQuantity?: ModelSubscriptionIntInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  quantityOrdered?: ModelSubscriptionIntInput | null,
  performancePerDay?: ModelSubscriptionStringInput | null,
  multiLanguages?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMenuItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionMenuItemFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPriceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  parentConceptID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  multiLanguages?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  symphonyID?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPriceFilterInput | null > | null,
  or?: Array< ModelSubscriptionPriceFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionChoiceGroupsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  parentConceptID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  required?: ModelSubscriptionBooleanInput | null,
  maxNumberOfChoices?: ModelSubscriptionIntInput | null,
  minNumberOfChoices?: ModelSubscriptionIntInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  choices?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  multiLanguages?: ModelSubscriptionStringInput | null,
  simphonyID?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionChoiceGroupsFilterInput | null > | null,
  or?: Array< ModelSubscriptionChoiceGroupsFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionChoiceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  price?: ModelSubscriptionFloatInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  choiceGroupID?: ModelSubscriptionIDInput | null,
  choiceGroupName?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  symphonyID?: ModelSubscriptionStringInput | null,
  multiLanguages?: ModelSubscriptionStringInput | null,
  definitionNumber?: ModelSubscriptionIntInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionChoiceFilterInput | null > | null,
  or?: Array< ModelSubscriptionChoiceFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionCartFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  userID?: ModelSubscriptionIDInput | null,
  specialRequest?: ModelSubscriptionStringInput | null,
  deliveryFee?: ModelSubscriptionFloatInput | null,
  subTotal?: ModelSubscriptionFloatInput | null,
  subTaxTotal?: ModelSubscriptionFloatInput | null,
  totalPrice?: ModelSubscriptionFloatInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionCartFilterInput | null > | null,
  or?: Array< ModelSubscriptionCartFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionAdminStatusFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  sub?: ModelSubscriptionStringInput | null,
  accountID?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  averageCallTime?: ModelSubscriptionFloatInput | null,
  numberOfCalls?: ModelSubscriptionFloatInput | null,
  idelDuration?: ModelSubscriptionFloatInput | null,
  activeDuration?: ModelSubscriptionFloatInput | null,
  awayDuration?: ModelSubscriptionFloatInput | null,
  lastCallTime?: ModelSubscriptionFloatInput | null,
  agentBarAverageCallTime?: ModelSubscriptionFloatInput | null,
  agentBarNumberOfCalls?: ModelSubscriptionFloatInput | null,
  type?: ModelSubscriptionStringInput | null,
  waiterStatus?: ModelSubscriptionStringInput | null,
  totalAverageRatio?: ModelSubscriptionFloatInput | null,
  performancePerDay?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionAdminStatusFilterInput | null > | null,
  or?: Array< ModelSubscriptionAdminStatusFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionOnlineOrderFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  statusID?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  reason?: ModelSubscriptionStringInput | null,
  orderType?: ModelSubscriptionStringInput | null,
  channel?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionIDInput | null,
  userName?: ModelSubscriptionStringInput | null,
  userPhoneNumber?: ModelSubscriptionStringInput | null,
  dispatcherID?: ModelSubscriptionStringInput | null,
  dispatcherName?: ModelSubscriptionStringInput | null,
  totalAmount?: ModelSubscriptionFloatInput | null,
  discountedValue?: ModelSubscriptionFloatInput | null,
  subTotal?: ModelSubscriptionFloatInput | null,
  subTaxTotal?: ModelSubscriptionFloatInput | null,
  tax?: ModelSubscriptionFloatInput | null,
  deliveryFee?: ModelSubscriptionFloatInput | null,
  orderTime?: ModelSubscriptionStringInput | null,
  executionArn?: ModelSubscriptionStringInput | null,
  specialRequest?: ModelSubscriptionStringInput | null,
  orderAddress?: ModelSubscriptionStringInput | null,
  discountID?: ModelSubscriptionIntInput | null,
  amount_cents?: ModelSubscriptionStringInput | null,
  transactionID?: ModelSubscriptionStringInput | null,
  paymentStatus?: ModelSubscriptionBooleanInput | null,
  paymentType?: ModelSubscriptionStringInput | null,
  referenceId?: ModelSubscriptionStringInput | null,
  cardHolderNumber?: ModelSubscriptionStringInput | null,
  cardHolderName?: ModelSubscriptionStringInput | null,
  cardSchemeName?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  sentToKitchenAt?: ModelSubscriptionStringInput | null,
  readyAt?: ModelSubscriptionStringInput | null,
  outForDeliveryAt?: ModelSubscriptionStringInput | null,
  deliveredAt?: ModelSubscriptionStringInput | null,
  promoCodeID?: ModelSubscriptionIDInput | null,
  promoCodeName?: ModelSubscriptionStringInput | null,
  promoCodeAppliedTo?: ModelSubscriptionStringInput | null,
  rating?: ModelSubscriptionIntInput | null,
  isReview?: ModelSubscriptionBooleanInput | null,
  reviewID?: ModelSubscriptionIDInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  tmpField?: ModelSubscriptionStringInput | null,
  otp?: ModelSubscriptionStringInput | null,
  avgOrderCompletionEstimate?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOnlineOrderFilterInput | null > | null,
  or?: Array< ModelSubscriptionOnlineOrderFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionOrderStatusFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  color?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionIntInput | null,
  accessibleStatus?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOrderStatusFilterInput | null > | null,
  or?: Array< ModelSubscriptionOrderStatusFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionDiscountCouponFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  discountPercent?: ModelSubscriptionStringInput | null,
  usedBy?: ModelSubscriptionIDInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionDiscountCouponFilterInput | null > | null,
  or?: Array< ModelSubscriptionDiscountCouponFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionZoneFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  location?: ModelSubscriptionStringInput | null,
  deliveryFee?: ModelSubscriptionIntInput | null,
  polygon?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionZoneFilterInput | null > | null,
  or?: Array< ModelSubscriptionZoneFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionUserIPFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  ip?: ModelSubscriptionStringInput | null,
  date?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserIPFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserIPFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionMainCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  openTime?: ModelSubscriptionStringInput | null,
  closeTime?: ModelSubscriptionStringInput | null,
  guestView?: ModelSubscriptionBooleanInput | null,
  guestOrder?: ModelSubscriptionBooleanInput | null,
  staffOrder?: ModelSubscriptionBooleanInput | null,
  image?: ModelSubscriptionStringInput | null,
  menuPDF?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  multiLanguages?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMainCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionMainCategoryFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionSubCategoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  precedence?: ModelSubscriptionStringInput | null,
  openTime?: ModelSubscriptionStringInput | null,
  closeTime?: ModelSubscriptionStringInput | null,
  mainCategoryID?: ModelSubscriptionIDInput | null,
  guestView?: ModelSubscriptionBooleanInput | null,
  guestOrder?: ModelSubscriptionBooleanInput | null,
  staffOrder?: ModelSubscriptionBooleanInput | null,
  image?: ModelSubscriptionStringInput | null,
  menuPDF?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  multiLanguages?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSubCategoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionSubCategoryFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionChannelFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  numberOfOrders?: ModelSubscriptionIntInput | null,
  isActive?: ModelSubscriptionBooleanInput | null,
  multiLanguages?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionChannelFilterInput | null > | null,
  or?: Array< ModelSubscriptionChannelFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPromocodeFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  redemptions?: ModelSubscriptionIntInput | null,
  status?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  appliesTo?: ModelSubscriptionStringInput | null,
  minOrderReq?: ModelSubscriptionStringInput | null,
  minOrderVal?: ModelSubscriptionIntInput | null,
  minOrderQty?: ModelSubscriptionIntInput | null,
  tenderReq?: ModelSubscriptionStringInput | null,
  discountVal?: ModelSubscriptionIntInput | null,
  discountLimit?: ModelSubscriptionIntInput | null,
  quota?: ModelSubscriptionIntInput | null,
  quotaUsed?: ModelSubscriptionIntInput | null,
  totalRevenue?: ModelSubscriptionIntInput | null,
  appliedDiscountedVal?: ModelSubscriptionIntInput | null,
  usePerCustomer?: ModelSubscriptionIntInput | null,
  channelReq?: ModelSubscriptionStringInput | null,
  customerEligibility?: ModelSubscriptionStringInput | null,
  customerReq?: ModelSubscriptionStringInput | null,
  spendingExceeds?: ModelSubscriptionIntInput | null,
  spendingBelow?: ModelSubscriptionIntInput | null,
  orderSinceDate?: ModelSubscriptionStringInput | null,
  orderFromDate?: ModelSubscriptionStringInput | null,
  orderToDate?: ModelSubscriptionStringInput | null,
  isDisplayed?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  parentConceptIDs?: ModelSubscriptionIDInput | null,
  conceptIDs?: ModelSubscriptionIDInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPromocodeFilterInput | null > | null,
  or?: Array< ModelSubscriptionPromocodeFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionPromocodeDashboardFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  totalRevenue?: ModelSubscriptionIntInput | null,
  totalRedemptions?: ModelSubscriptionIntInput | null,
  totalDiscount?: ModelSubscriptionIntInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPromocodeDashboardFilterInput | null > | null,
  or?: Array< ModelSubscriptionPromocodeDashboardFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionConceptRevenueFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  date?: ModelSubscriptionStringInput | null,
  driveThruRevenue?: ModelSubscriptionFloatInput | null,
  pickupRevenue?: ModelSubscriptionFloatInput | null,
  deliveryRevenue?: ModelSubscriptionFloatInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionConceptRevenueFilterInput | null > | null,
  or?: Array< ModelSubscriptionConceptRevenueFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionLoyaltyItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  parentConceptID?: ModelSubscriptionIDInput | null,
  description?: ModelSubscriptionStringInput | null,
  startDate?: ModelSubscriptionStringInput | null,
  endDate?: ModelSubscriptionStringInput | null,
  options?: ModelSubscriptionStringInput | null,
  points?: ModelSubscriptionIntInput | null,
  redemptionPerUser?: ModelSubscriptionIntInput | null,
  image?: ModelSubscriptionStringInput | null,
  simphonyID?: ModelSubscriptionStringInput | null,
  multiLanguages?: ModelSubscriptionStringInput | null,
  isActive?: ModelSubscriptionStringInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  createdByID?: ModelSubscriptionStringInput | null,
  createdByName?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionLoyaltyItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionLoyaltyItemFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionOTPFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  OTP?: ModelSubscriptionStringInput | null,
  redeemed?: ModelSubscriptionBooleanInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  userID?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionIDInput | null,
  loyaltyItemID?: ModelSubscriptionIDInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionOTPFilterInput | null > | null,
  or?: Array< ModelSubscriptionOTPFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type ModelSubscriptionMostOrderedItemsFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  conceptID?: ModelSubscriptionStringInput | null,
  menuItemID?: ModelSubscriptionStringInput | null,
  menuItemName?: ModelSubscriptionStringInput | null,
  menuItemImage?: ModelSubscriptionStringInput | null,
  quantity?: ModelSubscriptionIntInput | null,
  deleted?: ModelSubscriptionStringInput | null,
  createdAt?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionMostOrderedItemsFilterInput | null > | null,
  or?: Array< ModelSubscriptionMostOrderedItemsFilterInput | null > | null,
  _deleted?: ModelBooleanInput | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    accountID: string,
    username?: string | null,
    preferred_username?: string | null,
    email?: string | null,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    pictureCrop?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    facebook?: string | null,
    instagram?: string | null,
    group?: string | null,
    phones?: Array< string | null > | null,
    flags?: Array< string | null > | null,
    flagsName?: Array< string | null > | null,
    interests?: Array< string | null > | null,
    interestsName?: Array< string | null > | null,
    status?: string | null,
    stats?: Array< string | null > | null,
    spending?: number | null,
    prevOrderDate?: string | null,
    lastOrderDate?: string | null,
    promocodes?:  Array< {
      __typename: "PromocodeItem",
      name: string,
      promocodeId: string,
      canBeUsed: number,
      discountVal: number,
      discountLimit?: number | null,
      isDisplayed?: boolean | null,
    } | null > | null,
    mostOrderedItems?: string | null,
    points?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    cognitoUsername?: string | null,
    cognitoSub?: string | null,
    autoGeneratedPassword: boolean,
    updated?: string | null,
    sub?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    accountID: string,
    username?: string | null,
    preferred_username?: string | null,
    email?: string | null,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    pictureCrop?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    facebook?: string | null,
    instagram?: string | null,
    group?: string | null,
    phones?: Array< string | null > | null,
    flags?: Array< string | null > | null,
    flagsName?: Array< string | null > | null,
    interests?: Array< string | null > | null,
    interestsName?: Array< string | null > | null,
    status?: string | null,
    stats?: Array< string | null > | null,
    spending?: number | null,
    prevOrderDate?: string | null,
    lastOrderDate?: string | null,
    promocodes?:  Array< {
      __typename: "PromocodeItem",
      name: string,
      promocodeId: string,
      canBeUsed: number,
      discountVal: number,
      discountLimit?: number | null,
      isDisplayed?: boolean | null,
    } | null > | null,
    mostOrderedItems?: string | null,
    points?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    cognitoUsername?: string | null,
    cognitoSub?: string | null,
    autoGeneratedPassword: boolean,
    updated?: string | null,
    sub?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    accountID: string,
    username?: string | null,
    preferred_username?: string | null,
    email?: string | null,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    pictureCrop?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    facebook?: string | null,
    instagram?: string | null,
    group?: string | null,
    phones?: Array< string | null > | null,
    flags?: Array< string | null > | null,
    flagsName?: Array< string | null > | null,
    interests?: Array< string | null > | null,
    interestsName?: Array< string | null > | null,
    status?: string | null,
    stats?: Array< string | null > | null,
    spending?: number | null,
    prevOrderDate?: string | null,
    lastOrderDate?: string | null,
    promocodes?:  Array< {
      __typename: "PromocodeItem",
      name: string,
      promocodeId: string,
      canBeUsed: number,
      discountVal: number,
      discountLimit?: number | null,
      isDisplayed?: boolean | null,
    } | null > | null,
    mostOrderedItems?: string | null,
    points?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    cognitoUsername?: string | null,
    cognitoSub?: string | null,
    autoGeneratedPassword: boolean,
    updated?: string | null,
    sub?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateReviewMutationVariables = {
  input: CreateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type CreateReviewMutation = {
  createReview?:  {
    __typename: "Review",
    id: string,
    conceptID?: string | null,
    conceptName?: string | null,
    accountID?: string | null,
    bookingID?: string | null,
    parentConceptID?: string | null,
    guestName?: string | null,
    guestId?: string | null,
    reason?: Array< string | null > | null,
    value?: string | null,
    userID?: string | null,
    orderID?: string | null,
    rate?: number | null,
    feedback?: string | null,
    satisfaction?: string | null,
    thoughts?: string | null,
    itemReviews?:  Array< {
      __typename: "ItemReview",
      itemID: string,
      rate?: number | null,
      satisfaction?: string | null,
      feedback?: string | null,
      multiLanguages?: string | null,
    } | null > | null,
    photos?: Array< string | null > | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    phoneUser?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateReviewMutationVariables = {
  input: UpdateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type UpdateReviewMutation = {
  updateReview?:  {
    __typename: "Review",
    id: string,
    conceptID?: string | null,
    conceptName?: string | null,
    accountID?: string | null,
    bookingID?: string | null,
    parentConceptID?: string | null,
    guestName?: string | null,
    guestId?: string | null,
    reason?: Array< string | null > | null,
    value?: string | null,
    userID?: string | null,
    orderID?: string | null,
    rate?: number | null,
    feedback?: string | null,
    satisfaction?: string | null,
    thoughts?: string | null,
    itemReviews?:  Array< {
      __typename: "ItemReview",
      itemID: string,
      rate?: number | null,
      satisfaction?: string | null,
      feedback?: string | null,
      multiLanguages?: string | null,
    } | null > | null,
    photos?: Array< string | null > | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    phoneUser?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteReviewMutationVariables = {
  input: DeleteReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type DeleteReviewMutation = {
  deleteReview?:  {
    __typename: "Review",
    id: string,
    conceptID?: string | null,
    conceptName?: string | null,
    accountID?: string | null,
    bookingID?: string | null,
    parentConceptID?: string | null,
    guestName?: string | null,
    guestId?: string | null,
    reason?: Array< string | null > | null,
    value?: string | null,
    userID?: string | null,
    orderID?: string | null,
    rate?: number | null,
    feedback?: string | null,
    satisfaction?: string | null,
    thoughts?: string | null,
    itemReviews?:  Array< {
      __typename: "ItemReview",
      itemID: string,
      rate?: number | null,
      satisfaction?: string | null,
      feedback?: string | null,
      multiLanguages?: string | null,
    } | null > | null,
    photos?: Array< string | null > | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    phoneUser?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAccountMutationVariables = {
  input: CreateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type CreateAccountMutation = {
  createAccount?:  {
    __typename: "Account",
    id: string,
    logo?: string | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    callCenterEmployeesIds?: Array< string | null > | null,
    kpiReportResetFrequency?: string | null,
    agentBarResetFrequency?: string | null,
    dynamicStatus?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type UpdateAccountMutation = {
  updateAccount?:  {
    __typename: "Account",
    id: string,
    logo?: string | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    callCenterEmployeesIds?: Array< string | null > | null,
    kpiReportResetFrequency?: string | null,
    agentBarResetFrequency?: string | null,
    dynamicStatus?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAccountMutationVariables = {
  input: DeleteAccountInput,
  condition?: ModelAccountConditionInput | null,
};

export type DeleteAccountMutation = {
  deleteAccount?:  {
    __typename: "Account",
    id: string,
    logo?: string | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    callCenterEmployeesIds?: Array< string | null > | null,
    kpiReportResetFrequency?: string | null,
    agentBarResetFrequency?: string | null,
    dynamicStatus?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateFeatureMutationVariables = {
  input: CreateFeatureInput,
  condition?: ModelFeatureConditionInput | null,
};

export type CreateFeatureMutation = {
  createFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateFeatureMutationVariables = {
  input: UpdateFeatureInput,
  condition?: ModelFeatureConditionInput | null,
};

export type UpdateFeatureMutation = {
  updateFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteFeatureMutationVariables = {
  input: DeleteFeatureInput,
  condition?: ModelFeatureConditionInput | null,
};

export type DeleteFeatureMutation = {
  deleteFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMediaMutationVariables = {
  input: CreateMediaInput,
  condition?: ModelMediaConditionInput | null,
};

export type CreateMediaMutation = {
  createMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMediaMutationVariables = {
  input: UpdateMediaInput,
  condition?: ModelMediaConditionInput | null,
};

export type UpdateMediaMutation = {
  updateMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMediaMutationVariables = {
  input: DeleteMediaInput,
  condition?: ModelMediaConditionInput | null,
};

export type DeleteMediaMutation = {
  deleteMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateConceptMutationVariables = {
  input: CreateConceptInput,
  condition?: ModelConceptConditionInput | null,
};

export type CreateConceptMutation = {
  createConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    kiosks?: Array< string | null > | null,
    merchantID?: string | null,
    merchantUsername?: string | null,
    merchantPassword?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    paymentSharePercentage?: number | null,
    simphonyServerBaseURL?: string | null,
    simphonyShortOrgName?: string | null,
    simphonyLocRef?: string | null,
    simphonyToken?: string | null,
    revenueCenterID?: number | null,
    employeeID?: number | null,
    tenderID?: number | null,
    tenders?:  {
      __typename: "ModelTenderConnection",
      items:  Array< {
        __typename: "Tender",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptTendersId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderType?:  {
      __typename: "ModelOrderTypeConnection",
      items:  Array< {
        __typename: "OrderType",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptOrderTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    discountID?: number | null,
    useServiceCharge?: boolean | null,
    serviceChargeId?: number | null,
    specialRequestID?: number | null,
    addressID?: number | null,
    ratingCount?: number | null,
    averageRating?: number | null,
    feesback?: string | null,
    simphonyAuthClientId?: string | null,
    simphonyAuthUsername?: string | null,
    simphonyAuthPassword?: string | null,
    simphonyAuthBaseURL?: string | null,
    orderTypeDineIn?: number | null,
    orderTypeTakeAway?: number | null,
    orderTypeDelivery?: number | null,
    simphonyTakeAwayRvc?: number | null,
    simphonyDeliveryRvc?: number | null,
    exportInterval?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    foodicsToken?: string | null,
    enableTableFoodics?: string | null,
    image?: string | null,
    logo?: string | null,
    album?: Array< string | null > | null,
    type?: string | null,
    rating?: number | null,
    location?: string | null,
    description?: string | null,
    areas?: Array< string | null > | null,
    seatTypes?: Array< string | null > | null,
    needAssistance?: boolean | null,
    spaBooking?: boolean | null,
    showerBooking?: boolean | null,
    cigarMenu?: boolean | null,
    precedence?: number | null,
    isPinRequired?: boolean | null,
    viewOnly?: boolean | null,
    openTime?: string | null,
    closeTime?: string | null,
    varifyForAmount?: number | null,
    varifyFirstOrder?: boolean | null,
    varifyNewAddress?: boolean | null,
    sliderImages?: Array< string | null > | null,
    receiveWaiters?: Array< string | null > | null,
    WaiterNotificationOption?: string | null,
    applyLoyalty?: boolean | null,
    rewardPoints?: number | null,
    moneySpent?: number | null,
    afterVat?: boolean | null,
    afterDeliveryFee?: boolean | null,
    beforePromoCode?: boolean | null,
    pointsCalcWithPromoCode?: boolean | null,
    isBusy?: boolean | null,
    allowOnlineOrdering?: boolean | null,
    minOrderVal?: number | null,
    vatPercentage?: number | null,
    serviceChargePercentage?: number | null,
    addDeliveryToVat?: boolean | null,
    categoryTagIDs?: Array< string | null > | null,
    parentConceptID: string,
    containerColor?: string | null,
    avgOrderCompletionEstimate?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateConceptMutationVariables = {
  input: UpdateConceptInput,
  condition?: ModelConceptConditionInput | null,
};

export type UpdateConceptMutation = {
  updateConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    kiosks?: Array< string | null > | null,
    merchantID?: string | null,
    merchantUsername?: string | null,
    merchantPassword?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    paymentSharePercentage?: number | null,
    simphonyServerBaseURL?: string | null,
    simphonyShortOrgName?: string | null,
    simphonyLocRef?: string | null,
    simphonyToken?: string | null,
    revenueCenterID?: number | null,
    employeeID?: number | null,
    tenderID?: number | null,
    tenders?:  {
      __typename: "ModelTenderConnection",
      items:  Array< {
        __typename: "Tender",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptTendersId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderType?:  {
      __typename: "ModelOrderTypeConnection",
      items:  Array< {
        __typename: "OrderType",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptOrderTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    discountID?: number | null,
    useServiceCharge?: boolean | null,
    serviceChargeId?: number | null,
    specialRequestID?: number | null,
    addressID?: number | null,
    ratingCount?: number | null,
    averageRating?: number | null,
    feesback?: string | null,
    simphonyAuthClientId?: string | null,
    simphonyAuthUsername?: string | null,
    simphonyAuthPassword?: string | null,
    simphonyAuthBaseURL?: string | null,
    orderTypeDineIn?: number | null,
    orderTypeTakeAway?: number | null,
    orderTypeDelivery?: number | null,
    simphonyTakeAwayRvc?: number | null,
    simphonyDeliveryRvc?: number | null,
    exportInterval?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    foodicsToken?: string | null,
    enableTableFoodics?: string | null,
    image?: string | null,
    logo?: string | null,
    album?: Array< string | null > | null,
    type?: string | null,
    rating?: number | null,
    location?: string | null,
    description?: string | null,
    areas?: Array< string | null > | null,
    seatTypes?: Array< string | null > | null,
    needAssistance?: boolean | null,
    spaBooking?: boolean | null,
    showerBooking?: boolean | null,
    cigarMenu?: boolean | null,
    precedence?: number | null,
    isPinRequired?: boolean | null,
    viewOnly?: boolean | null,
    openTime?: string | null,
    closeTime?: string | null,
    varifyForAmount?: number | null,
    varifyFirstOrder?: boolean | null,
    varifyNewAddress?: boolean | null,
    sliderImages?: Array< string | null > | null,
    receiveWaiters?: Array< string | null > | null,
    WaiterNotificationOption?: string | null,
    applyLoyalty?: boolean | null,
    rewardPoints?: number | null,
    moneySpent?: number | null,
    afterVat?: boolean | null,
    afterDeliveryFee?: boolean | null,
    beforePromoCode?: boolean | null,
    pointsCalcWithPromoCode?: boolean | null,
    isBusy?: boolean | null,
    allowOnlineOrdering?: boolean | null,
    minOrderVal?: number | null,
    vatPercentage?: number | null,
    serviceChargePercentage?: number | null,
    addDeliveryToVat?: boolean | null,
    categoryTagIDs?: Array< string | null > | null,
    parentConceptID: string,
    containerColor?: string | null,
    avgOrderCompletionEstimate?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteConceptMutationVariables = {
  input: DeleteConceptInput,
  condition?: ModelConceptConditionInput | null,
};

export type DeleteConceptMutation = {
  deleteConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    kiosks?: Array< string | null > | null,
    merchantID?: string | null,
    merchantUsername?: string | null,
    merchantPassword?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    paymentSharePercentage?: number | null,
    simphonyServerBaseURL?: string | null,
    simphonyShortOrgName?: string | null,
    simphonyLocRef?: string | null,
    simphonyToken?: string | null,
    revenueCenterID?: number | null,
    employeeID?: number | null,
    tenderID?: number | null,
    tenders?:  {
      __typename: "ModelTenderConnection",
      items:  Array< {
        __typename: "Tender",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptTendersId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderType?:  {
      __typename: "ModelOrderTypeConnection",
      items:  Array< {
        __typename: "OrderType",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptOrderTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    discountID?: number | null,
    useServiceCharge?: boolean | null,
    serviceChargeId?: number | null,
    specialRequestID?: number | null,
    addressID?: number | null,
    ratingCount?: number | null,
    averageRating?: number | null,
    feesback?: string | null,
    simphonyAuthClientId?: string | null,
    simphonyAuthUsername?: string | null,
    simphonyAuthPassword?: string | null,
    simphonyAuthBaseURL?: string | null,
    orderTypeDineIn?: number | null,
    orderTypeTakeAway?: number | null,
    orderTypeDelivery?: number | null,
    simphonyTakeAwayRvc?: number | null,
    simphonyDeliveryRvc?: number | null,
    exportInterval?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    foodicsToken?: string | null,
    enableTableFoodics?: string | null,
    image?: string | null,
    logo?: string | null,
    album?: Array< string | null > | null,
    type?: string | null,
    rating?: number | null,
    location?: string | null,
    description?: string | null,
    areas?: Array< string | null > | null,
    seatTypes?: Array< string | null > | null,
    needAssistance?: boolean | null,
    spaBooking?: boolean | null,
    showerBooking?: boolean | null,
    cigarMenu?: boolean | null,
    precedence?: number | null,
    isPinRequired?: boolean | null,
    viewOnly?: boolean | null,
    openTime?: string | null,
    closeTime?: string | null,
    varifyForAmount?: number | null,
    varifyFirstOrder?: boolean | null,
    varifyNewAddress?: boolean | null,
    sliderImages?: Array< string | null > | null,
    receiveWaiters?: Array< string | null > | null,
    WaiterNotificationOption?: string | null,
    applyLoyalty?: boolean | null,
    rewardPoints?: number | null,
    moneySpent?: number | null,
    afterVat?: boolean | null,
    afterDeliveryFee?: boolean | null,
    beforePromoCode?: boolean | null,
    pointsCalcWithPromoCode?: boolean | null,
    isBusy?: boolean | null,
    allowOnlineOrdering?: boolean | null,
    minOrderVal?: number | null,
    vatPercentage?: number | null,
    serviceChargePercentage?: number | null,
    addDeliveryToVat?: boolean | null,
    categoryTagIDs?: Array< string | null > | null,
    parentConceptID: string,
    containerColor?: string | null,
    avgOrderCompletionEstimate?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCategoryTagMutationVariables = {
  input: CreateCategoryTagInput,
  condition?: ModelCategoryTagConditionInput | null,
};

export type CreateCategoryTagMutation = {
  createCategoryTag?:  {
    __typename: "CategoryTag",
    id: string,
    parentConceptID: string,
    name: string,
    image?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCategoryTagMutationVariables = {
  input: UpdateCategoryTagInput,
  condition?: ModelCategoryTagConditionInput | null,
};

export type UpdateCategoryTagMutation = {
  updateCategoryTag?:  {
    __typename: "CategoryTag",
    id: string,
    parentConceptID: string,
    name: string,
    image?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCategoryTagMutationVariables = {
  input: DeleteCategoryTagInput,
  condition?: ModelCategoryTagConditionInput | null,
};

export type DeleteCategoryTagMutation = {
  deleteCategoryTag?:  {
    __typename: "CategoryTag",
    id: string,
    parentConceptID: string,
    name: string,
    image?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTenderMutationVariables = {
  input: CreateTenderInput,
  condition?: ModelTenderConditionInput | null,
};

export type CreateTenderMutation = {
  createTender?:  {
    __typename: "Tender",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptTendersId?: string | null,
  } | null,
};

export type UpdateTenderMutationVariables = {
  input: UpdateTenderInput,
  condition?: ModelTenderConditionInput | null,
};

export type UpdateTenderMutation = {
  updateTender?:  {
    __typename: "Tender",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptTendersId?: string | null,
  } | null,
};

export type DeleteTenderMutationVariables = {
  input: DeleteTenderInput,
  condition?: ModelTenderConditionInput | null,
};

export type DeleteTenderMutation = {
  deleteTender?:  {
    __typename: "Tender",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptTendersId?: string | null,
  } | null,
};

export type CreateOrderTypeMutationVariables = {
  input: CreateOrderTypeInput,
  condition?: ModelOrderTypeConditionInput | null,
};

export type CreateOrderTypeMutation = {
  createOrderType?:  {
    __typename: "OrderType",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptOrderTypeId?: string | null,
  } | null,
};

export type UpdateOrderTypeMutationVariables = {
  input: UpdateOrderTypeInput,
  condition?: ModelOrderTypeConditionInput | null,
};

export type UpdateOrderTypeMutation = {
  updateOrderType?:  {
    __typename: "OrderType",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptOrderTypeId?: string | null,
  } | null,
};

export type DeleteOrderTypeMutationVariables = {
  input: DeleteOrderTypeInput,
  condition?: ModelOrderTypeConditionInput | null,
};

export type DeleteOrderTypeMutation = {
  deleteOrderType?:  {
    __typename: "OrderType",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptOrderTypeId?: string | null,
  } | null,
};

export type CreateParentConceptMutationVariables = {
  input: CreateParentConceptInput,
  condition?: ModelParentConceptConditionInput | null,
};

export type CreateParentConceptMutation = {
  createParentConcept?:  {
    __typename: "ParentConcept",
    id: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    image?: string | null,
    name?: string | null,
    logo?: string | null,
    type?: string | null,
    venues?:  {
      __typename: "ModelConceptConnection",
      items:  Array< {
        __typename: "Concept",
        id: string,
        accountID: string,
        name: string,
        kiosks?: Array< string | null > | null,
        merchantID?: string | null,
        merchantUsername?: string | null,
        merchantPassword?: string | null,
        merchantIntegrationNumber?: string | null,
        merchantAPIKey?: string | null,
        paymentSharePercentage?: number | null,
        simphonyServerBaseURL?: string | null,
        simphonyShortOrgName?: string | null,
        simphonyLocRef?: string | null,
        simphonyToken?: string | null,
        revenueCenterID?: number | null,
        employeeID?: number | null,
        tenderID?: number | null,
        tenders?:  {
          __typename: "ModelTenderConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderType?:  {
          __typename: "ModelOrderTypeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        discountID?: number | null,
        useServiceCharge?: boolean | null,
        serviceChargeId?: number | null,
        specialRequestID?: number | null,
        addressID?: number | null,
        ratingCount?: number | null,
        averageRating?: number | null,
        feesback?: string | null,
        simphonyAuthClientId?: string | null,
        simphonyAuthUsername?: string | null,
        simphonyAuthPassword?: string | null,
        simphonyAuthBaseURL?: string | null,
        orderTypeDineIn?: number | null,
        orderTypeTakeAway?: number | null,
        orderTypeDelivery?: number | null,
        simphonyTakeAwayRvc?: number | null,
        simphonyDeliveryRvc?: number | null,
        exportInterval?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        foodicsToken?: string | null,
        enableTableFoodics?: string | null,
        image?: string | null,
        logo?: string | null,
        album?: Array< string | null > | null,
        type?: string | null,
        rating?: number | null,
        location?: string | null,
        description?: string | null,
        areas?: Array< string | null > | null,
        seatTypes?: Array< string | null > | null,
        needAssistance?: boolean | null,
        spaBooking?: boolean | null,
        showerBooking?: boolean | null,
        cigarMenu?: boolean | null,
        precedence?: number | null,
        isPinRequired?: boolean | null,
        viewOnly?: boolean | null,
        openTime?: string | null,
        closeTime?: string | null,
        varifyForAmount?: number | null,
        varifyFirstOrder?: boolean | null,
        varifyNewAddress?: boolean | null,
        sliderImages?: Array< string | null > | null,
        receiveWaiters?: Array< string | null > | null,
        WaiterNotificationOption?: string | null,
        applyLoyalty?: boolean | null,
        rewardPoints?: number | null,
        moneySpent?: number | null,
        afterVat?: boolean | null,
        afterDeliveryFee?: boolean | null,
        beforePromoCode?: boolean | null,
        pointsCalcWithPromoCode?: boolean | null,
        isBusy?: boolean | null,
        allowOnlineOrdering?: boolean | null,
        minOrderVal?: number | null,
        vatPercentage?: number | null,
        serviceChargePercentage?: number | null,
        addDeliveryToVat?: boolean | null,
        categoryTagIDs?: Array< string | null > | null,
        parentConceptID: string,
        containerColor?: string | null,
        avgOrderCompletionEstimate?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    index?: number | null,
    categoryTags?:  {
      __typename: "ModelCategoryTagConnection",
      items:  Array< {
        __typename: "CategoryTag",
        id: string,
        parentConceptID: string,
        name: string,
        image?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateParentConceptMutationVariables = {
  input: UpdateParentConceptInput,
  condition?: ModelParentConceptConditionInput | null,
};

export type UpdateParentConceptMutation = {
  updateParentConcept?:  {
    __typename: "ParentConcept",
    id: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    image?: string | null,
    name?: string | null,
    logo?: string | null,
    type?: string | null,
    venues?:  {
      __typename: "ModelConceptConnection",
      items:  Array< {
        __typename: "Concept",
        id: string,
        accountID: string,
        name: string,
        kiosks?: Array< string | null > | null,
        merchantID?: string | null,
        merchantUsername?: string | null,
        merchantPassword?: string | null,
        merchantIntegrationNumber?: string | null,
        merchantAPIKey?: string | null,
        paymentSharePercentage?: number | null,
        simphonyServerBaseURL?: string | null,
        simphonyShortOrgName?: string | null,
        simphonyLocRef?: string | null,
        simphonyToken?: string | null,
        revenueCenterID?: number | null,
        employeeID?: number | null,
        tenderID?: number | null,
        tenders?:  {
          __typename: "ModelTenderConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderType?:  {
          __typename: "ModelOrderTypeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        discountID?: number | null,
        useServiceCharge?: boolean | null,
        serviceChargeId?: number | null,
        specialRequestID?: number | null,
        addressID?: number | null,
        ratingCount?: number | null,
        averageRating?: number | null,
        feesback?: string | null,
        simphonyAuthClientId?: string | null,
        simphonyAuthUsername?: string | null,
        simphonyAuthPassword?: string | null,
        simphonyAuthBaseURL?: string | null,
        orderTypeDineIn?: number | null,
        orderTypeTakeAway?: number | null,
        orderTypeDelivery?: number | null,
        simphonyTakeAwayRvc?: number | null,
        simphonyDeliveryRvc?: number | null,
        exportInterval?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        foodicsToken?: string | null,
        enableTableFoodics?: string | null,
        image?: string | null,
        logo?: string | null,
        album?: Array< string | null > | null,
        type?: string | null,
        rating?: number | null,
        location?: string | null,
        description?: string | null,
        areas?: Array< string | null > | null,
        seatTypes?: Array< string | null > | null,
        needAssistance?: boolean | null,
        spaBooking?: boolean | null,
        showerBooking?: boolean | null,
        cigarMenu?: boolean | null,
        precedence?: number | null,
        isPinRequired?: boolean | null,
        viewOnly?: boolean | null,
        openTime?: string | null,
        closeTime?: string | null,
        varifyForAmount?: number | null,
        varifyFirstOrder?: boolean | null,
        varifyNewAddress?: boolean | null,
        sliderImages?: Array< string | null > | null,
        receiveWaiters?: Array< string | null > | null,
        WaiterNotificationOption?: string | null,
        applyLoyalty?: boolean | null,
        rewardPoints?: number | null,
        moneySpent?: number | null,
        afterVat?: boolean | null,
        afterDeliveryFee?: boolean | null,
        beforePromoCode?: boolean | null,
        pointsCalcWithPromoCode?: boolean | null,
        isBusy?: boolean | null,
        allowOnlineOrdering?: boolean | null,
        minOrderVal?: number | null,
        vatPercentage?: number | null,
        serviceChargePercentage?: number | null,
        addDeliveryToVat?: boolean | null,
        categoryTagIDs?: Array< string | null > | null,
        parentConceptID: string,
        containerColor?: string | null,
        avgOrderCompletionEstimate?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    index?: number | null,
    categoryTags?:  {
      __typename: "ModelCategoryTagConnection",
      items:  Array< {
        __typename: "CategoryTag",
        id: string,
        parentConceptID: string,
        name: string,
        image?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteParentConceptMutationVariables = {
  input: DeleteParentConceptInput,
  condition?: ModelParentConceptConditionInput | null,
};

export type DeleteParentConceptMutation = {
  deleteParentConcept?:  {
    __typename: "ParentConcept",
    id: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    image?: string | null,
    name?: string | null,
    logo?: string | null,
    type?: string | null,
    venues?:  {
      __typename: "ModelConceptConnection",
      items:  Array< {
        __typename: "Concept",
        id: string,
        accountID: string,
        name: string,
        kiosks?: Array< string | null > | null,
        merchantID?: string | null,
        merchantUsername?: string | null,
        merchantPassword?: string | null,
        merchantIntegrationNumber?: string | null,
        merchantAPIKey?: string | null,
        paymentSharePercentage?: number | null,
        simphonyServerBaseURL?: string | null,
        simphonyShortOrgName?: string | null,
        simphonyLocRef?: string | null,
        simphonyToken?: string | null,
        revenueCenterID?: number | null,
        employeeID?: number | null,
        tenderID?: number | null,
        tenders?:  {
          __typename: "ModelTenderConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderType?:  {
          __typename: "ModelOrderTypeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        discountID?: number | null,
        useServiceCharge?: boolean | null,
        serviceChargeId?: number | null,
        specialRequestID?: number | null,
        addressID?: number | null,
        ratingCount?: number | null,
        averageRating?: number | null,
        feesback?: string | null,
        simphonyAuthClientId?: string | null,
        simphonyAuthUsername?: string | null,
        simphonyAuthPassword?: string | null,
        simphonyAuthBaseURL?: string | null,
        orderTypeDineIn?: number | null,
        orderTypeTakeAway?: number | null,
        orderTypeDelivery?: number | null,
        simphonyTakeAwayRvc?: number | null,
        simphonyDeliveryRvc?: number | null,
        exportInterval?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        foodicsToken?: string | null,
        enableTableFoodics?: string | null,
        image?: string | null,
        logo?: string | null,
        album?: Array< string | null > | null,
        type?: string | null,
        rating?: number | null,
        location?: string | null,
        description?: string | null,
        areas?: Array< string | null > | null,
        seatTypes?: Array< string | null > | null,
        needAssistance?: boolean | null,
        spaBooking?: boolean | null,
        showerBooking?: boolean | null,
        cigarMenu?: boolean | null,
        precedence?: number | null,
        isPinRequired?: boolean | null,
        viewOnly?: boolean | null,
        openTime?: string | null,
        closeTime?: string | null,
        varifyForAmount?: number | null,
        varifyFirstOrder?: boolean | null,
        varifyNewAddress?: boolean | null,
        sliderImages?: Array< string | null > | null,
        receiveWaiters?: Array< string | null > | null,
        WaiterNotificationOption?: string | null,
        applyLoyalty?: boolean | null,
        rewardPoints?: number | null,
        moneySpent?: number | null,
        afterVat?: boolean | null,
        afterDeliveryFee?: boolean | null,
        beforePromoCode?: boolean | null,
        pointsCalcWithPromoCode?: boolean | null,
        isBusy?: boolean | null,
        allowOnlineOrdering?: boolean | null,
        minOrderVal?: number | null,
        vatPercentage?: number | null,
        serviceChargePercentage?: number | null,
        addDeliveryToVat?: boolean | null,
        categoryTagIDs?: Array< string | null > | null,
        parentConceptID: string,
        containerColor?: string | null,
        avgOrderCompletionEstimate?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    index?: number | null,
    categoryTags?:  {
      __typename: "ModelCategoryTagConnection",
      items:  Array< {
        __typename: "CategoryTag",
        id: string,
        parentConceptID: string,
        name: string,
        image?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTimelineMutationVariables = {
  input: CreateTimelineInput,
  condition?: ModelTimelineConditionInput | null,
};

export type CreateTimelineMutation = {
  createTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    transactionId?: string | null,
    customerId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTimelineMutationVariables = {
  input: UpdateTimelineInput,
  condition?: ModelTimelineConditionInput | null,
};

export type UpdateTimelineMutation = {
  updateTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    transactionId?: string | null,
    customerId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTimelineMutationVariables = {
  input: DeleteTimelineInput,
  condition?: ModelTimelineConditionInput | null,
};

export type DeleteTimelineMutation = {
  deleteTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    transactionId?: string | null,
    customerId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCallMutationVariables = {
  input: CreateCallInput,
  condition?: ModelCallConditionInput | null,
};

export type CreateCallMutation = {
  createCall?:  {
    __typename: "Call",
    id: string,
    phone_number?: string | null,
    customer?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      autoGeneratedPassword: boolean,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    endedAt?: string | null,
    duration?: string | null,
    date: string,
    employeeId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    callCustomerId?: string | null,
  } | null,
};

export type UpdateCallMutationVariables = {
  input: UpdateCallInput,
  condition?: ModelCallConditionInput | null,
};

export type UpdateCallMutation = {
  updateCall?:  {
    __typename: "Call",
    id: string,
    phone_number?: string | null,
    customer?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      autoGeneratedPassword: boolean,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    endedAt?: string | null,
    duration?: string | null,
    date: string,
    employeeId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    callCustomerId?: string | null,
  } | null,
};

export type DeleteCallMutationVariables = {
  input: DeleteCallInput,
  condition?: ModelCallConditionInput | null,
};

export type DeleteCallMutation = {
  deleteCall?:  {
    __typename: "Call",
    id: string,
    phone_number?: string | null,
    customer?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      autoGeneratedPassword: boolean,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    endedAt?: string | null,
    duration?: string | null,
    date: string,
    employeeId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    callCustomerId?: string | null,
  } | null,
};

export type CreateAdminRoleMutationVariables = {
  input: CreateAdminRoleInput,
  condition?: ModelAdminRoleConditionInput | null,
};

export type CreateAdminRoleMutation = {
  createAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAdminRoleMutationVariables = {
  input: UpdateAdminRoleInput,
  condition?: ModelAdminRoleConditionInput | null,
};

export type UpdateAdminRoleMutation = {
  updateAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAdminRoleMutationVariables = {
  input: DeleteAdminRoleInput,
  condition?: ModelAdminRoleConditionInput | null,
};

export type DeleteAdminRoleMutation = {
  deleteAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAdminGroupMutationVariables = {
  input: CreateAdminGroupInput,
  condition?: ModelAdminGroupConditionInput | null,
};

export type CreateAdminGroupMutation = {
  createAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAdminGroupMutationVariables = {
  input: UpdateAdminGroupInput,
  condition?: ModelAdminGroupConditionInput | null,
};

export type UpdateAdminGroupMutation = {
  updateAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAdminGroupMutationVariables = {
  input: DeleteAdminGroupInput,
  condition?: ModelAdminGroupConditionInput | null,
};

export type DeleteAdminGroupMutation = {
  deleteAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserConceptsMutationVariables = {
  input: CreateUserConceptsInput,
  condition?: ModelUserConceptsConditionInput | null,
};

export type CreateUserConceptsMutation = {
  createUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    parentConcepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserConceptsMutationVariables = {
  input: UpdateUserConceptsInput,
  condition?: ModelUserConceptsConditionInput | null,
};

export type UpdateUserConceptsMutation = {
  updateUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    parentConcepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserConceptsMutationVariables = {
  input: DeleteUserConceptsInput,
  condition?: ModelUserConceptsConditionInput | null,
};

export type DeleteUserConceptsMutation = {
  deleteUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    parentConcepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateTransactionMutationVariables = {
  input: CreateTransactionInput,
  condition?: ModelTransactionConditionInput | null,
};

export type CreateTransactionMutation = {
  createTransaction?:  {
    __typename: "Transaction",
    id: string,
    transactionID: string,
    date: string,
    guestName: string,
    guestPhone: string,
    guestID: string,
    guestGroup: string,
    guestsNames?: Array< string | null > | null,
    bookingID?: string | null,
    bookingDate?: string | null,
    amount_cents: string,
    status: boolean,
    failureReason?: string | null,
    type: string,
    timeSlots?: Array< string | null > | null,
    tables?: Array< string | null > | null,
    conceptID: string,
    currency: string,
    refund: boolean,
    reference_orderID?: string | null,
    ownerID: string,
    refunded_amount_cents: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateTransactionMutationVariables = {
  input: UpdateTransactionInput,
  condition?: ModelTransactionConditionInput | null,
};

export type UpdateTransactionMutation = {
  updateTransaction?:  {
    __typename: "Transaction",
    id: string,
    transactionID: string,
    date: string,
    guestName: string,
    guestPhone: string,
    guestID: string,
    guestGroup: string,
    guestsNames?: Array< string | null > | null,
    bookingID?: string | null,
    bookingDate?: string | null,
    amount_cents: string,
    status: boolean,
    failureReason?: string | null,
    type: string,
    timeSlots?: Array< string | null > | null,
    tables?: Array< string | null > | null,
    conceptID: string,
    currency: string,
    refund: boolean,
    reference_orderID?: string | null,
    ownerID: string,
    refunded_amount_cents: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteTransactionMutationVariables = {
  input: DeleteTransactionInput,
  condition?: ModelTransactionConditionInput | null,
};

export type DeleteTransactionMutation = {
  deleteTransaction?:  {
    __typename: "Transaction",
    id: string,
    transactionID: string,
    date: string,
    guestName: string,
    guestPhone: string,
    guestID: string,
    guestGroup: string,
    guestsNames?: Array< string | null > | null,
    bookingID?: string | null,
    bookingDate?: string | null,
    amount_cents: string,
    status: boolean,
    failureReason?: string | null,
    type: string,
    timeSlots?: Array< string | null > | null,
    tables?: Array< string | null > | null,
    conceptID: string,
    currency: string,
    refund: boolean,
    reference_orderID?: string | null,
    ownerID: string,
    refunded_amount_cents: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePaymentStatMutationVariables = {
  input: CreatePaymentStatInput,
  condition?: ModelPaymentStatConditionInput | null,
};

export type CreatePaymentStatMutation = {
  createPaymentStat?:  {
    __typename: "PaymentStat",
    id: string,
    accountID?: string | null,
    conceptID: string,
    year: number,
    month: number,
    monthName: string,
    earning: number,
    refund: number,
    earningTrxCount: number,
    refundTrxCount: number,
    deleted?: string | null,
    createdAt: string,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePaymentStatMutationVariables = {
  input: UpdatePaymentStatInput,
  condition?: ModelPaymentStatConditionInput | null,
};

export type UpdatePaymentStatMutation = {
  updatePaymentStat?:  {
    __typename: "PaymentStat",
    id: string,
    accountID?: string | null,
    conceptID: string,
    year: number,
    month: number,
    monthName: string,
    earning: number,
    refund: number,
    earningTrxCount: number,
    refundTrxCount: number,
    deleted?: string | null,
    createdAt: string,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePaymentStatMutationVariables = {
  input: DeletePaymentStatInput,
  condition?: ModelPaymentStatConditionInput | null,
};

export type DeletePaymentStatMutation = {
  deletePaymentStat?:  {
    __typename: "PaymentStat",
    id: string,
    accountID?: string | null,
    conceptID: string,
    year: number,
    month: number,
    monthName: string,
    earning: number,
    refund: number,
    earningTrxCount: number,
    refundTrxCount: number,
    deleted?: string | null,
    createdAt: string,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    conceptID: string,
    parentConceptID: string,
    preparationAreaID?: string | null,
    subCategory?: string | null,
    name: string,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    darkImage?: string | null,
    menuItems?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        conceptID: string,
        kioskID?: string | null,
        name: string,
        description?: string | null,
        image?: string | null,
        precedence?: string | null,
        categoryID: string,
        categoryName?: string | null,
        outOfStock?: boolean | null,
        requiredPoints?: number | null,
        symphonyID?: string | null,
        comboItemID?: string | null,
        ratings?: Array< string | null > | null,
        prices?: Array< string | null > | null,
        choiceGroups?: Array< string | null > | null,
        pricesItems?:  Array< {
          __typename: "Price",
          id: string,
          conceptID: string,
          parentConceptID: string,
          name: string,
          multiLanguages?: string | null,
          price: number,
          symphonyID: string,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null > | null,
        choiceGroupsItems?:  Array< {
          __typename: "ChoiceGroups",
          id: string,
          conceptID: string,
          parentConceptID: string,
          name: string,
          required?: boolean | null,
          maxNumberOfChoices?: number | null,
          minNumberOfChoices?: number | null,
          precedence?: string | null,
          type?: string | null,
          choices?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          multiLanguages?: string | null,
          simphonyID?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null > | null,
        choiceGroupsRules?:  Array< {
          __typename: "ChoiceGroupRules",
          id: string,
          minNumberOfChoices?: number | null,
          maxNumberOfChoices?: number | null,
        } | null > | null,
        ExtraChoiceGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        hasLimitedQuantity?: boolean | null,
        quantity?: number | null,
        autoRestock?: boolean | null,
        restockQuantity?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        quantityOrdered?: number | null,
        performancePerDay?: Array< string | null > | null,
        multiLanguages?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    precedence: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    symphonyID?: string | null,
    isHidden?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    conceptID: string,
    parentConceptID: string,
    preparationAreaID?: string | null,
    subCategory?: string | null,
    name: string,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    darkImage?: string | null,
    menuItems?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        conceptID: string,
        kioskID?: string | null,
        name: string,
        description?: string | null,
        image?: string | null,
        precedence?: string | null,
        categoryID: string,
        categoryName?: string | null,
        outOfStock?: boolean | null,
        requiredPoints?: number | null,
        symphonyID?: string | null,
        comboItemID?: string | null,
        ratings?: Array< string | null > | null,
        prices?: Array< string | null > | null,
        choiceGroups?: Array< string | null > | null,
        pricesItems?:  Array< {
          __typename: "Price",
          id: string,
          conceptID: string,
          parentConceptID: string,
          name: string,
          multiLanguages?: string | null,
          price: number,
          symphonyID: string,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null > | null,
        choiceGroupsItems?:  Array< {
          __typename: "ChoiceGroups",
          id: string,
          conceptID: string,
          parentConceptID: string,
          name: string,
          required?: boolean | null,
          maxNumberOfChoices?: number | null,
          minNumberOfChoices?: number | null,
          precedence?: string | null,
          type?: string | null,
          choices?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          multiLanguages?: string | null,
          simphonyID?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null > | null,
        choiceGroupsRules?:  Array< {
          __typename: "ChoiceGroupRules",
          id: string,
          minNumberOfChoices?: number | null,
          maxNumberOfChoices?: number | null,
        } | null > | null,
        ExtraChoiceGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        hasLimitedQuantity?: boolean | null,
        quantity?: number | null,
        autoRestock?: boolean | null,
        restockQuantity?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        quantityOrdered?: number | null,
        performancePerDay?: Array< string | null > | null,
        multiLanguages?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    precedence: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    symphonyID?: string | null,
    isHidden?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    conceptID: string,
    parentConceptID: string,
    preparationAreaID?: string | null,
    subCategory?: string | null,
    name: string,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    darkImage?: string | null,
    menuItems?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        conceptID: string,
        kioskID?: string | null,
        name: string,
        description?: string | null,
        image?: string | null,
        precedence?: string | null,
        categoryID: string,
        categoryName?: string | null,
        outOfStock?: boolean | null,
        requiredPoints?: number | null,
        symphonyID?: string | null,
        comboItemID?: string | null,
        ratings?: Array< string | null > | null,
        prices?: Array< string | null > | null,
        choiceGroups?: Array< string | null > | null,
        pricesItems?:  Array< {
          __typename: "Price",
          id: string,
          conceptID: string,
          parentConceptID: string,
          name: string,
          multiLanguages?: string | null,
          price: number,
          symphonyID: string,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null > | null,
        choiceGroupsItems?:  Array< {
          __typename: "ChoiceGroups",
          id: string,
          conceptID: string,
          parentConceptID: string,
          name: string,
          required?: boolean | null,
          maxNumberOfChoices?: number | null,
          minNumberOfChoices?: number | null,
          precedence?: string | null,
          type?: string | null,
          choices?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          multiLanguages?: string | null,
          simphonyID?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null > | null,
        choiceGroupsRules?:  Array< {
          __typename: "ChoiceGroupRules",
          id: string,
          minNumberOfChoices?: number | null,
          maxNumberOfChoices?: number | null,
        } | null > | null,
        ExtraChoiceGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        hasLimitedQuantity?: boolean | null,
        quantity?: number | null,
        autoRestock?: boolean | null,
        restockQuantity?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        quantityOrdered?: number | null,
        performancePerDay?: Array< string | null > | null,
        multiLanguages?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    precedence: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    symphonyID?: string | null,
    isHidden?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMenuItemMutationVariables = {
  input: CreateMenuItemInput,
  condition?: ModelMenuItemConditionInput | null,
};

export type CreateMenuItemMutation = {
  createMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    conceptID: string,
    kioskID?: string | null,
    name: string,
    description?: string | null,
    image?: string | null,
    precedence?: string | null,
    categoryID: string,
    categoryName?: string | null,
    outOfStock?: boolean | null,
    requiredPoints?: number | null,
    symphonyID?: string | null,
    comboItemID?: string | null,
    ratings?: Array< string | null > | null,
    prices?: Array< string | null > | null,
    choiceGroups?: Array< string | null > | null,
    pricesItems?:  Array< {
      __typename: "Price",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      multiLanguages?: string | null,
      price: number,
      symphonyID: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null > | null,
    choiceGroupsItems?:  Array< {
      __typename: "ChoiceGroups",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      required?: boolean | null,
      maxNumberOfChoices?: number | null,
      minNumberOfChoices?: number | null,
      precedence?: string | null,
      type?: string | null,
      choices?: Array< string | null > | null,
      choiceItems?:  {
        __typename: "ModelChoiceConnection",
        items:  Array< {
          __typename: "Choice",
          id: string,
          conceptID: string,
          name: string,
          price: number,
          precedence?: string | null,
          choiceGroupID: string,
          choiceGroupName?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          symphonyID?: string | null,
          multiLanguages?: string | null,
          definitionNumber?: number | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      simphonyID?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null > | null,
    choiceGroupsRules?:  Array< {
      __typename: "ChoiceGroupRules",
      id: string,
      minNumberOfChoices?: number | null,
      maxNumberOfChoices?: number | null,
    } | null > | null,
    ExtraChoiceGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    hasLimitedQuantity?: boolean | null,
    quantity?: number | null,
    autoRestock?: boolean | null,
    restockQuantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    quantityOrdered?: number | null,
    performancePerDay?: Array< string | null > | null,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMenuItemMutationVariables = {
  input: UpdateMenuItemInput,
  condition?: ModelMenuItemConditionInput | null,
};

export type UpdateMenuItemMutation = {
  updateMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    conceptID: string,
    kioskID?: string | null,
    name: string,
    description?: string | null,
    image?: string | null,
    precedence?: string | null,
    categoryID: string,
    categoryName?: string | null,
    outOfStock?: boolean | null,
    requiredPoints?: number | null,
    symphonyID?: string | null,
    comboItemID?: string | null,
    ratings?: Array< string | null > | null,
    prices?: Array< string | null > | null,
    choiceGroups?: Array< string | null > | null,
    pricesItems?:  Array< {
      __typename: "Price",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      multiLanguages?: string | null,
      price: number,
      symphonyID: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null > | null,
    choiceGroupsItems?:  Array< {
      __typename: "ChoiceGroups",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      required?: boolean | null,
      maxNumberOfChoices?: number | null,
      minNumberOfChoices?: number | null,
      precedence?: string | null,
      type?: string | null,
      choices?: Array< string | null > | null,
      choiceItems?:  {
        __typename: "ModelChoiceConnection",
        items:  Array< {
          __typename: "Choice",
          id: string,
          conceptID: string,
          name: string,
          price: number,
          precedence?: string | null,
          choiceGroupID: string,
          choiceGroupName?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          symphonyID?: string | null,
          multiLanguages?: string | null,
          definitionNumber?: number | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      simphonyID?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null > | null,
    choiceGroupsRules?:  Array< {
      __typename: "ChoiceGroupRules",
      id: string,
      minNumberOfChoices?: number | null,
      maxNumberOfChoices?: number | null,
    } | null > | null,
    ExtraChoiceGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    hasLimitedQuantity?: boolean | null,
    quantity?: number | null,
    autoRestock?: boolean | null,
    restockQuantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    quantityOrdered?: number | null,
    performancePerDay?: Array< string | null > | null,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMenuItemMutationVariables = {
  input: DeleteMenuItemInput,
  condition?: ModelMenuItemConditionInput | null,
};

export type DeleteMenuItemMutation = {
  deleteMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    conceptID: string,
    kioskID?: string | null,
    name: string,
    description?: string | null,
    image?: string | null,
    precedence?: string | null,
    categoryID: string,
    categoryName?: string | null,
    outOfStock?: boolean | null,
    requiredPoints?: number | null,
    symphonyID?: string | null,
    comboItemID?: string | null,
    ratings?: Array< string | null > | null,
    prices?: Array< string | null > | null,
    choiceGroups?: Array< string | null > | null,
    pricesItems?:  Array< {
      __typename: "Price",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      multiLanguages?: string | null,
      price: number,
      symphonyID: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null > | null,
    choiceGroupsItems?:  Array< {
      __typename: "ChoiceGroups",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      required?: boolean | null,
      maxNumberOfChoices?: number | null,
      minNumberOfChoices?: number | null,
      precedence?: string | null,
      type?: string | null,
      choices?: Array< string | null > | null,
      choiceItems?:  {
        __typename: "ModelChoiceConnection",
        items:  Array< {
          __typename: "Choice",
          id: string,
          conceptID: string,
          name: string,
          price: number,
          precedence?: string | null,
          choiceGroupID: string,
          choiceGroupName?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          symphonyID?: string | null,
          multiLanguages?: string | null,
          definitionNumber?: number | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      simphonyID?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null > | null,
    choiceGroupsRules?:  Array< {
      __typename: "ChoiceGroupRules",
      id: string,
      minNumberOfChoices?: number | null,
      maxNumberOfChoices?: number | null,
    } | null > | null,
    ExtraChoiceGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    hasLimitedQuantity?: boolean | null,
    quantity?: number | null,
    autoRestock?: boolean | null,
    restockQuantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    quantityOrdered?: number | null,
    performancePerDay?: Array< string | null > | null,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePriceMutationVariables = {
  input: CreatePriceInput,
  condition?: ModelPriceConditionInput | null,
};

export type CreatePriceMutation = {
  createPrice?:  {
    __typename: "Price",
    id: string,
    conceptID: string,
    parentConceptID: string,
    name: string,
    multiLanguages?: string | null,
    price: number,
    symphonyID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePriceMutationVariables = {
  input: UpdatePriceInput,
  condition?: ModelPriceConditionInput | null,
};

export type UpdatePriceMutation = {
  updatePrice?:  {
    __typename: "Price",
    id: string,
    conceptID: string,
    parentConceptID: string,
    name: string,
    multiLanguages?: string | null,
    price: number,
    symphonyID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePriceMutationVariables = {
  input: DeletePriceInput,
  condition?: ModelPriceConditionInput | null,
};

export type DeletePriceMutation = {
  deletePrice?:  {
    __typename: "Price",
    id: string,
    conceptID: string,
    parentConceptID: string,
    name: string,
    multiLanguages?: string | null,
    price: number,
    symphonyID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateChoiceGroupsMutationVariables = {
  input: CreateChoiceGroupsInput,
  condition?: ModelChoiceGroupsConditionInput | null,
};

export type CreateChoiceGroupsMutation = {
  createChoiceGroups?:  {
    __typename: "ChoiceGroups",
    id: string,
    conceptID: string,
    parentConceptID: string,
    name: string,
    required?: boolean | null,
    maxNumberOfChoices?: number | null,
    minNumberOfChoices?: number | null,
    precedence?: string | null,
    type?: string | null,
    choices?: Array< string | null > | null,
    choiceItems?:  {
      __typename: "ModelChoiceConnection",
      items:  Array< {
        __typename: "Choice",
        id: string,
        conceptID: string,
        name: string,
        price: number,
        precedence?: string | null,
        choiceGroupID: string,
        choiceGroupName?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        symphonyID?: string | null,
        multiLanguages?: string | null,
        definitionNumber?: number | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    simphonyID?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateChoiceGroupsMutationVariables = {
  input: UpdateChoiceGroupsInput,
  condition?: ModelChoiceGroupsConditionInput | null,
};

export type UpdateChoiceGroupsMutation = {
  updateChoiceGroups?:  {
    __typename: "ChoiceGroups",
    id: string,
    conceptID: string,
    parentConceptID: string,
    name: string,
    required?: boolean | null,
    maxNumberOfChoices?: number | null,
    minNumberOfChoices?: number | null,
    precedence?: string | null,
    type?: string | null,
    choices?: Array< string | null > | null,
    choiceItems?:  {
      __typename: "ModelChoiceConnection",
      items:  Array< {
        __typename: "Choice",
        id: string,
        conceptID: string,
        name: string,
        price: number,
        precedence?: string | null,
        choiceGroupID: string,
        choiceGroupName?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        symphonyID?: string | null,
        multiLanguages?: string | null,
        definitionNumber?: number | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    simphonyID?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteChoiceGroupsMutationVariables = {
  input: DeleteChoiceGroupsInput,
  condition?: ModelChoiceGroupsConditionInput | null,
};

export type DeleteChoiceGroupsMutation = {
  deleteChoiceGroups?:  {
    __typename: "ChoiceGroups",
    id: string,
    conceptID: string,
    parentConceptID: string,
    name: string,
    required?: boolean | null,
    maxNumberOfChoices?: number | null,
    minNumberOfChoices?: number | null,
    precedence?: string | null,
    type?: string | null,
    choices?: Array< string | null > | null,
    choiceItems?:  {
      __typename: "ModelChoiceConnection",
      items:  Array< {
        __typename: "Choice",
        id: string,
        conceptID: string,
        name: string,
        price: number,
        precedence?: string | null,
        choiceGroupID: string,
        choiceGroupName?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        symphonyID?: string | null,
        multiLanguages?: string | null,
        definitionNumber?: number | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    simphonyID?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateChoiceMutationVariables = {
  input: CreateChoiceInput,
  condition?: ModelChoiceConditionInput | null,
};

export type CreateChoiceMutation = {
  createChoice?:  {
    __typename: "Choice",
    id: string,
    conceptID: string,
    name: string,
    price: number,
    precedence?: string | null,
    choiceGroupID: string,
    choiceGroupName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    symphonyID?: string | null,
    multiLanguages?: string | null,
    definitionNumber?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateChoiceMutationVariables = {
  input: UpdateChoiceInput,
  condition?: ModelChoiceConditionInput | null,
};

export type UpdateChoiceMutation = {
  updateChoice?:  {
    __typename: "Choice",
    id: string,
    conceptID: string,
    name: string,
    price: number,
    precedence?: string | null,
    choiceGroupID: string,
    choiceGroupName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    symphonyID?: string | null,
    multiLanguages?: string | null,
    definitionNumber?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteChoiceMutationVariables = {
  input: DeleteChoiceInput,
  condition?: ModelChoiceConditionInput | null,
};

export type DeleteChoiceMutation = {
  deleteChoice?:  {
    __typename: "Choice",
    id: string,
    conceptID: string,
    name: string,
    price: number,
    precedence?: string | null,
    choiceGroupID: string,
    choiceGroupName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    symphonyID?: string | null,
    multiLanguages?: string | null,
    definitionNumber?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateCartMutationVariables = {
  input: CreateCartInput,
  condition?: ModelCartConditionInput | null,
};

export type CreateCartMutation = {
  createCart?:  {
    __typename: "Cart",
    id: string,
    conceptID: string,
    userID: string,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      categoryID: string,
      name?: string | null,
      quantity: number,
      selectedChoiceGroups?:  Array< {
        __typename: "CartChoiceGroup",
        id: string,
        name?: string | null,
        selectedChoices?:  Array< {
          __typename: "CartChoice",
          id: string,
          name?: string | null,
          price?: number | null,
        } | null > | null,
        precedence?: string | null,
      } | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      type?: string | null,
    } | null > | null,
    specialRequest?: string | null,
    deliveryFee?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    totalPrice?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateCartMutationVariables = {
  input: UpdateCartInput,
  condition?: ModelCartConditionInput | null,
};

export type UpdateCartMutation = {
  updateCart?:  {
    __typename: "Cart",
    id: string,
    conceptID: string,
    userID: string,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      categoryID: string,
      name?: string | null,
      quantity: number,
      selectedChoiceGroups?:  Array< {
        __typename: "CartChoiceGroup",
        id: string,
        name?: string | null,
        selectedChoices?:  Array< {
          __typename: "CartChoice",
          id: string,
          name?: string | null,
          price?: number | null,
        } | null > | null,
        precedence?: string | null,
      } | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      type?: string | null,
    } | null > | null,
    specialRequest?: string | null,
    deliveryFee?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    totalPrice?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteCartMutationVariables = {
  input: DeleteCartInput,
  condition?: ModelCartConditionInput | null,
};

export type DeleteCartMutation = {
  deleteCart?:  {
    __typename: "Cart",
    id: string,
    conceptID: string,
    userID: string,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      categoryID: string,
      name?: string | null,
      quantity: number,
      selectedChoiceGroups?:  Array< {
        __typename: "CartChoiceGroup",
        id: string,
        name?: string | null,
        selectedChoices?:  Array< {
          __typename: "CartChoice",
          id: string,
          name?: string | null,
          price?: number | null,
        } | null > | null,
        precedence?: string | null,
      } | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      type?: string | null,
    } | null > | null,
    specialRequest?: string | null,
    deliveryFee?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    totalPrice?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateAdminStatusMutationVariables = {
  input: CreateAdminStatusInput,
  condition?: ModelAdminStatusConditionInput | null,
};

export type CreateAdminStatusMutation = {
  createAdminStatus?:  {
    __typename: "AdminStatus",
    id: string,
    sub: string,
    accountID: string,
    name?: string | null,
    averageCallTime?: number | null,
    numberOfCalls?: number | null,
    idelDuration?: number | null,
    activeDuration?: number | null,
    awayDuration?: number | null,
    lastCallTime?: number | null,
    agentBarAverageCallTime?: number | null,
    agentBarNumberOfCalls?: number | null,
    type?: string | null,
    waiterStatus?: string | null,
    totalAverageRatio?: number | null,
    performancePerDay?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateAdminStatusMutationVariables = {
  input: UpdateAdminStatusInput,
  condition?: ModelAdminStatusConditionInput | null,
};

export type UpdateAdminStatusMutation = {
  updateAdminStatus?:  {
    __typename: "AdminStatus",
    id: string,
    sub: string,
    accountID: string,
    name?: string | null,
    averageCallTime?: number | null,
    numberOfCalls?: number | null,
    idelDuration?: number | null,
    activeDuration?: number | null,
    awayDuration?: number | null,
    lastCallTime?: number | null,
    agentBarAverageCallTime?: number | null,
    agentBarNumberOfCalls?: number | null,
    type?: string | null,
    waiterStatus?: string | null,
    totalAverageRatio?: number | null,
    performancePerDay?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteAdminStatusMutationVariables = {
  input: DeleteAdminStatusInput,
  condition?: ModelAdminStatusConditionInput | null,
};

export type DeleteAdminStatusMutation = {
  deleteAdminStatus?:  {
    __typename: "AdminStatus",
    id: string,
    sub: string,
    accountID: string,
    name?: string | null,
    averageCallTime?: number | null,
    numberOfCalls?: number | null,
    idelDuration?: number | null,
    activeDuration?: number | null,
    awayDuration?: number | null,
    lastCallTime?: number | null,
    agentBarAverageCallTime?: number | null,
    agentBarNumberOfCalls?: number | null,
    type?: string | null,
    waiterStatus?: string | null,
    totalAverageRatio?: number | null,
    performancePerDay?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateOnlineOrderMutationVariables = {
  input: CreateOnlineOrderInput,
  condition?: ModelOnlineOrderConditionInput | null,
};

export type CreateOnlineOrderMutation = {
  createOnlineOrder?:  {
    __typename: "OnlineOrder",
    id: string,
    conceptID: string,
    statusID?: string | null,
    status?: string | null,
    reason?: string | null,
    orderType?: string | null,
    channel?: string | null,
    userID: string,
    userName?: string | null,
    userPhoneNumber?: string | null,
    dispatcherID?: string | null,
    dispatcherName?: string | null,
    totalAmount?: number | null,
    discountedValue?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    tax?: number | null,
    deliveryFee?: number | null,
    orderTime?: string | null,
    executionArn?: string | null,
    specialRequest?: string | null,
    orderAddress?: string | null,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      categoryID: string,
      name?: string | null,
      quantity: number,
      selectedChoiceGroups?:  Array< {
        __typename: "CartChoiceGroup",
        id: string,
        name?: string | null,
        selectedChoices?:  Array< {
          __typename: "CartChoice",
          id: string,
          name?: string | null,
          price?: number | null,
        } | null > | null,
        precedence?: string | null,
      } | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      type?: string | null,
    } | null > | null,
    discountID?: number | null,
    amount_cents?: string | null,
    transactionID?: string | null,
    paymentStatus?: boolean | null,
    paymentType?: string | null,
    referenceId?: string | null,
    cardHolderNumber?: string | null,
    cardHolderName?: string | null,
    cardSchemeName?: string | null,
    deleted?: string | null,
    createdAt: string,
    sentToKitchenAt?: string | null,
    readyAt?: string | null,
    outForDeliveryAt?: string | null,
    deliveredAt?: string | null,
    promoCodeID?: string | null,
    promoCodeName?: string | null,
    promoCodeAppliedTo?: string | null,
    rating?: number | null,
    isReview?: boolean | null,
    reviewID?: string | null,
    review?:  {
      __typename: "Review",
      id: string,
      conceptID?: string | null,
      conceptName?: string | null,
      accountID?: string | null,
      bookingID?: string | null,
      parentConceptID?: string | null,
      guestName?: string | null,
      guestId?: string | null,
      reason?: Array< string | null > | null,
      value?: string | null,
      userID?: string | null,
      orderID?: string | null,
      rate?: number | null,
      feedback?: string | null,
      satisfaction?: string | null,
      thoughts?: string | null,
      itemReviews?:  Array< {
        __typename: "ItemReview",
        itemID: string,
        rate?: number | null,
        satisfaction?: string | null,
        feedback?: string | null,
        multiLanguages?: string | null,
      } | null > | null,
      photos?: Array< string | null > | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      phoneUser?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdByID: string,
    createdByName: string,
    tmpField?: string | null,
    otp?: string | null,
    avgOrderCompletionEstimate?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateOnlineOrderMutationVariables = {
  input: UpdateOnlineOrderInput,
  condition?: ModelOnlineOrderConditionInput | null,
};

export type UpdateOnlineOrderMutation = {
  updateOnlineOrder?:  {
    __typename: "OnlineOrder",
    id: string,
    conceptID: string,
    statusID?: string | null,
    status?: string | null,
    reason?: string | null,
    orderType?: string | null,
    channel?: string | null,
    userID: string,
    userName?: string | null,
    userPhoneNumber?: string | null,
    dispatcherID?: string | null,
    dispatcherName?: string | null,
    totalAmount?: number | null,
    discountedValue?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    tax?: number | null,
    deliveryFee?: number | null,
    orderTime?: string | null,
    executionArn?: string | null,
    specialRequest?: string | null,
    orderAddress?: string | null,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      categoryID: string,
      name?: string | null,
      quantity: number,
      selectedChoiceGroups?:  Array< {
        __typename: "CartChoiceGroup",
        id: string,
        name?: string | null,
        selectedChoices?:  Array< {
          __typename: "CartChoice",
          id: string,
          name?: string | null,
          price?: number | null,
        } | null > | null,
        precedence?: string | null,
      } | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      type?: string | null,
    } | null > | null,
    discountID?: number | null,
    amount_cents?: string | null,
    transactionID?: string | null,
    paymentStatus?: boolean | null,
    paymentType?: string | null,
    referenceId?: string | null,
    cardHolderNumber?: string | null,
    cardHolderName?: string | null,
    cardSchemeName?: string | null,
    deleted?: string | null,
    createdAt: string,
    sentToKitchenAt?: string | null,
    readyAt?: string | null,
    outForDeliveryAt?: string | null,
    deliveredAt?: string | null,
    promoCodeID?: string | null,
    promoCodeName?: string | null,
    promoCodeAppliedTo?: string | null,
    rating?: number | null,
    isReview?: boolean | null,
    reviewID?: string | null,
    review?:  {
      __typename: "Review",
      id: string,
      conceptID?: string | null,
      conceptName?: string | null,
      accountID?: string | null,
      bookingID?: string | null,
      parentConceptID?: string | null,
      guestName?: string | null,
      guestId?: string | null,
      reason?: Array< string | null > | null,
      value?: string | null,
      userID?: string | null,
      orderID?: string | null,
      rate?: number | null,
      feedback?: string | null,
      satisfaction?: string | null,
      thoughts?: string | null,
      itemReviews?:  Array< {
        __typename: "ItemReview",
        itemID: string,
        rate?: number | null,
        satisfaction?: string | null,
        feedback?: string | null,
        multiLanguages?: string | null,
      } | null > | null,
      photos?: Array< string | null > | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      phoneUser?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdByID: string,
    createdByName: string,
    tmpField?: string | null,
    otp?: string | null,
    avgOrderCompletionEstimate?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteOnlineOrderMutationVariables = {
  input: DeleteOnlineOrderInput,
  condition?: ModelOnlineOrderConditionInput | null,
};

export type DeleteOnlineOrderMutation = {
  deleteOnlineOrder?:  {
    __typename: "OnlineOrder",
    id: string,
    conceptID: string,
    statusID?: string | null,
    status?: string | null,
    reason?: string | null,
    orderType?: string | null,
    channel?: string | null,
    userID: string,
    userName?: string | null,
    userPhoneNumber?: string | null,
    dispatcherID?: string | null,
    dispatcherName?: string | null,
    totalAmount?: number | null,
    discountedValue?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    tax?: number | null,
    deliveryFee?: number | null,
    orderTime?: string | null,
    executionArn?: string | null,
    specialRequest?: string | null,
    orderAddress?: string | null,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      categoryID: string,
      name?: string | null,
      quantity: number,
      selectedChoiceGroups?:  Array< {
        __typename: "CartChoiceGroup",
        id: string,
        name?: string | null,
        selectedChoices?:  Array< {
          __typename: "CartChoice",
          id: string,
          name?: string | null,
          price?: number | null,
        } | null > | null,
        precedence?: string | null,
      } | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      type?: string | null,
    } | null > | null,
    discountID?: number | null,
    amount_cents?: string | null,
    transactionID?: string | null,
    paymentStatus?: boolean | null,
    paymentType?: string | null,
    referenceId?: string | null,
    cardHolderNumber?: string | null,
    cardHolderName?: string | null,
    cardSchemeName?: string | null,
    deleted?: string | null,
    createdAt: string,
    sentToKitchenAt?: string | null,
    readyAt?: string | null,
    outForDeliveryAt?: string | null,
    deliveredAt?: string | null,
    promoCodeID?: string | null,
    promoCodeName?: string | null,
    promoCodeAppliedTo?: string | null,
    rating?: number | null,
    isReview?: boolean | null,
    reviewID?: string | null,
    review?:  {
      __typename: "Review",
      id: string,
      conceptID?: string | null,
      conceptName?: string | null,
      accountID?: string | null,
      bookingID?: string | null,
      parentConceptID?: string | null,
      guestName?: string | null,
      guestId?: string | null,
      reason?: Array< string | null > | null,
      value?: string | null,
      userID?: string | null,
      orderID?: string | null,
      rate?: number | null,
      feedback?: string | null,
      satisfaction?: string | null,
      thoughts?: string | null,
      itemReviews?:  Array< {
        __typename: "ItemReview",
        itemID: string,
        rate?: number | null,
        satisfaction?: string | null,
        feedback?: string | null,
        multiLanguages?: string | null,
      } | null > | null,
      photos?: Array< string | null > | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      phoneUser?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdByID: string,
    createdByName: string,
    tmpField?: string | null,
    otp?: string | null,
    avgOrderCompletionEstimate?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateOrderStatusMutationVariables = {
  input: CreateOrderStatusInput,
  condition?: ModelOrderStatusConditionInput | null,
};

export type CreateOrderStatusMutation = {
  createOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    conceptID: string,
    name: string,
    color?: string | null,
    precedence?: number | null,
    accessibleStatus?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateOrderStatusMutationVariables = {
  input: UpdateOrderStatusInput,
  condition?: ModelOrderStatusConditionInput | null,
};

export type UpdateOrderStatusMutation = {
  updateOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    conceptID: string,
    name: string,
    color?: string | null,
    precedence?: number | null,
    accessibleStatus?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteOrderStatusMutationVariables = {
  input: DeleteOrderStatusInput,
  condition?: ModelOrderStatusConditionInput | null,
};

export type DeleteOrderStatusMutation = {
  deleteOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    conceptID: string,
    name: string,
    color?: string | null,
    precedence?: number | null,
    accessibleStatus?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateDiscountCouponMutationVariables = {
  input: CreateDiscountCouponInput,
  condition?: ModelDiscountCouponConditionInput | null,
};

export type CreateDiscountCouponMutation = {
  createDiscountCoupon?:  {
    __typename: "DiscountCoupon",
    id: string,
    discountPercent: string,
    usedBy?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateDiscountCouponMutationVariables = {
  input: UpdateDiscountCouponInput,
  condition?: ModelDiscountCouponConditionInput | null,
};

export type UpdateDiscountCouponMutation = {
  updateDiscountCoupon?:  {
    __typename: "DiscountCoupon",
    id: string,
    discountPercent: string,
    usedBy?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteDiscountCouponMutationVariables = {
  input: DeleteDiscountCouponInput,
  condition?: ModelDiscountCouponConditionInput | null,
};

export type DeleteDiscountCouponMutation = {
  deleteDiscountCoupon?:  {
    __typename: "DiscountCoupon",
    id: string,
    discountPercent: string,
    usedBy?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateZoneMutationVariables = {
  input: CreateZoneInput,
  condition?: ModelZoneConditionInput | null,
};

export type CreateZoneMutation = {
  createZone?:  {
    __typename: "Zone",
    id: string,
    conceptID: string,
    location: string,
    deliveryFee: number,
    concepts?:  Array< {
      __typename: "ZoneConcept",
      id: string,
      deliveryFee: number,
    } | null > | null,
    polygon?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateZoneMutationVariables = {
  input: UpdateZoneInput,
  condition?: ModelZoneConditionInput | null,
};

export type UpdateZoneMutation = {
  updateZone?:  {
    __typename: "Zone",
    id: string,
    conceptID: string,
    location: string,
    deliveryFee: number,
    concepts?:  Array< {
      __typename: "ZoneConcept",
      id: string,
      deliveryFee: number,
    } | null > | null,
    polygon?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteZoneMutationVariables = {
  input: DeleteZoneInput,
  condition?: ModelZoneConditionInput | null,
};

export type DeleteZoneMutation = {
  deleteZone?:  {
    __typename: "Zone",
    id: string,
    conceptID: string,
    location: string,
    deliveryFee: number,
    concepts?:  Array< {
      __typename: "ZoneConcept",
      id: string,
      deliveryFee: number,
    } | null > | null,
    polygon?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateUserIPMutationVariables = {
  input: CreateUserIPInput,
  condition?: ModelUserIPConditionInput | null,
};

export type CreateUserIPMutation = {
  createUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateUserIPMutationVariables = {
  input: UpdateUserIPInput,
  condition?: ModelUserIPConditionInput | null,
};

export type UpdateUserIPMutation = {
  updateUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteUserIPMutationVariables = {
  input: DeleteUserIPInput,
  condition?: ModelUserIPConditionInput | null,
};

export type DeleteUserIPMutation = {
  deleteUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMainCategoryMutationVariables = {
  input: CreateMainCategoryInput,
  condition?: ModelMainCategoryConditionInput | null,
};

export type CreateMainCategoryMutation = {
  createMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMainCategoryMutationVariables = {
  input: UpdateMainCategoryInput,
  condition?: ModelMainCategoryConditionInput | null,
};

export type UpdateMainCategoryMutation = {
  updateMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMainCategoryMutationVariables = {
  input: DeleteMainCategoryInput,
  condition?: ModelMainCategoryConditionInput | null,
};

export type DeleteMainCategoryMutation = {
  deleteMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateSubCategoryMutationVariables = {
  input: CreateSubCategoryInput,
  condition?: ModelSubCategoryConditionInput | null,
};

export type CreateSubCategoryMutation = {
  createSubCategory?:  {
    __typename: "SubCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    mainCategoryID?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateSubCategoryMutationVariables = {
  input: UpdateSubCategoryInput,
  condition?: ModelSubCategoryConditionInput | null,
};

export type UpdateSubCategoryMutation = {
  updateSubCategory?:  {
    __typename: "SubCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    mainCategoryID?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteSubCategoryMutationVariables = {
  input: DeleteSubCategoryInput,
  condition?: ModelSubCategoryConditionInput | null,
};

export type DeleteSubCategoryMutation = {
  deleteSubCategory?:  {
    __typename: "SubCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    mainCategoryID?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateChannelMutationVariables = {
  input: CreateChannelInput,
  condition?: ModelChannelConditionInput | null,
};

export type CreateChannelMutation = {
  createChannel?:  {
    __typename: "Channel",
    id: string,
    name: string,
    description?: string | null,
    numberOfOrders?: number | null,
    isActive?: boolean | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateChannelMutationVariables = {
  input: UpdateChannelInput,
  condition?: ModelChannelConditionInput | null,
};

export type UpdateChannelMutation = {
  updateChannel?:  {
    __typename: "Channel",
    id: string,
    name: string,
    description?: string | null,
    numberOfOrders?: number | null,
    isActive?: boolean | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteChannelMutationVariables = {
  input: DeleteChannelInput,
  condition?: ModelChannelConditionInput | null,
};

export type DeleteChannelMutation = {
  deleteChannel?:  {
    __typename: "Channel",
    id: string,
    name: string,
    description?: string | null,
    numberOfOrders?: number | null,
    isActive?: boolean | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePromocodeMutationVariables = {
  input: CreatePromocodeInput,
  condition?: ModelPromocodeConditionInput | null,
};

export type CreatePromocodeMutation = {
  createPromocode?:  {
    __typename: "Promocode",
    id: string,
    name: string,
    redemptions: number,
    status?: string | null,
    startDate: string,
    endDate: string,
    appliesTo: string,
    minOrderReq: string,
    minOrderVal?: number | null,
    minOrderQty?: number | null,
    tenderReq: Array< string | null >,
    discountVal: number,
    discountLimit?: number | null,
    quota?: number | null,
    quotaUsed?: number | null,
    totalRevenue?: number | null,
    appliedDiscountedVal?: number | null,
    usePerCustomer?: number | null,
    channelReq: Array< string | null >,
    customerEligibility: string,
    customerReq?: Array< string | null > | null,
    spendingExceeds?: number | null,
    spendingBelow?: number | null,
    orderSinceDate?: string | null,
    orderFromDate?: string | null,
    orderToDate?: string | null,
    isDisplayed?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    parentConceptIDs?: Array< string | null > | null,
    conceptIDs?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePromocodeMutationVariables = {
  input: UpdatePromocodeInput,
  condition?: ModelPromocodeConditionInput | null,
};

export type UpdatePromocodeMutation = {
  updatePromocode?:  {
    __typename: "Promocode",
    id: string,
    name: string,
    redemptions: number,
    status?: string | null,
    startDate: string,
    endDate: string,
    appliesTo: string,
    minOrderReq: string,
    minOrderVal?: number | null,
    minOrderQty?: number | null,
    tenderReq: Array< string | null >,
    discountVal: number,
    discountLimit?: number | null,
    quota?: number | null,
    quotaUsed?: number | null,
    totalRevenue?: number | null,
    appliedDiscountedVal?: number | null,
    usePerCustomer?: number | null,
    channelReq: Array< string | null >,
    customerEligibility: string,
    customerReq?: Array< string | null > | null,
    spendingExceeds?: number | null,
    spendingBelow?: number | null,
    orderSinceDate?: string | null,
    orderFromDate?: string | null,
    orderToDate?: string | null,
    isDisplayed?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    parentConceptIDs?: Array< string | null > | null,
    conceptIDs?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePromocodeMutationVariables = {
  input: DeletePromocodeInput,
  condition?: ModelPromocodeConditionInput | null,
};

export type DeletePromocodeMutation = {
  deletePromocode?:  {
    __typename: "Promocode",
    id: string,
    name: string,
    redemptions: number,
    status?: string | null,
    startDate: string,
    endDate: string,
    appliesTo: string,
    minOrderReq: string,
    minOrderVal?: number | null,
    minOrderQty?: number | null,
    tenderReq: Array< string | null >,
    discountVal: number,
    discountLimit?: number | null,
    quota?: number | null,
    quotaUsed?: number | null,
    totalRevenue?: number | null,
    appliedDiscountedVal?: number | null,
    usePerCustomer?: number | null,
    channelReq: Array< string | null >,
    customerEligibility: string,
    customerReq?: Array< string | null > | null,
    spendingExceeds?: number | null,
    spendingBelow?: number | null,
    orderSinceDate?: string | null,
    orderFromDate?: string | null,
    orderToDate?: string | null,
    isDisplayed?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    parentConceptIDs?: Array< string | null > | null,
    conceptIDs?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreatePromocodeDashboardMutationVariables = {
  input: CreatePromocodeDashboardInput,
  condition?: ModelPromocodeDashboardConditionInput | null,
};

export type CreatePromocodeDashboardMutation = {
  createPromocodeDashboard?:  {
    __typename: "PromocodeDashboard",
    id: string,
    conceptID: string,
    totalRevenue?: number | null,
    totalRedemptions?: number | null,
    totalDiscount?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdatePromocodeDashboardMutationVariables = {
  input: UpdatePromocodeDashboardInput,
  condition?: ModelPromocodeDashboardConditionInput | null,
};

export type UpdatePromocodeDashboardMutation = {
  updatePromocodeDashboard?:  {
    __typename: "PromocodeDashboard",
    id: string,
    conceptID: string,
    totalRevenue?: number | null,
    totalRedemptions?: number | null,
    totalDiscount?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeletePromocodeDashboardMutationVariables = {
  input: DeletePromocodeDashboardInput,
  condition?: ModelPromocodeDashboardConditionInput | null,
};

export type DeletePromocodeDashboardMutation = {
  deletePromocodeDashboard?:  {
    __typename: "PromocodeDashboard",
    id: string,
    conceptID: string,
    totalRevenue?: number | null,
    totalRedemptions?: number | null,
    totalDiscount?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateConceptRevenueMutationVariables = {
  input: CreateConceptRevenueInput,
  condition?: ModelConceptRevenueConditionInput | null,
};

export type CreateConceptRevenueMutation = {
  createConceptRevenue?:  {
    __typename: "ConceptRevenue",
    id: string,
    conceptID: string,
    date: string,
    driveThruRevenue?: number | null,
    pickupRevenue?: number | null,
    deliveryRevenue?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateConceptRevenueMutationVariables = {
  input: UpdateConceptRevenueInput,
  condition?: ModelConceptRevenueConditionInput | null,
};

export type UpdateConceptRevenueMutation = {
  updateConceptRevenue?:  {
    __typename: "ConceptRevenue",
    id: string,
    conceptID: string,
    date: string,
    driveThruRevenue?: number | null,
    pickupRevenue?: number | null,
    deliveryRevenue?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteConceptRevenueMutationVariables = {
  input: DeleteConceptRevenueInput,
  condition?: ModelConceptRevenueConditionInput | null,
};

export type DeleteConceptRevenueMutation = {
  deleteConceptRevenue?:  {
    __typename: "ConceptRevenue",
    id: string,
    conceptID: string,
    date: string,
    driveThruRevenue?: number | null,
    pickupRevenue?: number | null,
    deliveryRevenue?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateLoyaltyItemMutationVariables = {
  input: CreateLoyaltyItemInput,
  condition?: ModelLoyaltyItemConditionInput | null,
};

export type CreateLoyaltyItemMutation = {
  createLoyaltyItem?:  {
    __typename: "LoyaltyItem",
    id: string,
    name: string,
    conceptID: string,
    parentConceptID: string,
    description?: string | null,
    startDate: string,
    endDate?: string | null,
    options?: Array< string | null > | null,
    points: number,
    redemptionPerUser?: number | null,
    image?: string | null,
    simphonyID?: string | null,
    multiLanguages?: string | null,
    isActive?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateLoyaltyItemMutationVariables = {
  input: UpdateLoyaltyItemInput,
  condition?: ModelLoyaltyItemConditionInput | null,
};

export type UpdateLoyaltyItemMutation = {
  updateLoyaltyItem?:  {
    __typename: "LoyaltyItem",
    id: string,
    name: string,
    conceptID: string,
    parentConceptID: string,
    description?: string | null,
    startDate: string,
    endDate?: string | null,
    options?: Array< string | null > | null,
    points: number,
    redemptionPerUser?: number | null,
    image?: string | null,
    simphonyID?: string | null,
    multiLanguages?: string | null,
    isActive?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteLoyaltyItemMutationVariables = {
  input: DeleteLoyaltyItemInput,
  condition?: ModelLoyaltyItemConditionInput | null,
};

export type DeleteLoyaltyItemMutation = {
  deleteLoyaltyItem?:  {
    __typename: "LoyaltyItem",
    id: string,
    name: string,
    conceptID: string,
    parentConceptID: string,
    description?: string | null,
    startDate: string,
    endDate?: string | null,
    options?: Array< string | null > | null,
    points: number,
    redemptionPerUser?: number | null,
    image?: string | null,
    simphonyID?: string | null,
    multiLanguages?: string | null,
    isActive?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateOTPMutationVariables = {
  input: CreateOTPInput,
  condition?: ModelOTPConditionInput | null,
};

export type CreateOTPMutation = {
  createOTP?:  {
    __typename: "OTP",
    id: string,
    OTP: string,
    redeemed?: boolean | null,
    deleted?: string | null,
    userID: string,
    conceptID?: string | null,
    loyaltyItemID?: string | null,
    loyaltyItem?:  {
      __typename: "LoyaltyItem",
      id: string,
      name: string,
      conceptID: string,
      parentConceptID: string,
      description?: string | null,
      startDate: string,
      endDate?: string | null,
      options?: Array< string | null > | null,
      points: number,
      redemptionPerUser?: number | null,
      image?: string | null,
      simphonyID?: string | null,
      multiLanguages?: string | null,
      isActive?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      autoGeneratedPassword: boolean,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    concept?:  {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      kiosks?: Array< string | null > | null,
      merchantID?: string | null,
      merchantUsername?: string | null,
      merchantPassword?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      paymentSharePercentage?: number | null,
      simphonyServerBaseURL?: string | null,
      simphonyShortOrgName?: string | null,
      simphonyLocRef?: string | null,
      simphonyToken?: string | null,
      revenueCenterID?: number | null,
      employeeID?: number | null,
      tenderID?: number | null,
      tenders?:  {
        __typename: "ModelTenderConnection",
        items:  Array< {
          __typename: "Tender",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptTendersId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderType?:  {
        __typename: "ModelOrderTypeConnection",
        items:  Array< {
          __typename: "OrderType",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptOrderTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      discountID?: number | null,
      useServiceCharge?: boolean | null,
      serviceChargeId?: number | null,
      specialRequestID?: number | null,
      addressID?: number | null,
      ratingCount?: number | null,
      averageRating?: number | null,
      feesback?: string | null,
      simphonyAuthClientId?: string | null,
      simphonyAuthUsername?: string | null,
      simphonyAuthPassword?: string | null,
      simphonyAuthBaseURL?: string | null,
      orderTypeDineIn?: number | null,
      orderTypeTakeAway?: number | null,
      orderTypeDelivery?: number | null,
      simphonyTakeAwayRvc?: number | null,
      simphonyDeliveryRvc?: number | null,
      exportInterval?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      foodicsToken?: string | null,
      enableTableFoodics?: string | null,
      image?: string | null,
      logo?: string | null,
      album?: Array< string | null > | null,
      type?: string | null,
      rating?: number | null,
      location?: string | null,
      description?: string | null,
      areas?: Array< string | null > | null,
      seatTypes?: Array< string | null > | null,
      needAssistance?: boolean | null,
      spaBooking?: boolean | null,
      showerBooking?: boolean | null,
      cigarMenu?: boolean | null,
      precedence?: number | null,
      isPinRequired?: boolean | null,
      viewOnly?: boolean | null,
      openTime?: string | null,
      closeTime?: string | null,
      varifyForAmount?: number | null,
      varifyFirstOrder?: boolean | null,
      varifyNewAddress?: boolean | null,
      sliderImages?: Array< string | null > | null,
      receiveWaiters?: Array< string | null > | null,
      WaiterNotificationOption?: string | null,
      applyLoyalty?: boolean | null,
      rewardPoints?: number | null,
      moneySpent?: number | null,
      afterVat?: boolean | null,
      afterDeliveryFee?: boolean | null,
      beforePromoCode?: boolean | null,
      pointsCalcWithPromoCode?: boolean | null,
      isBusy?: boolean | null,
      allowOnlineOrdering?: boolean | null,
      minOrderVal?: number | null,
      vatPercentage?: number | null,
      serviceChargePercentage?: number | null,
      addDeliveryToVat?: boolean | null,
      categoryTagIDs?: Array< string | null > | null,
      parentConceptID: string,
      containerColor?: string | null,
      avgOrderCompletionEstimate?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateOTPMutationVariables = {
  input: UpdateOTPInput,
  condition?: ModelOTPConditionInput | null,
};

export type UpdateOTPMutation = {
  updateOTP?:  {
    __typename: "OTP",
    id: string,
    OTP: string,
    redeemed?: boolean | null,
    deleted?: string | null,
    userID: string,
    conceptID?: string | null,
    loyaltyItemID?: string | null,
    loyaltyItem?:  {
      __typename: "LoyaltyItem",
      id: string,
      name: string,
      conceptID: string,
      parentConceptID: string,
      description?: string | null,
      startDate: string,
      endDate?: string | null,
      options?: Array< string | null > | null,
      points: number,
      redemptionPerUser?: number | null,
      image?: string | null,
      simphonyID?: string | null,
      multiLanguages?: string | null,
      isActive?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      autoGeneratedPassword: boolean,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    concept?:  {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      kiosks?: Array< string | null > | null,
      merchantID?: string | null,
      merchantUsername?: string | null,
      merchantPassword?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      paymentSharePercentage?: number | null,
      simphonyServerBaseURL?: string | null,
      simphonyShortOrgName?: string | null,
      simphonyLocRef?: string | null,
      simphonyToken?: string | null,
      revenueCenterID?: number | null,
      employeeID?: number | null,
      tenderID?: number | null,
      tenders?:  {
        __typename: "ModelTenderConnection",
        items:  Array< {
          __typename: "Tender",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptTendersId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderType?:  {
        __typename: "ModelOrderTypeConnection",
        items:  Array< {
          __typename: "OrderType",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptOrderTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      discountID?: number | null,
      useServiceCharge?: boolean | null,
      serviceChargeId?: number | null,
      specialRequestID?: number | null,
      addressID?: number | null,
      ratingCount?: number | null,
      averageRating?: number | null,
      feesback?: string | null,
      simphonyAuthClientId?: string | null,
      simphonyAuthUsername?: string | null,
      simphonyAuthPassword?: string | null,
      simphonyAuthBaseURL?: string | null,
      orderTypeDineIn?: number | null,
      orderTypeTakeAway?: number | null,
      orderTypeDelivery?: number | null,
      simphonyTakeAwayRvc?: number | null,
      simphonyDeliveryRvc?: number | null,
      exportInterval?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      foodicsToken?: string | null,
      enableTableFoodics?: string | null,
      image?: string | null,
      logo?: string | null,
      album?: Array< string | null > | null,
      type?: string | null,
      rating?: number | null,
      location?: string | null,
      description?: string | null,
      areas?: Array< string | null > | null,
      seatTypes?: Array< string | null > | null,
      needAssistance?: boolean | null,
      spaBooking?: boolean | null,
      showerBooking?: boolean | null,
      cigarMenu?: boolean | null,
      precedence?: number | null,
      isPinRequired?: boolean | null,
      viewOnly?: boolean | null,
      openTime?: string | null,
      closeTime?: string | null,
      varifyForAmount?: number | null,
      varifyFirstOrder?: boolean | null,
      varifyNewAddress?: boolean | null,
      sliderImages?: Array< string | null > | null,
      receiveWaiters?: Array< string | null > | null,
      WaiterNotificationOption?: string | null,
      applyLoyalty?: boolean | null,
      rewardPoints?: number | null,
      moneySpent?: number | null,
      afterVat?: boolean | null,
      afterDeliveryFee?: boolean | null,
      beforePromoCode?: boolean | null,
      pointsCalcWithPromoCode?: boolean | null,
      isBusy?: boolean | null,
      allowOnlineOrdering?: boolean | null,
      minOrderVal?: number | null,
      vatPercentage?: number | null,
      serviceChargePercentage?: number | null,
      addDeliveryToVat?: boolean | null,
      categoryTagIDs?: Array< string | null > | null,
      parentConceptID: string,
      containerColor?: string | null,
      avgOrderCompletionEstimate?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteOTPMutationVariables = {
  input: DeleteOTPInput,
  condition?: ModelOTPConditionInput | null,
};

export type DeleteOTPMutation = {
  deleteOTP?:  {
    __typename: "OTP",
    id: string,
    OTP: string,
    redeemed?: boolean | null,
    deleted?: string | null,
    userID: string,
    conceptID?: string | null,
    loyaltyItemID?: string | null,
    loyaltyItem?:  {
      __typename: "LoyaltyItem",
      id: string,
      name: string,
      conceptID: string,
      parentConceptID: string,
      description?: string | null,
      startDate: string,
      endDate?: string | null,
      options?: Array< string | null > | null,
      points: number,
      redemptionPerUser?: number | null,
      image?: string | null,
      simphonyID?: string | null,
      multiLanguages?: string | null,
      isActive?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      autoGeneratedPassword: boolean,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    concept?:  {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      kiosks?: Array< string | null > | null,
      merchantID?: string | null,
      merchantUsername?: string | null,
      merchantPassword?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      paymentSharePercentage?: number | null,
      simphonyServerBaseURL?: string | null,
      simphonyShortOrgName?: string | null,
      simphonyLocRef?: string | null,
      simphonyToken?: string | null,
      revenueCenterID?: number | null,
      employeeID?: number | null,
      tenderID?: number | null,
      tenders?:  {
        __typename: "ModelTenderConnection",
        items:  Array< {
          __typename: "Tender",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptTendersId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderType?:  {
        __typename: "ModelOrderTypeConnection",
        items:  Array< {
          __typename: "OrderType",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptOrderTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      discountID?: number | null,
      useServiceCharge?: boolean | null,
      serviceChargeId?: number | null,
      specialRequestID?: number | null,
      addressID?: number | null,
      ratingCount?: number | null,
      averageRating?: number | null,
      feesback?: string | null,
      simphonyAuthClientId?: string | null,
      simphonyAuthUsername?: string | null,
      simphonyAuthPassword?: string | null,
      simphonyAuthBaseURL?: string | null,
      orderTypeDineIn?: number | null,
      orderTypeTakeAway?: number | null,
      orderTypeDelivery?: number | null,
      simphonyTakeAwayRvc?: number | null,
      simphonyDeliveryRvc?: number | null,
      exportInterval?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      foodicsToken?: string | null,
      enableTableFoodics?: string | null,
      image?: string | null,
      logo?: string | null,
      album?: Array< string | null > | null,
      type?: string | null,
      rating?: number | null,
      location?: string | null,
      description?: string | null,
      areas?: Array< string | null > | null,
      seatTypes?: Array< string | null > | null,
      needAssistance?: boolean | null,
      spaBooking?: boolean | null,
      showerBooking?: boolean | null,
      cigarMenu?: boolean | null,
      precedence?: number | null,
      isPinRequired?: boolean | null,
      viewOnly?: boolean | null,
      openTime?: string | null,
      closeTime?: string | null,
      varifyForAmount?: number | null,
      varifyFirstOrder?: boolean | null,
      varifyNewAddress?: boolean | null,
      sliderImages?: Array< string | null > | null,
      receiveWaiters?: Array< string | null > | null,
      WaiterNotificationOption?: string | null,
      applyLoyalty?: boolean | null,
      rewardPoints?: number | null,
      moneySpent?: number | null,
      afterVat?: boolean | null,
      afterDeliveryFee?: boolean | null,
      beforePromoCode?: boolean | null,
      pointsCalcWithPromoCode?: boolean | null,
      isBusy?: boolean | null,
      allowOnlineOrdering?: boolean | null,
      minOrderVal?: number | null,
      vatPercentage?: number | null,
      serviceChargePercentage?: number | null,
      addDeliveryToVat?: boolean | null,
      categoryTagIDs?: Array< string | null > | null,
      parentConceptID: string,
      containerColor?: string | null,
      avgOrderCompletionEstimate?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type CreateMostOrderedItemsMutationVariables = {
  input: CreateMostOrderedItemsInput,
  condition?: ModelMostOrderedItemsConditionInput | null,
};

export type CreateMostOrderedItemsMutation = {
  createMostOrderedItems?:  {
    __typename: "MostOrderedItems",
    id: string,
    conceptID?: string | null,
    menuItemID: string,
    menuItemName?: string | null,
    menuItemImage?: string | null,
    quantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type UpdateMostOrderedItemsMutationVariables = {
  input: UpdateMostOrderedItemsInput,
  condition?: ModelMostOrderedItemsConditionInput | null,
};

export type UpdateMostOrderedItemsMutation = {
  updateMostOrderedItems?:  {
    __typename: "MostOrderedItems",
    id: string,
    conceptID?: string | null,
    menuItemID: string,
    menuItemName?: string | null,
    menuItemImage?: string | null,
    quantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type DeleteMostOrderedItemsMutationVariables = {
  input: DeleteMostOrderedItemsInput,
  condition?: ModelMostOrderedItemsConditionInput | null,
};

export type DeleteMostOrderedItemsMutation = {
  deleteMostOrderedItems?:  {
    __typename: "MostOrderedItems",
    id: string,
    conceptID?: string | null,
    menuItemID: string,
    menuItemName?: string | null,
    menuItemImage?: string | null,
    quantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    accountID: string,
    username?: string | null,
    preferred_username?: string | null,
    email?: string | null,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    pictureCrop?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    facebook?: string | null,
    instagram?: string | null,
    group?: string | null,
    phones?: Array< string | null > | null,
    flags?: Array< string | null > | null,
    flagsName?: Array< string | null > | null,
    interests?: Array< string | null > | null,
    interestsName?: Array< string | null > | null,
    status?: string | null,
    stats?: Array< string | null > | null,
    spending?: number | null,
    prevOrderDate?: string | null,
    lastOrderDate?: string | null,
    promocodes?:  Array< {
      __typename: "PromocodeItem",
      name: string,
      promocodeId: string,
      canBeUsed: number,
      discountVal: number,
      discountLimit?: number | null,
      isDisplayed?: boolean | null,
    } | null > | null,
    mostOrderedItems?: string | null,
    points?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    cognitoUsername?: string | null,
    cognitoSub?: string | null,
    autoGeneratedPassword: boolean,
    updated?: string | null,
    sub?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      autoGeneratedPassword: boolean,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
    scannedCount?: number | null,
    count?: number | null,
  } | null,
};

export type SyncUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUsersQuery = {
  syncUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      autoGeneratedPassword: boolean,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
    scannedCount?: number | null,
    count?: number | null,
  } | null,
};

export type ByPhoneNumberQueryVariables = {
  phone_number: string,
  deletedNameCreatedAt?: ModelUserUsersByPhoneNumberCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByPhoneNumberQuery = {
  ByPhoneNumber?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      autoGeneratedPassword: boolean,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
    scannedCount?: number | null,
    count?: number | null,
  } | null,
};

export type GetReviewQueryVariables = {
  id: string,
};

export type GetReviewQuery = {
  getReview?:  {
    __typename: "Review",
    id: string,
    conceptID?: string | null,
    conceptName?: string | null,
    accountID?: string | null,
    bookingID?: string | null,
    parentConceptID?: string | null,
    guestName?: string | null,
    guestId?: string | null,
    reason?: Array< string | null > | null,
    value?: string | null,
    userID?: string | null,
    orderID?: string | null,
    rate?: number | null,
    feedback?: string | null,
    satisfaction?: string | null,
    thoughts?: string | null,
    itemReviews?:  Array< {
      __typename: "ItemReview",
      itemID: string,
      rate?: number | null,
      satisfaction?: string | null,
      feedback?: string | null,
      multiLanguages?: string | null,
    } | null > | null,
    photos?: Array< string | null > | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    phoneUser?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListReviewsQueryVariables = {
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewsQuery = {
  listReviews?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      conceptID?: string | null,
      conceptName?: string | null,
      accountID?: string | null,
      bookingID?: string | null,
      parentConceptID?: string | null,
      guestName?: string | null,
      guestId?: string | null,
      reason?: Array< string | null > | null,
      value?: string | null,
      userID?: string | null,
      orderID?: string | null,
      rate?: number | null,
      feedback?: string | null,
      satisfaction?: string | null,
      thoughts?: string | null,
      itemReviews?:  Array< {
        __typename: "ItemReview",
        itemID: string,
        rate?: number | null,
        satisfaction?: string | null,
        feedback?: string | null,
        multiLanguages?: string | null,
      } | null > | null,
      photos?: Array< string | null > | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      phoneUser?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncReviewsQueryVariables = {
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncReviewsQuery = {
  syncReviews?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      conceptID?: string | null,
      conceptName?: string | null,
      accountID?: string | null,
      bookingID?: string | null,
      parentConceptID?: string | null,
      guestName?: string | null,
      guestId?: string | null,
      reason?: Array< string | null > | null,
      value?: string | null,
      userID?: string | null,
      orderID?: string | null,
      rate?: number | null,
      feedback?: string | null,
      satisfaction?: string | null,
      thoughts?: string | null,
      itemReviews?:  Array< {
        __typename: "ItemReview",
        itemID: string,
        rate?: number | null,
        satisfaction?: string | null,
        feedback?: string | null,
        multiLanguages?: string | null,
      } | null > | null,
      photos?: Array< string | null > | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      phoneUser?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ReviewsByConceptIDQueryVariables = {
  conceptID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReviewsByConceptIDQuery = {
  reviewsByConceptID?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      conceptID?: string | null,
      conceptName?: string | null,
      accountID?: string | null,
      bookingID?: string | null,
      parentConceptID?: string | null,
      guestName?: string | null,
      guestId?: string | null,
      reason?: Array< string | null > | null,
      value?: string | null,
      userID?: string | null,
      orderID?: string | null,
      rate?: number | null,
      feedback?: string | null,
      satisfaction?: string | null,
      thoughts?: string | null,
      itemReviews?:  Array< {
        __typename: "ItemReview",
        itemID: string,
        rate?: number | null,
        satisfaction?: string | null,
        feedback?: string | null,
        multiLanguages?: string | null,
      } | null > | null,
      photos?: Array< string | null > | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      phoneUser?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAccountQueryVariables = {
  id: string,
};

export type GetAccountQuery = {
  getAccount?:  {
    __typename: "Account",
    id: string,
    logo?: string | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    callCenterEmployeesIds?: Array< string | null > | null,
    kpiReportResetFrequency?: string | null,
    agentBarResetFrequency?: string | null,
    dynamicStatus?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAccountsQueryVariables = {
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAccountsQuery = {
  listAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      logo?: string | null,
      domain: string,
      siteTitle: string,
      guestsCount?: number | null,
      tagline: string,
      description: string,
      siteAddress: string,
      defaultLanguage: string,
      languages: Array< string | null >,
      features: Array< string | null >,
      status: string,
      socialLinks?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      callCenterEmployeesIds?: Array< string | null > | null,
      kpiReportResetFrequency?: string | null,
      agentBarResetFrequency?: string | null,
      dynamicStatus?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAccountsQueryVariables = {
  filter?: ModelAccountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAccountsQuery = {
  syncAccounts?:  {
    __typename: "ModelAccountConnection",
    items:  Array< {
      __typename: "Account",
      id: string,
      logo?: string | null,
      domain: string,
      siteTitle: string,
      guestsCount?: number | null,
      tagline: string,
      description: string,
      siteAddress: string,
      defaultLanguage: string,
      languages: Array< string | null >,
      features: Array< string | null >,
      status: string,
      socialLinks?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      callCenterEmployeesIds?: Array< string | null > | null,
      kpiReportResetFrequency?: string | null,
      agentBarResetFrequency?: string | null,
      dynamicStatus?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetFeatureQueryVariables = {
  id: string,
};

export type GetFeatureQuery = {
  getFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListFeaturesQueryVariables = {
  filter?: ModelFeatureFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFeaturesQuery = {
  listFeatures?:  {
    __typename: "ModelFeatureConnection",
    items:  Array< {
      __typename: "Feature",
      id: string,
      name: string,
      icon?: string | null,
      slug: string,
      precedence: string,
      parent?: string | null,
      private?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncFeaturesQueryVariables = {
  filter?: ModelFeatureFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncFeaturesQuery = {
  syncFeatures?:  {
    __typename: "ModelFeatureConnection",
    items:  Array< {
      __typename: "Feature",
      id: string,
      name: string,
      icon?: string | null,
      slug: string,
      precedence: string,
      parent?: string | null,
      private?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMediaQueryVariables = {
  id: string,
};

export type GetMediaQuery = {
  getMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMediaQueryVariables = {
  filter?: ModelMediaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMediaQuery = {
  listMedia?:  {
    __typename: "ModelMediaConnection",
    items:  Array< {
      __typename: "Media",
      id: string,
      accountID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      attachments?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMediaQueryVariables = {
  filter?: ModelMediaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMediaQuery = {
  syncMedia?:  {
    __typename: "ModelMediaConnection",
    items:  Array< {
      __typename: "Media",
      id: string,
      accountID: string,
      fileUrl: string,
      filename: string,
      filetype?: string | null,
      fileSize?: number | null,
      alternativeText?: string | null,
      caption?: string | null,
      description?: string | null,
      attachments?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetConceptQueryVariables = {
  id: string,
};

export type GetConceptQuery = {
  getConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    kiosks?: Array< string | null > | null,
    merchantID?: string | null,
    merchantUsername?: string | null,
    merchantPassword?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    paymentSharePercentage?: number | null,
    simphonyServerBaseURL?: string | null,
    simphonyShortOrgName?: string | null,
    simphonyLocRef?: string | null,
    simphonyToken?: string | null,
    revenueCenterID?: number | null,
    employeeID?: number | null,
    tenderID?: number | null,
    tenders?:  {
      __typename: "ModelTenderConnection",
      items:  Array< {
        __typename: "Tender",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptTendersId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderType?:  {
      __typename: "ModelOrderTypeConnection",
      items:  Array< {
        __typename: "OrderType",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptOrderTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    discountID?: number | null,
    useServiceCharge?: boolean | null,
    serviceChargeId?: number | null,
    specialRequestID?: number | null,
    addressID?: number | null,
    ratingCount?: number | null,
    averageRating?: number | null,
    feesback?: string | null,
    simphonyAuthClientId?: string | null,
    simphonyAuthUsername?: string | null,
    simphonyAuthPassword?: string | null,
    simphonyAuthBaseURL?: string | null,
    orderTypeDineIn?: number | null,
    orderTypeTakeAway?: number | null,
    orderTypeDelivery?: number | null,
    simphonyTakeAwayRvc?: number | null,
    simphonyDeliveryRvc?: number | null,
    exportInterval?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    foodicsToken?: string | null,
    enableTableFoodics?: string | null,
    image?: string | null,
    logo?: string | null,
    album?: Array< string | null > | null,
    type?: string | null,
    rating?: number | null,
    location?: string | null,
    description?: string | null,
    areas?: Array< string | null > | null,
    seatTypes?: Array< string | null > | null,
    needAssistance?: boolean | null,
    spaBooking?: boolean | null,
    showerBooking?: boolean | null,
    cigarMenu?: boolean | null,
    precedence?: number | null,
    isPinRequired?: boolean | null,
    viewOnly?: boolean | null,
    openTime?: string | null,
    closeTime?: string | null,
    varifyForAmount?: number | null,
    varifyFirstOrder?: boolean | null,
    varifyNewAddress?: boolean | null,
    sliderImages?: Array< string | null > | null,
    receiveWaiters?: Array< string | null > | null,
    WaiterNotificationOption?: string | null,
    applyLoyalty?: boolean | null,
    rewardPoints?: number | null,
    moneySpent?: number | null,
    afterVat?: boolean | null,
    afterDeliveryFee?: boolean | null,
    beforePromoCode?: boolean | null,
    pointsCalcWithPromoCode?: boolean | null,
    isBusy?: boolean | null,
    allowOnlineOrdering?: boolean | null,
    minOrderVal?: number | null,
    vatPercentage?: number | null,
    serviceChargePercentage?: number | null,
    addDeliveryToVat?: boolean | null,
    categoryTagIDs?: Array< string | null > | null,
    parentConceptID: string,
    containerColor?: string | null,
    avgOrderCompletionEstimate?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListConceptsQueryVariables = {
  filter?: ModelConceptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConceptsQuery = {
  listConcepts?:  {
    __typename: "ModelConceptConnection",
    items:  Array< {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      kiosks?: Array< string | null > | null,
      merchantID?: string | null,
      merchantUsername?: string | null,
      merchantPassword?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      paymentSharePercentage?: number | null,
      simphonyServerBaseURL?: string | null,
      simphonyShortOrgName?: string | null,
      simphonyLocRef?: string | null,
      simphonyToken?: string | null,
      revenueCenterID?: number | null,
      employeeID?: number | null,
      tenderID?: number | null,
      tenders?:  {
        __typename: "ModelTenderConnection",
        items:  Array< {
          __typename: "Tender",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptTendersId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderType?:  {
        __typename: "ModelOrderTypeConnection",
        items:  Array< {
          __typename: "OrderType",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptOrderTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      discountID?: number | null,
      useServiceCharge?: boolean | null,
      serviceChargeId?: number | null,
      specialRequestID?: number | null,
      addressID?: number | null,
      ratingCount?: number | null,
      averageRating?: number | null,
      feesback?: string | null,
      simphonyAuthClientId?: string | null,
      simphonyAuthUsername?: string | null,
      simphonyAuthPassword?: string | null,
      simphonyAuthBaseURL?: string | null,
      orderTypeDineIn?: number | null,
      orderTypeTakeAway?: number | null,
      orderTypeDelivery?: number | null,
      simphonyTakeAwayRvc?: number | null,
      simphonyDeliveryRvc?: number | null,
      exportInterval?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      foodicsToken?: string | null,
      enableTableFoodics?: string | null,
      image?: string | null,
      logo?: string | null,
      album?: Array< string | null > | null,
      type?: string | null,
      rating?: number | null,
      location?: string | null,
      description?: string | null,
      areas?: Array< string | null > | null,
      seatTypes?: Array< string | null > | null,
      needAssistance?: boolean | null,
      spaBooking?: boolean | null,
      showerBooking?: boolean | null,
      cigarMenu?: boolean | null,
      precedence?: number | null,
      isPinRequired?: boolean | null,
      viewOnly?: boolean | null,
      openTime?: string | null,
      closeTime?: string | null,
      varifyForAmount?: number | null,
      varifyFirstOrder?: boolean | null,
      varifyNewAddress?: boolean | null,
      sliderImages?: Array< string | null > | null,
      receiveWaiters?: Array< string | null > | null,
      WaiterNotificationOption?: string | null,
      applyLoyalty?: boolean | null,
      rewardPoints?: number | null,
      moneySpent?: number | null,
      afterVat?: boolean | null,
      afterDeliveryFee?: boolean | null,
      beforePromoCode?: boolean | null,
      pointsCalcWithPromoCode?: boolean | null,
      isBusy?: boolean | null,
      allowOnlineOrdering?: boolean | null,
      minOrderVal?: number | null,
      vatPercentage?: number | null,
      serviceChargePercentage?: number | null,
      addDeliveryToVat?: boolean | null,
      categoryTagIDs?: Array< string | null > | null,
      parentConceptID: string,
      containerColor?: string | null,
      avgOrderCompletionEstimate?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncConceptsQueryVariables = {
  filter?: ModelConceptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncConceptsQuery = {
  syncConcepts?:  {
    __typename: "ModelConceptConnection",
    items:  Array< {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      kiosks?: Array< string | null > | null,
      merchantID?: string | null,
      merchantUsername?: string | null,
      merchantPassword?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      paymentSharePercentage?: number | null,
      simphonyServerBaseURL?: string | null,
      simphonyShortOrgName?: string | null,
      simphonyLocRef?: string | null,
      simphonyToken?: string | null,
      revenueCenterID?: number | null,
      employeeID?: number | null,
      tenderID?: number | null,
      tenders?:  {
        __typename: "ModelTenderConnection",
        items:  Array< {
          __typename: "Tender",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptTendersId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderType?:  {
        __typename: "ModelOrderTypeConnection",
        items:  Array< {
          __typename: "OrderType",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptOrderTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      discountID?: number | null,
      useServiceCharge?: boolean | null,
      serviceChargeId?: number | null,
      specialRequestID?: number | null,
      addressID?: number | null,
      ratingCount?: number | null,
      averageRating?: number | null,
      feesback?: string | null,
      simphonyAuthClientId?: string | null,
      simphonyAuthUsername?: string | null,
      simphonyAuthPassword?: string | null,
      simphonyAuthBaseURL?: string | null,
      orderTypeDineIn?: number | null,
      orderTypeTakeAway?: number | null,
      orderTypeDelivery?: number | null,
      simphonyTakeAwayRvc?: number | null,
      simphonyDeliveryRvc?: number | null,
      exportInterval?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      foodicsToken?: string | null,
      enableTableFoodics?: string | null,
      image?: string | null,
      logo?: string | null,
      album?: Array< string | null > | null,
      type?: string | null,
      rating?: number | null,
      location?: string | null,
      description?: string | null,
      areas?: Array< string | null > | null,
      seatTypes?: Array< string | null > | null,
      needAssistance?: boolean | null,
      spaBooking?: boolean | null,
      showerBooking?: boolean | null,
      cigarMenu?: boolean | null,
      precedence?: number | null,
      isPinRequired?: boolean | null,
      viewOnly?: boolean | null,
      openTime?: string | null,
      closeTime?: string | null,
      varifyForAmount?: number | null,
      varifyFirstOrder?: boolean | null,
      varifyNewAddress?: boolean | null,
      sliderImages?: Array< string | null > | null,
      receiveWaiters?: Array< string | null > | null,
      WaiterNotificationOption?: string | null,
      applyLoyalty?: boolean | null,
      rewardPoints?: number | null,
      moneySpent?: number | null,
      afterVat?: boolean | null,
      afterDeliveryFee?: boolean | null,
      beforePromoCode?: boolean | null,
      pointsCalcWithPromoCode?: boolean | null,
      isBusy?: boolean | null,
      allowOnlineOrdering?: boolean | null,
      minOrderVal?: number | null,
      vatPercentage?: number | null,
      serviceChargePercentage?: number | null,
      addDeliveryToVat?: boolean | null,
      categoryTagIDs?: Array< string | null > | null,
      parentConceptID: string,
      containerColor?: string | null,
      avgOrderCompletionEstimate?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ConceptsByParentConceptIDAndPrecedenceQueryVariables = {
  parentConceptID: string,
  precedence?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConceptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ConceptsByParentConceptIDAndPrecedenceQuery = {
  conceptsByParentConceptIDAndPrecedence?:  {
    __typename: "ModelConceptConnection",
    items:  Array< {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      kiosks?: Array< string | null > | null,
      merchantID?: string | null,
      merchantUsername?: string | null,
      merchantPassword?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      paymentSharePercentage?: number | null,
      simphonyServerBaseURL?: string | null,
      simphonyShortOrgName?: string | null,
      simphonyLocRef?: string | null,
      simphonyToken?: string | null,
      revenueCenterID?: number | null,
      employeeID?: number | null,
      tenderID?: number | null,
      tenders?:  {
        __typename: "ModelTenderConnection",
        items:  Array< {
          __typename: "Tender",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptTendersId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderType?:  {
        __typename: "ModelOrderTypeConnection",
        items:  Array< {
          __typename: "OrderType",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptOrderTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      discountID?: number | null,
      useServiceCharge?: boolean | null,
      serviceChargeId?: number | null,
      specialRequestID?: number | null,
      addressID?: number | null,
      ratingCount?: number | null,
      averageRating?: number | null,
      feesback?: string | null,
      simphonyAuthClientId?: string | null,
      simphonyAuthUsername?: string | null,
      simphonyAuthPassword?: string | null,
      simphonyAuthBaseURL?: string | null,
      orderTypeDineIn?: number | null,
      orderTypeTakeAway?: number | null,
      orderTypeDelivery?: number | null,
      simphonyTakeAwayRvc?: number | null,
      simphonyDeliveryRvc?: number | null,
      exportInterval?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      foodicsToken?: string | null,
      enableTableFoodics?: string | null,
      image?: string | null,
      logo?: string | null,
      album?: Array< string | null > | null,
      type?: string | null,
      rating?: number | null,
      location?: string | null,
      description?: string | null,
      areas?: Array< string | null > | null,
      seatTypes?: Array< string | null > | null,
      needAssistance?: boolean | null,
      spaBooking?: boolean | null,
      showerBooking?: boolean | null,
      cigarMenu?: boolean | null,
      precedence?: number | null,
      isPinRequired?: boolean | null,
      viewOnly?: boolean | null,
      openTime?: string | null,
      closeTime?: string | null,
      varifyForAmount?: number | null,
      varifyFirstOrder?: boolean | null,
      varifyNewAddress?: boolean | null,
      sliderImages?: Array< string | null > | null,
      receiveWaiters?: Array< string | null > | null,
      WaiterNotificationOption?: string | null,
      applyLoyalty?: boolean | null,
      rewardPoints?: number | null,
      moneySpent?: number | null,
      afterVat?: boolean | null,
      afterDeliveryFee?: boolean | null,
      beforePromoCode?: boolean | null,
      pointsCalcWithPromoCode?: boolean | null,
      isBusy?: boolean | null,
      allowOnlineOrdering?: boolean | null,
      minOrderVal?: number | null,
      vatPercentage?: number | null,
      serviceChargePercentage?: number | null,
      addDeliveryToVat?: boolean | null,
      categoryTagIDs?: Array< string | null > | null,
      parentConceptID: string,
      containerColor?: string | null,
      avgOrderCompletionEstimate?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCategoryTagQueryVariables = {
  id: string,
};

export type GetCategoryTagQuery = {
  getCategoryTag?:  {
    __typename: "CategoryTag",
    id: string,
    parentConceptID: string,
    name: string,
    image?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCategoryTagsQueryVariables = {
  filter?: ModelCategoryTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategoryTagsQuery = {
  listCategoryTags?:  {
    __typename: "ModelCategoryTagConnection",
    items:  Array< {
      __typename: "CategoryTag",
      id: string,
      parentConceptID: string,
      name: string,
      image?: string | null,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCategoryTagsQueryVariables = {
  filter?: ModelCategoryTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCategoryTagsQuery = {
  syncCategoryTags?:  {
    __typename: "ModelCategoryTagConnection",
    items:  Array< {
      __typename: "CategoryTag",
      id: string,
      parentConceptID: string,
      name: string,
      image?: string | null,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CategoryTagsByParentConceptIDAndPrecedenceQueryVariables = {
  parentConceptID: string,
  precedence?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryTagFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoryTagsByParentConceptIDAndPrecedenceQuery = {
  categoryTagsByParentConceptIDAndPrecedence?:  {
    __typename: "ModelCategoryTagConnection",
    items:  Array< {
      __typename: "CategoryTag",
      id: string,
      parentConceptID: string,
      name: string,
      image?: string | null,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTenderQueryVariables = {
  id: string,
};

export type GetTenderQuery = {
  getTender?:  {
    __typename: "Tender",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptTendersId?: string | null,
  } | null,
};

export type ListTendersQueryVariables = {
  id?: string | null,
  filter?: ModelTenderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListTendersQuery = {
  listTenders?:  {
    __typename: "ModelTenderConnection",
    items:  Array< {
      __typename: "Tender",
      id: string,
      name: string,
      simphonyID?: string | null,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      conceptTendersId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTendersQueryVariables = {
  filter?: ModelTenderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTendersQuery = {
  syncTenders?:  {
    __typename: "ModelTenderConnection",
    items:  Array< {
      __typename: "Tender",
      id: string,
      name: string,
      simphonyID?: string | null,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      conceptTendersId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOrderTypeQueryVariables = {
  id: string,
};

export type GetOrderTypeQuery = {
  getOrderType?:  {
    __typename: "OrderType",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptOrderTypeId?: string | null,
  } | null,
};

export type ListOrderTypesQueryVariables = {
  id?: string | null,
  filter?: ModelOrderTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListOrderTypesQuery = {
  listOrderTypes?:  {
    __typename: "ModelOrderTypeConnection",
    items:  Array< {
      __typename: "OrderType",
      id: string,
      name: string,
      simphonyID?: string | null,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      conceptOrderTypeId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOrderTypesQueryVariables = {
  filter?: ModelOrderTypeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOrderTypesQuery = {
  syncOrderTypes?:  {
    __typename: "ModelOrderTypeConnection",
    items:  Array< {
      __typename: "OrderType",
      id: string,
      name: string,
      simphonyID?: string | null,
      precedence?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      conceptOrderTypeId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetParentConceptQueryVariables = {
  id: string,
};

export type GetParentConceptQuery = {
  getParentConcept?:  {
    __typename: "ParentConcept",
    id: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    image?: string | null,
    name?: string | null,
    logo?: string | null,
    type?: string | null,
    venues?:  {
      __typename: "ModelConceptConnection",
      items:  Array< {
        __typename: "Concept",
        id: string,
        accountID: string,
        name: string,
        kiosks?: Array< string | null > | null,
        merchantID?: string | null,
        merchantUsername?: string | null,
        merchantPassword?: string | null,
        merchantIntegrationNumber?: string | null,
        merchantAPIKey?: string | null,
        paymentSharePercentage?: number | null,
        simphonyServerBaseURL?: string | null,
        simphonyShortOrgName?: string | null,
        simphonyLocRef?: string | null,
        simphonyToken?: string | null,
        revenueCenterID?: number | null,
        employeeID?: number | null,
        tenderID?: number | null,
        tenders?:  {
          __typename: "ModelTenderConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderType?:  {
          __typename: "ModelOrderTypeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        discountID?: number | null,
        useServiceCharge?: boolean | null,
        serviceChargeId?: number | null,
        specialRequestID?: number | null,
        addressID?: number | null,
        ratingCount?: number | null,
        averageRating?: number | null,
        feesback?: string | null,
        simphonyAuthClientId?: string | null,
        simphonyAuthUsername?: string | null,
        simphonyAuthPassword?: string | null,
        simphonyAuthBaseURL?: string | null,
        orderTypeDineIn?: number | null,
        orderTypeTakeAway?: number | null,
        orderTypeDelivery?: number | null,
        simphonyTakeAwayRvc?: number | null,
        simphonyDeliveryRvc?: number | null,
        exportInterval?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        foodicsToken?: string | null,
        enableTableFoodics?: string | null,
        image?: string | null,
        logo?: string | null,
        album?: Array< string | null > | null,
        type?: string | null,
        rating?: number | null,
        location?: string | null,
        description?: string | null,
        areas?: Array< string | null > | null,
        seatTypes?: Array< string | null > | null,
        needAssistance?: boolean | null,
        spaBooking?: boolean | null,
        showerBooking?: boolean | null,
        cigarMenu?: boolean | null,
        precedence?: number | null,
        isPinRequired?: boolean | null,
        viewOnly?: boolean | null,
        openTime?: string | null,
        closeTime?: string | null,
        varifyForAmount?: number | null,
        varifyFirstOrder?: boolean | null,
        varifyNewAddress?: boolean | null,
        sliderImages?: Array< string | null > | null,
        receiveWaiters?: Array< string | null > | null,
        WaiterNotificationOption?: string | null,
        applyLoyalty?: boolean | null,
        rewardPoints?: number | null,
        moneySpent?: number | null,
        afterVat?: boolean | null,
        afterDeliveryFee?: boolean | null,
        beforePromoCode?: boolean | null,
        pointsCalcWithPromoCode?: boolean | null,
        isBusy?: boolean | null,
        allowOnlineOrdering?: boolean | null,
        minOrderVal?: number | null,
        vatPercentage?: number | null,
        serviceChargePercentage?: number | null,
        addDeliveryToVat?: boolean | null,
        categoryTagIDs?: Array< string | null > | null,
        parentConceptID: string,
        containerColor?: string | null,
        avgOrderCompletionEstimate?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    index?: number | null,
    categoryTags?:  {
      __typename: "ModelCategoryTagConnection",
      items:  Array< {
        __typename: "CategoryTag",
        id: string,
        parentConceptID: string,
        name: string,
        image?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListParentConceptsQueryVariables = {
  filter?: ModelParentConceptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListParentConceptsQuery = {
  listParentConcepts?:  {
    __typename: "ModelParentConceptConnection",
    items:  Array< {
      __typename: "ParentConcept",
      id: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      image?: string | null,
      name?: string | null,
      logo?: string | null,
      type?: string | null,
      venues?:  {
        __typename: "ModelConceptConnection",
        items:  Array< {
          __typename: "Concept",
          id: string,
          accountID: string,
          name: string,
          kiosks?: Array< string | null > | null,
          merchantID?: string | null,
          merchantUsername?: string | null,
          merchantPassword?: string | null,
          merchantIntegrationNumber?: string | null,
          merchantAPIKey?: string | null,
          paymentSharePercentage?: number | null,
          simphonyServerBaseURL?: string | null,
          simphonyShortOrgName?: string | null,
          simphonyLocRef?: string | null,
          simphonyToken?: string | null,
          revenueCenterID?: number | null,
          employeeID?: number | null,
          tenderID?: number | null,
          discountID?: number | null,
          useServiceCharge?: boolean | null,
          serviceChargeId?: number | null,
          specialRequestID?: number | null,
          addressID?: number | null,
          ratingCount?: number | null,
          averageRating?: number | null,
          feesback?: string | null,
          simphonyAuthClientId?: string | null,
          simphonyAuthUsername?: string | null,
          simphonyAuthPassword?: string | null,
          simphonyAuthBaseURL?: string | null,
          orderTypeDineIn?: number | null,
          orderTypeTakeAway?: number | null,
          orderTypeDelivery?: number | null,
          simphonyTakeAwayRvc?: number | null,
          simphonyDeliveryRvc?: number | null,
          exportInterval?: number | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          foodicsToken?: string | null,
          enableTableFoodics?: string | null,
          image?: string | null,
          logo?: string | null,
          album?: Array< string | null > | null,
          type?: string | null,
          rating?: number | null,
          location?: string | null,
          description?: string | null,
          areas?: Array< string | null > | null,
          seatTypes?: Array< string | null > | null,
          needAssistance?: boolean | null,
          spaBooking?: boolean | null,
          showerBooking?: boolean | null,
          cigarMenu?: boolean | null,
          precedence?: number | null,
          isPinRequired?: boolean | null,
          viewOnly?: boolean | null,
          openTime?: string | null,
          closeTime?: string | null,
          varifyForAmount?: number | null,
          varifyFirstOrder?: boolean | null,
          varifyNewAddress?: boolean | null,
          sliderImages?: Array< string | null > | null,
          receiveWaiters?: Array< string | null > | null,
          WaiterNotificationOption?: string | null,
          applyLoyalty?: boolean | null,
          rewardPoints?: number | null,
          moneySpent?: number | null,
          afterVat?: boolean | null,
          afterDeliveryFee?: boolean | null,
          beforePromoCode?: boolean | null,
          pointsCalcWithPromoCode?: boolean | null,
          isBusy?: boolean | null,
          allowOnlineOrdering?: boolean | null,
          minOrderVal?: number | null,
          vatPercentage?: number | null,
          serviceChargePercentage?: number | null,
          addDeliveryToVat?: boolean | null,
          categoryTagIDs?: Array< string | null > | null,
          parentConceptID: string,
          containerColor?: string | null,
          avgOrderCompletionEstimate?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      index?: number | null,
      categoryTags?:  {
        __typename: "ModelCategoryTagConnection",
        items:  Array< {
          __typename: "CategoryTag",
          id: string,
          parentConceptID: string,
          name: string,
          image?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncParentConceptsQueryVariables = {
  filter?: ModelParentConceptFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncParentConceptsQuery = {
  syncParentConcepts?:  {
    __typename: "ModelParentConceptConnection",
    items:  Array< {
      __typename: "ParentConcept",
      id: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      image?: string | null,
      name?: string | null,
      logo?: string | null,
      type?: string | null,
      venues?:  {
        __typename: "ModelConceptConnection",
        items:  Array< {
          __typename: "Concept",
          id: string,
          accountID: string,
          name: string,
          kiosks?: Array< string | null > | null,
          merchantID?: string | null,
          merchantUsername?: string | null,
          merchantPassword?: string | null,
          merchantIntegrationNumber?: string | null,
          merchantAPIKey?: string | null,
          paymentSharePercentage?: number | null,
          simphonyServerBaseURL?: string | null,
          simphonyShortOrgName?: string | null,
          simphonyLocRef?: string | null,
          simphonyToken?: string | null,
          revenueCenterID?: number | null,
          employeeID?: number | null,
          tenderID?: number | null,
          discountID?: number | null,
          useServiceCharge?: boolean | null,
          serviceChargeId?: number | null,
          specialRequestID?: number | null,
          addressID?: number | null,
          ratingCount?: number | null,
          averageRating?: number | null,
          feesback?: string | null,
          simphonyAuthClientId?: string | null,
          simphonyAuthUsername?: string | null,
          simphonyAuthPassword?: string | null,
          simphonyAuthBaseURL?: string | null,
          orderTypeDineIn?: number | null,
          orderTypeTakeAway?: number | null,
          orderTypeDelivery?: number | null,
          simphonyTakeAwayRvc?: number | null,
          simphonyDeliveryRvc?: number | null,
          exportInterval?: number | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          foodicsToken?: string | null,
          enableTableFoodics?: string | null,
          image?: string | null,
          logo?: string | null,
          album?: Array< string | null > | null,
          type?: string | null,
          rating?: number | null,
          location?: string | null,
          description?: string | null,
          areas?: Array< string | null > | null,
          seatTypes?: Array< string | null > | null,
          needAssistance?: boolean | null,
          spaBooking?: boolean | null,
          showerBooking?: boolean | null,
          cigarMenu?: boolean | null,
          precedence?: number | null,
          isPinRequired?: boolean | null,
          viewOnly?: boolean | null,
          openTime?: string | null,
          closeTime?: string | null,
          varifyForAmount?: number | null,
          varifyFirstOrder?: boolean | null,
          varifyNewAddress?: boolean | null,
          sliderImages?: Array< string | null > | null,
          receiveWaiters?: Array< string | null > | null,
          WaiterNotificationOption?: string | null,
          applyLoyalty?: boolean | null,
          rewardPoints?: number | null,
          moneySpent?: number | null,
          afterVat?: boolean | null,
          afterDeliveryFee?: boolean | null,
          beforePromoCode?: boolean | null,
          pointsCalcWithPromoCode?: boolean | null,
          isBusy?: boolean | null,
          allowOnlineOrdering?: boolean | null,
          minOrderVal?: number | null,
          vatPercentage?: number | null,
          serviceChargePercentage?: number | null,
          addDeliveryToVat?: boolean | null,
          categoryTagIDs?: Array< string | null > | null,
          parentConceptID: string,
          containerColor?: string | null,
          avgOrderCompletionEstimate?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      index?: number | null,
      categoryTags?:  {
        __typename: "ModelCategoryTagConnection",
        items:  Array< {
          __typename: "CategoryTag",
          id: string,
          parentConceptID: string,
          name: string,
          image?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTimelineQueryVariables = {
  id: string,
};

export type GetTimelineQuery = {
  getTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    transactionId?: string | null,
    customerId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTimelinesQueryVariables = {
  filter?: ModelTimelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTimelinesQuery = {
  listTimelines?:  {
    __typename: "ModelTimelineConnection",
    items:  Array< {
      __typename: "Timeline",
      id: string,
      actionName: string,
      oldStatus: string,
      newStatus: string,
      bookingId?: string | null,
      transactionId?: string | null,
      customerId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTimelinesQueryVariables = {
  filter?: ModelTimelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTimelinesQuery = {
  syncTimelines?:  {
    __typename: "ModelTimelineConnection",
    items:  Array< {
      __typename: "Timeline",
      id: string,
      actionName: string,
      oldStatus: string,
      newStatus: string,
      bookingId?: string | null,
      transactionId?: string | null,
      customerId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type TimelineByTransactionIDQueryVariables = {
  transactionId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTimelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TimelineByTransactionIDQuery = {
  timelineByTransactionID?:  {
    __typename: "ModelTimelineConnection",
    items:  Array< {
      __typename: "Timeline",
      id: string,
      actionName: string,
      oldStatus: string,
      newStatus: string,
      bookingId?: string | null,
      transactionId?: string | null,
      customerId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type TimelineByCustomerIDQueryVariables = {
  customerId: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTimelineFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TimelineByCustomerIDQuery = {
  timelineByCustomerID?:  {
    __typename: "ModelTimelineConnection",
    items:  Array< {
      __typename: "Timeline",
      id: string,
      actionName: string,
      oldStatus: string,
      newStatus: string,
      bookingId?: string | null,
      transactionId?: string | null,
      customerId?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCallQueryVariables = {
  id: string,
};

export type GetCallQuery = {
  getCall?:  {
    __typename: "Call",
    id: string,
    phone_number?: string | null,
    customer?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      autoGeneratedPassword: boolean,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    endedAt?: string | null,
    duration?: string | null,
    date: string,
    employeeId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    callCustomerId?: string | null,
  } | null,
};

export type ListCallsQueryVariables = {
  filter?: ModelCallFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCallsQuery = {
  listCalls?:  {
    __typename: "ModelCallConnection",
    items:  Array< {
      __typename: "Call",
      id: string,
      phone_number?: string | null,
      customer?:  {
        __typename: "User",
        id: string,
        accountID: string,
        username?: string | null,
        preferred_username?: string | null,
        email?: string | null,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        pictureCrop?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        facebook?: string | null,
        instagram?: string | null,
        group?: string | null,
        phones?: Array< string | null > | null,
        flags?: Array< string | null > | null,
        flagsName?: Array< string | null > | null,
        interests?: Array< string | null > | null,
        interestsName?: Array< string | null > | null,
        status?: string | null,
        stats?: Array< string | null > | null,
        spending?: number | null,
        prevOrderDate?: string | null,
        lastOrderDate?: string | null,
        promocodes?:  Array< {
          __typename: "PromocodeItem",
          name: string,
          promocodeId: string,
          canBeUsed: number,
          discountVal: number,
          discountLimit?: number | null,
          isDisplayed?: boolean | null,
        } | null > | null,
        mostOrderedItems?: string | null,
        points?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        cognitoUsername?: string | null,
        cognitoSub?: string | null,
        autoGeneratedPassword: boolean,
        updated?: string | null,
        sub?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      endedAt?: string | null,
      duration?: string | null,
      date: string,
      employeeId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      callCustomerId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCallsQueryVariables = {
  filter?: ModelCallFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCallsQuery = {
  syncCalls?:  {
    __typename: "ModelCallConnection",
    items:  Array< {
      __typename: "Call",
      id: string,
      phone_number?: string | null,
      customer?:  {
        __typename: "User",
        id: string,
        accountID: string,
        username?: string | null,
        preferred_username?: string | null,
        email?: string | null,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        pictureCrop?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        facebook?: string | null,
        instagram?: string | null,
        group?: string | null,
        phones?: Array< string | null > | null,
        flags?: Array< string | null > | null,
        flagsName?: Array< string | null > | null,
        interests?: Array< string | null > | null,
        interestsName?: Array< string | null > | null,
        status?: string | null,
        stats?: Array< string | null > | null,
        spending?: number | null,
        prevOrderDate?: string | null,
        lastOrderDate?: string | null,
        promocodes?:  Array< {
          __typename: "PromocodeItem",
          name: string,
          promocodeId: string,
          canBeUsed: number,
          discountVal: number,
          discountLimit?: number | null,
          isDisplayed?: boolean | null,
        } | null > | null,
        mostOrderedItems?: string | null,
        points?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        cognitoUsername?: string | null,
        cognitoSub?: string | null,
        autoGeneratedPassword: boolean,
        updated?: string | null,
        sub?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      endedAt?: string | null,
      duration?: string | null,
      date: string,
      employeeId: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
      callCustomerId?: string | null,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAdminRoleQueryVariables = {
  id: string,
};

export type GetAdminRoleQuery = {
  getAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAdminRolesQueryVariables = {
  filter?: ModelAdminRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdminRolesQuery = {
  listAdminRoles?:  {
    __typename: "ModelAdminRoleConnection",
    items:  Array< {
      __typename: "AdminRole",
      id: string,
      name?: string | null,
      description?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAdminRolesQueryVariables = {
  filter?: ModelAdminRoleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAdminRolesQuery = {
  syncAdminRoles?:  {
    __typename: "ModelAdminRoleConnection",
    items:  Array< {
      __typename: "AdminRole",
      id: string,
      name?: string | null,
      description?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAdminGroupQueryVariables = {
  id: string,
};

export type GetAdminGroupQuery = {
  getAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAdminGroupsQueryVariables = {
  filter?: ModelAdminGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdminGroupsQuery = {
  listAdminGroups?:  {
    __typename: "ModelAdminGroupConnection",
    items:  Array< {
      __typename: "AdminGroup",
      id: string,
      name?: string | null,
      description?: string | null,
      roles?: Array< string | null > | null,
      users?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAdminGroupsQueryVariables = {
  filter?: ModelAdminGroupFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAdminGroupsQuery = {
  syncAdminGroups?:  {
    __typename: "ModelAdminGroupConnection",
    items:  Array< {
      __typename: "AdminGroup",
      id: string,
      name?: string | null,
      description?: string | null,
      roles?: Array< string | null > | null,
      users?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserConceptsQueryVariables = {
  id: string,
};

export type GetUserConceptsQuery = {
  getUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    parentConcepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserConceptsQueryVariables = {
  filter?: ModelUserConceptsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserConceptsQuery = {
  listUserConcepts?:  {
    __typename: "ModelUserConceptsConnection",
    items:  Array< {
      __typename: "UserConcepts",
      id: string,
      defaultConcept?: string | null,
      concepts?: Array< string | null > | null,
      parentConcepts?: Array< string | null > | null,
      conceptsRoles?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserConceptsQueryVariables = {
  filter?: ModelUserConceptsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserConceptsQuery = {
  syncUserConcepts?:  {
    __typename: "ModelUserConceptsConnection",
    items:  Array< {
      __typename: "UserConcepts",
      id: string,
      defaultConcept?: string | null,
      concepts?: Array< string | null > | null,
      parentConcepts?: Array< string | null > | null,
      conceptsRoles?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetTransactionQueryVariables = {
  id: string,
};

export type GetTransactionQuery = {
  getTransaction?:  {
    __typename: "Transaction",
    id: string,
    transactionID: string,
    date: string,
    guestName: string,
    guestPhone: string,
    guestID: string,
    guestGroup: string,
    guestsNames?: Array< string | null > | null,
    bookingID?: string | null,
    bookingDate?: string | null,
    amount_cents: string,
    status: boolean,
    failureReason?: string | null,
    type: string,
    timeSlots?: Array< string | null > | null,
    tables?: Array< string | null > | null,
    conceptID: string,
    currency: string,
    refund: boolean,
    reference_orderID?: string | null,
    ownerID: string,
    refunded_amount_cents: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListTransactionsQueryVariables = {
  filter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTransactionsQuery = {
  listTransactions?:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      transactionID: string,
      date: string,
      guestName: string,
      guestPhone: string,
      guestID: string,
      guestGroup: string,
      guestsNames?: Array< string | null > | null,
      bookingID?: string | null,
      bookingDate?: string | null,
      amount_cents: string,
      status: boolean,
      failureReason?: string | null,
      type: string,
      timeSlots?: Array< string | null > | null,
      tables?: Array< string | null > | null,
      conceptID: string,
      currency: string,
      refund: boolean,
      reference_orderID?: string | null,
      ownerID: string,
      refunded_amount_cents: string,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncTransactionsQueryVariables = {
  filter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncTransactionsQuery = {
  syncTransactions?:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      transactionID: string,
      date: string,
      guestName: string,
      guestPhone: string,
      guestID: string,
      guestGroup: string,
      guestsNames?: Array< string | null > | null,
      bookingID?: string | null,
      bookingDate?: string | null,
      amount_cents: string,
      status: boolean,
      failureReason?: string | null,
      type: string,
      timeSlots?: Array< string | null > | null,
      tables?: Array< string | null > | null,
      conceptID: string,
      currency: string,
      refund: boolean,
      reference_orderID?: string | null,
      ownerID: string,
      refunded_amount_cents: string,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type TransactionsByDeletedQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTransactionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TransactionsByDeletedQuery = {
  transactionsByDeleted?:  {
    __typename: "ModelTransactionConnection",
    items:  Array< {
      __typename: "Transaction",
      id: string,
      transactionID: string,
      date: string,
      guestName: string,
      guestPhone: string,
      guestID: string,
      guestGroup: string,
      guestsNames?: Array< string | null > | null,
      bookingID?: string | null,
      bookingDate?: string | null,
      amount_cents: string,
      status: boolean,
      failureReason?: string | null,
      type: string,
      timeSlots?: Array< string | null > | null,
      tables?: Array< string | null > | null,
      conceptID: string,
      currency: string,
      refund: boolean,
      reference_orderID?: string | null,
      ownerID: string,
      refunded_amount_cents: string,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPaymentStatQueryVariables = {
  id: string,
};

export type GetPaymentStatQuery = {
  getPaymentStat?:  {
    __typename: "PaymentStat",
    id: string,
    accountID?: string | null,
    conceptID: string,
    year: number,
    month: number,
    monthName: string,
    earning: number,
    refund: number,
    earningTrxCount: number,
    refundTrxCount: number,
    deleted?: string | null,
    createdAt: string,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPaymentStatsQueryVariables = {
  filter?: ModelPaymentStatFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPaymentStatsQuery = {
  listPaymentStats?:  {
    __typename: "ModelPaymentStatConnection",
    items:  Array< {
      __typename: "PaymentStat",
      id: string,
      accountID?: string | null,
      conceptID: string,
      year: number,
      month: number,
      monthName: string,
      earning: number,
      refund: number,
      earningTrxCount: number,
      refundTrxCount: number,
      deleted?: string | null,
      createdAt: string,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPaymentStatsQueryVariables = {
  filter?: ModelPaymentStatFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPaymentStatsQuery = {
  syncPaymentStats?:  {
    __typename: "ModelPaymentStatConnection",
    items:  Array< {
      __typename: "PaymentStat",
      id: string,
      accountID?: string | null,
      conceptID: string,
      year: number,
      month: number,
      monthName: string,
      earning: number,
      refund: number,
      earningTrxCount: number,
      refundTrxCount: number,
      deleted?: string | null,
      createdAt: string,
      createdByID?: string | null,
      createdByName?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    id: string,
    conceptID: string,
    parentConceptID: string,
    preparationAreaID?: string | null,
    subCategory?: string | null,
    name: string,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    darkImage?: string | null,
    menuItems?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        conceptID: string,
        kioskID?: string | null,
        name: string,
        description?: string | null,
        image?: string | null,
        precedence?: string | null,
        categoryID: string,
        categoryName?: string | null,
        outOfStock?: boolean | null,
        requiredPoints?: number | null,
        symphonyID?: string | null,
        comboItemID?: string | null,
        ratings?: Array< string | null > | null,
        prices?: Array< string | null > | null,
        choiceGroups?: Array< string | null > | null,
        pricesItems?:  Array< {
          __typename: "Price",
          id: string,
          conceptID: string,
          parentConceptID: string,
          name: string,
          multiLanguages?: string | null,
          price: number,
          symphonyID: string,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null > | null,
        choiceGroupsItems?:  Array< {
          __typename: "ChoiceGroups",
          id: string,
          conceptID: string,
          parentConceptID: string,
          name: string,
          required?: boolean | null,
          maxNumberOfChoices?: number | null,
          minNumberOfChoices?: number | null,
          precedence?: string | null,
          type?: string | null,
          choices?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          multiLanguages?: string | null,
          simphonyID?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null > | null,
        choiceGroupsRules?:  Array< {
          __typename: "ChoiceGroupRules",
          id: string,
          minNumberOfChoices?: number | null,
          maxNumberOfChoices?: number | null,
        } | null > | null,
        ExtraChoiceGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        hasLimitedQuantity?: boolean | null,
        quantity?: number | null,
        autoRestock?: boolean | null,
        restockQuantity?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        quantityOrdered?: number | null,
        performancePerDay?: Array< string | null > | null,
        multiLanguages?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    precedence: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    symphonyID?: string | null,
    isHidden?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCategoriesQueryVariables = {
  id?: string | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListCategoriesQuery = {
  listCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      conceptID: string,
      parentConceptID: string,
      preparationAreaID?: string | null,
      subCategory?: string | null,
      name: string,
      guestView?: boolean | null,
      guestOrder?: boolean | null,
      staffOrder?: boolean | null,
      image?: string | null,
      darkImage?: string | null,
      menuItems?:  {
        __typename: "ModelMenuItemConnection",
        items:  Array< {
          __typename: "MenuItem",
          id: string,
          conceptID: string,
          kioskID?: string | null,
          name: string,
          description?: string | null,
          image?: string | null,
          precedence?: string | null,
          categoryID: string,
          categoryName?: string | null,
          outOfStock?: boolean | null,
          requiredPoints?: number | null,
          symphonyID?: string | null,
          comboItemID?: string | null,
          ratings?: Array< string | null > | null,
          prices?: Array< string | null > | null,
          choiceGroups?: Array< string | null > | null,
          ExtraChoiceGroups?: Array< string | null > | null,
          enabled?: boolean | null,
          hasLimitedQuantity?: boolean | null,
          quantity?: number | null,
          autoRestock?: boolean | null,
          restockQuantity?: number | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          quantityOrdered?: number | null,
          performancePerDay?: Array< string | null > | null,
          multiLanguages?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      precedence: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      symphonyID?: string | null,
      isHidden?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCategoriesQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCategoriesQuery = {
  syncCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      conceptID: string,
      parentConceptID: string,
      preparationAreaID?: string | null,
      subCategory?: string | null,
      name: string,
      guestView?: boolean | null,
      guestOrder?: boolean | null,
      staffOrder?: boolean | null,
      image?: string | null,
      darkImage?: string | null,
      menuItems?:  {
        __typename: "ModelMenuItemConnection",
        items:  Array< {
          __typename: "MenuItem",
          id: string,
          conceptID: string,
          kioskID?: string | null,
          name: string,
          description?: string | null,
          image?: string | null,
          precedence?: string | null,
          categoryID: string,
          categoryName?: string | null,
          outOfStock?: boolean | null,
          requiredPoints?: number | null,
          symphonyID?: string | null,
          comboItemID?: string | null,
          ratings?: Array< string | null > | null,
          prices?: Array< string | null > | null,
          choiceGroups?: Array< string | null > | null,
          ExtraChoiceGroups?: Array< string | null > | null,
          enabled?: boolean | null,
          hasLimitedQuantity?: boolean | null,
          quantity?: number | null,
          autoRestock?: boolean | null,
          restockQuantity?: number | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          quantityOrdered?: number | null,
          performancePerDay?: Array< string | null > | null,
          multiLanguages?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      precedence: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      symphonyID?: string | null,
      isHidden?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CategoryByConceptIDQueryVariables = {
  conceptID: string,
  precedence?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoryByConceptIDQuery = {
  categoryByConceptID?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      conceptID: string,
      parentConceptID: string,
      preparationAreaID?: string | null,
      subCategory?: string | null,
      name: string,
      guestView?: boolean | null,
      guestOrder?: boolean | null,
      staffOrder?: boolean | null,
      image?: string | null,
      darkImage?: string | null,
      menuItems?:  {
        __typename: "ModelMenuItemConnection",
        items:  Array< {
          __typename: "MenuItem",
          id: string,
          conceptID: string,
          kioskID?: string | null,
          name: string,
          description?: string | null,
          image?: string | null,
          precedence?: string | null,
          categoryID: string,
          categoryName?: string | null,
          outOfStock?: boolean | null,
          requiredPoints?: number | null,
          symphonyID?: string | null,
          comboItemID?: string | null,
          ratings?: Array< string | null > | null,
          prices?: Array< string | null > | null,
          choiceGroups?: Array< string | null > | null,
          ExtraChoiceGroups?: Array< string | null > | null,
          enabled?: boolean | null,
          hasLimitedQuantity?: boolean | null,
          quantity?: number | null,
          autoRestock?: boolean | null,
          restockQuantity?: number | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          quantityOrdered?: number | null,
          performancePerDay?: Array< string | null > | null,
          multiLanguages?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      precedence: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      symphonyID?: string | null,
      isHidden?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CategoryByParentConceptIDQueryVariables = {
  parentConceptID: string,
  precedence?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoryByParentConceptIDQuery = {
  categoryByParentConceptID?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      conceptID: string,
      parentConceptID: string,
      preparationAreaID?: string | null,
      subCategory?: string | null,
      name: string,
      guestView?: boolean | null,
      guestOrder?: boolean | null,
      staffOrder?: boolean | null,
      image?: string | null,
      darkImage?: string | null,
      menuItems?:  {
        __typename: "ModelMenuItemConnection",
        items:  Array< {
          __typename: "MenuItem",
          id: string,
          conceptID: string,
          kioskID?: string | null,
          name: string,
          description?: string | null,
          image?: string | null,
          precedence?: string | null,
          categoryID: string,
          categoryName?: string | null,
          outOfStock?: boolean | null,
          requiredPoints?: number | null,
          symphonyID?: string | null,
          comboItemID?: string | null,
          ratings?: Array< string | null > | null,
          prices?: Array< string | null > | null,
          choiceGroups?: Array< string | null > | null,
          ExtraChoiceGroups?: Array< string | null > | null,
          enabled?: boolean | null,
          hasLimitedQuantity?: boolean | null,
          quantity?: number | null,
          autoRestock?: boolean | null,
          restockQuantity?: number | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          quantityOrdered?: number | null,
          performancePerDay?: Array< string | null > | null,
          multiLanguages?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      precedence: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      symphonyID?: string | null,
      isHidden?: boolean | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMenuItemQueryVariables = {
  id: string,
};

export type GetMenuItemQuery = {
  getMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    conceptID: string,
    kioskID?: string | null,
    name: string,
    description?: string | null,
    image?: string | null,
    precedence?: string | null,
    categoryID: string,
    categoryName?: string | null,
    outOfStock?: boolean | null,
    requiredPoints?: number | null,
    symphonyID?: string | null,
    comboItemID?: string | null,
    ratings?: Array< string | null > | null,
    prices?: Array< string | null > | null,
    choiceGroups?: Array< string | null > | null,
    pricesItems?:  Array< {
      __typename: "Price",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      multiLanguages?: string | null,
      price: number,
      symphonyID: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null > | null,
    choiceGroupsItems?:  Array< {
      __typename: "ChoiceGroups",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      required?: boolean | null,
      maxNumberOfChoices?: number | null,
      minNumberOfChoices?: number | null,
      precedence?: string | null,
      type?: string | null,
      choices?: Array< string | null > | null,
      choiceItems?:  {
        __typename: "ModelChoiceConnection",
        items:  Array< {
          __typename: "Choice",
          id: string,
          conceptID: string,
          name: string,
          price: number,
          precedence?: string | null,
          choiceGroupID: string,
          choiceGroupName?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          symphonyID?: string | null,
          multiLanguages?: string | null,
          definitionNumber?: number | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      simphonyID?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null > | null,
    choiceGroupsRules?:  Array< {
      __typename: "ChoiceGroupRules",
      id: string,
      minNumberOfChoices?: number | null,
      maxNumberOfChoices?: number | null,
    } | null > | null,
    ExtraChoiceGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    hasLimitedQuantity?: boolean | null,
    quantity?: number | null,
    autoRestock?: boolean | null,
    restockQuantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    quantityOrdered?: number | null,
    performancePerDay?: Array< string | null > | null,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMenuItemsQueryVariables = {
  id?: string | null,
  filter?: ModelMenuItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListMenuItemsQuery = {
  listMenuItems?:  {
    __typename: "ModelMenuItemConnection",
    items:  Array< {
      __typename: "MenuItem",
      id: string,
      conceptID: string,
      kioskID?: string | null,
      name: string,
      description?: string | null,
      image?: string | null,
      precedence?: string | null,
      categoryID: string,
      categoryName?: string | null,
      outOfStock?: boolean | null,
      requiredPoints?: number | null,
      symphonyID?: string | null,
      comboItemID?: string | null,
      ratings?: Array< string | null > | null,
      prices?: Array< string | null > | null,
      choiceGroups?: Array< string | null > | null,
      pricesItems?:  Array< {
        __typename: "Price",
        id: string,
        conceptID: string,
        parentConceptID: string,
        name: string,
        multiLanguages?: string | null,
        price: number,
        symphonyID: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null > | null,
      choiceGroupsItems?:  Array< {
        __typename: "ChoiceGroups",
        id: string,
        conceptID: string,
        parentConceptID: string,
        name: string,
        required?: boolean | null,
        maxNumberOfChoices?: number | null,
        minNumberOfChoices?: number | null,
        precedence?: string | null,
        type?: string | null,
        choices?: Array< string | null > | null,
        choiceItems?:  {
          __typename: "ModelChoiceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        multiLanguages?: string | null,
        simphonyID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null > | null,
      choiceGroupsRules?:  Array< {
        __typename: "ChoiceGroupRules",
        id: string,
        minNumberOfChoices?: number | null,
        maxNumberOfChoices?: number | null,
      } | null > | null,
      ExtraChoiceGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      hasLimitedQuantity?: boolean | null,
      quantity?: number | null,
      autoRestock?: boolean | null,
      restockQuantity?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      quantityOrdered?: number | null,
      performancePerDay?: Array< string | null > | null,
      multiLanguages?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMenuItemsQueryVariables = {
  filter?: ModelMenuItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMenuItemsQuery = {
  syncMenuItems?:  {
    __typename: "ModelMenuItemConnection",
    items:  Array< {
      __typename: "MenuItem",
      id: string,
      conceptID: string,
      kioskID?: string | null,
      name: string,
      description?: string | null,
      image?: string | null,
      precedence?: string | null,
      categoryID: string,
      categoryName?: string | null,
      outOfStock?: boolean | null,
      requiredPoints?: number | null,
      symphonyID?: string | null,
      comboItemID?: string | null,
      ratings?: Array< string | null > | null,
      prices?: Array< string | null > | null,
      choiceGroups?: Array< string | null > | null,
      pricesItems?:  Array< {
        __typename: "Price",
        id: string,
        conceptID: string,
        parentConceptID: string,
        name: string,
        multiLanguages?: string | null,
        price: number,
        symphonyID: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null > | null,
      choiceGroupsItems?:  Array< {
        __typename: "ChoiceGroups",
        id: string,
        conceptID: string,
        parentConceptID: string,
        name: string,
        required?: boolean | null,
        maxNumberOfChoices?: number | null,
        minNumberOfChoices?: number | null,
        precedence?: string | null,
        type?: string | null,
        choices?: Array< string | null > | null,
        choiceItems?:  {
          __typename: "ModelChoiceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        multiLanguages?: string | null,
        simphonyID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null > | null,
      choiceGroupsRules?:  Array< {
        __typename: "ChoiceGroupRules",
        id: string,
        minNumberOfChoices?: number | null,
        maxNumberOfChoices?: number | null,
      } | null > | null,
      ExtraChoiceGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      hasLimitedQuantity?: boolean | null,
      quantity?: number | null,
      autoRestock?: boolean | null,
      restockQuantity?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      quantityOrdered?: number | null,
      performancePerDay?: Array< string | null > | null,
      multiLanguages?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MenuItemByConceptIDQueryVariables = {
  conceptID: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMenuItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MenuItemByConceptIDQuery = {
  menuItemByConceptID?:  {
    __typename: "ModelMenuItemConnection",
    items:  Array< {
      __typename: "MenuItem",
      id: string,
      conceptID: string,
      kioskID?: string | null,
      name: string,
      description?: string | null,
      image?: string | null,
      precedence?: string | null,
      categoryID: string,
      categoryName?: string | null,
      outOfStock?: boolean | null,
      requiredPoints?: number | null,
      symphonyID?: string | null,
      comboItemID?: string | null,
      ratings?: Array< string | null > | null,
      prices?: Array< string | null > | null,
      choiceGroups?: Array< string | null > | null,
      pricesItems?:  Array< {
        __typename: "Price",
        id: string,
        conceptID: string,
        parentConceptID: string,
        name: string,
        multiLanguages?: string | null,
        price: number,
        symphonyID: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null > | null,
      choiceGroupsItems?:  Array< {
        __typename: "ChoiceGroups",
        id: string,
        conceptID: string,
        parentConceptID: string,
        name: string,
        required?: boolean | null,
        maxNumberOfChoices?: number | null,
        minNumberOfChoices?: number | null,
        precedence?: string | null,
        type?: string | null,
        choices?: Array< string | null > | null,
        choiceItems?:  {
          __typename: "ModelChoiceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        multiLanguages?: string | null,
        simphonyID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null > | null,
      choiceGroupsRules?:  Array< {
        __typename: "ChoiceGroupRules",
        id: string,
        minNumberOfChoices?: number | null,
        maxNumberOfChoices?: number | null,
      } | null > | null,
      ExtraChoiceGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      hasLimitedQuantity?: boolean | null,
      quantity?: number | null,
      autoRestock?: boolean | null,
      restockQuantity?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      quantityOrdered?: number | null,
      performancePerDay?: Array< string | null > | null,
      multiLanguages?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MenuItemsByCategoryIDAndPrecedenceQueryVariables = {
  categoryID: string,
  precedence?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMenuItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MenuItemsByCategoryIDAndPrecedenceQuery = {
  menuItemsByCategoryIDAndPrecedence?:  {
    __typename: "ModelMenuItemConnection",
    items:  Array< {
      __typename: "MenuItem",
      id: string,
      conceptID: string,
      kioskID?: string | null,
      name: string,
      description?: string | null,
      image?: string | null,
      precedence?: string | null,
      categoryID: string,
      categoryName?: string | null,
      outOfStock?: boolean | null,
      requiredPoints?: number | null,
      symphonyID?: string | null,
      comboItemID?: string | null,
      ratings?: Array< string | null > | null,
      prices?: Array< string | null > | null,
      choiceGroups?: Array< string | null > | null,
      pricesItems?:  Array< {
        __typename: "Price",
        id: string,
        conceptID: string,
        parentConceptID: string,
        name: string,
        multiLanguages?: string | null,
        price: number,
        symphonyID: string,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null > | null,
      choiceGroupsItems?:  Array< {
        __typename: "ChoiceGroups",
        id: string,
        conceptID: string,
        parentConceptID: string,
        name: string,
        required?: boolean | null,
        maxNumberOfChoices?: number | null,
        minNumberOfChoices?: number | null,
        precedence?: string | null,
        type?: string | null,
        choices?: Array< string | null > | null,
        choiceItems?:  {
          __typename: "ModelChoiceConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        multiLanguages?: string | null,
        simphonyID?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null > | null,
      choiceGroupsRules?:  Array< {
        __typename: "ChoiceGroupRules",
        id: string,
        minNumberOfChoices?: number | null,
        maxNumberOfChoices?: number | null,
      } | null > | null,
      ExtraChoiceGroups?: Array< string | null > | null,
      enabled?: boolean | null,
      hasLimitedQuantity?: boolean | null,
      quantity?: number | null,
      autoRestock?: boolean | null,
      restockQuantity?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      quantityOrdered?: number | null,
      performancePerDay?: Array< string | null > | null,
      multiLanguages?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPriceQueryVariables = {
  id: string,
};

export type GetPriceQuery = {
  getPrice?:  {
    __typename: "Price",
    id: string,
    conceptID: string,
    parentConceptID: string,
    name: string,
    multiLanguages?: string | null,
    price: number,
    symphonyID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPricesQueryVariables = {
  filter?: ModelPriceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPricesQuery = {
  listPrices?:  {
    __typename: "ModelPriceConnection",
    items:  Array< {
      __typename: "Price",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      multiLanguages?: string | null,
      price: number,
      symphonyID: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPricesQueryVariables = {
  filter?: ModelPriceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPricesQuery = {
  syncPrices?:  {
    __typename: "ModelPriceConnection",
    items:  Array< {
      __typename: "Price",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      multiLanguages?: string | null,
      price: number,
      symphonyID: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PriceByConceptIDQueryVariables = {
  conceptID: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPriceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PriceByConceptIDQuery = {
  priceByConceptID?:  {
    __typename: "ModelPriceConnection",
    items:  Array< {
      __typename: "Price",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      multiLanguages?: string | null,
      price: number,
      symphonyID: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PriceByParentConceptIDQueryVariables = {
  parentConceptID: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPriceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PriceByParentConceptIDQuery = {
  priceByParentConceptID?:  {
    __typename: "ModelPriceConnection",
    items:  Array< {
      __typename: "Price",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      multiLanguages?: string | null,
      price: number,
      symphonyID: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetChoiceGroupsQueryVariables = {
  id: string,
};

export type GetChoiceGroupsQuery = {
  getChoiceGroups?:  {
    __typename: "ChoiceGroups",
    id: string,
    conceptID: string,
    parentConceptID: string,
    name: string,
    required?: boolean | null,
    maxNumberOfChoices?: number | null,
    minNumberOfChoices?: number | null,
    precedence?: string | null,
    type?: string | null,
    choices?: Array< string | null > | null,
    choiceItems?:  {
      __typename: "ModelChoiceConnection",
      items:  Array< {
        __typename: "Choice",
        id: string,
        conceptID: string,
        name: string,
        price: number,
        precedence?: string | null,
        choiceGroupID: string,
        choiceGroupName?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        symphonyID?: string | null,
        multiLanguages?: string | null,
        definitionNumber?: number | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    simphonyID?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListChoiceGroupsQueryVariables = {
  filter?: ModelChoiceGroupsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChoiceGroupsQuery = {
  listChoiceGroups?:  {
    __typename: "ModelChoiceGroupsConnection",
    items:  Array< {
      __typename: "ChoiceGroups",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      required?: boolean | null,
      maxNumberOfChoices?: number | null,
      minNumberOfChoices?: number | null,
      precedence?: string | null,
      type?: string | null,
      choices?: Array< string | null > | null,
      choiceItems?:  {
        __typename: "ModelChoiceConnection",
        items:  Array< {
          __typename: "Choice",
          id: string,
          conceptID: string,
          name: string,
          price: number,
          precedence?: string | null,
          choiceGroupID: string,
          choiceGroupName?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          symphonyID?: string | null,
          multiLanguages?: string | null,
          definitionNumber?: number | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      simphonyID?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncChoiceGroupsQueryVariables = {
  filter?: ModelChoiceGroupsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncChoiceGroupsQuery = {
  syncChoiceGroups?:  {
    __typename: "ModelChoiceGroupsConnection",
    items:  Array< {
      __typename: "ChoiceGroups",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      required?: boolean | null,
      maxNumberOfChoices?: number | null,
      minNumberOfChoices?: number | null,
      precedence?: string | null,
      type?: string | null,
      choices?: Array< string | null > | null,
      choiceItems?:  {
        __typename: "ModelChoiceConnection",
        items:  Array< {
          __typename: "Choice",
          id: string,
          conceptID: string,
          name: string,
          price: number,
          precedence?: string | null,
          choiceGroupID: string,
          choiceGroupName?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          symphonyID?: string | null,
          multiLanguages?: string | null,
          definitionNumber?: number | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      simphonyID?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ChoiceGroupsByConceptIDQueryVariables = {
  conceptID: string,
  precedence?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChoiceGroupsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChoiceGroupsByConceptIDQuery = {
  choiceGroupsByConceptID?:  {
    __typename: "ModelChoiceGroupsConnection",
    items:  Array< {
      __typename: "ChoiceGroups",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      required?: boolean | null,
      maxNumberOfChoices?: number | null,
      minNumberOfChoices?: number | null,
      precedence?: string | null,
      type?: string | null,
      choices?: Array< string | null > | null,
      choiceItems?:  {
        __typename: "ModelChoiceConnection",
        items:  Array< {
          __typename: "Choice",
          id: string,
          conceptID: string,
          name: string,
          price: number,
          precedence?: string | null,
          choiceGroupID: string,
          choiceGroupName?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          symphonyID?: string | null,
          multiLanguages?: string | null,
          definitionNumber?: number | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      simphonyID?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ChoiceGroupsByParentConceptIDQueryVariables = {
  parentConceptID: string,
  precedence?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChoiceGroupsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChoiceGroupsByParentConceptIDQuery = {
  choiceGroupsByParentConceptID?:  {
    __typename: "ModelChoiceGroupsConnection",
    items:  Array< {
      __typename: "ChoiceGroups",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      required?: boolean | null,
      maxNumberOfChoices?: number | null,
      minNumberOfChoices?: number | null,
      precedence?: string | null,
      type?: string | null,
      choices?: Array< string | null > | null,
      choiceItems?:  {
        __typename: "ModelChoiceConnection",
        items:  Array< {
          __typename: "Choice",
          id: string,
          conceptID: string,
          name: string,
          price: number,
          precedence?: string | null,
          choiceGroupID: string,
          choiceGroupName?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          symphonyID?: string | null,
          multiLanguages?: string | null,
          definitionNumber?: number | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      simphonyID?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetChoiceQueryVariables = {
  id: string,
};

export type GetChoiceQuery = {
  getChoice?:  {
    __typename: "Choice",
    id: string,
    conceptID: string,
    name: string,
    price: number,
    precedence?: string | null,
    choiceGroupID: string,
    choiceGroupName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    symphonyID?: string | null,
    multiLanguages?: string | null,
    definitionNumber?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListChoicesQueryVariables = {
  filter?: ModelChoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChoicesQuery = {
  listChoices?:  {
    __typename: "ModelChoiceConnection",
    items:  Array< {
      __typename: "Choice",
      id: string,
      conceptID: string,
      name: string,
      price: number,
      precedence?: string | null,
      choiceGroupID: string,
      choiceGroupName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      symphonyID?: string | null,
      multiLanguages?: string | null,
      definitionNumber?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncChoicesQueryVariables = {
  filter?: ModelChoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncChoicesQuery = {
  syncChoices?:  {
    __typename: "ModelChoiceConnection",
    items:  Array< {
      __typename: "Choice",
      id: string,
      conceptID: string,
      name: string,
      price: number,
      precedence?: string | null,
      choiceGroupID: string,
      choiceGroupName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      symphonyID?: string | null,
      multiLanguages?: string | null,
      definitionNumber?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ChoiceByConceptIDQueryVariables = {
  conceptID: string,
  name?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChoiceByConceptIDQuery = {
  choiceByConceptID?:  {
    __typename: "ModelChoiceConnection",
    items:  Array< {
      __typename: "Choice",
      id: string,
      conceptID: string,
      name: string,
      price: number,
      precedence?: string | null,
      choiceGroupID: string,
      choiceGroupName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      symphonyID?: string | null,
      multiLanguages?: string | null,
      definitionNumber?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ChoicesByChoiceGroupIDAndPrecedenceQueryVariables = {
  choiceGroupID: string,
  precedence?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelChoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ChoicesByChoiceGroupIDAndPrecedenceQuery = {
  choicesByChoiceGroupIDAndPrecedence?:  {
    __typename: "ModelChoiceConnection",
    items:  Array< {
      __typename: "Choice",
      id: string,
      conceptID: string,
      name: string,
      price: number,
      precedence?: string | null,
      choiceGroupID: string,
      choiceGroupName?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      symphonyID?: string | null,
      multiLanguages?: string | null,
      definitionNumber?: number | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetCartQueryVariables = {
  id: string,
};

export type GetCartQuery = {
  getCart?:  {
    __typename: "Cart",
    id: string,
    conceptID: string,
    userID: string,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      categoryID: string,
      name?: string | null,
      quantity: number,
      selectedChoiceGroups?:  Array< {
        __typename: "CartChoiceGroup",
        id: string,
        name?: string | null,
        selectedChoices?:  Array< {
          __typename: "CartChoice",
          id: string,
          name?: string | null,
          price?: number | null,
        } | null > | null,
        precedence?: string | null,
      } | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      type?: string | null,
    } | null > | null,
    specialRequest?: string | null,
    deliveryFee?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    totalPrice?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListCartsQueryVariables = {
  filter?: ModelCartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCartsQuery = {
  listCarts?:  {
    __typename: "ModelCartConnection",
    items:  Array< {
      __typename: "Cart",
      id: string,
      conceptID: string,
      userID: string,
      orderedItems?:  Array< {
        __typename: "CartItem",
        menuItemID: string,
        categoryID: string,
        name?: string | null,
        quantity: number,
        selectedChoiceGroups?:  Array< {
          __typename: "CartChoiceGroup",
          id: string,
          name?: string | null,
          precedence?: string | null,
        } | null > | null,
        price: number,
        priceName?: string | null,
        priceID?: string | null,
        priceMultiLanguages?: string | null,
        multiLanguages?: string | null,
        notes?: string | null,
        image?: string | null,
        type?: string | null,
      } | null > | null,
      specialRequest?: string | null,
      deliveryFee?: number | null,
      subTotal?: number | null,
      subTaxTotal?: number | null,
      totalPrice?: number | null,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncCartsQueryVariables = {
  filter?: ModelCartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncCartsQuery = {
  syncCarts?:  {
    __typename: "ModelCartConnection",
    items:  Array< {
      __typename: "Cart",
      id: string,
      conceptID: string,
      userID: string,
      orderedItems?:  Array< {
        __typename: "CartItem",
        menuItemID: string,
        categoryID: string,
        name?: string | null,
        quantity: number,
        selectedChoiceGroups?:  Array< {
          __typename: "CartChoiceGroup",
          id: string,
          name?: string | null,
          precedence?: string | null,
        } | null > | null,
        price: number,
        priceName?: string | null,
        priceID?: string | null,
        priceMultiLanguages?: string | null,
        multiLanguages?: string | null,
        notes?: string | null,
        image?: string | null,
        type?: string | null,
      } | null > | null,
      specialRequest?: string | null,
      deliveryFee?: number | null,
      subTotal?: number | null,
      subTaxTotal?: number | null,
      totalPrice?: number | null,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type CartByUserIDQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCartFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CartByUserIDQuery = {
  cartByUserID?:  {
    __typename: "ModelCartConnection",
    items:  Array< {
      __typename: "Cart",
      id: string,
      conceptID: string,
      userID: string,
      orderedItems?:  Array< {
        __typename: "CartItem",
        menuItemID: string,
        categoryID: string,
        name?: string | null,
        quantity: number,
        selectedChoiceGroups?:  Array< {
          __typename: "CartChoiceGroup",
          id: string,
          name?: string | null,
          precedence?: string | null,
        } | null > | null,
        price: number,
        priceName?: string | null,
        priceID?: string | null,
        priceMultiLanguages?: string | null,
        multiLanguages?: string | null,
        notes?: string | null,
        image?: string | null,
        type?: string | null,
      } | null > | null,
      specialRequest?: string | null,
      deliveryFee?: number | null,
      subTotal?: number | null,
      subTaxTotal?: number | null,
      totalPrice?: number | null,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetAdminStatusQueryVariables = {
  id: string,
};

export type GetAdminStatusQuery = {
  getAdminStatus?:  {
    __typename: "AdminStatus",
    id: string,
    sub: string,
    accountID: string,
    name?: string | null,
    averageCallTime?: number | null,
    numberOfCalls?: number | null,
    idelDuration?: number | null,
    activeDuration?: number | null,
    awayDuration?: number | null,
    lastCallTime?: number | null,
    agentBarAverageCallTime?: number | null,
    agentBarNumberOfCalls?: number | null,
    type?: string | null,
    waiterStatus?: string | null,
    totalAverageRatio?: number | null,
    performancePerDay?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListAdminStatusesQueryVariables = {
  filter?: ModelAdminStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdminStatusesQuery = {
  listAdminStatuses?:  {
    __typename: "ModelAdminStatusConnection",
    items:  Array< {
      __typename: "AdminStatus",
      id: string,
      sub: string,
      accountID: string,
      name?: string | null,
      averageCallTime?: number | null,
      numberOfCalls?: number | null,
      idelDuration?: number | null,
      activeDuration?: number | null,
      awayDuration?: number | null,
      lastCallTime?: number | null,
      agentBarAverageCallTime?: number | null,
      agentBarNumberOfCalls?: number | null,
      type?: string | null,
      waiterStatus?: string | null,
      totalAverageRatio?: number | null,
      performancePerDay?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncAdminStatusesQueryVariables = {
  filter?: ModelAdminStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncAdminStatusesQuery = {
  syncAdminStatuses?:  {
    __typename: "ModelAdminStatusConnection",
    items:  Array< {
      __typename: "AdminStatus",
      id: string,
      sub: string,
      accountID: string,
      name?: string | null,
      averageCallTime?: number | null,
      numberOfCalls?: number | null,
      idelDuration?: number | null,
      activeDuration?: number | null,
      awayDuration?: number | null,
      lastCallTime?: number | null,
      agentBarAverageCallTime?: number | null,
      agentBarNumberOfCalls?: number | null,
      type?: string | null,
      waiterStatus?: string | null,
      totalAverageRatio?: number | null,
      performancePerDay?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ByTotalAverageRatioQueryVariables = {
  name: string,
  totalAverageRatio?: ModelFloatKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAdminStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByTotalAverageRatioQuery = {
  ByTotalAverageRatio?:  {
    __typename: "ModelAdminStatusConnection",
    items:  Array< {
      __typename: "AdminStatus",
      id: string,
      sub: string,
      accountID: string,
      name?: string | null,
      averageCallTime?: number | null,
      numberOfCalls?: number | null,
      idelDuration?: number | null,
      activeDuration?: number | null,
      awayDuration?: number | null,
      lastCallTime?: number | null,
      agentBarAverageCallTime?: number | null,
      agentBarNumberOfCalls?: number | null,
      type?: string | null,
      waiterStatus?: string | null,
      totalAverageRatio?: number | null,
      performancePerDay?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOnlineOrderQueryVariables = {
  id: string,
};

export type GetOnlineOrderQuery = {
  getOnlineOrder?:  {
    __typename: "OnlineOrder",
    id: string,
    conceptID: string,
    statusID?: string | null,
    status?: string | null,
    reason?: string | null,
    orderType?: string | null,
    channel?: string | null,
    userID: string,
    userName?: string | null,
    userPhoneNumber?: string | null,
    dispatcherID?: string | null,
    dispatcherName?: string | null,
    totalAmount?: number | null,
    discountedValue?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    tax?: number | null,
    deliveryFee?: number | null,
    orderTime?: string | null,
    executionArn?: string | null,
    specialRequest?: string | null,
    orderAddress?: string | null,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      categoryID: string,
      name?: string | null,
      quantity: number,
      selectedChoiceGroups?:  Array< {
        __typename: "CartChoiceGroup",
        id: string,
        name?: string | null,
        selectedChoices?:  Array< {
          __typename: "CartChoice",
          id: string,
          name?: string | null,
          price?: number | null,
        } | null > | null,
        precedence?: string | null,
      } | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      type?: string | null,
    } | null > | null,
    discountID?: number | null,
    amount_cents?: string | null,
    transactionID?: string | null,
    paymentStatus?: boolean | null,
    paymentType?: string | null,
    referenceId?: string | null,
    cardHolderNumber?: string | null,
    cardHolderName?: string | null,
    cardSchemeName?: string | null,
    deleted?: string | null,
    createdAt: string,
    sentToKitchenAt?: string | null,
    readyAt?: string | null,
    outForDeliveryAt?: string | null,
    deliveredAt?: string | null,
    promoCodeID?: string | null,
    promoCodeName?: string | null,
    promoCodeAppliedTo?: string | null,
    rating?: number | null,
    isReview?: boolean | null,
    reviewID?: string | null,
    review?:  {
      __typename: "Review",
      id: string,
      conceptID?: string | null,
      conceptName?: string | null,
      accountID?: string | null,
      bookingID?: string | null,
      parentConceptID?: string | null,
      guestName?: string | null,
      guestId?: string | null,
      reason?: Array< string | null > | null,
      value?: string | null,
      userID?: string | null,
      orderID?: string | null,
      rate?: number | null,
      feedback?: string | null,
      satisfaction?: string | null,
      thoughts?: string | null,
      itemReviews?:  Array< {
        __typename: "ItemReview",
        itemID: string,
        rate?: number | null,
        satisfaction?: string | null,
        feedback?: string | null,
        multiLanguages?: string | null,
      } | null > | null,
      photos?: Array< string | null > | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      phoneUser?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdByID: string,
    createdByName: string,
    tmpField?: string | null,
    otp?: string | null,
    avgOrderCompletionEstimate?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListOnlineOrdersQueryVariables = {
  filter?: ModelOnlineOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOnlineOrdersQuery = {
  listOnlineOrders?:  {
    __typename: "ModelOnlineOrderConnection",
    items:  Array< {
      __typename: "OnlineOrder",
      id: string,
      conceptID: string,
      statusID?: string | null,
      status?: string | null,
      reason?: string | null,
      orderType?: string | null,
      channel?: string | null,
      userID: string,
      userName?: string | null,
      userPhoneNumber?: string | null,
      dispatcherID?: string | null,
      dispatcherName?: string | null,
      totalAmount?: number | null,
      discountedValue?: number | null,
      subTotal?: number | null,
      subTaxTotal?: number | null,
      tax?: number | null,
      deliveryFee?: number | null,
      orderTime?: string | null,
      executionArn?: string | null,
      specialRequest?: string | null,
      orderAddress?: string | null,
      orderedItems?:  Array< {
        __typename: "CartItem",
        menuItemID: string,
        categoryID: string,
        name?: string | null,
        quantity: number,
        selectedChoiceGroups?:  Array< {
          __typename: "CartChoiceGroup",
          id: string,
          name?: string | null,
          precedence?: string | null,
        } | null > | null,
        price: number,
        priceName?: string | null,
        priceID?: string | null,
        priceMultiLanguages?: string | null,
        multiLanguages?: string | null,
        notes?: string | null,
        image?: string | null,
        type?: string | null,
      } | null > | null,
      discountID?: number | null,
      amount_cents?: string | null,
      transactionID?: string | null,
      paymentStatus?: boolean | null,
      paymentType?: string | null,
      referenceId?: string | null,
      cardHolderNumber?: string | null,
      cardHolderName?: string | null,
      cardSchemeName?: string | null,
      deleted?: string | null,
      createdAt: string,
      sentToKitchenAt?: string | null,
      readyAt?: string | null,
      outForDeliveryAt?: string | null,
      deliveredAt?: string | null,
      promoCodeID?: string | null,
      promoCodeName?: string | null,
      promoCodeAppliedTo?: string | null,
      rating?: number | null,
      isReview?: boolean | null,
      reviewID?: string | null,
      review?:  {
        __typename: "Review",
        id: string,
        conceptID?: string | null,
        conceptName?: string | null,
        accountID?: string | null,
        bookingID?: string | null,
        parentConceptID?: string | null,
        guestName?: string | null,
        guestId?: string | null,
        reason?: Array< string | null > | null,
        value?: string | null,
        userID?: string | null,
        orderID?: string | null,
        rate?: number | null,
        feedback?: string | null,
        satisfaction?: string | null,
        thoughts?: string | null,
        itemReviews?:  Array< {
          __typename: "ItemReview",
          itemID: string,
          rate?: number | null,
          satisfaction?: string | null,
          feedback?: string | null,
          multiLanguages?: string | null,
        } | null > | null,
        photos?: Array< string | null > | null,
        multiLanguages?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        phoneUser?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdByID: string,
      createdByName: string,
      tmpField?: string | null,
      otp?: string | null,
      avgOrderCompletionEstimate?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOnlineOrdersQueryVariables = {
  filter?: ModelOnlineOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOnlineOrdersQuery = {
  syncOnlineOrders?:  {
    __typename: "ModelOnlineOrderConnection",
    items:  Array< {
      __typename: "OnlineOrder",
      id: string,
      conceptID: string,
      statusID?: string | null,
      status?: string | null,
      reason?: string | null,
      orderType?: string | null,
      channel?: string | null,
      userID: string,
      userName?: string | null,
      userPhoneNumber?: string | null,
      dispatcherID?: string | null,
      dispatcherName?: string | null,
      totalAmount?: number | null,
      discountedValue?: number | null,
      subTotal?: number | null,
      subTaxTotal?: number | null,
      tax?: number | null,
      deliveryFee?: number | null,
      orderTime?: string | null,
      executionArn?: string | null,
      specialRequest?: string | null,
      orderAddress?: string | null,
      orderedItems?:  Array< {
        __typename: "CartItem",
        menuItemID: string,
        categoryID: string,
        name?: string | null,
        quantity: number,
        selectedChoiceGroups?:  Array< {
          __typename: "CartChoiceGroup",
          id: string,
          name?: string | null,
          precedence?: string | null,
        } | null > | null,
        price: number,
        priceName?: string | null,
        priceID?: string | null,
        priceMultiLanguages?: string | null,
        multiLanguages?: string | null,
        notes?: string | null,
        image?: string | null,
        type?: string | null,
      } | null > | null,
      discountID?: number | null,
      amount_cents?: string | null,
      transactionID?: string | null,
      paymentStatus?: boolean | null,
      paymentType?: string | null,
      referenceId?: string | null,
      cardHolderNumber?: string | null,
      cardHolderName?: string | null,
      cardSchemeName?: string | null,
      deleted?: string | null,
      createdAt: string,
      sentToKitchenAt?: string | null,
      readyAt?: string | null,
      outForDeliveryAt?: string | null,
      deliveredAt?: string | null,
      promoCodeID?: string | null,
      promoCodeName?: string | null,
      promoCodeAppliedTo?: string | null,
      rating?: number | null,
      isReview?: boolean | null,
      reviewID?: string | null,
      review?:  {
        __typename: "Review",
        id: string,
        conceptID?: string | null,
        conceptName?: string | null,
        accountID?: string | null,
        bookingID?: string | null,
        parentConceptID?: string | null,
        guestName?: string | null,
        guestId?: string | null,
        reason?: Array< string | null > | null,
        value?: string | null,
        userID?: string | null,
        orderID?: string | null,
        rate?: number | null,
        feedback?: string | null,
        satisfaction?: string | null,
        thoughts?: string | null,
        itemReviews?:  Array< {
          __typename: "ItemReview",
          itemID: string,
          rate?: number | null,
          satisfaction?: string | null,
          feedback?: string | null,
          multiLanguages?: string | null,
        } | null > | null,
        photos?: Array< string | null > | null,
        multiLanguages?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        phoneUser?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdByID: string,
      createdByName: string,
      tmpField?: string | null,
      otp?: string | null,
      avgOrderCompletionEstimate?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OrderByConceptIDQueryVariables = {
  conceptID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOnlineOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderByConceptIDQuery = {
  orderByConceptID?:  {
    __typename: "ModelOnlineOrderConnection",
    items:  Array< {
      __typename: "OnlineOrder",
      id: string,
      conceptID: string,
      statusID?: string | null,
      status?: string | null,
      reason?: string | null,
      orderType?: string | null,
      channel?: string | null,
      userID: string,
      userName?: string | null,
      userPhoneNumber?: string | null,
      dispatcherID?: string | null,
      dispatcherName?: string | null,
      totalAmount?: number | null,
      discountedValue?: number | null,
      subTotal?: number | null,
      subTaxTotal?: number | null,
      tax?: number | null,
      deliveryFee?: number | null,
      orderTime?: string | null,
      executionArn?: string | null,
      specialRequest?: string | null,
      orderAddress?: string | null,
      orderedItems?:  Array< {
        __typename: "CartItem",
        menuItemID: string,
        categoryID: string,
        name?: string | null,
        quantity: number,
        selectedChoiceGroups?:  Array< {
          __typename: "CartChoiceGroup",
          id: string,
          name?: string | null,
          precedence?: string | null,
        } | null > | null,
        price: number,
        priceName?: string | null,
        priceID?: string | null,
        priceMultiLanguages?: string | null,
        multiLanguages?: string | null,
        notes?: string | null,
        image?: string | null,
        type?: string | null,
      } | null > | null,
      discountID?: number | null,
      amount_cents?: string | null,
      transactionID?: string | null,
      paymentStatus?: boolean | null,
      paymentType?: string | null,
      referenceId?: string | null,
      cardHolderNumber?: string | null,
      cardHolderName?: string | null,
      cardSchemeName?: string | null,
      deleted?: string | null,
      createdAt: string,
      sentToKitchenAt?: string | null,
      readyAt?: string | null,
      outForDeliveryAt?: string | null,
      deliveredAt?: string | null,
      promoCodeID?: string | null,
      promoCodeName?: string | null,
      promoCodeAppliedTo?: string | null,
      rating?: number | null,
      isReview?: boolean | null,
      reviewID?: string | null,
      review?:  {
        __typename: "Review",
        id: string,
        conceptID?: string | null,
        conceptName?: string | null,
        accountID?: string | null,
        bookingID?: string | null,
        parentConceptID?: string | null,
        guestName?: string | null,
        guestId?: string | null,
        reason?: Array< string | null > | null,
        value?: string | null,
        userID?: string | null,
        orderID?: string | null,
        rate?: number | null,
        feedback?: string | null,
        satisfaction?: string | null,
        thoughts?: string | null,
        itemReviews?:  Array< {
          __typename: "ItemReview",
          itemID: string,
          rate?: number | null,
          satisfaction?: string | null,
          feedback?: string | null,
          multiLanguages?: string | null,
        } | null > | null,
        photos?: Array< string | null > | null,
        multiLanguages?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        phoneUser?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdByID: string,
      createdByName: string,
      tmpField?: string | null,
      otp?: string | null,
      avgOrderCompletionEstimate?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OrderByStatusQueryVariables = {
  status: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOnlineOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderByStatusQuery = {
  orderByStatus?:  {
    __typename: "ModelOnlineOrderConnection",
    items:  Array< {
      __typename: "OnlineOrder",
      id: string,
      conceptID: string,
      statusID?: string | null,
      status?: string | null,
      reason?: string | null,
      orderType?: string | null,
      channel?: string | null,
      userID: string,
      userName?: string | null,
      userPhoneNumber?: string | null,
      dispatcherID?: string | null,
      dispatcherName?: string | null,
      totalAmount?: number | null,
      discountedValue?: number | null,
      subTotal?: number | null,
      subTaxTotal?: number | null,
      tax?: number | null,
      deliveryFee?: number | null,
      orderTime?: string | null,
      executionArn?: string | null,
      specialRequest?: string | null,
      orderAddress?: string | null,
      orderedItems?:  Array< {
        __typename: "CartItem",
        menuItemID: string,
        categoryID: string,
        name?: string | null,
        quantity: number,
        selectedChoiceGroups?:  Array< {
          __typename: "CartChoiceGroup",
          id: string,
          name?: string | null,
          precedence?: string | null,
        } | null > | null,
        price: number,
        priceName?: string | null,
        priceID?: string | null,
        priceMultiLanguages?: string | null,
        multiLanguages?: string | null,
        notes?: string | null,
        image?: string | null,
        type?: string | null,
      } | null > | null,
      discountID?: number | null,
      amount_cents?: string | null,
      transactionID?: string | null,
      paymentStatus?: boolean | null,
      paymentType?: string | null,
      referenceId?: string | null,
      cardHolderNumber?: string | null,
      cardHolderName?: string | null,
      cardSchemeName?: string | null,
      deleted?: string | null,
      createdAt: string,
      sentToKitchenAt?: string | null,
      readyAt?: string | null,
      outForDeliveryAt?: string | null,
      deliveredAt?: string | null,
      promoCodeID?: string | null,
      promoCodeName?: string | null,
      promoCodeAppliedTo?: string | null,
      rating?: number | null,
      isReview?: boolean | null,
      reviewID?: string | null,
      review?:  {
        __typename: "Review",
        id: string,
        conceptID?: string | null,
        conceptName?: string | null,
        accountID?: string | null,
        bookingID?: string | null,
        parentConceptID?: string | null,
        guestName?: string | null,
        guestId?: string | null,
        reason?: Array< string | null > | null,
        value?: string | null,
        userID?: string | null,
        orderID?: string | null,
        rate?: number | null,
        feedback?: string | null,
        satisfaction?: string | null,
        thoughts?: string | null,
        itemReviews?:  Array< {
          __typename: "ItemReview",
          itemID: string,
          rate?: number | null,
          satisfaction?: string | null,
          feedback?: string | null,
          multiLanguages?: string | null,
        } | null > | null,
        photos?: Array< string | null > | null,
        multiLanguages?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        phoneUser?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdByID: string,
      createdByName: string,
      tmpField?: string | null,
      otp?: string | null,
      avgOrderCompletionEstimate?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OrdersByUserIDQueryVariables = {
  userID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOnlineOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrdersByUserIDQuery = {
  ordersByUserID?:  {
    __typename: "ModelOnlineOrderConnection",
    items:  Array< {
      __typename: "OnlineOrder",
      id: string,
      conceptID: string,
      statusID?: string | null,
      status?: string | null,
      reason?: string | null,
      orderType?: string | null,
      channel?: string | null,
      userID: string,
      userName?: string | null,
      userPhoneNumber?: string | null,
      dispatcherID?: string | null,
      dispatcherName?: string | null,
      totalAmount?: number | null,
      discountedValue?: number | null,
      subTotal?: number | null,
      subTaxTotal?: number | null,
      tax?: number | null,
      deliveryFee?: number | null,
      orderTime?: string | null,
      executionArn?: string | null,
      specialRequest?: string | null,
      orderAddress?: string | null,
      orderedItems?:  Array< {
        __typename: "CartItem",
        menuItemID: string,
        categoryID: string,
        name?: string | null,
        quantity: number,
        selectedChoiceGroups?:  Array< {
          __typename: "CartChoiceGroup",
          id: string,
          name?: string | null,
          precedence?: string | null,
        } | null > | null,
        price: number,
        priceName?: string | null,
        priceID?: string | null,
        priceMultiLanguages?: string | null,
        multiLanguages?: string | null,
        notes?: string | null,
        image?: string | null,
        type?: string | null,
      } | null > | null,
      discountID?: number | null,
      amount_cents?: string | null,
      transactionID?: string | null,
      paymentStatus?: boolean | null,
      paymentType?: string | null,
      referenceId?: string | null,
      cardHolderNumber?: string | null,
      cardHolderName?: string | null,
      cardSchemeName?: string | null,
      deleted?: string | null,
      createdAt: string,
      sentToKitchenAt?: string | null,
      readyAt?: string | null,
      outForDeliveryAt?: string | null,
      deliveredAt?: string | null,
      promoCodeID?: string | null,
      promoCodeName?: string | null,
      promoCodeAppliedTo?: string | null,
      rating?: number | null,
      isReview?: boolean | null,
      reviewID?: string | null,
      review?:  {
        __typename: "Review",
        id: string,
        conceptID?: string | null,
        conceptName?: string | null,
        accountID?: string | null,
        bookingID?: string | null,
        parentConceptID?: string | null,
        guestName?: string | null,
        guestId?: string | null,
        reason?: Array< string | null > | null,
        value?: string | null,
        userID?: string | null,
        orderID?: string | null,
        rate?: number | null,
        feedback?: string | null,
        satisfaction?: string | null,
        thoughts?: string | null,
        itemReviews?:  Array< {
          __typename: "ItemReview",
          itemID: string,
          rate?: number | null,
          satisfaction?: string | null,
          feedback?: string | null,
          multiLanguages?: string | null,
        } | null > | null,
        photos?: Array< string | null > | null,
        multiLanguages?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        phoneUser?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdByID: string,
      createdByName: string,
      tmpField?: string | null,
      otp?: string | null,
      avgOrderCompletionEstimate?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OrderByDeletedQueryVariables = {
  deleted: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOnlineOrderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OrderByDeletedQuery = {
  orderByDeleted?:  {
    __typename: "ModelOnlineOrderConnection",
    items:  Array< {
      __typename: "OnlineOrder",
      id: string,
      conceptID: string,
      statusID?: string | null,
      status?: string | null,
      reason?: string | null,
      orderType?: string | null,
      channel?: string | null,
      userID: string,
      userName?: string | null,
      userPhoneNumber?: string | null,
      dispatcherID?: string | null,
      dispatcherName?: string | null,
      totalAmount?: number | null,
      discountedValue?: number | null,
      subTotal?: number | null,
      subTaxTotal?: number | null,
      tax?: number | null,
      deliveryFee?: number | null,
      orderTime?: string | null,
      executionArn?: string | null,
      specialRequest?: string | null,
      orderAddress?: string | null,
      orderedItems?:  Array< {
        __typename: "CartItem",
        menuItemID: string,
        categoryID: string,
        name?: string | null,
        quantity: number,
        selectedChoiceGroups?:  Array< {
          __typename: "CartChoiceGroup",
          id: string,
          name?: string | null,
          precedence?: string | null,
        } | null > | null,
        price: number,
        priceName?: string | null,
        priceID?: string | null,
        priceMultiLanguages?: string | null,
        multiLanguages?: string | null,
        notes?: string | null,
        image?: string | null,
        type?: string | null,
      } | null > | null,
      discountID?: number | null,
      amount_cents?: string | null,
      transactionID?: string | null,
      paymentStatus?: boolean | null,
      paymentType?: string | null,
      referenceId?: string | null,
      cardHolderNumber?: string | null,
      cardHolderName?: string | null,
      cardSchemeName?: string | null,
      deleted?: string | null,
      createdAt: string,
      sentToKitchenAt?: string | null,
      readyAt?: string | null,
      outForDeliveryAt?: string | null,
      deliveredAt?: string | null,
      promoCodeID?: string | null,
      promoCodeName?: string | null,
      promoCodeAppliedTo?: string | null,
      rating?: number | null,
      isReview?: boolean | null,
      reviewID?: string | null,
      review?:  {
        __typename: "Review",
        id: string,
        conceptID?: string | null,
        conceptName?: string | null,
        accountID?: string | null,
        bookingID?: string | null,
        parentConceptID?: string | null,
        guestName?: string | null,
        guestId?: string | null,
        reason?: Array< string | null > | null,
        value?: string | null,
        userID?: string | null,
        orderID?: string | null,
        rate?: number | null,
        feedback?: string | null,
        satisfaction?: string | null,
        thoughts?: string | null,
        itemReviews?:  Array< {
          __typename: "ItemReview",
          itemID: string,
          rate?: number | null,
          satisfaction?: string | null,
          feedback?: string | null,
          multiLanguages?: string | null,
        } | null > | null,
        photos?: Array< string | null > | null,
        multiLanguages?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        phoneUser?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdByID: string,
      createdByName: string,
      tmpField?: string | null,
      otp?: string | null,
      avgOrderCompletionEstimate?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOrderStatusQueryVariables = {
  id: string,
};

export type GetOrderStatusQuery = {
  getOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    conceptID: string,
    name: string,
    color?: string | null,
    precedence?: number | null,
    accessibleStatus?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListOrderStatusesQueryVariables = {
  filter?: ModelOrderStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrderStatusesQuery = {
  listOrderStatuses?:  {
    __typename: "ModelOrderStatusConnection",
    items:  Array< {
      __typename: "OrderStatus",
      id: string,
      conceptID: string,
      name: string,
      color?: string | null,
      precedence?: number | null,
      accessibleStatus?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOrderStatusesQueryVariables = {
  filter?: ModelOrderStatusFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOrderStatusesQuery = {
  syncOrderStatuses?:  {
    __typename: "ModelOrderStatusConnection",
    items:  Array< {
      __typename: "OrderStatus",
      id: string,
      conceptID: string,
      name: string,
      color?: string | null,
      precedence?: number | null,
      accessibleStatus?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetDiscountCouponQueryVariables = {
  id: string,
};

export type GetDiscountCouponQuery = {
  getDiscountCoupon?:  {
    __typename: "DiscountCoupon",
    id: string,
    discountPercent: string,
    usedBy?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListDiscountCouponsQueryVariables = {
  filter?: ModelDiscountCouponFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDiscountCouponsQuery = {
  listDiscountCoupons?:  {
    __typename: "ModelDiscountCouponConnection",
    items:  Array< {
      __typename: "DiscountCoupon",
      id: string,
      discountPercent: string,
      usedBy?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncDiscountCouponsQueryVariables = {
  filter?: ModelDiscountCouponFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncDiscountCouponsQuery = {
  syncDiscountCoupons?:  {
    __typename: "ModelDiscountCouponConnection",
    items:  Array< {
      __typename: "DiscountCoupon",
      id: string,
      discountPercent: string,
      usedBy?: Array< string | null > | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetZoneQueryVariables = {
  id: string,
};

export type GetZoneQuery = {
  getZone?:  {
    __typename: "Zone",
    id: string,
    conceptID: string,
    location: string,
    deliveryFee: number,
    concepts?:  Array< {
      __typename: "ZoneConcept",
      id: string,
      deliveryFee: number,
    } | null > | null,
    polygon?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListZonesQueryVariables = {
  filter?: ModelZoneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListZonesQuery = {
  listZones?:  {
    __typename: "ModelZoneConnection",
    items:  Array< {
      __typename: "Zone",
      id: string,
      conceptID: string,
      location: string,
      deliveryFee: number,
      concepts?:  Array< {
        __typename: "ZoneConcept",
        id: string,
        deliveryFee: number,
      } | null > | null,
      polygon?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncZonesQueryVariables = {
  filter?: ModelZoneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncZonesQuery = {
  syncZones?:  {
    __typename: "ModelZoneConnection",
    items:  Array< {
      __typename: "Zone",
      id: string,
      conceptID: string,
      location: string,
      deliveryFee: number,
      concepts?:  Array< {
        __typename: "ZoneConcept",
        id: string,
        deliveryFee: number,
      } | null > | null,
      polygon?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ZoneByConceptIDQueryVariables = {
  conceptID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelZoneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ZoneByConceptIDQuery = {
  zoneByConceptID?:  {
    __typename: "ModelZoneConnection",
    items:  Array< {
      __typename: "Zone",
      id: string,
      conceptID: string,
      location: string,
      deliveryFee: number,
      concepts?:  Array< {
        __typename: "ZoneConcept",
        id: string,
        deliveryFee: number,
      } | null > | null,
      polygon?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ZoneByConceptIDSortedByLocationNameQueryVariables = {
  conceptID: string,
  location?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelZoneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ZoneByConceptIDSortedByLocationNameQuery = {
  zoneByConceptIDSortedByLocationName?:  {
    __typename: "ModelZoneConnection",
    items:  Array< {
      __typename: "Zone",
      id: string,
      conceptID: string,
      location: string,
      deliveryFee: number,
      concepts?:  Array< {
        __typename: "ZoneConcept",
        id: string,
        deliveryFee: number,
      } | null > | null,
      polygon?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetUserIPQueryVariables = {
  id: string,
};

export type GetUserIPQuery = {
  getUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListUserIPSQueryVariables = {
  filter?: ModelUserIPFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserIPSQuery = {
  listUserIPS?:  {
    __typename: "ModelUserIPConnection",
    items:  Array< {
      __typename: "UserIP",
      id: string,
      ip?: string | null,
      date?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncUserIPSQueryVariables = {
  filter?: ModelUserIPFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncUserIPSQuery = {
  syncUserIPS?:  {
    __typename: "ModelUserIPConnection",
    items:  Array< {
      __typename: "UserIP",
      id: string,
      ip?: string | null,
      date?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMainCategoryQueryVariables = {
  id: string,
};

export type GetMainCategoryQuery = {
  getMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMainCategoriesQueryVariables = {
  filter?: ModelMainCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMainCategoriesQuery = {
  listMainCategories?:  {
    __typename: "ModelMainCategoryConnection",
    items:  Array< {
      __typename: "MainCategory",
      id: string,
      conceptID: string,
      name: string,
      precedence: string,
      openTime?: string | null,
      closeTime?: string | null,
      guestView?: boolean | null,
      guestOrder?: boolean | null,
      staffOrder?: boolean | null,
      image?: string | null,
      menuPDF?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMainCategoriesQueryVariables = {
  filter?: ModelMainCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMainCategoriesQuery = {
  syncMainCategories?:  {
    __typename: "ModelMainCategoryConnection",
    items:  Array< {
      __typename: "MainCategory",
      id: string,
      conceptID: string,
      name: string,
      precedence: string,
      openTime?: string | null,
      closeTime?: string | null,
      guestView?: boolean | null,
      guestOrder?: boolean | null,
      staffOrder?: boolean | null,
      image?: string | null,
      menuPDF?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MainCategoryByConceptIDQueryVariables = {
  conceptID: string,
  precedence?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMainCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MainCategoryByConceptIDQuery = {
  mainCategoryByConceptID?:  {
    __typename: "ModelMainCategoryConnection",
    items:  Array< {
      __typename: "MainCategory",
      id: string,
      conceptID: string,
      name: string,
      precedence: string,
      openTime?: string | null,
      closeTime?: string | null,
      guestView?: boolean | null,
      guestOrder?: boolean | null,
      staffOrder?: boolean | null,
      image?: string | null,
      menuPDF?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetSubCategoryQueryVariables = {
  id: string,
};

export type GetSubCategoryQuery = {
  getSubCategory?:  {
    __typename: "SubCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    mainCategoryID?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListSubCategoriesQueryVariables = {
  filter?: ModelSubCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSubCategoriesQuery = {
  listSubCategories?:  {
    __typename: "ModelSubCategoryConnection",
    items:  Array< {
      __typename: "SubCategory",
      id: string,
      conceptID: string,
      name: string,
      precedence: string,
      openTime?: string | null,
      closeTime?: string | null,
      mainCategoryID?: string | null,
      guestView?: boolean | null,
      guestOrder?: boolean | null,
      staffOrder?: boolean | null,
      image?: string | null,
      menuPDF?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncSubCategoriesQueryVariables = {
  filter?: ModelSubCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncSubCategoriesQuery = {
  syncSubCategories?:  {
    __typename: "ModelSubCategoryConnection",
    items:  Array< {
      __typename: "SubCategory",
      id: string,
      conceptID: string,
      name: string,
      precedence: string,
      openTime?: string | null,
      closeTime?: string | null,
      mainCategoryID?: string | null,
      guestView?: boolean | null,
      guestOrder?: boolean | null,
      staffOrder?: boolean | null,
      image?: string | null,
      menuPDF?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SubCategoryByConceptIDQueryVariables = {
  conceptID: string,
  precedence?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSubCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SubCategoryByConceptIDQuery = {
  subCategoryByConceptID?:  {
    __typename: "ModelSubCategoryConnection",
    items:  Array< {
      __typename: "SubCategory",
      id: string,
      conceptID: string,
      name: string,
      precedence: string,
      openTime?: string | null,
      closeTime?: string | null,
      mainCategoryID?: string | null,
      guestView?: boolean | null,
      guestOrder?: boolean | null,
      staffOrder?: boolean | null,
      image?: string | null,
      menuPDF?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetChannelQueryVariables = {
  id: string,
};

export type GetChannelQuery = {
  getChannel?:  {
    __typename: "Channel",
    id: string,
    name: string,
    description?: string | null,
    numberOfOrders?: number | null,
    isActive?: boolean | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListChannelsQueryVariables = {
  filter?: ModelChannelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListChannelsQuery = {
  listChannels?:  {
    __typename: "ModelChannelConnection",
    items:  Array< {
      __typename: "Channel",
      id: string,
      name: string,
      description?: string | null,
      numberOfOrders?: number | null,
      isActive?: boolean | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncChannelsQueryVariables = {
  filter?: ModelChannelFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncChannelsQuery = {
  syncChannels?:  {
    __typename: "ModelChannelConnection",
    items:  Array< {
      __typename: "Channel",
      id: string,
      name: string,
      description?: string | null,
      numberOfOrders?: number | null,
      isActive?: boolean | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPromocodeQueryVariables = {
  id: string,
};

export type GetPromocodeQuery = {
  getPromocode?:  {
    __typename: "Promocode",
    id: string,
    name: string,
    redemptions: number,
    status?: string | null,
    startDate: string,
    endDate: string,
    appliesTo: string,
    minOrderReq: string,
    minOrderVal?: number | null,
    minOrderQty?: number | null,
    tenderReq: Array< string | null >,
    discountVal: number,
    discountLimit?: number | null,
    quota?: number | null,
    quotaUsed?: number | null,
    totalRevenue?: number | null,
    appliedDiscountedVal?: number | null,
    usePerCustomer?: number | null,
    channelReq: Array< string | null >,
    customerEligibility: string,
    customerReq?: Array< string | null > | null,
    spendingExceeds?: number | null,
    spendingBelow?: number | null,
    orderSinceDate?: string | null,
    orderFromDate?: string | null,
    orderToDate?: string | null,
    isDisplayed?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    parentConceptIDs?: Array< string | null > | null,
    conceptIDs?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPromocodesQueryVariables = {
  filter?: ModelPromocodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPromocodesQuery = {
  listPromocodes?:  {
    __typename: "ModelPromocodeConnection",
    items:  Array< {
      __typename: "Promocode",
      id: string,
      name: string,
      redemptions: number,
      status?: string | null,
      startDate: string,
      endDate: string,
      appliesTo: string,
      minOrderReq: string,
      minOrderVal?: number | null,
      minOrderQty?: number | null,
      tenderReq: Array< string | null >,
      discountVal: number,
      discountLimit?: number | null,
      quota?: number | null,
      quotaUsed?: number | null,
      totalRevenue?: number | null,
      appliedDiscountedVal?: number | null,
      usePerCustomer?: number | null,
      channelReq: Array< string | null >,
      customerEligibility: string,
      customerReq?: Array< string | null > | null,
      spendingExceeds?: number | null,
      spendingBelow?: number | null,
      orderSinceDate?: string | null,
      orderFromDate?: string | null,
      orderToDate?: string | null,
      isDisplayed?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      parentConceptIDs?: Array< string | null > | null,
      conceptIDs?: Array< string | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPromocodesQueryVariables = {
  filter?: ModelPromocodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPromocodesQuery = {
  syncPromocodes?:  {
    __typename: "ModelPromocodeConnection",
    items:  Array< {
      __typename: "Promocode",
      id: string,
      name: string,
      redemptions: number,
      status?: string | null,
      startDate: string,
      endDate: string,
      appliesTo: string,
      minOrderReq: string,
      minOrderVal?: number | null,
      minOrderQty?: number | null,
      tenderReq: Array< string | null >,
      discountVal: number,
      discountLimit?: number | null,
      quota?: number | null,
      quotaUsed?: number | null,
      totalRevenue?: number | null,
      appliedDiscountedVal?: number | null,
      usePerCustomer?: number | null,
      channelReq: Array< string | null >,
      customerEligibility: string,
      customerReq?: Array< string | null > | null,
      spendingExceeds?: number | null,
      spendingBelow?: number | null,
      orderSinceDate?: string | null,
      orderFromDate?: string | null,
      orderToDate?: string | null,
      isDisplayed?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      parentConceptIDs?: Array< string | null > | null,
      conceptIDs?: Array< string | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PromoCodeByNameQueryVariables = {
  name: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPromocodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PromoCodeByNameQuery = {
  promoCodeByName?:  {
    __typename: "ModelPromocodeConnection",
    items:  Array< {
      __typename: "Promocode",
      id: string,
      name: string,
      redemptions: number,
      status?: string | null,
      startDate: string,
      endDate: string,
      appliesTo: string,
      minOrderReq: string,
      minOrderVal?: number | null,
      minOrderQty?: number | null,
      tenderReq: Array< string | null >,
      discountVal: number,
      discountLimit?: number | null,
      quota?: number | null,
      quotaUsed?: number | null,
      totalRevenue?: number | null,
      appliedDiscountedVal?: number | null,
      usePerCustomer?: number | null,
      channelReq: Array< string | null >,
      customerEligibility: string,
      customerReq?: Array< string | null > | null,
      spendingExceeds?: number | null,
      spendingBelow?: number | null,
      orderSinceDate?: string | null,
      orderFromDate?: string | null,
      orderToDate?: string | null,
      isDisplayed?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      parentConceptIDs?: Array< string | null > | null,
      conceptIDs?: Array< string | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type PromoCodeByDeletedQueryVariables = {
  deleted: string,
  totalRevenue?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPromocodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PromoCodeByDeletedQuery = {
  promoCodeByDeleted?:  {
    __typename: "ModelPromocodeConnection",
    items:  Array< {
      __typename: "Promocode",
      id: string,
      name: string,
      redemptions: number,
      status?: string | null,
      startDate: string,
      endDate: string,
      appliesTo: string,
      minOrderReq: string,
      minOrderVal?: number | null,
      minOrderQty?: number | null,
      tenderReq: Array< string | null >,
      discountVal: number,
      discountLimit?: number | null,
      quota?: number | null,
      quotaUsed?: number | null,
      totalRevenue?: number | null,
      appliedDiscountedVal?: number | null,
      usePerCustomer?: number | null,
      channelReq: Array< string | null >,
      customerEligibility: string,
      customerReq?: Array< string | null > | null,
      spendingExceeds?: number | null,
      spendingBelow?: number | null,
      orderSinceDate?: string | null,
      orderFromDate?: string | null,
      orderToDate?: string | null,
      isDisplayed?: boolean | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      parentConceptIDs?: Array< string | null > | null,
      conceptIDs?: Array< string | null > | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetPromocodeDashboardQueryVariables = {
  id: string,
};

export type GetPromocodeDashboardQuery = {
  getPromocodeDashboard?:  {
    __typename: "PromocodeDashboard",
    id: string,
    conceptID: string,
    totalRevenue?: number | null,
    totalRedemptions?: number | null,
    totalDiscount?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListPromocodeDashboardsQueryVariables = {
  filter?: ModelPromocodeDashboardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPromocodeDashboardsQuery = {
  listPromocodeDashboards?:  {
    __typename: "ModelPromocodeDashboardConnection",
    items:  Array< {
      __typename: "PromocodeDashboard",
      id: string,
      conceptID: string,
      totalRevenue?: number | null,
      totalRedemptions?: number | null,
      totalDiscount?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncPromocodeDashboardsQueryVariables = {
  filter?: ModelPromocodeDashboardFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncPromocodeDashboardsQuery = {
  syncPromocodeDashboards?:  {
    __typename: "ModelPromocodeDashboardConnection",
    items:  Array< {
      __typename: "PromocodeDashboard",
      id: string,
      conceptID: string,
      totalRevenue?: number | null,
      totalRedemptions?: number | null,
      totalDiscount?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetConceptRevenueQueryVariables = {
  id: string,
};

export type GetConceptRevenueQuery = {
  getConceptRevenue?:  {
    __typename: "ConceptRevenue",
    id: string,
    conceptID: string,
    date: string,
    driveThruRevenue?: number | null,
    pickupRevenue?: number | null,
    deliveryRevenue?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListConceptRevenuesQueryVariables = {
  filter?: ModelConceptRevenueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListConceptRevenuesQuery = {
  listConceptRevenues?:  {
    __typename: "ModelConceptRevenueConnection",
    items:  Array< {
      __typename: "ConceptRevenue",
      id: string,
      conceptID: string,
      date: string,
      driveThruRevenue?: number | null,
      pickupRevenue?: number | null,
      deliveryRevenue?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncConceptRevenuesQueryVariables = {
  filter?: ModelConceptRevenueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncConceptRevenuesQuery = {
  syncConceptRevenues?:  {
    __typename: "ModelConceptRevenueConnection",
    items:  Array< {
      __typename: "ConceptRevenue",
      id: string,
      conceptID: string,
      date: string,
      driveThruRevenue?: number | null,
      pickupRevenue?: number | null,
      deliveryRevenue?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type RevenueByConceptIDQueryVariables = {
  conceptID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelConceptRevenueFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RevenueByConceptIDQuery = {
  RevenueByConceptID?:  {
    __typename: "ModelConceptRevenueConnection",
    items:  Array< {
      __typename: "ConceptRevenue",
      id: string,
      conceptID: string,
      date: string,
      driveThruRevenue?: number | null,
      pickupRevenue?: number | null,
      deliveryRevenue?: number | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetLoyaltyItemQueryVariables = {
  id: string,
};

export type GetLoyaltyItemQuery = {
  getLoyaltyItem?:  {
    __typename: "LoyaltyItem",
    id: string,
    name: string,
    conceptID: string,
    parentConceptID: string,
    description?: string | null,
    startDate: string,
    endDate?: string | null,
    options?: Array< string | null > | null,
    points: number,
    redemptionPerUser?: number | null,
    image?: string | null,
    simphonyID?: string | null,
    multiLanguages?: string | null,
    isActive?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListLoyaltyItemsQueryVariables = {
  filter?: ModelLoyaltyItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLoyaltyItemsQuery = {
  listLoyaltyItems?:  {
    __typename: "ModelLoyaltyItemConnection",
    items:  Array< {
      __typename: "LoyaltyItem",
      id: string,
      name: string,
      conceptID: string,
      parentConceptID: string,
      description?: string | null,
      startDate: string,
      endDate?: string | null,
      options?: Array< string | null > | null,
      points: number,
      redemptionPerUser?: number | null,
      image?: string | null,
      simphonyID?: string | null,
      multiLanguages?: string | null,
      isActive?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncLoyaltyItemsQueryVariables = {
  filter?: ModelLoyaltyItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncLoyaltyItemsQuery = {
  syncLoyaltyItems?:  {
    __typename: "ModelLoyaltyItemConnection",
    items:  Array< {
      __typename: "LoyaltyItem",
      id: string,
      name: string,
      conceptID: string,
      parentConceptID: string,
      description?: string | null,
      startDate: string,
      endDate?: string | null,
      options?: Array< string | null > | null,
      points: number,
      redemptionPerUser?: number | null,
      image?: string | null,
      simphonyID?: string | null,
      multiLanguages?: string | null,
      isActive?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type LoyaltyItemByConceptIDQueryVariables = {
  conceptID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLoyaltyItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LoyaltyItemByConceptIDQuery = {
  LoyaltyItemByConceptID?:  {
    __typename: "ModelLoyaltyItemConnection",
    items:  Array< {
      __typename: "LoyaltyItem",
      id: string,
      name: string,
      conceptID: string,
      parentConceptID: string,
      description?: string | null,
      startDate: string,
      endDate?: string | null,
      options?: Array< string | null > | null,
      points: number,
      redemptionPerUser?: number | null,
      image?: string | null,
      simphonyID?: string | null,
      multiLanguages?: string | null,
      isActive?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type LoyaltyItemByParentConceptIDQueryVariables = {
  parentConceptID: string,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelLoyaltyItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LoyaltyItemByParentConceptIDQuery = {
  LoyaltyItemByParentConceptID?:  {
    __typename: "ModelLoyaltyItemConnection",
    items:  Array< {
      __typename: "LoyaltyItem",
      id: string,
      name: string,
      conceptID: string,
      parentConceptID: string,
      description?: string | null,
      startDate: string,
      endDate?: string | null,
      options?: Array< string | null > | null,
      points: number,
      redemptionPerUser?: number | null,
      image?: string | null,
      simphonyID?: string | null,
      multiLanguages?: string | null,
      isActive?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetOTPQueryVariables = {
  id: string,
};

export type GetOTPQuery = {
  getOTP?:  {
    __typename: "OTP",
    id: string,
    OTP: string,
    redeemed?: boolean | null,
    deleted?: string | null,
    userID: string,
    conceptID?: string | null,
    loyaltyItemID?: string | null,
    loyaltyItem?:  {
      __typename: "LoyaltyItem",
      id: string,
      name: string,
      conceptID: string,
      parentConceptID: string,
      description?: string | null,
      startDate: string,
      endDate?: string | null,
      options?: Array< string | null > | null,
      points: number,
      redemptionPerUser?: number | null,
      image?: string | null,
      simphonyID?: string | null,
      multiLanguages?: string | null,
      isActive?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      autoGeneratedPassword: boolean,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    concept?:  {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      kiosks?: Array< string | null > | null,
      merchantID?: string | null,
      merchantUsername?: string | null,
      merchantPassword?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      paymentSharePercentage?: number | null,
      simphonyServerBaseURL?: string | null,
      simphonyShortOrgName?: string | null,
      simphonyLocRef?: string | null,
      simphonyToken?: string | null,
      revenueCenterID?: number | null,
      employeeID?: number | null,
      tenderID?: number | null,
      tenders?:  {
        __typename: "ModelTenderConnection",
        items:  Array< {
          __typename: "Tender",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptTendersId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderType?:  {
        __typename: "ModelOrderTypeConnection",
        items:  Array< {
          __typename: "OrderType",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptOrderTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      discountID?: number | null,
      useServiceCharge?: boolean | null,
      serviceChargeId?: number | null,
      specialRequestID?: number | null,
      addressID?: number | null,
      ratingCount?: number | null,
      averageRating?: number | null,
      feesback?: string | null,
      simphonyAuthClientId?: string | null,
      simphonyAuthUsername?: string | null,
      simphonyAuthPassword?: string | null,
      simphonyAuthBaseURL?: string | null,
      orderTypeDineIn?: number | null,
      orderTypeTakeAway?: number | null,
      orderTypeDelivery?: number | null,
      simphonyTakeAwayRvc?: number | null,
      simphonyDeliveryRvc?: number | null,
      exportInterval?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      foodicsToken?: string | null,
      enableTableFoodics?: string | null,
      image?: string | null,
      logo?: string | null,
      album?: Array< string | null > | null,
      type?: string | null,
      rating?: number | null,
      location?: string | null,
      description?: string | null,
      areas?: Array< string | null > | null,
      seatTypes?: Array< string | null > | null,
      needAssistance?: boolean | null,
      spaBooking?: boolean | null,
      showerBooking?: boolean | null,
      cigarMenu?: boolean | null,
      precedence?: number | null,
      isPinRequired?: boolean | null,
      viewOnly?: boolean | null,
      openTime?: string | null,
      closeTime?: string | null,
      varifyForAmount?: number | null,
      varifyFirstOrder?: boolean | null,
      varifyNewAddress?: boolean | null,
      sliderImages?: Array< string | null > | null,
      receiveWaiters?: Array< string | null > | null,
      WaiterNotificationOption?: string | null,
      applyLoyalty?: boolean | null,
      rewardPoints?: number | null,
      moneySpent?: number | null,
      afterVat?: boolean | null,
      afterDeliveryFee?: boolean | null,
      beforePromoCode?: boolean | null,
      pointsCalcWithPromoCode?: boolean | null,
      isBusy?: boolean | null,
      allowOnlineOrdering?: boolean | null,
      minOrderVal?: number | null,
      vatPercentage?: number | null,
      serviceChargePercentage?: number | null,
      addDeliveryToVat?: boolean | null,
      categoryTagIDs?: Array< string | null > | null,
      parentConceptID: string,
      containerColor?: string | null,
      avgOrderCompletionEstimate?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListOTPSQueryVariables = {
  filter?: ModelOTPFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOTPSQuery = {
  listOTPS?:  {
    __typename: "ModelOTPConnection",
    items:  Array< {
      __typename: "OTP",
      id: string,
      OTP: string,
      redeemed?: boolean | null,
      deleted?: string | null,
      userID: string,
      conceptID?: string | null,
      loyaltyItemID?: string | null,
      loyaltyItem?:  {
        __typename: "LoyaltyItem",
        id: string,
        name: string,
        conceptID: string,
        parentConceptID: string,
        description?: string | null,
        startDate: string,
        endDate?: string | null,
        options?: Array< string | null > | null,
        points: number,
        redemptionPerUser?: number | null,
        image?: string | null,
        simphonyID?: string | null,
        multiLanguages?: string | null,
        isActive?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        accountID: string,
        username?: string | null,
        preferred_username?: string | null,
        email?: string | null,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        pictureCrop?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        facebook?: string | null,
        instagram?: string | null,
        group?: string | null,
        phones?: Array< string | null > | null,
        flags?: Array< string | null > | null,
        flagsName?: Array< string | null > | null,
        interests?: Array< string | null > | null,
        interestsName?: Array< string | null > | null,
        status?: string | null,
        stats?: Array< string | null > | null,
        spending?: number | null,
        prevOrderDate?: string | null,
        lastOrderDate?: string | null,
        promocodes?:  Array< {
          __typename: "PromocodeItem",
          name: string,
          promocodeId: string,
          canBeUsed: number,
          discountVal: number,
          discountLimit?: number | null,
          isDisplayed?: boolean | null,
        } | null > | null,
        mostOrderedItems?: string | null,
        points?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        cognitoUsername?: string | null,
        cognitoSub?: string | null,
        autoGeneratedPassword: boolean,
        updated?: string | null,
        sub?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      concept?:  {
        __typename: "Concept",
        id: string,
        accountID: string,
        name: string,
        kiosks?: Array< string | null > | null,
        merchantID?: string | null,
        merchantUsername?: string | null,
        merchantPassword?: string | null,
        merchantIntegrationNumber?: string | null,
        merchantAPIKey?: string | null,
        paymentSharePercentage?: number | null,
        simphonyServerBaseURL?: string | null,
        simphonyShortOrgName?: string | null,
        simphonyLocRef?: string | null,
        simphonyToken?: string | null,
        revenueCenterID?: number | null,
        employeeID?: number | null,
        tenderID?: number | null,
        tenders?:  {
          __typename: "ModelTenderConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderType?:  {
          __typename: "ModelOrderTypeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        discountID?: number | null,
        useServiceCharge?: boolean | null,
        serviceChargeId?: number | null,
        specialRequestID?: number | null,
        addressID?: number | null,
        ratingCount?: number | null,
        averageRating?: number | null,
        feesback?: string | null,
        simphonyAuthClientId?: string | null,
        simphonyAuthUsername?: string | null,
        simphonyAuthPassword?: string | null,
        simphonyAuthBaseURL?: string | null,
        orderTypeDineIn?: number | null,
        orderTypeTakeAway?: number | null,
        orderTypeDelivery?: number | null,
        simphonyTakeAwayRvc?: number | null,
        simphonyDeliveryRvc?: number | null,
        exportInterval?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        foodicsToken?: string | null,
        enableTableFoodics?: string | null,
        image?: string | null,
        logo?: string | null,
        album?: Array< string | null > | null,
        type?: string | null,
        rating?: number | null,
        location?: string | null,
        description?: string | null,
        areas?: Array< string | null > | null,
        seatTypes?: Array< string | null > | null,
        needAssistance?: boolean | null,
        spaBooking?: boolean | null,
        showerBooking?: boolean | null,
        cigarMenu?: boolean | null,
        precedence?: number | null,
        isPinRequired?: boolean | null,
        viewOnly?: boolean | null,
        openTime?: string | null,
        closeTime?: string | null,
        varifyForAmount?: number | null,
        varifyFirstOrder?: boolean | null,
        varifyNewAddress?: boolean | null,
        sliderImages?: Array< string | null > | null,
        receiveWaiters?: Array< string | null > | null,
        WaiterNotificationOption?: string | null,
        applyLoyalty?: boolean | null,
        rewardPoints?: number | null,
        moneySpent?: number | null,
        afterVat?: boolean | null,
        afterDeliveryFee?: boolean | null,
        beforePromoCode?: boolean | null,
        pointsCalcWithPromoCode?: boolean | null,
        isBusy?: boolean | null,
        allowOnlineOrdering?: boolean | null,
        minOrderVal?: number | null,
        vatPercentage?: number | null,
        serviceChargePercentage?: number | null,
        addDeliveryToVat?: boolean | null,
        categoryTagIDs?: Array< string | null > | null,
        parentConceptID: string,
        containerColor?: string | null,
        avgOrderCompletionEstimate?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncOTPSQueryVariables = {
  filter?: ModelOTPFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncOTPSQuery = {
  syncOTPS?:  {
    __typename: "ModelOTPConnection",
    items:  Array< {
      __typename: "OTP",
      id: string,
      OTP: string,
      redeemed?: boolean | null,
      deleted?: string | null,
      userID: string,
      conceptID?: string | null,
      loyaltyItemID?: string | null,
      loyaltyItem?:  {
        __typename: "LoyaltyItem",
        id: string,
        name: string,
        conceptID: string,
        parentConceptID: string,
        description?: string | null,
        startDate: string,
        endDate?: string | null,
        options?: Array< string | null > | null,
        points: number,
        redemptionPerUser?: number | null,
        image?: string | null,
        simphonyID?: string | null,
        multiLanguages?: string | null,
        isActive?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        accountID: string,
        username?: string | null,
        preferred_username?: string | null,
        email?: string | null,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        pictureCrop?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        facebook?: string | null,
        instagram?: string | null,
        group?: string | null,
        phones?: Array< string | null > | null,
        flags?: Array< string | null > | null,
        flagsName?: Array< string | null > | null,
        interests?: Array< string | null > | null,
        interestsName?: Array< string | null > | null,
        status?: string | null,
        stats?: Array< string | null > | null,
        spending?: number | null,
        prevOrderDate?: string | null,
        lastOrderDate?: string | null,
        promocodes?:  Array< {
          __typename: "PromocodeItem",
          name: string,
          promocodeId: string,
          canBeUsed: number,
          discountVal: number,
          discountLimit?: number | null,
          isDisplayed?: boolean | null,
        } | null > | null,
        mostOrderedItems?: string | null,
        points?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        cognitoUsername?: string | null,
        cognitoSub?: string | null,
        autoGeneratedPassword: boolean,
        updated?: string | null,
        sub?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      concept?:  {
        __typename: "Concept",
        id: string,
        accountID: string,
        name: string,
        kiosks?: Array< string | null > | null,
        merchantID?: string | null,
        merchantUsername?: string | null,
        merchantPassword?: string | null,
        merchantIntegrationNumber?: string | null,
        merchantAPIKey?: string | null,
        paymentSharePercentage?: number | null,
        simphonyServerBaseURL?: string | null,
        simphonyShortOrgName?: string | null,
        simphonyLocRef?: string | null,
        simphonyToken?: string | null,
        revenueCenterID?: number | null,
        employeeID?: number | null,
        tenderID?: number | null,
        tenders?:  {
          __typename: "ModelTenderConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderType?:  {
          __typename: "ModelOrderTypeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        discountID?: number | null,
        useServiceCharge?: boolean | null,
        serviceChargeId?: number | null,
        specialRequestID?: number | null,
        addressID?: number | null,
        ratingCount?: number | null,
        averageRating?: number | null,
        feesback?: string | null,
        simphonyAuthClientId?: string | null,
        simphonyAuthUsername?: string | null,
        simphonyAuthPassword?: string | null,
        simphonyAuthBaseURL?: string | null,
        orderTypeDineIn?: number | null,
        orderTypeTakeAway?: number | null,
        orderTypeDelivery?: number | null,
        simphonyTakeAwayRvc?: number | null,
        simphonyDeliveryRvc?: number | null,
        exportInterval?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        foodicsToken?: string | null,
        enableTableFoodics?: string | null,
        image?: string | null,
        logo?: string | null,
        album?: Array< string | null > | null,
        type?: string | null,
        rating?: number | null,
        location?: string | null,
        description?: string | null,
        areas?: Array< string | null > | null,
        seatTypes?: Array< string | null > | null,
        needAssistance?: boolean | null,
        spaBooking?: boolean | null,
        showerBooking?: boolean | null,
        cigarMenu?: boolean | null,
        precedence?: number | null,
        isPinRequired?: boolean | null,
        viewOnly?: boolean | null,
        openTime?: string | null,
        closeTime?: string | null,
        varifyForAmount?: number | null,
        varifyFirstOrder?: boolean | null,
        varifyNewAddress?: boolean | null,
        sliderImages?: Array< string | null > | null,
        receiveWaiters?: Array< string | null > | null,
        WaiterNotificationOption?: string | null,
        applyLoyalty?: boolean | null,
        rewardPoints?: number | null,
        moneySpent?: number | null,
        afterVat?: boolean | null,
        afterDeliveryFee?: boolean | null,
        beforePromoCode?: boolean | null,
        pointsCalcWithPromoCode?: boolean | null,
        isBusy?: boolean | null,
        allowOnlineOrdering?: boolean | null,
        minOrderVal?: number | null,
        vatPercentage?: number | null,
        serviceChargePercentage?: number | null,
        addDeliveryToVat?: boolean | null,
        categoryTagIDs?: Array< string | null > | null,
        parentConceptID: string,
        containerColor?: string | null,
        avgOrderCompletionEstimate?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type ByOTPQueryVariables = {
  OTP: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOTPFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ByOTPQuery = {
  ByOTP?:  {
    __typename: "ModelOTPConnection",
    items:  Array< {
      __typename: "OTP",
      id: string,
      OTP: string,
      redeemed?: boolean | null,
      deleted?: string | null,
      userID: string,
      conceptID?: string | null,
      loyaltyItemID?: string | null,
      loyaltyItem?:  {
        __typename: "LoyaltyItem",
        id: string,
        name: string,
        conceptID: string,
        parentConceptID: string,
        description?: string | null,
        startDate: string,
        endDate?: string | null,
        options?: Array< string | null > | null,
        points: number,
        redemptionPerUser?: number | null,
        image?: string | null,
        simphonyID?: string | null,
        multiLanguages?: string | null,
        isActive?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        accountID: string,
        username?: string | null,
        preferred_username?: string | null,
        email?: string | null,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        pictureCrop?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        facebook?: string | null,
        instagram?: string | null,
        group?: string | null,
        phones?: Array< string | null > | null,
        flags?: Array< string | null > | null,
        flagsName?: Array< string | null > | null,
        interests?: Array< string | null > | null,
        interestsName?: Array< string | null > | null,
        status?: string | null,
        stats?: Array< string | null > | null,
        spending?: number | null,
        prevOrderDate?: string | null,
        lastOrderDate?: string | null,
        promocodes?:  Array< {
          __typename: "PromocodeItem",
          name: string,
          promocodeId: string,
          canBeUsed: number,
          discountVal: number,
          discountLimit?: number | null,
          isDisplayed?: boolean | null,
        } | null > | null,
        mostOrderedItems?: string | null,
        points?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        cognitoUsername?: string | null,
        cognitoSub?: string | null,
        autoGeneratedPassword: boolean,
        updated?: string | null,
        sub?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      concept?:  {
        __typename: "Concept",
        id: string,
        accountID: string,
        name: string,
        kiosks?: Array< string | null > | null,
        merchantID?: string | null,
        merchantUsername?: string | null,
        merchantPassword?: string | null,
        merchantIntegrationNumber?: string | null,
        merchantAPIKey?: string | null,
        paymentSharePercentage?: number | null,
        simphonyServerBaseURL?: string | null,
        simphonyShortOrgName?: string | null,
        simphonyLocRef?: string | null,
        simphonyToken?: string | null,
        revenueCenterID?: number | null,
        employeeID?: number | null,
        tenderID?: number | null,
        tenders?:  {
          __typename: "ModelTenderConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderType?:  {
          __typename: "ModelOrderTypeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        discountID?: number | null,
        useServiceCharge?: boolean | null,
        serviceChargeId?: number | null,
        specialRequestID?: number | null,
        addressID?: number | null,
        ratingCount?: number | null,
        averageRating?: number | null,
        feesback?: string | null,
        simphonyAuthClientId?: string | null,
        simphonyAuthUsername?: string | null,
        simphonyAuthPassword?: string | null,
        simphonyAuthBaseURL?: string | null,
        orderTypeDineIn?: number | null,
        orderTypeTakeAway?: number | null,
        orderTypeDelivery?: number | null,
        simphonyTakeAwayRvc?: number | null,
        simphonyDeliveryRvc?: number | null,
        exportInterval?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        foodicsToken?: string | null,
        enableTableFoodics?: string | null,
        image?: string | null,
        logo?: string | null,
        album?: Array< string | null > | null,
        type?: string | null,
        rating?: number | null,
        location?: string | null,
        description?: string | null,
        areas?: Array< string | null > | null,
        seatTypes?: Array< string | null > | null,
        needAssistance?: boolean | null,
        spaBooking?: boolean | null,
        showerBooking?: boolean | null,
        cigarMenu?: boolean | null,
        precedence?: number | null,
        isPinRequired?: boolean | null,
        viewOnly?: boolean | null,
        openTime?: string | null,
        closeTime?: string | null,
        varifyForAmount?: number | null,
        varifyFirstOrder?: boolean | null,
        varifyNewAddress?: boolean | null,
        sliderImages?: Array< string | null > | null,
        receiveWaiters?: Array< string | null > | null,
        WaiterNotificationOption?: string | null,
        applyLoyalty?: boolean | null,
        rewardPoints?: number | null,
        moneySpent?: number | null,
        afterVat?: boolean | null,
        afterDeliveryFee?: boolean | null,
        beforePromoCode?: boolean | null,
        pointsCalcWithPromoCode?: boolean | null,
        isBusy?: boolean | null,
        allowOnlineOrdering?: boolean | null,
        minOrderVal?: number | null,
        vatPercentage?: number | null,
        serviceChargePercentage?: number | null,
        addDeliveryToVat?: boolean | null,
        categoryTagIDs?: Array< string | null > | null,
        parentConceptID: string,
        containerColor?: string | null,
        avgOrderCompletionEstimate?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OtpByUserIDQueryVariables = {
  userID: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelOTPFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type OtpByUserIDQuery = {
  otpByUserID?:  {
    __typename: "ModelOTPConnection",
    items:  Array< {
      __typename: "OTP",
      id: string,
      OTP: string,
      redeemed?: boolean | null,
      deleted?: string | null,
      userID: string,
      conceptID?: string | null,
      loyaltyItemID?: string | null,
      loyaltyItem?:  {
        __typename: "LoyaltyItem",
        id: string,
        name: string,
        conceptID: string,
        parentConceptID: string,
        description?: string | null,
        startDate: string,
        endDate?: string | null,
        options?: Array< string | null > | null,
        points: number,
        redemptionPerUser?: number | null,
        image?: string | null,
        simphonyID?: string | null,
        multiLanguages?: string | null,
        isActive?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      user?:  {
        __typename: "User",
        id: string,
        accountID: string,
        username?: string | null,
        preferred_username?: string | null,
        email?: string | null,
        email_verified?: boolean | null,
        phone_number?: string | null,
        phone_number_verified?: boolean | null,
        name?: string | null,
        nickname?: string | null,
        given_name?: string | null,
        middle_name?: string | null,
        family_name?: string | null,
        address?: string | null,
        birthdate?: string | null,
        gender?: string | null,
        locale?: string | null,
        picture?: string | null,
        pictureCrop?: string | null,
        website?: string | null,
        zoneinfo?: string | null,
        verification_code?: string | null,
        facebook?: string | null,
        instagram?: string | null,
        group?: string | null,
        phones?: Array< string | null > | null,
        flags?: Array< string | null > | null,
        flagsName?: Array< string | null > | null,
        interests?: Array< string | null > | null,
        interestsName?: Array< string | null > | null,
        status?: string | null,
        stats?: Array< string | null > | null,
        spending?: number | null,
        prevOrderDate?: string | null,
        lastOrderDate?: string | null,
        promocodes?:  Array< {
          __typename: "PromocodeItem",
          name: string,
          promocodeId: string,
          canBeUsed: number,
          discountVal: number,
          discountLimit?: number | null,
          isDisplayed?: boolean | null,
        } | null > | null,
        mostOrderedItems?: string | null,
        points?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        cognitoUsername?: string | null,
        cognitoSub?: string | null,
        autoGeneratedPassword: boolean,
        updated?: string | null,
        sub?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      concept?:  {
        __typename: "Concept",
        id: string,
        accountID: string,
        name: string,
        kiosks?: Array< string | null > | null,
        merchantID?: string | null,
        merchantUsername?: string | null,
        merchantPassword?: string | null,
        merchantIntegrationNumber?: string | null,
        merchantAPIKey?: string | null,
        paymentSharePercentage?: number | null,
        simphonyServerBaseURL?: string | null,
        simphonyShortOrgName?: string | null,
        simphonyLocRef?: string | null,
        simphonyToken?: string | null,
        revenueCenterID?: number | null,
        employeeID?: number | null,
        tenderID?: number | null,
        tenders?:  {
          __typename: "ModelTenderConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderType?:  {
          __typename: "ModelOrderTypeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        discountID?: number | null,
        useServiceCharge?: boolean | null,
        serviceChargeId?: number | null,
        specialRequestID?: number | null,
        addressID?: number | null,
        ratingCount?: number | null,
        averageRating?: number | null,
        feesback?: string | null,
        simphonyAuthClientId?: string | null,
        simphonyAuthUsername?: string | null,
        simphonyAuthPassword?: string | null,
        simphonyAuthBaseURL?: string | null,
        orderTypeDineIn?: number | null,
        orderTypeTakeAway?: number | null,
        orderTypeDelivery?: number | null,
        simphonyTakeAwayRvc?: number | null,
        simphonyDeliveryRvc?: number | null,
        exportInterval?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        foodicsToken?: string | null,
        enableTableFoodics?: string | null,
        image?: string | null,
        logo?: string | null,
        album?: Array< string | null > | null,
        type?: string | null,
        rating?: number | null,
        location?: string | null,
        description?: string | null,
        areas?: Array< string | null > | null,
        seatTypes?: Array< string | null > | null,
        needAssistance?: boolean | null,
        spaBooking?: boolean | null,
        showerBooking?: boolean | null,
        cigarMenu?: boolean | null,
        precedence?: number | null,
        isPinRequired?: boolean | null,
        viewOnly?: boolean | null,
        openTime?: string | null,
        closeTime?: string | null,
        varifyForAmount?: number | null,
        varifyFirstOrder?: boolean | null,
        varifyNewAddress?: boolean | null,
        sliderImages?: Array< string | null > | null,
        receiveWaiters?: Array< string | null > | null,
        WaiterNotificationOption?: string | null,
        applyLoyalty?: boolean | null,
        rewardPoints?: number | null,
        moneySpent?: number | null,
        afterVat?: boolean | null,
        afterDeliveryFee?: boolean | null,
        beforePromoCode?: boolean | null,
        pointsCalcWithPromoCode?: boolean | null,
        isBusy?: boolean | null,
        allowOnlineOrdering?: boolean | null,
        minOrderVal?: number | null,
        vatPercentage?: number | null,
        serviceChargePercentage?: number | null,
        addDeliveryToVat?: boolean | null,
        categoryTagIDs?: Array< string | null > | null,
        parentConceptID: string,
        containerColor?: string | null,
        avgOrderCompletionEstimate?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type GetMostOrderedItemsQueryVariables = {
  id: string,
};

export type GetMostOrderedItemsQuery = {
  getMostOrderedItems?:  {
    __typename: "MostOrderedItems",
    id: string,
    conceptID?: string | null,
    menuItemID: string,
    menuItemName?: string | null,
    menuItemImage?: string | null,
    quantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type ListMostOrderedItemsQueryVariables = {
  filter?: ModelMostOrderedItemsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListMostOrderedItemsQuery = {
  listMostOrderedItems?:  {
    __typename: "ModelMostOrderedItemsConnection",
    items:  Array< {
      __typename: "MostOrderedItems",
      id: string,
      conceptID?: string | null,
      menuItemID: string,
      menuItemName?: string | null,
      menuItemImage?: string | null,
      quantity?: number | null,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type SyncMostOrderedItemsQueryVariables = {
  filter?: ModelMostOrderedItemsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  lastSync?: number | null,
};

export type SyncMostOrderedItemsQuery = {
  syncMostOrderedItems?:  {
    __typename: "ModelMostOrderedItemsConnection",
    items:  Array< {
      __typename: "MostOrderedItems",
      id: string,
      conceptID?: string | null,
      menuItemID: string,
      menuItemName?: string | null,
      menuItemImage?: string | null,
      quantity?: number | null,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type MostOrderedItemsByConceptIDQueryVariables = {
  conceptID: string,
  quantity?: ModelIntKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelMostOrderedItemsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type MostOrderedItemsByConceptIDQuery = {
  mostOrderedItemsByConceptID?:  {
    __typename: "ModelMostOrderedItemsConnection",
    items:  Array< {
      __typename: "MostOrderedItems",
      id: string,
      conceptID?: string | null,
      menuItemID: string,
      menuItemName?: string | null,
      menuItemImage?: string | null,
      quantity?: number | null,
      deleted?: string | null,
      createdAt: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null >,
    nextToken?: string | null,
    startedAt?: number | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    accountID: string,
    username?: string | null,
    preferred_username?: string | null,
    email?: string | null,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    pictureCrop?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    facebook?: string | null,
    instagram?: string | null,
    group?: string | null,
    phones?: Array< string | null > | null,
    flags?: Array< string | null > | null,
    flagsName?: Array< string | null > | null,
    interests?: Array< string | null > | null,
    interestsName?: Array< string | null > | null,
    status?: string | null,
    stats?: Array< string | null > | null,
    spending?: number | null,
    prevOrderDate?: string | null,
    lastOrderDate?: string | null,
    promocodes?:  Array< {
      __typename: "PromocodeItem",
      name: string,
      promocodeId: string,
      canBeUsed: number,
      discountVal: number,
      discountLimit?: number | null,
      isDisplayed?: boolean | null,
    } | null > | null,
    mostOrderedItems?: string | null,
    points?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    cognitoUsername?: string | null,
    cognitoSub?: string | null,
    autoGeneratedPassword: boolean,
    updated?: string | null,
    sub?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    accountID: string,
    username?: string | null,
    preferred_username?: string | null,
    email?: string | null,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    pictureCrop?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    facebook?: string | null,
    instagram?: string | null,
    group?: string | null,
    phones?: Array< string | null > | null,
    flags?: Array< string | null > | null,
    flagsName?: Array< string | null > | null,
    interests?: Array< string | null > | null,
    interestsName?: Array< string | null > | null,
    status?: string | null,
    stats?: Array< string | null > | null,
    spending?: number | null,
    prevOrderDate?: string | null,
    lastOrderDate?: string | null,
    promocodes?:  Array< {
      __typename: "PromocodeItem",
      name: string,
      promocodeId: string,
      canBeUsed: number,
      discountVal: number,
      discountLimit?: number | null,
      isDisplayed?: boolean | null,
    } | null > | null,
    mostOrderedItems?: string | null,
    points?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    cognitoUsername?: string | null,
    cognitoSub?: string | null,
    autoGeneratedPassword: boolean,
    updated?: string | null,
    sub?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    accountID: string,
    username?: string | null,
    preferred_username?: string | null,
    email?: string | null,
    email_verified?: boolean | null,
    phone_number?: string | null,
    phone_number_verified?: boolean | null,
    name?: string | null,
    nickname?: string | null,
    given_name?: string | null,
    middle_name?: string | null,
    family_name?: string | null,
    address?: string | null,
    birthdate?: string | null,
    gender?: string | null,
    locale?: string | null,
    picture?: string | null,
    pictureCrop?: string | null,
    website?: string | null,
    zoneinfo?: string | null,
    verification_code?: string | null,
    facebook?: string | null,
    instagram?: string | null,
    group?: string | null,
    phones?: Array< string | null > | null,
    flags?: Array< string | null > | null,
    flagsName?: Array< string | null > | null,
    interests?: Array< string | null > | null,
    interestsName?: Array< string | null > | null,
    status?: string | null,
    stats?: Array< string | null > | null,
    spending?: number | null,
    prevOrderDate?: string | null,
    lastOrderDate?: string | null,
    promocodes?:  Array< {
      __typename: "PromocodeItem",
      name: string,
      promocodeId: string,
      canBeUsed: number,
      discountVal: number,
      discountLimit?: number | null,
      isDisplayed?: boolean | null,
    } | null > | null,
    mostOrderedItems?: string | null,
    points?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    cognitoUsername?: string | null,
    cognitoSub?: string | null,
    autoGeneratedPassword: boolean,
    updated?: string | null,
    sub?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateReviewSubscriptionVariables = {
  filter?: ModelSubscriptionReviewFilterInput | null,
};

export type OnCreateReviewSubscription = {
  onCreateReview?:  {
    __typename: "Review",
    id: string,
    conceptID?: string | null,
    conceptName?: string | null,
    accountID?: string | null,
    bookingID?: string | null,
    parentConceptID?: string | null,
    guestName?: string | null,
    guestId?: string | null,
    reason?: Array< string | null > | null,
    value?: string | null,
    userID?: string | null,
    orderID?: string | null,
    rate?: number | null,
    feedback?: string | null,
    satisfaction?: string | null,
    thoughts?: string | null,
    itemReviews?:  Array< {
      __typename: "ItemReview",
      itemID: string,
      rate?: number | null,
      satisfaction?: string | null,
      feedback?: string | null,
      multiLanguages?: string | null,
    } | null > | null,
    photos?: Array< string | null > | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    phoneUser?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateReviewSubscriptionVariables = {
  filter?: ModelSubscriptionReviewFilterInput | null,
};

export type OnUpdateReviewSubscription = {
  onUpdateReview?:  {
    __typename: "Review",
    id: string,
    conceptID?: string | null,
    conceptName?: string | null,
    accountID?: string | null,
    bookingID?: string | null,
    parentConceptID?: string | null,
    guestName?: string | null,
    guestId?: string | null,
    reason?: Array< string | null > | null,
    value?: string | null,
    userID?: string | null,
    orderID?: string | null,
    rate?: number | null,
    feedback?: string | null,
    satisfaction?: string | null,
    thoughts?: string | null,
    itemReviews?:  Array< {
      __typename: "ItemReview",
      itemID: string,
      rate?: number | null,
      satisfaction?: string | null,
      feedback?: string | null,
      multiLanguages?: string | null,
    } | null > | null,
    photos?: Array< string | null > | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    phoneUser?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteReviewSubscriptionVariables = {
  filter?: ModelSubscriptionReviewFilterInput | null,
};

export type OnDeleteReviewSubscription = {
  onDeleteReview?:  {
    __typename: "Review",
    id: string,
    conceptID?: string | null,
    conceptName?: string | null,
    accountID?: string | null,
    bookingID?: string | null,
    parentConceptID?: string | null,
    guestName?: string | null,
    guestId?: string | null,
    reason?: Array< string | null > | null,
    value?: string | null,
    userID?: string | null,
    orderID?: string | null,
    rate?: number | null,
    feedback?: string | null,
    satisfaction?: string | null,
    thoughts?: string | null,
    itemReviews?:  Array< {
      __typename: "ItemReview",
      itemID: string,
      rate?: number | null,
      satisfaction?: string | null,
      feedback?: string | null,
      multiLanguages?: string | null,
    } | null > | null,
    photos?: Array< string | null > | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    phoneUser?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnCreateAccountSubscription = {
  onCreateAccount?:  {
    __typename: "Account",
    id: string,
    logo?: string | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    callCenterEmployeesIds?: Array< string | null > | null,
    kpiReportResetFrequency?: string | null,
    agentBarResetFrequency?: string | null,
    dynamicStatus?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnUpdateAccountSubscription = {
  onUpdateAccount?:  {
    __typename: "Account",
    id: string,
    logo?: string | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    callCenterEmployeesIds?: Array< string | null > | null,
    kpiReportResetFrequency?: string | null,
    agentBarResetFrequency?: string | null,
    dynamicStatus?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAccountSubscriptionVariables = {
  filter?: ModelSubscriptionAccountFilterInput | null,
};

export type OnDeleteAccountSubscription = {
  onDeleteAccount?:  {
    __typename: "Account",
    id: string,
    logo?: string | null,
    domain: string,
    siteTitle: string,
    guestsCount?: number | null,
    tagline: string,
    description: string,
    siteAddress: string,
    defaultLanguage: string,
    languages: Array< string | null >,
    features: Array< string | null >,
    status: string,
    socialLinks?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    callCenterEmployeesIds?: Array< string | null > | null,
    kpiReportResetFrequency?: string | null,
    agentBarResetFrequency?: string | null,
    dynamicStatus?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateFeatureSubscriptionVariables = {
  filter?: ModelSubscriptionFeatureFilterInput | null,
};

export type OnCreateFeatureSubscription = {
  onCreateFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateFeatureSubscriptionVariables = {
  filter?: ModelSubscriptionFeatureFilterInput | null,
};

export type OnUpdateFeatureSubscription = {
  onUpdateFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteFeatureSubscriptionVariables = {
  filter?: ModelSubscriptionFeatureFilterInput | null,
};

export type OnDeleteFeatureSubscription = {
  onDeleteFeature?:  {
    __typename: "Feature",
    id: string,
    name: string,
    icon?: string | null,
    slug: string,
    precedence: string,
    parent?: string | null,
    private?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateMediaSubscriptionVariables = {
  filter?: ModelSubscriptionMediaFilterInput | null,
};

export type OnCreateMediaSubscription = {
  onCreateMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMediaSubscriptionVariables = {
  filter?: ModelSubscriptionMediaFilterInput | null,
};

export type OnUpdateMediaSubscription = {
  onUpdateMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMediaSubscriptionVariables = {
  filter?: ModelSubscriptionMediaFilterInput | null,
};

export type OnDeleteMediaSubscription = {
  onDeleteMedia?:  {
    __typename: "Media",
    id: string,
    accountID: string,
    fileUrl: string,
    filename: string,
    filetype?: string | null,
    fileSize?: number | null,
    alternativeText?: string | null,
    caption?: string | null,
    description?: string | null,
    attachments?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateConceptSubscriptionVariables = {
  filter?: ModelSubscriptionConceptFilterInput | null,
};

export type OnCreateConceptSubscription = {
  onCreateConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    kiosks?: Array< string | null > | null,
    merchantID?: string | null,
    merchantUsername?: string | null,
    merchantPassword?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    paymentSharePercentage?: number | null,
    simphonyServerBaseURL?: string | null,
    simphonyShortOrgName?: string | null,
    simphonyLocRef?: string | null,
    simphonyToken?: string | null,
    revenueCenterID?: number | null,
    employeeID?: number | null,
    tenderID?: number | null,
    tenders?:  {
      __typename: "ModelTenderConnection",
      items:  Array< {
        __typename: "Tender",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptTendersId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderType?:  {
      __typename: "ModelOrderTypeConnection",
      items:  Array< {
        __typename: "OrderType",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptOrderTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    discountID?: number | null,
    useServiceCharge?: boolean | null,
    serviceChargeId?: number | null,
    specialRequestID?: number | null,
    addressID?: number | null,
    ratingCount?: number | null,
    averageRating?: number | null,
    feesback?: string | null,
    simphonyAuthClientId?: string | null,
    simphonyAuthUsername?: string | null,
    simphonyAuthPassword?: string | null,
    simphonyAuthBaseURL?: string | null,
    orderTypeDineIn?: number | null,
    orderTypeTakeAway?: number | null,
    orderTypeDelivery?: number | null,
    simphonyTakeAwayRvc?: number | null,
    simphonyDeliveryRvc?: number | null,
    exportInterval?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    foodicsToken?: string | null,
    enableTableFoodics?: string | null,
    image?: string | null,
    logo?: string | null,
    album?: Array< string | null > | null,
    type?: string | null,
    rating?: number | null,
    location?: string | null,
    description?: string | null,
    areas?: Array< string | null > | null,
    seatTypes?: Array< string | null > | null,
    needAssistance?: boolean | null,
    spaBooking?: boolean | null,
    showerBooking?: boolean | null,
    cigarMenu?: boolean | null,
    precedence?: number | null,
    isPinRequired?: boolean | null,
    viewOnly?: boolean | null,
    openTime?: string | null,
    closeTime?: string | null,
    varifyForAmount?: number | null,
    varifyFirstOrder?: boolean | null,
    varifyNewAddress?: boolean | null,
    sliderImages?: Array< string | null > | null,
    receiveWaiters?: Array< string | null > | null,
    WaiterNotificationOption?: string | null,
    applyLoyalty?: boolean | null,
    rewardPoints?: number | null,
    moneySpent?: number | null,
    afterVat?: boolean | null,
    afterDeliveryFee?: boolean | null,
    beforePromoCode?: boolean | null,
    pointsCalcWithPromoCode?: boolean | null,
    isBusy?: boolean | null,
    allowOnlineOrdering?: boolean | null,
    minOrderVal?: number | null,
    vatPercentage?: number | null,
    serviceChargePercentage?: number | null,
    addDeliveryToVat?: boolean | null,
    categoryTagIDs?: Array< string | null > | null,
    parentConceptID: string,
    containerColor?: string | null,
    avgOrderCompletionEstimate?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateConceptSubscriptionVariables = {
  filter?: ModelSubscriptionConceptFilterInput | null,
};

export type OnUpdateConceptSubscription = {
  onUpdateConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    kiosks?: Array< string | null > | null,
    merchantID?: string | null,
    merchantUsername?: string | null,
    merchantPassword?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    paymentSharePercentage?: number | null,
    simphonyServerBaseURL?: string | null,
    simphonyShortOrgName?: string | null,
    simphonyLocRef?: string | null,
    simphonyToken?: string | null,
    revenueCenterID?: number | null,
    employeeID?: number | null,
    tenderID?: number | null,
    tenders?:  {
      __typename: "ModelTenderConnection",
      items:  Array< {
        __typename: "Tender",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptTendersId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderType?:  {
      __typename: "ModelOrderTypeConnection",
      items:  Array< {
        __typename: "OrderType",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptOrderTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    discountID?: number | null,
    useServiceCharge?: boolean | null,
    serviceChargeId?: number | null,
    specialRequestID?: number | null,
    addressID?: number | null,
    ratingCount?: number | null,
    averageRating?: number | null,
    feesback?: string | null,
    simphonyAuthClientId?: string | null,
    simphonyAuthUsername?: string | null,
    simphonyAuthPassword?: string | null,
    simphonyAuthBaseURL?: string | null,
    orderTypeDineIn?: number | null,
    orderTypeTakeAway?: number | null,
    orderTypeDelivery?: number | null,
    simphonyTakeAwayRvc?: number | null,
    simphonyDeliveryRvc?: number | null,
    exportInterval?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    foodicsToken?: string | null,
    enableTableFoodics?: string | null,
    image?: string | null,
    logo?: string | null,
    album?: Array< string | null > | null,
    type?: string | null,
    rating?: number | null,
    location?: string | null,
    description?: string | null,
    areas?: Array< string | null > | null,
    seatTypes?: Array< string | null > | null,
    needAssistance?: boolean | null,
    spaBooking?: boolean | null,
    showerBooking?: boolean | null,
    cigarMenu?: boolean | null,
    precedence?: number | null,
    isPinRequired?: boolean | null,
    viewOnly?: boolean | null,
    openTime?: string | null,
    closeTime?: string | null,
    varifyForAmount?: number | null,
    varifyFirstOrder?: boolean | null,
    varifyNewAddress?: boolean | null,
    sliderImages?: Array< string | null > | null,
    receiveWaiters?: Array< string | null > | null,
    WaiterNotificationOption?: string | null,
    applyLoyalty?: boolean | null,
    rewardPoints?: number | null,
    moneySpent?: number | null,
    afterVat?: boolean | null,
    afterDeliveryFee?: boolean | null,
    beforePromoCode?: boolean | null,
    pointsCalcWithPromoCode?: boolean | null,
    isBusy?: boolean | null,
    allowOnlineOrdering?: boolean | null,
    minOrderVal?: number | null,
    vatPercentage?: number | null,
    serviceChargePercentage?: number | null,
    addDeliveryToVat?: boolean | null,
    categoryTagIDs?: Array< string | null > | null,
    parentConceptID: string,
    containerColor?: string | null,
    avgOrderCompletionEstimate?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteConceptSubscriptionVariables = {
  filter?: ModelSubscriptionConceptFilterInput | null,
};

export type OnDeleteConceptSubscription = {
  onDeleteConcept?:  {
    __typename: "Concept",
    id: string,
    accountID: string,
    name: string,
    kiosks?: Array< string | null > | null,
    merchantID?: string | null,
    merchantUsername?: string | null,
    merchantPassword?: string | null,
    merchantIntegrationNumber?: string | null,
    merchantAPIKey?: string | null,
    paymentSharePercentage?: number | null,
    simphonyServerBaseURL?: string | null,
    simphonyShortOrgName?: string | null,
    simphonyLocRef?: string | null,
    simphonyToken?: string | null,
    revenueCenterID?: number | null,
    employeeID?: number | null,
    tenderID?: number | null,
    tenders?:  {
      __typename: "ModelTenderConnection",
      items:  Array< {
        __typename: "Tender",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptTendersId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    orderType?:  {
      __typename: "ModelOrderTypeConnection",
      items:  Array< {
        __typename: "OrderType",
        id: string,
        name: string,
        simphonyID?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
        conceptOrderTypeId?: string | null,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    discountID?: number | null,
    useServiceCharge?: boolean | null,
    serviceChargeId?: number | null,
    specialRequestID?: number | null,
    addressID?: number | null,
    ratingCount?: number | null,
    averageRating?: number | null,
    feesback?: string | null,
    simphonyAuthClientId?: string | null,
    simphonyAuthUsername?: string | null,
    simphonyAuthPassword?: string | null,
    simphonyAuthBaseURL?: string | null,
    orderTypeDineIn?: number | null,
    orderTypeTakeAway?: number | null,
    orderTypeDelivery?: number | null,
    simphonyTakeAwayRvc?: number | null,
    simphonyDeliveryRvc?: number | null,
    exportInterval?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    foodicsToken?: string | null,
    enableTableFoodics?: string | null,
    image?: string | null,
    logo?: string | null,
    album?: Array< string | null > | null,
    type?: string | null,
    rating?: number | null,
    location?: string | null,
    description?: string | null,
    areas?: Array< string | null > | null,
    seatTypes?: Array< string | null > | null,
    needAssistance?: boolean | null,
    spaBooking?: boolean | null,
    showerBooking?: boolean | null,
    cigarMenu?: boolean | null,
    precedence?: number | null,
    isPinRequired?: boolean | null,
    viewOnly?: boolean | null,
    openTime?: string | null,
    closeTime?: string | null,
    varifyForAmount?: number | null,
    varifyFirstOrder?: boolean | null,
    varifyNewAddress?: boolean | null,
    sliderImages?: Array< string | null > | null,
    receiveWaiters?: Array< string | null > | null,
    WaiterNotificationOption?: string | null,
    applyLoyalty?: boolean | null,
    rewardPoints?: number | null,
    moneySpent?: number | null,
    afterVat?: boolean | null,
    afterDeliveryFee?: boolean | null,
    beforePromoCode?: boolean | null,
    pointsCalcWithPromoCode?: boolean | null,
    isBusy?: boolean | null,
    allowOnlineOrdering?: boolean | null,
    minOrderVal?: number | null,
    vatPercentage?: number | null,
    serviceChargePercentage?: number | null,
    addDeliveryToVat?: boolean | null,
    categoryTagIDs?: Array< string | null > | null,
    parentConceptID: string,
    containerColor?: string | null,
    avgOrderCompletionEstimate?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCategoryTagSubscriptionVariables = {
  filter?: ModelSubscriptionCategoryTagFilterInput | null,
};

export type OnCreateCategoryTagSubscription = {
  onCreateCategoryTag?:  {
    __typename: "CategoryTag",
    id: string,
    parentConceptID: string,
    name: string,
    image?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCategoryTagSubscriptionVariables = {
  filter?: ModelSubscriptionCategoryTagFilterInput | null,
};

export type OnUpdateCategoryTagSubscription = {
  onUpdateCategoryTag?:  {
    __typename: "CategoryTag",
    id: string,
    parentConceptID: string,
    name: string,
    image?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCategoryTagSubscriptionVariables = {
  filter?: ModelSubscriptionCategoryTagFilterInput | null,
};

export type OnDeleteCategoryTagSubscription = {
  onDeleteCategoryTag?:  {
    __typename: "CategoryTag",
    id: string,
    parentConceptID: string,
    name: string,
    image?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTenderSubscriptionVariables = {
  filter?: ModelSubscriptionTenderFilterInput | null,
};

export type OnCreateTenderSubscription = {
  onCreateTender?:  {
    __typename: "Tender",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptTendersId?: string | null,
  } | null,
};

export type OnUpdateTenderSubscriptionVariables = {
  filter?: ModelSubscriptionTenderFilterInput | null,
};

export type OnUpdateTenderSubscription = {
  onUpdateTender?:  {
    __typename: "Tender",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptTendersId?: string | null,
  } | null,
};

export type OnDeleteTenderSubscriptionVariables = {
  filter?: ModelSubscriptionTenderFilterInput | null,
};

export type OnDeleteTenderSubscription = {
  onDeleteTender?:  {
    __typename: "Tender",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptTendersId?: string | null,
  } | null,
};

export type OnCreateOrderTypeSubscriptionVariables = {
  filter?: ModelSubscriptionOrderTypeFilterInput | null,
};

export type OnCreateOrderTypeSubscription = {
  onCreateOrderType?:  {
    __typename: "OrderType",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptOrderTypeId?: string | null,
  } | null,
};

export type OnUpdateOrderTypeSubscriptionVariables = {
  filter?: ModelSubscriptionOrderTypeFilterInput | null,
};

export type OnUpdateOrderTypeSubscription = {
  onUpdateOrderType?:  {
    __typename: "OrderType",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptOrderTypeId?: string | null,
  } | null,
};

export type OnDeleteOrderTypeSubscriptionVariables = {
  filter?: ModelSubscriptionOrderTypeFilterInput | null,
};

export type OnDeleteOrderTypeSubscription = {
  onDeleteOrderType?:  {
    __typename: "OrderType",
    id: string,
    name: string,
    simphonyID?: string | null,
    precedence?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    conceptOrderTypeId?: string | null,
  } | null,
};

export type OnCreateParentConceptSubscriptionVariables = {
  filter?: ModelSubscriptionParentConceptFilterInput | null,
};

export type OnCreateParentConceptSubscription = {
  onCreateParentConcept?:  {
    __typename: "ParentConcept",
    id: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    image?: string | null,
    name?: string | null,
    logo?: string | null,
    type?: string | null,
    venues?:  {
      __typename: "ModelConceptConnection",
      items:  Array< {
        __typename: "Concept",
        id: string,
        accountID: string,
        name: string,
        kiosks?: Array< string | null > | null,
        merchantID?: string | null,
        merchantUsername?: string | null,
        merchantPassword?: string | null,
        merchantIntegrationNumber?: string | null,
        merchantAPIKey?: string | null,
        paymentSharePercentage?: number | null,
        simphonyServerBaseURL?: string | null,
        simphonyShortOrgName?: string | null,
        simphonyLocRef?: string | null,
        simphonyToken?: string | null,
        revenueCenterID?: number | null,
        employeeID?: number | null,
        tenderID?: number | null,
        tenders?:  {
          __typename: "ModelTenderConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderType?:  {
          __typename: "ModelOrderTypeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        discountID?: number | null,
        useServiceCharge?: boolean | null,
        serviceChargeId?: number | null,
        specialRequestID?: number | null,
        addressID?: number | null,
        ratingCount?: number | null,
        averageRating?: number | null,
        feesback?: string | null,
        simphonyAuthClientId?: string | null,
        simphonyAuthUsername?: string | null,
        simphonyAuthPassword?: string | null,
        simphonyAuthBaseURL?: string | null,
        orderTypeDineIn?: number | null,
        orderTypeTakeAway?: number | null,
        orderTypeDelivery?: number | null,
        simphonyTakeAwayRvc?: number | null,
        simphonyDeliveryRvc?: number | null,
        exportInterval?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        foodicsToken?: string | null,
        enableTableFoodics?: string | null,
        image?: string | null,
        logo?: string | null,
        album?: Array< string | null > | null,
        type?: string | null,
        rating?: number | null,
        location?: string | null,
        description?: string | null,
        areas?: Array< string | null > | null,
        seatTypes?: Array< string | null > | null,
        needAssistance?: boolean | null,
        spaBooking?: boolean | null,
        showerBooking?: boolean | null,
        cigarMenu?: boolean | null,
        precedence?: number | null,
        isPinRequired?: boolean | null,
        viewOnly?: boolean | null,
        openTime?: string | null,
        closeTime?: string | null,
        varifyForAmount?: number | null,
        varifyFirstOrder?: boolean | null,
        varifyNewAddress?: boolean | null,
        sliderImages?: Array< string | null > | null,
        receiveWaiters?: Array< string | null > | null,
        WaiterNotificationOption?: string | null,
        applyLoyalty?: boolean | null,
        rewardPoints?: number | null,
        moneySpent?: number | null,
        afterVat?: boolean | null,
        afterDeliveryFee?: boolean | null,
        beforePromoCode?: boolean | null,
        pointsCalcWithPromoCode?: boolean | null,
        isBusy?: boolean | null,
        allowOnlineOrdering?: boolean | null,
        minOrderVal?: number | null,
        vatPercentage?: number | null,
        serviceChargePercentage?: number | null,
        addDeliveryToVat?: boolean | null,
        categoryTagIDs?: Array< string | null > | null,
        parentConceptID: string,
        containerColor?: string | null,
        avgOrderCompletionEstimate?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    index?: number | null,
    categoryTags?:  {
      __typename: "ModelCategoryTagConnection",
      items:  Array< {
        __typename: "CategoryTag",
        id: string,
        parentConceptID: string,
        name: string,
        image?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateParentConceptSubscriptionVariables = {
  filter?: ModelSubscriptionParentConceptFilterInput | null,
};

export type OnUpdateParentConceptSubscription = {
  onUpdateParentConcept?:  {
    __typename: "ParentConcept",
    id: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    image?: string | null,
    name?: string | null,
    logo?: string | null,
    type?: string | null,
    venues?:  {
      __typename: "ModelConceptConnection",
      items:  Array< {
        __typename: "Concept",
        id: string,
        accountID: string,
        name: string,
        kiosks?: Array< string | null > | null,
        merchantID?: string | null,
        merchantUsername?: string | null,
        merchantPassword?: string | null,
        merchantIntegrationNumber?: string | null,
        merchantAPIKey?: string | null,
        paymentSharePercentage?: number | null,
        simphonyServerBaseURL?: string | null,
        simphonyShortOrgName?: string | null,
        simphonyLocRef?: string | null,
        simphonyToken?: string | null,
        revenueCenterID?: number | null,
        employeeID?: number | null,
        tenderID?: number | null,
        tenders?:  {
          __typename: "ModelTenderConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderType?:  {
          __typename: "ModelOrderTypeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        discountID?: number | null,
        useServiceCharge?: boolean | null,
        serviceChargeId?: number | null,
        specialRequestID?: number | null,
        addressID?: number | null,
        ratingCount?: number | null,
        averageRating?: number | null,
        feesback?: string | null,
        simphonyAuthClientId?: string | null,
        simphonyAuthUsername?: string | null,
        simphonyAuthPassword?: string | null,
        simphonyAuthBaseURL?: string | null,
        orderTypeDineIn?: number | null,
        orderTypeTakeAway?: number | null,
        orderTypeDelivery?: number | null,
        simphonyTakeAwayRvc?: number | null,
        simphonyDeliveryRvc?: number | null,
        exportInterval?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        foodicsToken?: string | null,
        enableTableFoodics?: string | null,
        image?: string | null,
        logo?: string | null,
        album?: Array< string | null > | null,
        type?: string | null,
        rating?: number | null,
        location?: string | null,
        description?: string | null,
        areas?: Array< string | null > | null,
        seatTypes?: Array< string | null > | null,
        needAssistance?: boolean | null,
        spaBooking?: boolean | null,
        showerBooking?: boolean | null,
        cigarMenu?: boolean | null,
        precedence?: number | null,
        isPinRequired?: boolean | null,
        viewOnly?: boolean | null,
        openTime?: string | null,
        closeTime?: string | null,
        varifyForAmount?: number | null,
        varifyFirstOrder?: boolean | null,
        varifyNewAddress?: boolean | null,
        sliderImages?: Array< string | null > | null,
        receiveWaiters?: Array< string | null > | null,
        WaiterNotificationOption?: string | null,
        applyLoyalty?: boolean | null,
        rewardPoints?: number | null,
        moneySpent?: number | null,
        afterVat?: boolean | null,
        afterDeliveryFee?: boolean | null,
        beforePromoCode?: boolean | null,
        pointsCalcWithPromoCode?: boolean | null,
        isBusy?: boolean | null,
        allowOnlineOrdering?: boolean | null,
        minOrderVal?: number | null,
        vatPercentage?: number | null,
        serviceChargePercentage?: number | null,
        addDeliveryToVat?: boolean | null,
        categoryTagIDs?: Array< string | null > | null,
        parentConceptID: string,
        containerColor?: string | null,
        avgOrderCompletionEstimate?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    index?: number | null,
    categoryTags?:  {
      __typename: "ModelCategoryTagConnection",
      items:  Array< {
        __typename: "CategoryTag",
        id: string,
        parentConceptID: string,
        name: string,
        image?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteParentConceptSubscriptionVariables = {
  filter?: ModelSubscriptionParentConceptFilterInput | null,
};

export type OnDeleteParentConceptSubscription = {
  onDeleteParentConcept?:  {
    __typename: "ParentConcept",
    id: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    image?: string | null,
    name?: string | null,
    logo?: string | null,
    type?: string | null,
    venues?:  {
      __typename: "ModelConceptConnection",
      items:  Array< {
        __typename: "Concept",
        id: string,
        accountID: string,
        name: string,
        kiosks?: Array< string | null > | null,
        merchantID?: string | null,
        merchantUsername?: string | null,
        merchantPassword?: string | null,
        merchantIntegrationNumber?: string | null,
        merchantAPIKey?: string | null,
        paymentSharePercentage?: number | null,
        simphonyServerBaseURL?: string | null,
        simphonyShortOrgName?: string | null,
        simphonyLocRef?: string | null,
        simphonyToken?: string | null,
        revenueCenterID?: number | null,
        employeeID?: number | null,
        tenderID?: number | null,
        tenders?:  {
          __typename: "ModelTenderConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        orderType?:  {
          __typename: "ModelOrderTypeConnection",
          nextToken?: string | null,
          startedAt?: number | null,
        } | null,
        discountID?: number | null,
        useServiceCharge?: boolean | null,
        serviceChargeId?: number | null,
        specialRequestID?: number | null,
        addressID?: number | null,
        ratingCount?: number | null,
        averageRating?: number | null,
        feesback?: string | null,
        simphonyAuthClientId?: string | null,
        simphonyAuthUsername?: string | null,
        simphonyAuthPassword?: string | null,
        simphonyAuthBaseURL?: string | null,
        orderTypeDineIn?: number | null,
        orderTypeTakeAway?: number | null,
        orderTypeDelivery?: number | null,
        simphonyTakeAwayRvc?: number | null,
        simphonyDeliveryRvc?: number | null,
        exportInterval?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        foodicsToken?: string | null,
        enableTableFoodics?: string | null,
        image?: string | null,
        logo?: string | null,
        album?: Array< string | null > | null,
        type?: string | null,
        rating?: number | null,
        location?: string | null,
        description?: string | null,
        areas?: Array< string | null > | null,
        seatTypes?: Array< string | null > | null,
        needAssistance?: boolean | null,
        spaBooking?: boolean | null,
        showerBooking?: boolean | null,
        cigarMenu?: boolean | null,
        precedence?: number | null,
        isPinRequired?: boolean | null,
        viewOnly?: boolean | null,
        openTime?: string | null,
        closeTime?: string | null,
        varifyForAmount?: number | null,
        varifyFirstOrder?: boolean | null,
        varifyNewAddress?: boolean | null,
        sliderImages?: Array< string | null > | null,
        receiveWaiters?: Array< string | null > | null,
        WaiterNotificationOption?: string | null,
        applyLoyalty?: boolean | null,
        rewardPoints?: number | null,
        moneySpent?: number | null,
        afterVat?: boolean | null,
        afterDeliveryFee?: boolean | null,
        beforePromoCode?: boolean | null,
        pointsCalcWithPromoCode?: boolean | null,
        isBusy?: boolean | null,
        allowOnlineOrdering?: boolean | null,
        minOrderVal?: number | null,
        vatPercentage?: number | null,
        serviceChargePercentage?: number | null,
        addDeliveryToVat?: boolean | null,
        categoryTagIDs?: Array< string | null > | null,
        parentConceptID: string,
        containerColor?: string | null,
        avgOrderCompletionEstimate?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    index?: number | null,
    categoryTags?:  {
      __typename: "ModelCategoryTagConnection",
      items:  Array< {
        __typename: "CategoryTag",
        id: string,
        parentConceptID: string,
        name: string,
        image?: string | null,
        precedence?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTimelineSubscriptionVariables = {
  filter?: ModelSubscriptionTimelineFilterInput | null,
};

export type OnCreateTimelineSubscription = {
  onCreateTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    transactionId?: string | null,
    customerId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTimelineSubscriptionVariables = {
  filter?: ModelSubscriptionTimelineFilterInput | null,
};

export type OnUpdateTimelineSubscription = {
  onUpdateTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    transactionId?: string | null,
    customerId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTimelineSubscriptionVariables = {
  filter?: ModelSubscriptionTimelineFilterInput | null,
};

export type OnDeleteTimelineSubscription = {
  onDeleteTimeline?:  {
    __typename: "Timeline",
    id: string,
    actionName: string,
    oldStatus: string,
    newStatus: string,
    bookingId?: string | null,
    transactionId?: string | null,
    customerId?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCallSubscriptionVariables = {
  filter?: ModelSubscriptionCallFilterInput | null,
};

export type OnCreateCallSubscription = {
  onCreateCall?:  {
    __typename: "Call",
    id: string,
    phone_number?: string | null,
    customer?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      autoGeneratedPassword: boolean,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    endedAt?: string | null,
    duration?: string | null,
    date: string,
    employeeId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    callCustomerId?: string | null,
  } | null,
};

export type OnUpdateCallSubscriptionVariables = {
  filter?: ModelSubscriptionCallFilterInput | null,
};

export type OnUpdateCallSubscription = {
  onUpdateCall?:  {
    __typename: "Call",
    id: string,
    phone_number?: string | null,
    customer?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      autoGeneratedPassword: boolean,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    endedAt?: string | null,
    duration?: string | null,
    date: string,
    employeeId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    callCustomerId?: string | null,
  } | null,
};

export type OnDeleteCallSubscriptionVariables = {
  filter?: ModelSubscriptionCallFilterInput | null,
};

export type OnDeleteCallSubscription = {
  onDeleteCall?:  {
    __typename: "Call",
    id: string,
    phone_number?: string | null,
    customer?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      autoGeneratedPassword: boolean,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    endedAt?: string | null,
    duration?: string | null,
    date: string,
    employeeId: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
    callCustomerId?: string | null,
  } | null,
};

export type OnCreateAdminRoleSubscriptionVariables = {
  filter?: ModelSubscriptionAdminRoleFilterInput | null,
};

export type OnCreateAdminRoleSubscription = {
  onCreateAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAdminRoleSubscriptionVariables = {
  filter?: ModelSubscriptionAdminRoleFilterInput | null,
};

export type OnUpdateAdminRoleSubscription = {
  onUpdateAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAdminRoleSubscriptionVariables = {
  filter?: ModelSubscriptionAdminRoleFilterInput | null,
};

export type OnDeleteAdminRoleSubscription = {
  onDeleteAdminRole?:  {
    __typename: "AdminRole",
    id: string,
    name?: string | null,
    description?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAdminGroupSubscriptionVariables = {
  filter?: ModelSubscriptionAdminGroupFilterInput | null,
};

export type OnCreateAdminGroupSubscription = {
  onCreateAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAdminGroupSubscriptionVariables = {
  filter?: ModelSubscriptionAdminGroupFilterInput | null,
};

export type OnUpdateAdminGroupSubscription = {
  onUpdateAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAdminGroupSubscriptionVariables = {
  filter?: ModelSubscriptionAdminGroupFilterInput | null,
};

export type OnDeleteAdminGroupSubscription = {
  onDeleteAdminGroup?:  {
    __typename: "AdminGroup",
    id: string,
    name?: string | null,
    description?: string | null,
    roles?: Array< string | null > | null,
    users?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserConceptsSubscriptionVariables = {
  filter?: ModelSubscriptionUserConceptsFilterInput | null,
};

export type OnCreateUserConceptsSubscription = {
  onCreateUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    parentConcepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserConceptsSubscriptionVariables = {
  filter?: ModelSubscriptionUserConceptsFilterInput | null,
};

export type OnUpdateUserConceptsSubscription = {
  onUpdateUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    parentConcepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserConceptsSubscriptionVariables = {
  filter?: ModelSubscriptionUserConceptsFilterInput | null,
};

export type OnDeleteUserConceptsSubscription = {
  onDeleteUserConcepts?:  {
    __typename: "UserConcepts",
    id: string,
    defaultConcept?: string | null,
    concepts?: Array< string | null > | null,
    parentConcepts?: Array< string | null > | null,
    conceptsRoles?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateTransactionSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionFilterInput | null,
};

export type OnCreateTransactionSubscription = {
  onCreateTransaction?:  {
    __typename: "Transaction",
    id: string,
    transactionID: string,
    date: string,
    guestName: string,
    guestPhone: string,
    guestID: string,
    guestGroup: string,
    guestsNames?: Array< string | null > | null,
    bookingID?: string | null,
    bookingDate?: string | null,
    amount_cents: string,
    status: boolean,
    failureReason?: string | null,
    type: string,
    timeSlots?: Array< string | null > | null,
    tables?: Array< string | null > | null,
    conceptID: string,
    currency: string,
    refund: boolean,
    reference_orderID?: string | null,
    ownerID: string,
    refunded_amount_cents: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateTransactionSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionFilterInput | null,
};

export type OnUpdateTransactionSubscription = {
  onUpdateTransaction?:  {
    __typename: "Transaction",
    id: string,
    transactionID: string,
    date: string,
    guestName: string,
    guestPhone: string,
    guestID: string,
    guestGroup: string,
    guestsNames?: Array< string | null > | null,
    bookingID?: string | null,
    bookingDate?: string | null,
    amount_cents: string,
    status: boolean,
    failureReason?: string | null,
    type: string,
    timeSlots?: Array< string | null > | null,
    tables?: Array< string | null > | null,
    conceptID: string,
    currency: string,
    refund: boolean,
    reference_orderID?: string | null,
    ownerID: string,
    refunded_amount_cents: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteTransactionSubscriptionVariables = {
  filter?: ModelSubscriptionTransactionFilterInput | null,
};

export type OnDeleteTransactionSubscription = {
  onDeleteTransaction?:  {
    __typename: "Transaction",
    id: string,
    transactionID: string,
    date: string,
    guestName: string,
    guestPhone: string,
    guestID: string,
    guestGroup: string,
    guestsNames?: Array< string | null > | null,
    bookingID?: string | null,
    bookingDate?: string | null,
    amount_cents: string,
    status: boolean,
    failureReason?: string | null,
    type: string,
    timeSlots?: Array< string | null > | null,
    tables?: Array< string | null > | null,
    conceptID: string,
    currency: string,
    refund: boolean,
    reference_orderID?: string | null,
    ownerID: string,
    refunded_amount_cents: string,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePaymentStatSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentStatFilterInput | null,
};

export type OnCreatePaymentStatSubscription = {
  onCreatePaymentStat?:  {
    __typename: "PaymentStat",
    id: string,
    accountID?: string | null,
    conceptID: string,
    year: number,
    month: number,
    monthName: string,
    earning: number,
    refund: number,
    earningTrxCount: number,
    refundTrxCount: number,
    deleted?: string | null,
    createdAt: string,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePaymentStatSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentStatFilterInput | null,
};

export type OnUpdatePaymentStatSubscription = {
  onUpdatePaymentStat?:  {
    __typename: "PaymentStat",
    id: string,
    accountID?: string | null,
    conceptID: string,
    year: number,
    month: number,
    monthName: string,
    earning: number,
    refund: number,
    earningTrxCount: number,
    refundTrxCount: number,
    deleted?: string | null,
    createdAt: string,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePaymentStatSubscriptionVariables = {
  filter?: ModelSubscriptionPaymentStatFilterInput | null,
};

export type OnDeletePaymentStatSubscription = {
  onDeletePaymentStat?:  {
    __typename: "PaymentStat",
    id: string,
    accountID?: string | null,
    conceptID: string,
    year: number,
    month: number,
    monthName: string,
    earning: number,
    refund: number,
    earningTrxCount: number,
    refundTrxCount: number,
    deleted?: string | null,
    createdAt: string,
    createdByID?: string | null,
    createdByName?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    id: string,
    conceptID: string,
    parentConceptID: string,
    preparationAreaID?: string | null,
    subCategory?: string | null,
    name: string,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    darkImage?: string | null,
    menuItems?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        conceptID: string,
        kioskID?: string | null,
        name: string,
        description?: string | null,
        image?: string | null,
        precedence?: string | null,
        categoryID: string,
        categoryName?: string | null,
        outOfStock?: boolean | null,
        requiredPoints?: number | null,
        symphonyID?: string | null,
        comboItemID?: string | null,
        ratings?: Array< string | null > | null,
        prices?: Array< string | null > | null,
        choiceGroups?: Array< string | null > | null,
        pricesItems?:  Array< {
          __typename: "Price",
          id: string,
          conceptID: string,
          parentConceptID: string,
          name: string,
          multiLanguages?: string | null,
          price: number,
          symphonyID: string,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null > | null,
        choiceGroupsItems?:  Array< {
          __typename: "ChoiceGroups",
          id: string,
          conceptID: string,
          parentConceptID: string,
          name: string,
          required?: boolean | null,
          maxNumberOfChoices?: number | null,
          minNumberOfChoices?: number | null,
          precedence?: string | null,
          type?: string | null,
          choices?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          multiLanguages?: string | null,
          simphonyID?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null > | null,
        choiceGroupsRules?:  Array< {
          __typename: "ChoiceGroupRules",
          id: string,
          minNumberOfChoices?: number | null,
          maxNumberOfChoices?: number | null,
        } | null > | null,
        ExtraChoiceGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        hasLimitedQuantity?: boolean | null,
        quantity?: number | null,
        autoRestock?: boolean | null,
        restockQuantity?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        quantityOrdered?: number | null,
        performancePerDay?: Array< string | null > | null,
        multiLanguages?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    precedence: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    symphonyID?: string | null,
    isHidden?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    id: string,
    conceptID: string,
    parentConceptID: string,
    preparationAreaID?: string | null,
    subCategory?: string | null,
    name: string,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    darkImage?: string | null,
    menuItems?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        conceptID: string,
        kioskID?: string | null,
        name: string,
        description?: string | null,
        image?: string | null,
        precedence?: string | null,
        categoryID: string,
        categoryName?: string | null,
        outOfStock?: boolean | null,
        requiredPoints?: number | null,
        symphonyID?: string | null,
        comboItemID?: string | null,
        ratings?: Array< string | null > | null,
        prices?: Array< string | null > | null,
        choiceGroups?: Array< string | null > | null,
        pricesItems?:  Array< {
          __typename: "Price",
          id: string,
          conceptID: string,
          parentConceptID: string,
          name: string,
          multiLanguages?: string | null,
          price: number,
          symphonyID: string,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null > | null,
        choiceGroupsItems?:  Array< {
          __typename: "ChoiceGroups",
          id: string,
          conceptID: string,
          parentConceptID: string,
          name: string,
          required?: boolean | null,
          maxNumberOfChoices?: number | null,
          minNumberOfChoices?: number | null,
          precedence?: string | null,
          type?: string | null,
          choices?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          multiLanguages?: string | null,
          simphonyID?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null > | null,
        choiceGroupsRules?:  Array< {
          __typename: "ChoiceGroupRules",
          id: string,
          minNumberOfChoices?: number | null,
          maxNumberOfChoices?: number | null,
        } | null > | null,
        ExtraChoiceGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        hasLimitedQuantity?: boolean | null,
        quantity?: number | null,
        autoRestock?: boolean | null,
        restockQuantity?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        quantityOrdered?: number | null,
        performancePerDay?: Array< string | null > | null,
        multiLanguages?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    precedence: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    symphonyID?: string | null,
    isHidden?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCategorySubscriptionVariables = {
  filter?: ModelSubscriptionCategoryFilterInput | null,
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    id: string,
    conceptID: string,
    parentConceptID: string,
    preparationAreaID?: string | null,
    subCategory?: string | null,
    name: string,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    darkImage?: string | null,
    menuItems?:  {
      __typename: "ModelMenuItemConnection",
      items:  Array< {
        __typename: "MenuItem",
        id: string,
        conceptID: string,
        kioskID?: string | null,
        name: string,
        description?: string | null,
        image?: string | null,
        precedence?: string | null,
        categoryID: string,
        categoryName?: string | null,
        outOfStock?: boolean | null,
        requiredPoints?: number | null,
        symphonyID?: string | null,
        comboItemID?: string | null,
        ratings?: Array< string | null > | null,
        prices?: Array< string | null > | null,
        choiceGroups?: Array< string | null > | null,
        pricesItems?:  Array< {
          __typename: "Price",
          id: string,
          conceptID: string,
          parentConceptID: string,
          name: string,
          multiLanguages?: string | null,
          price: number,
          symphonyID: string,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null > | null,
        choiceGroupsItems?:  Array< {
          __typename: "ChoiceGroups",
          id: string,
          conceptID: string,
          parentConceptID: string,
          name: string,
          required?: boolean | null,
          maxNumberOfChoices?: number | null,
          minNumberOfChoices?: number | null,
          precedence?: string | null,
          type?: string | null,
          choices?: Array< string | null > | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          multiLanguages?: string | null,
          simphonyID?: string | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null > | null,
        choiceGroupsRules?:  Array< {
          __typename: "ChoiceGroupRules",
          id: string,
          minNumberOfChoices?: number | null,
          maxNumberOfChoices?: number | null,
        } | null > | null,
        ExtraChoiceGroups?: Array< string | null > | null,
        enabled?: boolean | null,
        hasLimitedQuantity?: boolean | null,
        quantity?: number | null,
        autoRestock?: boolean | null,
        restockQuantity?: number | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        quantityOrdered?: number | null,
        performancePerDay?: Array< string | null > | null,
        multiLanguages?: string | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    precedence: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    symphonyID?: string | null,
    isHidden?: boolean | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateMenuItemSubscriptionVariables = {
  filter?: ModelSubscriptionMenuItemFilterInput | null,
};

export type OnCreateMenuItemSubscription = {
  onCreateMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    conceptID: string,
    kioskID?: string | null,
    name: string,
    description?: string | null,
    image?: string | null,
    precedence?: string | null,
    categoryID: string,
    categoryName?: string | null,
    outOfStock?: boolean | null,
    requiredPoints?: number | null,
    symphonyID?: string | null,
    comboItemID?: string | null,
    ratings?: Array< string | null > | null,
    prices?: Array< string | null > | null,
    choiceGroups?: Array< string | null > | null,
    pricesItems?:  Array< {
      __typename: "Price",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      multiLanguages?: string | null,
      price: number,
      symphonyID: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null > | null,
    choiceGroupsItems?:  Array< {
      __typename: "ChoiceGroups",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      required?: boolean | null,
      maxNumberOfChoices?: number | null,
      minNumberOfChoices?: number | null,
      precedence?: string | null,
      type?: string | null,
      choices?: Array< string | null > | null,
      choiceItems?:  {
        __typename: "ModelChoiceConnection",
        items:  Array< {
          __typename: "Choice",
          id: string,
          conceptID: string,
          name: string,
          price: number,
          precedence?: string | null,
          choiceGroupID: string,
          choiceGroupName?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          symphonyID?: string | null,
          multiLanguages?: string | null,
          definitionNumber?: number | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      simphonyID?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null > | null,
    choiceGroupsRules?:  Array< {
      __typename: "ChoiceGroupRules",
      id: string,
      minNumberOfChoices?: number | null,
      maxNumberOfChoices?: number | null,
    } | null > | null,
    ExtraChoiceGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    hasLimitedQuantity?: boolean | null,
    quantity?: number | null,
    autoRestock?: boolean | null,
    restockQuantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    quantityOrdered?: number | null,
    performancePerDay?: Array< string | null > | null,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMenuItemSubscriptionVariables = {
  filter?: ModelSubscriptionMenuItemFilterInput | null,
};

export type OnUpdateMenuItemSubscription = {
  onUpdateMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    conceptID: string,
    kioskID?: string | null,
    name: string,
    description?: string | null,
    image?: string | null,
    precedence?: string | null,
    categoryID: string,
    categoryName?: string | null,
    outOfStock?: boolean | null,
    requiredPoints?: number | null,
    symphonyID?: string | null,
    comboItemID?: string | null,
    ratings?: Array< string | null > | null,
    prices?: Array< string | null > | null,
    choiceGroups?: Array< string | null > | null,
    pricesItems?:  Array< {
      __typename: "Price",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      multiLanguages?: string | null,
      price: number,
      symphonyID: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null > | null,
    choiceGroupsItems?:  Array< {
      __typename: "ChoiceGroups",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      required?: boolean | null,
      maxNumberOfChoices?: number | null,
      minNumberOfChoices?: number | null,
      precedence?: string | null,
      type?: string | null,
      choices?: Array< string | null > | null,
      choiceItems?:  {
        __typename: "ModelChoiceConnection",
        items:  Array< {
          __typename: "Choice",
          id: string,
          conceptID: string,
          name: string,
          price: number,
          precedence?: string | null,
          choiceGroupID: string,
          choiceGroupName?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          symphonyID?: string | null,
          multiLanguages?: string | null,
          definitionNumber?: number | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      simphonyID?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null > | null,
    choiceGroupsRules?:  Array< {
      __typename: "ChoiceGroupRules",
      id: string,
      minNumberOfChoices?: number | null,
      maxNumberOfChoices?: number | null,
    } | null > | null,
    ExtraChoiceGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    hasLimitedQuantity?: boolean | null,
    quantity?: number | null,
    autoRestock?: boolean | null,
    restockQuantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    quantityOrdered?: number | null,
    performancePerDay?: Array< string | null > | null,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMenuItemSubscriptionVariables = {
  filter?: ModelSubscriptionMenuItemFilterInput | null,
};

export type OnDeleteMenuItemSubscription = {
  onDeleteMenuItem?:  {
    __typename: "MenuItem",
    id: string,
    conceptID: string,
    kioskID?: string | null,
    name: string,
    description?: string | null,
    image?: string | null,
    precedence?: string | null,
    categoryID: string,
    categoryName?: string | null,
    outOfStock?: boolean | null,
    requiredPoints?: number | null,
    symphonyID?: string | null,
    comboItemID?: string | null,
    ratings?: Array< string | null > | null,
    prices?: Array< string | null > | null,
    choiceGroups?: Array< string | null > | null,
    pricesItems?:  Array< {
      __typename: "Price",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      multiLanguages?: string | null,
      price: number,
      symphonyID: string,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null > | null,
    choiceGroupsItems?:  Array< {
      __typename: "ChoiceGroups",
      id: string,
      conceptID: string,
      parentConceptID: string,
      name: string,
      required?: boolean | null,
      maxNumberOfChoices?: number | null,
      minNumberOfChoices?: number | null,
      precedence?: string | null,
      type?: string | null,
      choices?: Array< string | null > | null,
      choiceItems?:  {
        __typename: "ModelChoiceConnection",
        items:  Array< {
          __typename: "Choice",
          id: string,
          conceptID: string,
          name: string,
          price: number,
          precedence?: string | null,
          choiceGroupID: string,
          choiceGroupName?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          symphonyID?: string | null,
          multiLanguages?: string | null,
          definitionNumber?: number | null,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      multiLanguages?: string | null,
      simphonyID?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null > | null,
    choiceGroupsRules?:  Array< {
      __typename: "ChoiceGroupRules",
      id: string,
      minNumberOfChoices?: number | null,
      maxNumberOfChoices?: number | null,
    } | null > | null,
    ExtraChoiceGroups?: Array< string | null > | null,
    enabled?: boolean | null,
    hasLimitedQuantity?: boolean | null,
    quantity?: number | null,
    autoRestock?: boolean | null,
    restockQuantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    quantityOrdered?: number | null,
    performancePerDay?: Array< string | null > | null,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePriceSubscriptionVariables = {
  filter?: ModelSubscriptionPriceFilterInput | null,
};

export type OnCreatePriceSubscription = {
  onCreatePrice?:  {
    __typename: "Price",
    id: string,
    conceptID: string,
    parentConceptID: string,
    name: string,
    multiLanguages?: string | null,
    price: number,
    symphonyID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePriceSubscriptionVariables = {
  filter?: ModelSubscriptionPriceFilterInput | null,
};

export type OnUpdatePriceSubscription = {
  onUpdatePrice?:  {
    __typename: "Price",
    id: string,
    conceptID: string,
    parentConceptID: string,
    name: string,
    multiLanguages?: string | null,
    price: number,
    symphonyID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePriceSubscriptionVariables = {
  filter?: ModelSubscriptionPriceFilterInput | null,
};

export type OnDeletePriceSubscription = {
  onDeletePrice?:  {
    __typename: "Price",
    id: string,
    conceptID: string,
    parentConceptID: string,
    name: string,
    multiLanguages?: string | null,
    price: number,
    symphonyID: string,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateChoiceGroupsSubscriptionVariables = {
  filter?: ModelSubscriptionChoiceGroupsFilterInput | null,
};

export type OnCreateChoiceGroupsSubscription = {
  onCreateChoiceGroups?:  {
    __typename: "ChoiceGroups",
    id: string,
    conceptID: string,
    parentConceptID: string,
    name: string,
    required?: boolean | null,
    maxNumberOfChoices?: number | null,
    minNumberOfChoices?: number | null,
    precedence?: string | null,
    type?: string | null,
    choices?: Array< string | null > | null,
    choiceItems?:  {
      __typename: "ModelChoiceConnection",
      items:  Array< {
        __typename: "Choice",
        id: string,
        conceptID: string,
        name: string,
        price: number,
        precedence?: string | null,
        choiceGroupID: string,
        choiceGroupName?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        symphonyID?: string | null,
        multiLanguages?: string | null,
        definitionNumber?: number | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    simphonyID?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateChoiceGroupsSubscriptionVariables = {
  filter?: ModelSubscriptionChoiceGroupsFilterInput | null,
};

export type OnUpdateChoiceGroupsSubscription = {
  onUpdateChoiceGroups?:  {
    __typename: "ChoiceGroups",
    id: string,
    conceptID: string,
    parentConceptID: string,
    name: string,
    required?: boolean | null,
    maxNumberOfChoices?: number | null,
    minNumberOfChoices?: number | null,
    precedence?: string | null,
    type?: string | null,
    choices?: Array< string | null > | null,
    choiceItems?:  {
      __typename: "ModelChoiceConnection",
      items:  Array< {
        __typename: "Choice",
        id: string,
        conceptID: string,
        name: string,
        price: number,
        precedence?: string | null,
        choiceGroupID: string,
        choiceGroupName?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        symphonyID?: string | null,
        multiLanguages?: string | null,
        definitionNumber?: number | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    simphonyID?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteChoiceGroupsSubscriptionVariables = {
  filter?: ModelSubscriptionChoiceGroupsFilterInput | null,
};

export type OnDeleteChoiceGroupsSubscription = {
  onDeleteChoiceGroups?:  {
    __typename: "ChoiceGroups",
    id: string,
    conceptID: string,
    parentConceptID: string,
    name: string,
    required?: boolean | null,
    maxNumberOfChoices?: number | null,
    minNumberOfChoices?: number | null,
    precedence?: string | null,
    type?: string | null,
    choices?: Array< string | null > | null,
    choiceItems?:  {
      __typename: "ModelChoiceConnection",
      items:  Array< {
        __typename: "Choice",
        id: string,
        conceptID: string,
        name: string,
        price: number,
        precedence?: string | null,
        choiceGroupID: string,
        choiceGroupName?: string | null,
        deleted?: string | null,
        createdAt: string,
        createdByID: string,
        createdByName: string,
        symphonyID?: string | null,
        multiLanguages?: string | null,
        definitionNumber?: number | null,
        updatedAt: string,
        _version: number,
        _deleted?: boolean | null,
        _lastChangedAt: number,
      } | null >,
      nextToken?: string | null,
      startedAt?: number | null,
    } | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    simphonyID?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateChoiceSubscriptionVariables = {
  filter?: ModelSubscriptionChoiceFilterInput | null,
};

export type OnCreateChoiceSubscription = {
  onCreateChoice?:  {
    __typename: "Choice",
    id: string,
    conceptID: string,
    name: string,
    price: number,
    precedence?: string | null,
    choiceGroupID: string,
    choiceGroupName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    symphonyID?: string | null,
    multiLanguages?: string | null,
    definitionNumber?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateChoiceSubscriptionVariables = {
  filter?: ModelSubscriptionChoiceFilterInput | null,
};

export type OnUpdateChoiceSubscription = {
  onUpdateChoice?:  {
    __typename: "Choice",
    id: string,
    conceptID: string,
    name: string,
    price: number,
    precedence?: string | null,
    choiceGroupID: string,
    choiceGroupName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    symphonyID?: string | null,
    multiLanguages?: string | null,
    definitionNumber?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteChoiceSubscriptionVariables = {
  filter?: ModelSubscriptionChoiceFilterInput | null,
};

export type OnDeleteChoiceSubscription = {
  onDeleteChoice?:  {
    __typename: "Choice",
    id: string,
    conceptID: string,
    name: string,
    price: number,
    precedence?: string | null,
    choiceGroupID: string,
    choiceGroupName?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    symphonyID?: string | null,
    multiLanguages?: string | null,
    definitionNumber?: number | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateCartSubscriptionVariables = {
  filter?: ModelSubscriptionCartFilterInput | null,
};

export type OnCreateCartSubscription = {
  onCreateCart?:  {
    __typename: "Cart",
    id: string,
    conceptID: string,
    userID: string,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      categoryID: string,
      name?: string | null,
      quantity: number,
      selectedChoiceGroups?:  Array< {
        __typename: "CartChoiceGroup",
        id: string,
        name?: string | null,
        selectedChoices?:  Array< {
          __typename: "CartChoice",
          id: string,
          name?: string | null,
          price?: number | null,
        } | null > | null,
        precedence?: string | null,
      } | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      type?: string | null,
    } | null > | null,
    specialRequest?: string | null,
    deliveryFee?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    totalPrice?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateCartSubscriptionVariables = {
  filter?: ModelSubscriptionCartFilterInput | null,
};

export type OnUpdateCartSubscription = {
  onUpdateCart?:  {
    __typename: "Cart",
    id: string,
    conceptID: string,
    userID: string,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      categoryID: string,
      name?: string | null,
      quantity: number,
      selectedChoiceGroups?:  Array< {
        __typename: "CartChoiceGroup",
        id: string,
        name?: string | null,
        selectedChoices?:  Array< {
          __typename: "CartChoice",
          id: string,
          name?: string | null,
          price?: number | null,
        } | null > | null,
        precedence?: string | null,
      } | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      type?: string | null,
    } | null > | null,
    specialRequest?: string | null,
    deliveryFee?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    totalPrice?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteCartSubscriptionVariables = {
  filter?: ModelSubscriptionCartFilterInput | null,
};

export type OnDeleteCartSubscription = {
  onDeleteCart?:  {
    __typename: "Cart",
    id: string,
    conceptID: string,
    userID: string,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      categoryID: string,
      name?: string | null,
      quantity: number,
      selectedChoiceGroups?:  Array< {
        __typename: "CartChoiceGroup",
        id: string,
        name?: string | null,
        selectedChoices?:  Array< {
          __typename: "CartChoice",
          id: string,
          name?: string | null,
          price?: number | null,
        } | null > | null,
        precedence?: string | null,
      } | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      type?: string | null,
    } | null > | null,
    specialRequest?: string | null,
    deliveryFee?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    totalPrice?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateAdminStatusSubscriptionVariables = {
  filter?: ModelSubscriptionAdminStatusFilterInput | null,
};

export type OnCreateAdminStatusSubscription = {
  onCreateAdminStatus?:  {
    __typename: "AdminStatus",
    id: string,
    sub: string,
    accountID: string,
    name?: string | null,
    averageCallTime?: number | null,
    numberOfCalls?: number | null,
    idelDuration?: number | null,
    activeDuration?: number | null,
    awayDuration?: number | null,
    lastCallTime?: number | null,
    agentBarAverageCallTime?: number | null,
    agentBarNumberOfCalls?: number | null,
    type?: string | null,
    waiterStatus?: string | null,
    totalAverageRatio?: number | null,
    performancePerDay?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateAdminStatusSubscriptionVariables = {
  filter?: ModelSubscriptionAdminStatusFilterInput | null,
};

export type OnUpdateAdminStatusSubscription = {
  onUpdateAdminStatus?:  {
    __typename: "AdminStatus",
    id: string,
    sub: string,
    accountID: string,
    name?: string | null,
    averageCallTime?: number | null,
    numberOfCalls?: number | null,
    idelDuration?: number | null,
    activeDuration?: number | null,
    awayDuration?: number | null,
    lastCallTime?: number | null,
    agentBarAverageCallTime?: number | null,
    agentBarNumberOfCalls?: number | null,
    type?: string | null,
    waiterStatus?: string | null,
    totalAverageRatio?: number | null,
    performancePerDay?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteAdminStatusSubscriptionVariables = {
  filter?: ModelSubscriptionAdminStatusFilterInput | null,
};

export type OnDeleteAdminStatusSubscription = {
  onDeleteAdminStatus?:  {
    __typename: "AdminStatus",
    id: string,
    sub: string,
    accountID: string,
    name?: string | null,
    averageCallTime?: number | null,
    numberOfCalls?: number | null,
    idelDuration?: number | null,
    activeDuration?: number | null,
    awayDuration?: number | null,
    lastCallTime?: number | null,
    agentBarAverageCallTime?: number | null,
    agentBarNumberOfCalls?: number | null,
    type?: string | null,
    waiterStatus?: string | null,
    totalAverageRatio?: number | null,
    performancePerDay?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateOnlineOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOnlineOrderFilterInput | null,
};

export type OnCreateOnlineOrderSubscription = {
  onCreateOnlineOrder?:  {
    __typename: "OnlineOrder",
    id: string,
    conceptID: string,
    statusID?: string | null,
    status?: string | null,
    reason?: string | null,
    orderType?: string | null,
    channel?: string | null,
    userID: string,
    userName?: string | null,
    userPhoneNumber?: string | null,
    dispatcherID?: string | null,
    dispatcherName?: string | null,
    totalAmount?: number | null,
    discountedValue?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    tax?: number | null,
    deliveryFee?: number | null,
    orderTime?: string | null,
    executionArn?: string | null,
    specialRequest?: string | null,
    orderAddress?: string | null,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      categoryID: string,
      name?: string | null,
      quantity: number,
      selectedChoiceGroups?:  Array< {
        __typename: "CartChoiceGroup",
        id: string,
        name?: string | null,
        selectedChoices?:  Array< {
          __typename: "CartChoice",
          id: string,
          name?: string | null,
          price?: number | null,
        } | null > | null,
        precedence?: string | null,
      } | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      type?: string | null,
    } | null > | null,
    discountID?: number | null,
    amount_cents?: string | null,
    transactionID?: string | null,
    paymentStatus?: boolean | null,
    paymentType?: string | null,
    referenceId?: string | null,
    cardHolderNumber?: string | null,
    cardHolderName?: string | null,
    cardSchemeName?: string | null,
    deleted?: string | null,
    createdAt: string,
    sentToKitchenAt?: string | null,
    readyAt?: string | null,
    outForDeliveryAt?: string | null,
    deliveredAt?: string | null,
    promoCodeID?: string | null,
    promoCodeName?: string | null,
    promoCodeAppliedTo?: string | null,
    rating?: number | null,
    isReview?: boolean | null,
    reviewID?: string | null,
    review?:  {
      __typename: "Review",
      id: string,
      conceptID?: string | null,
      conceptName?: string | null,
      accountID?: string | null,
      bookingID?: string | null,
      parentConceptID?: string | null,
      guestName?: string | null,
      guestId?: string | null,
      reason?: Array< string | null > | null,
      value?: string | null,
      userID?: string | null,
      orderID?: string | null,
      rate?: number | null,
      feedback?: string | null,
      satisfaction?: string | null,
      thoughts?: string | null,
      itemReviews?:  Array< {
        __typename: "ItemReview",
        itemID: string,
        rate?: number | null,
        satisfaction?: string | null,
        feedback?: string | null,
        multiLanguages?: string | null,
      } | null > | null,
      photos?: Array< string | null > | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      phoneUser?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdByID: string,
    createdByName: string,
    tmpField?: string | null,
    otp?: string | null,
    avgOrderCompletionEstimate?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateOnlineOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOnlineOrderFilterInput | null,
};

export type OnUpdateOnlineOrderSubscription = {
  onUpdateOnlineOrder?:  {
    __typename: "OnlineOrder",
    id: string,
    conceptID: string,
    statusID?: string | null,
    status?: string | null,
    reason?: string | null,
    orderType?: string | null,
    channel?: string | null,
    userID: string,
    userName?: string | null,
    userPhoneNumber?: string | null,
    dispatcherID?: string | null,
    dispatcherName?: string | null,
    totalAmount?: number | null,
    discountedValue?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    tax?: number | null,
    deliveryFee?: number | null,
    orderTime?: string | null,
    executionArn?: string | null,
    specialRequest?: string | null,
    orderAddress?: string | null,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      categoryID: string,
      name?: string | null,
      quantity: number,
      selectedChoiceGroups?:  Array< {
        __typename: "CartChoiceGroup",
        id: string,
        name?: string | null,
        selectedChoices?:  Array< {
          __typename: "CartChoice",
          id: string,
          name?: string | null,
          price?: number | null,
        } | null > | null,
        precedence?: string | null,
      } | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      type?: string | null,
    } | null > | null,
    discountID?: number | null,
    amount_cents?: string | null,
    transactionID?: string | null,
    paymentStatus?: boolean | null,
    paymentType?: string | null,
    referenceId?: string | null,
    cardHolderNumber?: string | null,
    cardHolderName?: string | null,
    cardSchemeName?: string | null,
    deleted?: string | null,
    createdAt: string,
    sentToKitchenAt?: string | null,
    readyAt?: string | null,
    outForDeliveryAt?: string | null,
    deliveredAt?: string | null,
    promoCodeID?: string | null,
    promoCodeName?: string | null,
    promoCodeAppliedTo?: string | null,
    rating?: number | null,
    isReview?: boolean | null,
    reviewID?: string | null,
    review?:  {
      __typename: "Review",
      id: string,
      conceptID?: string | null,
      conceptName?: string | null,
      accountID?: string | null,
      bookingID?: string | null,
      parentConceptID?: string | null,
      guestName?: string | null,
      guestId?: string | null,
      reason?: Array< string | null > | null,
      value?: string | null,
      userID?: string | null,
      orderID?: string | null,
      rate?: number | null,
      feedback?: string | null,
      satisfaction?: string | null,
      thoughts?: string | null,
      itemReviews?:  Array< {
        __typename: "ItemReview",
        itemID: string,
        rate?: number | null,
        satisfaction?: string | null,
        feedback?: string | null,
        multiLanguages?: string | null,
      } | null > | null,
      photos?: Array< string | null > | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      phoneUser?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdByID: string,
    createdByName: string,
    tmpField?: string | null,
    otp?: string | null,
    avgOrderCompletionEstimate?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteOnlineOrderSubscriptionVariables = {
  filter?: ModelSubscriptionOnlineOrderFilterInput | null,
};

export type OnDeleteOnlineOrderSubscription = {
  onDeleteOnlineOrder?:  {
    __typename: "OnlineOrder",
    id: string,
    conceptID: string,
    statusID?: string | null,
    status?: string | null,
    reason?: string | null,
    orderType?: string | null,
    channel?: string | null,
    userID: string,
    userName?: string | null,
    userPhoneNumber?: string | null,
    dispatcherID?: string | null,
    dispatcherName?: string | null,
    totalAmount?: number | null,
    discountedValue?: number | null,
    subTotal?: number | null,
    subTaxTotal?: number | null,
    tax?: number | null,
    deliveryFee?: number | null,
    orderTime?: string | null,
    executionArn?: string | null,
    specialRequest?: string | null,
    orderAddress?: string | null,
    orderedItems?:  Array< {
      __typename: "CartItem",
      menuItemID: string,
      categoryID: string,
      name?: string | null,
      quantity: number,
      selectedChoiceGroups?:  Array< {
        __typename: "CartChoiceGroup",
        id: string,
        name?: string | null,
        selectedChoices?:  Array< {
          __typename: "CartChoice",
          id: string,
          name?: string | null,
          price?: number | null,
        } | null > | null,
        precedence?: string | null,
      } | null > | null,
      price: number,
      priceName?: string | null,
      priceID?: string | null,
      priceMultiLanguages?: string | null,
      multiLanguages?: string | null,
      notes?: string | null,
      image?: string | null,
      type?: string | null,
    } | null > | null,
    discountID?: number | null,
    amount_cents?: string | null,
    transactionID?: string | null,
    paymentStatus?: boolean | null,
    paymentType?: string | null,
    referenceId?: string | null,
    cardHolderNumber?: string | null,
    cardHolderName?: string | null,
    cardSchemeName?: string | null,
    deleted?: string | null,
    createdAt: string,
    sentToKitchenAt?: string | null,
    readyAt?: string | null,
    outForDeliveryAt?: string | null,
    deliveredAt?: string | null,
    promoCodeID?: string | null,
    promoCodeName?: string | null,
    promoCodeAppliedTo?: string | null,
    rating?: number | null,
    isReview?: boolean | null,
    reviewID?: string | null,
    review?:  {
      __typename: "Review",
      id: string,
      conceptID?: string | null,
      conceptName?: string | null,
      accountID?: string | null,
      bookingID?: string | null,
      parentConceptID?: string | null,
      guestName?: string | null,
      guestId?: string | null,
      reason?: Array< string | null > | null,
      value?: string | null,
      userID?: string | null,
      orderID?: string | null,
      rate?: number | null,
      feedback?: string | null,
      satisfaction?: string | null,
      thoughts?: string | null,
      itemReviews?:  Array< {
        __typename: "ItemReview",
        itemID: string,
        rate?: number | null,
        satisfaction?: string | null,
        feedback?: string | null,
        multiLanguages?: string | null,
      } | null > | null,
      photos?: Array< string | null > | null,
      multiLanguages?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      phoneUser?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdByID: string,
    createdByName: string,
    tmpField?: string | null,
    otp?: string | null,
    avgOrderCompletionEstimate?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateOrderStatusSubscriptionVariables = {
  filter?: ModelSubscriptionOrderStatusFilterInput | null,
};

export type OnCreateOrderStatusSubscription = {
  onCreateOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    conceptID: string,
    name: string,
    color?: string | null,
    precedence?: number | null,
    accessibleStatus?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateOrderStatusSubscriptionVariables = {
  filter?: ModelSubscriptionOrderStatusFilterInput | null,
};

export type OnUpdateOrderStatusSubscription = {
  onUpdateOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    conceptID: string,
    name: string,
    color?: string | null,
    precedence?: number | null,
    accessibleStatus?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteOrderStatusSubscriptionVariables = {
  filter?: ModelSubscriptionOrderStatusFilterInput | null,
};

export type OnDeleteOrderStatusSubscription = {
  onDeleteOrderStatus?:  {
    __typename: "OrderStatus",
    id: string,
    conceptID: string,
    name: string,
    color?: string | null,
    precedence?: number | null,
    accessibleStatus?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateDiscountCouponSubscriptionVariables = {
  filter?: ModelSubscriptionDiscountCouponFilterInput | null,
};

export type OnCreateDiscountCouponSubscription = {
  onCreateDiscountCoupon?:  {
    __typename: "DiscountCoupon",
    id: string,
    discountPercent: string,
    usedBy?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateDiscountCouponSubscriptionVariables = {
  filter?: ModelSubscriptionDiscountCouponFilterInput | null,
};

export type OnUpdateDiscountCouponSubscription = {
  onUpdateDiscountCoupon?:  {
    __typename: "DiscountCoupon",
    id: string,
    discountPercent: string,
    usedBy?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteDiscountCouponSubscriptionVariables = {
  filter?: ModelSubscriptionDiscountCouponFilterInput | null,
};

export type OnDeleteDiscountCouponSubscription = {
  onDeleteDiscountCoupon?:  {
    __typename: "DiscountCoupon",
    id: string,
    discountPercent: string,
    usedBy?: Array< string | null > | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateZoneSubscriptionVariables = {
  filter?: ModelSubscriptionZoneFilterInput | null,
};

export type OnCreateZoneSubscription = {
  onCreateZone?:  {
    __typename: "Zone",
    id: string,
    conceptID: string,
    location: string,
    deliveryFee: number,
    concepts?:  Array< {
      __typename: "ZoneConcept",
      id: string,
      deliveryFee: number,
    } | null > | null,
    polygon?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateZoneSubscriptionVariables = {
  filter?: ModelSubscriptionZoneFilterInput | null,
};

export type OnUpdateZoneSubscription = {
  onUpdateZone?:  {
    __typename: "Zone",
    id: string,
    conceptID: string,
    location: string,
    deliveryFee: number,
    concepts?:  Array< {
      __typename: "ZoneConcept",
      id: string,
      deliveryFee: number,
    } | null > | null,
    polygon?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteZoneSubscriptionVariables = {
  filter?: ModelSubscriptionZoneFilterInput | null,
};

export type OnDeleteZoneSubscription = {
  onDeleteZone?:  {
    __typename: "Zone",
    id: string,
    conceptID: string,
    location: string,
    deliveryFee: number,
    concepts?:  Array< {
      __typename: "ZoneConcept",
      id: string,
      deliveryFee: number,
    } | null > | null,
    polygon?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateUserIPSubscriptionVariables = {
  filter?: ModelSubscriptionUserIPFilterInput | null,
};

export type OnCreateUserIPSubscription = {
  onCreateUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateUserIPSubscriptionVariables = {
  filter?: ModelSubscriptionUserIPFilterInput | null,
};

export type OnUpdateUserIPSubscription = {
  onUpdateUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteUserIPSubscriptionVariables = {
  filter?: ModelSubscriptionUserIPFilterInput | null,
};

export type OnDeleteUserIPSubscription = {
  onDeleteUserIP?:  {
    __typename: "UserIP",
    id: string,
    ip?: string | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateMainCategorySubscriptionVariables = {
  filter?: ModelSubscriptionMainCategoryFilterInput | null,
};

export type OnCreateMainCategorySubscription = {
  onCreateMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMainCategorySubscriptionVariables = {
  filter?: ModelSubscriptionMainCategoryFilterInput | null,
};

export type OnUpdateMainCategorySubscription = {
  onUpdateMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMainCategorySubscriptionVariables = {
  filter?: ModelSubscriptionMainCategoryFilterInput | null,
};

export type OnDeleteMainCategorySubscription = {
  onDeleteMainCategory?:  {
    __typename: "MainCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateSubCategorySubscriptionVariables = {
  filter?: ModelSubscriptionSubCategoryFilterInput | null,
};

export type OnCreateSubCategorySubscription = {
  onCreateSubCategory?:  {
    __typename: "SubCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    mainCategoryID?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateSubCategorySubscriptionVariables = {
  filter?: ModelSubscriptionSubCategoryFilterInput | null,
};

export type OnUpdateSubCategorySubscription = {
  onUpdateSubCategory?:  {
    __typename: "SubCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    mainCategoryID?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteSubCategorySubscriptionVariables = {
  filter?: ModelSubscriptionSubCategoryFilterInput | null,
};

export type OnDeleteSubCategorySubscription = {
  onDeleteSubCategory?:  {
    __typename: "SubCategory",
    id: string,
    conceptID: string,
    name: string,
    precedence: string,
    openTime?: string | null,
    closeTime?: string | null,
    mainCategoryID?: string | null,
    guestView?: boolean | null,
    guestOrder?: boolean | null,
    staffOrder?: boolean | null,
    image?: string | null,
    menuPDF?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    multiLanguages?: string | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateChannelSubscriptionVariables = {
  filter?: ModelSubscriptionChannelFilterInput | null,
};

export type OnCreateChannelSubscription = {
  onCreateChannel?:  {
    __typename: "Channel",
    id: string,
    name: string,
    description?: string | null,
    numberOfOrders?: number | null,
    isActive?: boolean | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateChannelSubscriptionVariables = {
  filter?: ModelSubscriptionChannelFilterInput | null,
};

export type OnUpdateChannelSubscription = {
  onUpdateChannel?:  {
    __typename: "Channel",
    id: string,
    name: string,
    description?: string | null,
    numberOfOrders?: number | null,
    isActive?: boolean | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteChannelSubscriptionVariables = {
  filter?: ModelSubscriptionChannelFilterInput | null,
};

export type OnDeleteChannelSubscription = {
  onDeleteChannel?:  {
    __typename: "Channel",
    id: string,
    name: string,
    description?: string | null,
    numberOfOrders?: number | null,
    isActive?: boolean | null,
    multiLanguages?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePromocodeSubscriptionVariables = {
  filter?: ModelSubscriptionPromocodeFilterInput | null,
};

export type OnCreatePromocodeSubscription = {
  onCreatePromocode?:  {
    __typename: "Promocode",
    id: string,
    name: string,
    redemptions: number,
    status?: string | null,
    startDate: string,
    endDate: string,
    appliesTo: string,
    minOrderReq: string,
    minOrderVal?: number | null,
    minOrderQty?: number | null,
    tenderReq: Array< string | null >,
    discountVal: number,
    discountLimit?: number | null,
    quota?: number | null,
    quotaUsed?: number | null,
    totalRevenue?: number | null,
    appliedDiscountedVal?: number | null,
    usePerCustomer?: number | null,
    channelReq: Array< string | null >,
    customerEligibility: string,
    customerReq?: Array< string | null > | null,
    spendingExceeds?: number | null,
    spendingBelow?: number | null,
    orderSinceDate?: string | null,
    orderFromDate?: string | null,
    orderToDate?: string | null,
    isDisplayed?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    parentConceptIDs?: Array< string | null > | null,
    conceptIDs?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePromocodeSubscriptionVariables = {
  filter?: ModelSubscriptionPromocodeFilterInput | null,
};

export type OnUpdatePromocodeSubscription = {
  onUpdatePromocode?:  {
    __typename: "Promocode",
    id: string,
    name: string,
    redemptions: number,
    status?: string | null,
    startDate: string,
    endDate: string,
    appliesTo: string,
    minOrderReq: string,
    minOrderVal?: number | null,
    minOrderQty?: number | null,
    tenderReq: Array< string | null >,
    discountVal: number,
    discountLimit?: number | null,
    quota?: number | null,
    quotaUsed?: number | null,
    totalRevenue?: number | null,
    appliedDiscountedVal?: number | null,
    usePerCustomer?: number | null,
    channelReq: Array< string | null >,
    customerEligibility: string,
    customerReq?: Array< string | null > | null,
    spendingExceeds?: number | null,
    spendingBelow?: number | null,
    orderSinceDate?: string | null,
    orderFromDate?: string | null,
    orderToDate?: string | null,
    isDisplayed?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    parentConceptIDs?: Array< string | null > | null,
    conceptIDs?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePromocodeSubscriptionVariables = {
  filter?: ModelSubscriptionPromocodeFilterInput | null,
};

export type OnDeletePromocodeSubscription = {
  onDeletePromocode?:  {
    __typename: "Promocode",
    id: string,
    name: string,
    redemptions: number,
    status?: string | null,
    startDate: string,
    endDate: string,
    appliesTo: string,
    minOrderReq: string,
    minOrderVal?: number | null,
    minOrderQty?: number | null,
    tenderReq: Array< string | null >,
    discountVal: number,
    discountLimit?: number | null,
    quota?: number | null,
    quotaUsed?: number | null,
    totalRevenue?: number | null,
    appliedDiscountedVal?: number | null,
    usePerCustomer?: number | null,
    channelReq: Array< string | null >,
    customerEligibility: string,
    customerReq?: Array< string | null > | null,
    spendingExceeds?: number | null,
    spendingBelow?: number | null,
    orderSinceDate?: string | null,
    orderFromDate?: string | null,
    orderToDate?: string | null,
    isDisplayed?: boolean | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    parentConceptIDs?: Array< string | null > | null,
    conceptIDs?: Array< string | null > | null,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreatePromocodeDashboardSubscriptionVariables = {
  filter?: ModelSubscriptionPromocodeDashboardFilterInput | null,
};

export type OnCreatePromocodeDashboardSubscription = {
  onCreatePromocodeDashboard?:  {
    __typename: "PromocodeDashboard",
    id: string,
    conceptID: string,
    totalRevenue?: number | null,
    totalRedemptions?: number | null,
    totalDiscount?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdatePromocodeDashboardSubscriptionVariables = {
  filter?: ModelSubscriptionPromocodeDashboardFilterInput | null,
};

export type OnUpdatePromocodeDashboardSubscription = {
  onUpdatePromocodeDashboard?:  {
    __typename: "PromocodeDashboard",
    id: string,
    conceptID: string,
    totalRevenue?: number | null,
    totalRedemptions?: number | null,
    totalDiscount?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeletePromocodeDashboardSubscriptionVariables = {
  filter?: ModelSubscriptionPromocodeDashboardFilterInput | null,
};

export type OnDeletePromocodeDashboardSubscription = {
  onDeletePromocodeDashboard?:  {
    __typename: "PromocodeDashboard",
    id: string,
    conceptID: string,
    totalRevenue?: number | null,
    totalRedemptions?: number | null,
    totalDiscount?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateConceptRevenueSubscriptionVariables = {
  filter?: ModelSubscriptionConceptRevenueFilterInput | null,
};

export type OnCreateConceptRevenueSubscription = {
  onCreateConceptRevenue?:  {
    __typename: "ConceptRevenue",
    id: string,
    conceptID: string,
    date: string,
    driveThruRevenue?: number | null,
    pickupRevenue?: number | null,
    deliveryRevenue?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateConceptRevenueSubscriptionVariables = {
  filter?: ModelSubscriptionConceptRevenueFilterInput | null,
};

export type OnUpdateConceptRevenueSubscription = {
  onUpdateConceptRevenue?:  {
    __typename: "ConceptRevenue",
    id: string,
    conceptID: string,
    date: string,
    driveThruRevenue?: number | null,
    pickupRevenue?: number | null,
    deliveryRevenue?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteConceptRevenueSubscriptionVariables = {
  filter?: ModelSubscriptionConceptRevenueFilterInput | null,
};

export type OnDeleteConceptRevenueSubscription = {
  onDeleteConceptRevenue?:  {
    __typename: "ConceptRevenue",
    id: string,
    conceptID: string,
    date: string,
    driveThruRevenue?: number | null,
    pickupRevenue?: number | null,
    deliveryRevenue?: number | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateLoyaltyItemSubscriptionVariables = {
  filter?: ModelSubscriptionLoyaltyItemFilterInput | null,
};

export type OnCreateLoyaltyItemSubscription = {
  onCreateLoyaltyItem?:  {
    __typename: "LoyaltyItem",
    id: string,
    name: string,
    conceptID: string,
    parentConceptID: string,
    description?: string | null,
    startDate: string,
    endDate?: string | null,
    options?: Array< string | null > | null,
    points: number,
    redemptionPerUser?: number | null,
    image?: string | null,
    simphonyID?: string | null,
    multiLanguages?: string | null,
    isActive?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateLoyaltyItemSubscriptionVariables = {
  filter?: ModelSubscriptionLoyaltyItemFilterInput | null,
};

export type OnUpdateLoyaltyItemSubscription = {
  onUpdateLoyaltyItem?:  {
    __typename: "LoyaltyItem",
    id: string,
    name: string,
    conceptID: string,
    parentConceptID: string,
    description?: string | null,
    startDate: string,
    endDate?: string | null,
    options?: Array< string | null > | null,
    points: number,
    redemptionPerUser?: number | null,
    image?: string | null,
    simphonyID?: string | null,
    multiLanguages?: string | null,
    isActive?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteLoyaltyItemSubscriptionVariables = {
  filter?: ModelSubscriptionLoyaltyItemFilterInput | null,
};

export type OnDeleteLoyaltyItemSubscription = {
  onDeleteLoyaltyItem?:  {
    __typename: "LoyaltyItem",
    id: string,
    name: string,
    conceptID: string,
    parentConceptID: string,
    description?: string | null,
    startDate: string,
    endDate?: string | null,
    options?: Array< string | null > | null,
    points: number,
    redemptionPerUser?: number | null,
    image?: string | null,
    simphonyID?: string | null,
    multiLanguages?: string | null,
    isActive?: string | null,
    deleted?: string | null,
    createdAt: string,
    createdByID: string,
    createdByName: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateOTPSubscriptionVariables = {
  filter?: ModelSubscriptionOTPFilterInput | null,
};

export type OnCreateOTPSubscription = {
  onCreateOTP?:  {
    __typename: "OTP",
    id: string,
    OTP: string,
    redeemed?: boolean | null,
    deleted?: string | null,
    userID: string,
    conceptID?: string | null,
    loyaltyItemID?: string | null,
    loyaltyItem?:  {
      __typename: "LoyaltyItem",
      id: string,
      name: string,
      conceptID: string,
      parentConceptID: string,
      description?: string | null,
      startDate: string,
      endDate?: string | null,
      options?: Array< string | null > | null,
      points: number,
      redemptionPerUser?: number | null,
      image?: string | null,
      simphonyID?: string | null,
      multiLanguages?: string | null,
      isActive?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      autoGeneratedPassword: boolean,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    concept?:  {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      kiosks?: Array< string | null > | null,
      merchantID?: string | null,
      merchantUsername?: string | null,
      merchantPassword?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      paymentSharePercentage?: number | null,
      simphonyServerBaseURL?: string | null,
      simphonyShortOrgName?: string | null,
      simphonyLocRef?: string | null,
      simphonyToken?: string | null,
      revenueCenterID?: number | null,
      employeeID?: number | null,
      tenderID?: number | null,
      tenders?:  {
        __typename: "ModelTenderConnection",
        items:  Array< {
          __typename: "Tender",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptTendersId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderType?:  {
        __typename: "ModelOrderTypeConnection",
        items:  Array< {
          __typename: "OrderType",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptOrderTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      discountID?: number | null,
      useServiceCharge?: boolean | null,
      serviceChargeId?: number | null,
      specialRequestID?: number | null,
      addressID?: number | null,
      ratingCount?: number | null,
      averageRating?: number | null,
      feesback?: string | null,
      simphonyAuthClientId?: string | null,
      simphonyAuthUsername?: string | null,
      simphonyAuthPassword?: string | null,
      simphonyAuthBaseURL?: string | null,
      orderTypeDineIn?: number | null,
      orderTypeTakeAway?: number | null,
      orderTypeDelivery?: number | null,
      simphonyTakeAwayRvc?: number | null,
      simphonyDeliveryRvc?: number | null,
      exportInterval?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      foodicsToken?: string | null,
      enableTableFoodics?: string | null,
      image?: string | null,
      logo?: string | null,
      album?: Array< string | null > | null,
      type?: string | null,
      rating?: number | null,
      location?: string | null,
      description?: string | null,
      areas?: Array< string | null > | null,
      seatTypes?: Array< string | null > | null,
      needAssistance?: boolean | null,
      spaBooking?: boolean | null,
      showerBooking?: boolean | null,
      cigarMenu?: boolean | null,
      precedence?: number | null,
      isPinRequired?: boolean | null,
      viewOnly?: boolean | null,
      openTime?: string | null,
      closeTime?: string | null,
      varifyForAmount?: number | null,
      varifyFirstOrder?: boolean | null,
      varifyNewAddress?: boolean | null,
      sliderImages?: Array< string | null > | null,
      receiveWaiters?: Array< string | null > | null,
      WaiterNotificationOption?: string | null,
      applyLoyalty?: boolean | null,
      rewardPoints?: number | null,
      moneySpent?: number | null,
      afterVat?: boolean | null,
      afterDeliveryFee?: boolean | null,
      beforePromoCode?: boolean | null,
      pointsCalcWithPromoCode?: boolean | null,
      isBusy?: boolean | null,
      allowOnlineOrdering?: boolean | null,
      minOrderVal?: number | null,
      vatPercentage?: number | null,
      serviceChargePercentage?: number | null,
      addDeliveryToVat?: boolean | null,
      categoryTagIDs?: Array< string | null > | null,
      parentConceptID: string,
      containerColor?: string | null,
      avgOrderCompletionEstimate?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateOTPSubscriptionVariables = {
  filter?: ModelSubscriptionOTPFilterInput | null,
};

export type OnUpdateOTPSubscription = {
  onUpdateOTP?:  {
    __typename: "OTP",
    id: string,
    OTP: string,
    redeemed?: boolean | null,
    deleted?: string | null,
    userID: string,
    conceptID?: string | null,
    loyaltyItemID?: string | null,
    loyaltyItem?:  {
      __typename: "LoyaltyItem",
      id: string,
      name: string,
      conceptID: string,
      parentConceptID: string,
      description?: string | null,
      startDate: string,
      endDate?: string | null,
      options?: Array< string | null > | null,
      points: number,
      redemptionPerUser?: number | null,
      image?: string | null,
      simphonyID?: string | null,
      multiLanguages?: string | null,
      isActive?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      autoGeneratedPassword: boolean,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    concept?:  {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      kiosks?: Array< string | null > | null,
      merchantID?: string | null,
      merchantUsername?: string | null,
      merchantPassword?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      paymentSharePercentage?: number | null,
      simphonyServerBaseURL?: string | null,
      simphonyShortOrgName?: string | null,
      simphonyLocRef?: string | null,
      simphonyToken?: string | null,
      revenueCenterID?: number | null,
      employeeID?: number | null,
      tenderID?: number | null,
      tenders?:  {
        __typename: "ModelTenderConnection",
        items:  Array< {
          __typename: "Tender",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptTendersId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderType?:  {
        __typename: "ModelOrderTypeConnection",
        items:  Array< {
          __typename: "OrderType",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptOrderTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      discountID?: number | null,
      useServiceCharge?: boolean | null,
      serviceChargeId?: number | null,
      specialRequestID?: number | null,
      addressID?: number | null,
      ratingCount?: number | null,
      averageRating?: number | null,
      feesback?: string | null,
      simphonyAuthClientId?: string | null,
      simphonyAuthUsername?: string | null,
      simphonyAuthPassword?: string | null,
      simphonyAuthBaseURL?: string | null,
      orderTypeDineIn?: number | null,
      orderTypeTakeAway?: number | null,
      orderTypeDelivery?: number | null,
      simphonyTakeAwayRvc?: number | null,
      simphonyDeliveryRvc?: number | null,
      exportInterval?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      foodicsToken?: string | null,
      enableTableFoodics?: string | null,
      image?: string | null,
      logo?: string | null,
      album?: Array< string | null > | null,
      type?: string | null,
      rating?: number | null,
      location?: string | null,
      description?: string | null,
      areas?: Array< string | null > | null,
      seatTypes?: Array< string | null > | null,
      needAssistance?: boolean | null,
      spaBooking?: boolean | null,
      showerBooking?: boolean | null,
      cigarMenu?: boolean | null,
      precedence?: number | null,
      isPinRequired?: boolean | null,
      viewOnly?: boolean | null,
      openTime?: string | null,
      closeTime?: string | null,
      varifyForAmount?: number | null,
      varifyFirstOrder?: boolean | null,
      varifyNewAddress?: boolean | null,
      sliderImages?: Array< string | null > | null,
      receiveWaiters?: Array< string | null > | null,
      WaiterNotificationOption?: string | null,
      applyLoyalty?: boolean | null,
      rewardPoints?: number | null,
      moneySpent?: number | null,
      afterVat?: boolean | null,
      afterDeliveryFee?: boolean | null,
      beforePromoCode?: boolean | null,
      pointsCalcWithPromoCode?: boolean | null,
      isBusy?: boolean | null,
      allowOnlineOrdering?: boolean | null,
      minOrderVal?: number | null,
      vatPercentage?: number | null,
      serviceChargePercentage?: number | null,
      addDeliveryToVat?: boolean | null,
      categoryTagIDs?: Array< string | null > | null,
      parentConceptID: string,
      containerColor?: string | null,
      avgOrderCompletionEstimate?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteOTPSubscriptionVariables = {
  filter?: ModelSubscriptionOTPFilterInput | null,
};

export type OnDeleteOTPSubscription = {
  onDeleteOTP?:  {
    __typename: "OTP",
    id: string,
    OTP: string,
    redeemed?: boolean | null,
    deleted?: string | null,
    userID: string,
    conceptID?: string | null,
    loyaltyItemID?: string | null,
    loyaltyItem?:  {
      __typename: "LoyaltyItem",
      id: string,
      name: string,
      conceptID: string,
      parentConceptID: string,
      description?: string | null,
      startDate: string,
      endDate?: string | null,
      options?: Array< string | null > | null,
      points: number,
      redemptionPerUser?: number | null,
      image?: string | null,
      simphonyID?: string | null,
      multiLanguages?: string | null,
      isActive?: string | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    user?:  {
      __typename: "User",
      id: string,
      accountID: string,
      username?: string | null,
      preferred_username?: string | null,
      email?: string | null,
      email_verified?: boolean | null,
      phone_number?: string | null,
      phone_number_verified?: boolean | null,
      name?: string | null,
      nickname?: string | null,
      given_name?: string | null,
      middle_name?: string | null,
      family_name?: string | null,
      address?: string | null,
      birthdate?: string | null,
      gender?: string | null,
      locale?: string | null,
      picture?: string | null,
      pictureCrop?: string | null,
      website?: string | null,
      zoneinfo?: string | null,
      verification_code?: string | null,
      facebook?: string | null,
      instagram?: string | null,
      group?: string | null,
      phones?: Array< string | null > | null,
      flags?: Array< string | null > | null,
      flagsName?: Array< string | null > | null,
      interests?: Array< string | null > | null,
      interestsName?: Array< string | null > | null,
      status?: string | null,
      stats?: Array< string | null > | null,
      spending?: number | null,
      prevOrderDate?: string | null,
      lastOrderDate?: string | null,
      promocodes?:  Array< {
        __typename: "PromocodeItem",
        name: string,
        promocodeId: string,
        canBeUsed: number,
        discountVal: number,
        discountLimit?: number | null,
        isDisplayed?: boolean | null,
      } | null > | null,
      mostOrderedItems?: string | null,
      points?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      cognitoUsername?: string | null,
      cognitoSub?: string | null,
      autoGeneratedPassword: boolean,
      updated?: string | null,
      sub?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    concept?:  {
      __typename: "Concept",
      id: string,
      accountID: string,
      name: string,
      kiosks?: Array< string | null > | null,
      merchantID?: string | null,
      merchantUsername?: string | null,
      merchantPassword?: string | null,
      merchantIntegrationNumber?: string | null,
      merchantAPIKey?: string | null,
      paymentSharePercentage?: number | null,
      simphonyServerBaseURL?: string | null,
      simphonyShortOrgName?: string | null,
      simphonyLocRef?: string | null,
      simphonyToken?: string | null,
      revenueCenterID?: number | null,
      employeeID?: number | null,
      tenderID?: number | null,
      tenders?:  {
        __typename: "ModelTenderConnection",
        items:  Array< {
          __typename: "Tender",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptTendersId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      orderType?:  {
        __typename: "ModelOrderTypeConnection",
        items:  Array< {
          __typename: "OrderType",
          id: string,
          name: string,
          simphonyID?: string | null,
          precedence?: string | null,
          deleted?: string | null,
          createdAt: string,
          createdByID: string,
          createdByName: string,
          updatedAt: string,
          _version: number,
          _deleted?: boolean | null,
          _lastChangedAt: number,
          conceptOrderTypeId?: string | null,
        } | null >,
        nextToken?: string | null,
        startedAt?: number | null,
      } | null,
      discountID?: number | null,
      useServiceCharge?: boolean | null,
      serviceChargeId?: number | null,
      specialRequestID?: number | null,
      addressID?: number | null,
      ratingCount?: number | null,
      averageRating?: number | null,
      feesback?: string | null,
      simphonyAuthClientId?: string | null,
      simphonyAuthUsername?: string | null,
      simphonyAuthPassword?: string | null,
      simphonyAuthBaseURL?: string | null,
      orderTypeDineIn?: number | null,
      orderTypeTakeAway?: number | null,
      orderTypeDelivery?: number | null,
      simphonyTakeAwayRvc?: number | null,
      simphonyDeliveryRvc?: number | null,
      exportInterval?: number | null,
      deleted?: string | null,
      createdAt: string,
      createdByID: string,
      createdByName: string,
      foodicsToken?: string | null,
      enableTableFoodics?: string | null,
      image?: string | null,
      logo?: string | null,
      album?: Array< string | null > | null,
      type?: string | null,
      rating?: number | null,
      location?: string | null,
      description?: string | null,
      areas?: Array< string | null > | null,
      seatTypes?: Array< string | null > | null,
      needAssistance?: boolean | null,
      spaBooking?: boolean | null,
      showerBooking?: boolean | null,
      cigarMenu?: boolean | null,
      precedence?: number | null,
      isPinRequired?: boolean | null,
      viewOnly?: boolean | null,
      openTime?: string | null,
      closeTime?: string | null,
      varifyForAmount?: number | null,
      varifyFirstOrder?: boolean | null,
      varifyNewAddress?: boolean | null,
      sliderImages?: Array< string | null > | null,
      receiveWaiters?: Array< string | null > | null,
      WaiterNotificationOption?: string | null,
      applyLoyalty?: boolean | null,
      rewardPoints?: number | null,
      moneySpent?: number | null,
      afterVat?: boolean | null,
      afterDeliveryFee?: boolean | null,
      beforePromoCode?: boolean | null,
      pointsCalcWithPromoCode?: boolean | null,
      isBusy?: boolean | null,
      allowOnlineOrdering?: boolean | null,
      minOrderVal?: number | null,
      vatPercentage?: number | null,
      serviceChargePercentage?: number | null,
      addDeliveryToVat?: boolean | null,
      categoryTagIDs?: Array< string | null > | null,
      parentConceptID: string,
      containerColor?: string | null,
      avgOrderCompletionEstimate?: string | null,
      updatedAt: string,
      _version: number,
      _deleted?: boolean | null,
      _lastChangedAt: number,
    } | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnCreateMostOrderedItemsSubscriptionVariables = {
  filter?: ModelSubscriptionMostOrderedItemsFilterInput | null,
};

export type OnCreateMostOrderedItemsSubscription = {
  onCreateMostOrderedItems?:  {
    __typename: "MostOrderedItems",
    id: string,
    conceptID?: string | null,
    menuItemID: string,
    menuItemName?: string | null,
    menuItemImage?: string | null,
    quantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnUpdateMostOrderedItemsSubscriptionVariables = {
  filter?: ModelSubscriptionMostOrderedItemsFilterInput | null,
};

export type OnUpdateMostOrderedItemsSubscription = {
  onUpdateMostOrderedItems?:  {
    __typename: "MostOrderedItems",
    id: string,
    conceptID?: string | null,
    menuItemID: string,
    menuItemName?: string | null,
    menuItemImage?: string | null,
    quantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};

export type OnDeleteMostOrderedItemsSubscriptionVariables = {
  filter?: ModelSubscriptionMostOrderedItemsFilterInput | null,
};

export type OnDeleteMostOrderedItemsSubscription = {
  onDeleteMostOrderedItems?:  {
    __typename: "MostOrderedItems",
    id: string,
    conceptID?: string | null,
    menuItemID: string,
    menuItemName?: string | null,
    menuItemImage?: string | null,
    quantity?: number | null,
    deleted?: string | null,
    createdAt: string,
    updatedAt: string,
    _version: number,
    _deleted?: boolean | null,
    _lastChangedAt: number,
  } | null,
};
