import {
  CreatePromocodeInput,
  ModelStringKeyConditionInput,
  UpdatePromocodeInput,
} from "./../models/GQL_API";
import { ListingByConceptVariables, Option } from "../models/app";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { API } from "aws-amplify";
import { GraphQLQuery } from "@aws-amplify/api";
import { useDispatch, useSelector } from "react-redux";
import useApp from "./useApp";
import { Promocode } from "../models";
import { createPromocode, updatePromocode } from "../graphql/mutations";
import {
  getPromocode,
  listPromocodes,
  promoCodeByDeleted,
  promoCodeByName,
} from "../graphql/queries";
import { HeadCell } from "../models/dataTable";
import {
  setListing,
  setSelectedFilters,
  setFilters,
  setNextToken,
  setLastIndex,
  setPreviousToken,
  setFilter,
} from "../store/ducks/campaign";
import { onCreatePromocode } from "../graphql/subscriptions";
import {
  mergeCampaignUpdates,
  updatePromocodeStatus,
} from "../services/campaignUtils";

const useCampaign = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();

  const session = useSelector((state: any) => state.app.session);
  let campaignsListing = useSelector((state: any) => state.campaigns.listing);
  let nextToken = useSelector((state: any) => state.onlineOrders.nextToken);
  let lastIndex = useSelector((state: any) => state.onlineOrders.lastIndex);
  const storedLimit = useSelector((state: any) => state.onlineOrders.limit);
  const previousTokens = useSelector(
    (state: any) => state.onlineOrders.previousTokens
  );
  let paginationFilter = useSelector((state: any) => state.onlineOrders.filter);

  async function fetch(params: any) {
    try {
      const {
        searchText,
        filterStatus,
        filterCreatedAtFrom,
        filterCreatedAtTo,
        filterStartDateFrom,
        filterStartDateTo,
      } = params;
      const filter: any = {
        deleted: { eq: "0" },
        and: [],
      };

      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      if (filterStatus.length > 0) {
        filter.status = { contains: filterStatus.toLowerCase() };
      }

      if (filterStartDateFrom || filterStartDateTo) {
        if (filterStartDateFrom && filterStartDateTo) {
          filter.and.push({
            startDate: { ge: new Date(filterStartDateFrom).toISOString() },
          });
          filter.and.push({
            startDate: { le: new Date(filterStartDateTo).toISOString() },
          });
        } else if (filterStartDateFrom) {
          filter.startDate = {
            ge: new Date(filterStartDateFrom).toISOString(),
          };
        } else {
          filter.startDate = {
            le: new Date(filterStartDateTo).toISOString(),
          };
        }
      }

      if (filterCreatedAtFrom || filterCreatedAtTo) {
        if (filterCreatedAtFrom && filterCreatedAtTo) {
          filter.startDate = {
            ge: new Date(filterCreatedAtFrom).toISOString(),
            le: new Date(filterCreatedAtTo).toISOString(),
          };
        } else if (filterCreatedAtFrom) {
          filter.startDate = {
            ge: new Date(filterCreatedAtFrom).toISOString(),
          };
        } else {
          filter.startDate = {
            le: new Date(filterCreatedAtTo).toISOString(),
          };
        }
      }

      const listing: any = await API.graphql<GraphQLQuery<Promocode>>({
        query: listPromocodes,
        variables: {
          filter,
        },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      // update status if it needs update and return the updated list
      const fetchedPromocodes = listing.data.listPromocodes.items;
      const updatePromocodePromises = fetchedPromocodes.map(
        async (promocode: any) => {
          return await updatePromocodeStatus(promocode, session);
        }
      );
      return await Promise.all(updatePromocodePromises);
    } catch (err) {
      showError(err);
    }
  }

  async function fetchByName(params: any) {
    try {
      const { name } = params;

      const listing: any = await API.graphql<GraphQLQuery<Promocode>>({
        query: promoCodeByName,
        variables: {
          name,
          filter: { deleted: { eq: "0" } },
        },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return listing.data.promoCodeByName.items;
    } catch (err) {
      showError(err);
    }
  }

  async function fetchByDeleted(params: any) {
    try {
      const {
        searchText,
        limit,
        startIndex,
        moveForward,
        filterStatus,
        filterCreatedAtFrom,
        filterCreatedAtTo,
        filterStartDateFrom,
        filterStartDateTo,
      } = params;
      // console.log(`params: ${JSON.stringify(params)}`);
      // let requestLimit = limit ? limit : storedLimit;
      let requestLimit = 100000;
      let requestStartIndex = startIndex;
      // const createdAtFilter: ModelStringKeyConditionInput = {};
      const filter: any = {};

      if (searchText.length > 0) {
        filter.name = { contains: searchText.toLowerCase() };
      }
      if (filterStatus.length > 0 && filterStatus?.toLowerCase() !== "all") {
        filter.status = { eq: filterStatus.toLowerCase() };
      }
      // if (filterCreatedAtFrom || filterCreatedAtTo) {
      //   if (filterCreatedAtFrom && filterCreatedAtTo) {
      //     const toDatePlusOneDay = new Date(filterCreatedAtTo);
      //     toDatePlusOneDay.setDate(toDatePlusOneDay.getDate() + 1);
      //     createdAtFilter.between = [
      //       new Date(filterCreatedAtFrom).toISOString(),
      //       new Date(toDatePlusOneDay).toISOString(),
      //     ];
      //   } else if (filterCreatedAtFrom) {
      //     createdAtFilter.ge = new Date(filterCreatedAtFrom).toISOString();
      //   } else {
      //     createdAtFilter.le = new Date(filterCreatedAtTo).toISOString();
      //   }
      // }
      if (filterCreatedAtFrom || filterCreatedAtTo) {
        if (filterCreatedAtFrom && filterCreatedAtTo) {
          filter.createdAt = {
            ge: new Date(filterCreatedAtFrom).toISOString(),
            le: new Date(filterCreatedAtTo).toISOString(),
          };
        } else if (filterCreatedAtFrom) {
          filter.createdAt = {
            ge: new Date(filterCreatedAtFrom).toISOString(),
          };
        } else {
          filter.createdAt = {
            le: new Date(filterCreatedAtTo).toISOString(),
          };
        }
      }
      if (filterStartDateFrom || filterStartDateTo) {
        if (filterStartDateFrom && filterStartDateTo) {
          filter.startDate = {
            ge: new Date(filterStartDateFrom).toISOString(),
            le: new Date(filterStartDateTo).toISOString(),
          };
        } else if (filterStartDateFrom) {
          filter.startDate = {
            ge: new Date(filterStartDateFrom).toISOString(),
          };
        } else {
          filter.startDate = {
            le: new Date(filterStartDateTo).toISOString(),
          };
        }
      }

      const listing: any = await API.graphql<GraphQLQuery<Promocode>>({
        query: promoCodeByDeleted,
        variables: {
          deleted: "0",
          sortDirection: "DESC",
          filter,
          // createdAt: createdAtFilter,
          limit: requestLimit ? requestLimit : 50,
          startIndex: requestStartIndex ? requestStartIndex + 1 : 0,
          nextToken,
        },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      // update status if it needs update and return the updated list
      const fetchedPromocodes = listing.data.promoCodeByDeleted.items;
      const updatePromocodePromises = fetchedPromocodes.map(
        async (promocode: any) => {
          return await updatePromocodeStatus(promocode, session);
        }
      );
      const updatedPromocodes = await Promise.all(updatePromocodePromises);
      dispatch(setLastIndex(startIndex));
      dispatch(setNextToken(listing.data.promoCodeByDeleted.nextToken));

      // dispatch(setListing(campaignsListing.concat(updatedPromocodes),),);
      dispatch(
        setListing(mergeCampaignUpdates(updatedPromocodes, campaignsListing))
      );
      dispatch(
        setFilter([
          searchText,
          limit,
          startIndex,
          moveForward,
          filterStatus,
          filterCreatedAtFrom,
          filterCreatedAtTo,
          filterStartDateFrom,
          filterStartDateTo,
        ])
      );
    } catch (err) {
      console.log(err);
    }
  }

  async function get(params: any) {
    const { id } = params;

    try {
      const promocode: any = await API.graphql({
        query: getPromocode,
        variables: { id },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      return promocode.data.getPromocode;
    } catch (err) {
      throw err;
    }
  }

  async function create(params: any, session = true) {
    let { userID, userName } = params;
    let {
      name,
      status,
      startDate,
      endDate,
      appliesTo,
      minOrderReq,
      minOrderVal,
      minOrderQty,
      tenderReq,
      discountVal,
      discountLimit,
      quota,
      usePerCustomer,
      channelReq,
      customerEligibility,
      customerReq,
      spendingExceeds,
      spendingBelow,
      orderSinceDate,
      orderFromDate,
      orderToDate,
      isDisplayed,
      parentConceptIDs,
      conceptIDs,
    } = params.data;
    try {
      const createInput: CreatePromocodeInput = {
        name: name ? name.toLowerCase() : "",
        status,
        redemptions: 0,
        totalRevenue: 0,
        appliedDiscountedVal: 0,
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(endDate).toISOString(),
        appliesTo,
        minOrderReq,
        minOrderVal,
        minOrderQty,
        tenderReq,
        discountVal,
        discountLimit,
        quota,
        quotaUsed: 0,
        usePerCustomer,
        channelReq,
        customerReq,
        customerEligibility,
        spendingExceeds,
        spendingBelow,
        orderSinceDate,
        orderFromDate,
        orderToDate,
        createdAt: new Date().toISOString(),
        createdByID: userID,
        createdByName: userName,
        parentConceptIDs,
        conceptIDs,
        isDisplayed: isDisplayed ? true : false,
      };

      const promocode = await API.graphql<GraphQLQuery<Promocode>>({
        query: createPromocode,
        variables: { input: createInput },
        authMode: session
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`New ${singleName} has been created successfully`);

      return promocode;
    } catch (err) {
      console.log(err);
      showError("Promocode must be unique");
      return false;
    }
  }

  async function update(params: any, session = false) {
    try {
      const { data } = params;
      let original = await get(params);

      const updateInput: UpdatePromocodeInput = {
        id: original.id,
        name: original.name,
        status: data.status ? data.status : original.status,
        deleted: original.deleted,
        startDate: data.startDate
          ? new Date(data.startDate).toISOString()
          : new Date(original.startDate).toISOString(),
        endDate: data.endDate
          ? new Date(data.endDate).toISOString()
          : new Date(original.endDate).toISOString(),
        parentConceptIDs: data.parentConceptIDs
          ? data.parentConceptIDs
          : original.parentConceptIDs,
        conceptIDs: data.conceptIDs ? data.conceptIDs : original.conceptIDs,
        appliesTo: data.appliesTo ? data.appliesTo : original.appliesTo,
        minOrderReq: data.minOrderReq ? data.minOrderReq : original.minOrderReq,
        minOrderVal: data.minOrderVal ? data.minOrderVal : original.minOrderVal,
        minOrderQty: data.minOrderQty ? data.minOrderQty : original.minOrderQty,
        tenderReq: data.tenderReq ? data.tenderReq : original.tenderReq,
        discountVal: data.discountVal ? data.discountVal : original.discountVal,
        discountLimit: data.discountLimit
          ? data.discountLimit
          : original.discountLimit,
        usePerCustomer: data.usePerCustomer
          ? data.usePerCustomer
          : original.usePerCustomer,
        channelReq: data.channelReq ? data.channelReq : original.channelReq,
        customerEligibility: data.customerEligibility
          ? data.customerEligibility
          : original.customerEligibility,
        customerReq: data.customerReq ? data.customerReq : original.customerReq,
        spendingExceeds: data.spendingExceeds
          ? data.spendingExceeds
          : original.spendingExceeds,
        spendingBelow: data.spendingBelow
          ? data.spendingBelow
          : original.spendingBelow,
        orderSinceDate: data.orderSinceDate
          ? data.orderSinceDate
          : original.orderSinceDate,
        orderFromDate: data.orderFromDate
          ? data.orderFromDate
          : original.orderFromDate,
        orderToDate: data.orderToDate ? data.orderToDate : original.orderToDate,
        isDisplayed: data.isDisplayed ? true : false,
        quota: data.quota ?? original.quota,
        quotaUsed: data.quotaUsed ?? original.quotaUsed,
        totalRevenue: data.totalRevenue ?? original.totalRevenue,
        appliedDiscountedVal:
          data.appliedDiscountedVal ?? original.appliedDiscountedVal,
        redemptions: data.redemptions ?? original.redemptions,

        _version: original._version,
      };

      const promocode: any = await API.graphql<GraphQLQuery<Promocode>>({
        query: updatePromocode,
        variables: { input: updateInput },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
      });

      if (!data.totalRevenue)
        showConfirm(`${singleName} has been updated successfully`);

      return promocode.data.updatePromocode;
    } catch (err) {
      showError(err);
      return false;
    }
  }

  async function trash(params: any) {
    try {
      let original = await get(params);

      const updateInput: UpdatePromocodeInput = {
        id: original.id,
        deleted: "1",

        _version: original._version,
      };

      await API.graphql<GraphQLQuery<Promocode>>({
        query: updatePromocode,
        variables: { input: updateInput },
        authMode: true
          ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
          : GRAPHQL_AUTH_MODE.AWS_IAM,
      });

      showConfirm(`${singleName} has been moved to trash successfully`);

      for (let i = 0; i < campaignsListing.length; i++) {
        if (campaignsListing[i].id === original.id) {
          campaignsListing.splice(i, 1);
          dispatch(setListing(campaignsListing));
          break;
        }
      }
    } catch (err) {
      showError(err);
    }
  }

  async function exportAll(params: ListingByConceptVariables) {
    const data = await fetch(params);
    return data;
  }

  function options(listing: Promocode[]) {
    const options: Option[] = [];

    for (let option of listing) {
      options.push({ label: option.name, value: option.id });
    }

    return options;
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Campaign Name",
    },
    {
      id: "redemptions",
      numeric: false,
      disablePadding: false,
      label: "Redemptions",
    },
    {
      id: "createdByName",
      numeric: false,
      disablePadding: false,
      label: "Created By",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Created At",
    },
  ];

  const dataCells: readonly string[] = ["status", "name", "redemptions"];

  const api: any = {};

  api[`${listingName}Model`] = Promocode as any;
  api[`${listingName}CreateSubscription`] = onCreatePromocode
    ? onCreatePromocode
    : "";
  api[`${listingName}HeadCells`] = headCells;
  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Fetch`] = fetchByDeleted;
  api[`${listingName}FetchByName`] = fetchByName;
  api[`${listingName}FetchByDeleted`] = fetchByDeleted;
  api[`${listingName}Options`] = options;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}Update`] = update;
  api[`${listingName}Trash`] = trash;
  api[`${listingName}Export`] = exportAll;
  api[`${listingName}ChangeListing`] = (listing: Promocode[]) => {
    dispatch(setListing(listing));
    dispatch(setFilters(listing.map((model: any) => model.name)));
  };
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) => {
    dispatch(setSelectedFilters(filters));
  };
  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = campaignsListing;
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  return api;
};

export default useCampaign;
