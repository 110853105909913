/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../models/GQL_API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    accountID
    username
    preferred_username
    email
    email_verified
    phone_number
    phone_number_verified
    name
    nickname
    given_name
    middle_name
    family_name
    address
    birthdate
    gender
    locale
    picture
    pictureCrop
    website
    zoneinfo
    verification_code
    facebook
    instagram
    group
    phones
    flags
    flagsName
    interests
    interestsName
    status
    stats
    spending
    prevOrderDate
    lastOrderDate
    promocodes {
      name
      promocodeId
      canBeUsed
      discountVal
      discountLimit
      isDisplayed
      __typename
    }
    mostOrderedItems
    points
    deleted
    createdAt
    createdByID
    createdByName
    cognitoUsername
    cognitoSub
    autoGeneratedPassword
    updated
    sub
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    accountID
    username
    preferred_username
    email
    email_verified
    phone_number
    phone_number_verified
    name
    nickname
    given_name
    middle_name
    family_name
    address
    birthdate
    gender
    locale
    picture
    pictureCrop
    website
    zoneinfo
    verification_code
    facebook
    instagram
    group
    phones
    flags
    flagsName
    interests
    interestsName
    status
    stats
    spending
    prevOrderDate
    lastOrderDate
    promocodes {
      name
      promocodeId
      canBeUsed
      discountVal
      discountLimit
      isDisplayed
      __typename
    }
    mostOrderedItems
    points
    deleted
    createdAt
    createdByID
    createdByName
    cognitoUsername
    cognitoSub
    autoGeneratedPassword
    updated
    sub
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    accountID
    username
    preferred_username
    email
    email_verified
    phone_number
    phone_number_verified
    name
    nickname
    given_name
    middle_name
    family_name
    address
    birthdate
    gender
    locale
    picture
    pictureCrop
    website
    zoneinfo
    verification_code
    facebook
    instagram
    group
    phones
    flags
    flagsName
    interests
    interestsName
    status
    stats
    spending
    prevOrderDate
    lastOrderDate
    promocodes {
      name
      promocodeId
      canBeUsed
      discountVal
      discountLimit
      isDisplayed
      __typename
    }
    mostOrderedItems
    points
    deleted
    createdAt
    createdByID
    createdByName
    cognitoUsername
    cognitoSub
    autoGeneratedPassword
    updated
    sub
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createReview = /* GraphQL */ `mutation CreateReview(
  $input: CreateReviewInput!
  $condition: ModelReviewConditionInput
) {
  createReview(input: $input, condition: $condition) {
    id
    conceptID
    conceptName
    accountID
    bookingID
    parentConceptID
    guestName
    guestId
    reason
    value
    userID
    orderID
    rate
    feedback
    satisfaction
    thoughts
    itemReviews {
      itemID
      rate
      satisfaction
      feedback
      multiLanguages
      __typename
    }
    photos
    multiLanguages
    deleted
    createdAt
    createdByID
    createdByName
    phoneUser
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReviewMutationVariables,
  APITypes.CreateReviewMutation
>;
export const updateReview = /* GraphQL */ `mutation UpdateReview(
  $input: UpdateReviewInput!
  $condition: ModelReviewConditionInput
) {
  updateReview(input: $input, condition: $condition) {
    id
    conceptID
    conceptName
    accountID
    bookingID
    parentConceptID
    guestName
    guestId
    reason
    value
    userID
    orderID
    rate
    feedback
    satisfaction
    thoughts
    itemReviews {
      itemID
      rate
      satisfaction
      feedback
      multiLanguages
      __typename
    }
    photos
    multiLanguages
    deleted
    createdAt
    createdByID
    createdByName
    phoneUser
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReviewMutationVariables,
  APITypes.UpdateReviewMutation
>;
export const deleteReview = /* GraphQL */ `mutation DeleteReview(
  $input: DeleteReviewInput!
  $condition: ModelReviewConditionInput
) {
  deleteReview(input: $input, condition: $condition) {
    id
    conceptID
    conceptName
    accountID
    bookingID
    parentConceptID
    guestName
    guestId
    reason
    value
    userID
    orderID
    rate
    feedback
    satisfaction
    thoughts
    itemReviews {
      itemID
      rate
      satisfaction
      feedback
      multiLanguages
      __typename
    }
    photos
    multiLanguages
    deleted
    createdAt
    createdByID
    createdByName
    phoneUser
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReviewMutationVariables,
  APITypes.DeleteReviewMutation
>;
export const createAccount = /* GraphQL */ `mutation CreateAccount(
  $input: CreateAccountInput!
  $condition: ModelAccountConditionInput
) {
  createAccount(input: $input, condition: $condition) {
    id
    logo
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    callCenterEmployeesIds
    kpiReportResetFrequency
    agentBarResetFrequency
    dynamicStatus
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountMutationVariables,
  APITypes.CreateAccountMutation
>;
export const updateAccount = /* GraphQL */ `mutation UpdateAccount(
  $input: UpdateAccountInput!
  $condition: ModelAccountConditionInput
) {
  updateAccount(input: $input, condition: $condition) {
    id
    logo
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    callCenterEmployeesIds
    kpiReportResetFrequency
    agentBarResetFrequency
    dynamicStatus
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountMutationVariables,
  APITypes.UpdateAccountMutation
>;
export const deleteAccount = /* GraphQL */ `mutation DeleteAccount(
  $input: DeleteAccountInput!
  $condition: ModelAccountConditionInput
) {
  deleteAccount(input: $input, condition: $condition) {
    id
    logo
    domain
    siteTitle
    guestsCount
    tagline
    description
    siteAddress
    defaultLanguage
    languages
    features
    status
    socialLinks
    deleted
    createdAt
    createdByID
    createdByName
    callCenterEmployeesIds
    kpiReportResetFrequency
    agentBarResetFrequency
    dynamicStatus
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountMutationVariables,
  APITypes.DeleteAccountMutation
>;
export const createFeature = /* GraphQL */ `mutation CreateFeature(
  $input: CreateFeatureInput!
  $condition: ModelFeatureConditionInput
) {
  createFeature(input: $input, condition: $condition) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFeatureMutationVariables,
  APITypes.CreateFeatureMutation
>;
export const updateFeature = /* GraphQL */ `mutation UpdateFeature(
  $input: UpdateFeatureInput!
  $condition: ModelFeatureConditionInput
) {
  updateFeature(input: $input, condition: $condition) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFeatureMutationVariables,
  APITypes.UpdateFeatureMutation
>;
export const deleteFeature = /* GraphQL */ `mutation DeleteFeature(
  $input: DeleteFeatureInput!
  $condition: ModelFeatureConditionInput
) {
  deleteFeature(input: $input, condition: $condition) {
    id
    name
    icon
    slug
    precedence
    parent
    private
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFeatureMutationVariables,
  APITypes.DeleteFeatureMutation
>;
export const createMedia = /* GraphQL */ `mutation CreateMedia(
  $input: CreateMediaInput!
  $condition: ModelMediaConditionInput
) {
  createMedia(input: $input, condition: $condition) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMediaMutationVariables,
  APITypes.CreateMediaMutation
>;
export const updateMedia = /* GraphQL */ `mutation UpdateMedia(
  $input: UpdateMediaInput!
  $condition: ModelMediaConditionInput
) {
  updateMedia(input: $input, condition: $condition) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMediaMutationVariables,
  APITypes.UpdateMediaMutation
>;
export const deleteMedia = /* GraphQL */ `mutation DeleteMedia(
  $input: DeleteMediaInput!
  $condition: ModelMediaConditionInput
) {
  deleteMedia(input: $input, condition: $condition) {
    id
    accountID
    fileUrl
    filename
    filetype
    fileSize
    alternativeText
    caption
    description
    attachments
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMediaMutationVariables,
  APITypes.DeleteMediaMutation
>;
export const createConcept = /* GraphQL */ `mutation CreateConcept(
  $input: CreateConceptInput!
  $condition: ModelConceptConditionInput
) {
  createConcept(input: $input, condition: $condition) {
    id
    accountID
    name
    kiosks
    merchantID
    merchantUsername
    merchantPassword
    merchantIntegrationNumber
    merchantAPIKey
    paymentSharePercentage
    simphonyServerBaseURL
    simphonyShortOrgName
    simphonyLocRef
    simphonyToken
    revenueCenterID
    employeeID
    tenderID
    tenders {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptTendersId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    orderType {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptOrderTypeId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    discountID
    useServiceCharge
    serviceChargeId
    specialRequestID
    addressID
    ratingCount
    averageRating
    feesback
    simphonyAuthClientId
    simphonyAuthUsername
    simphonyAuthPassword
    simphonyAuthBaseURL
    orderTypeDineIn
    orderTypeTakeAway
    orderTypeDelivery
    simphonyTakeAwayRvc
    simphonyDeliveryRvc
    exportInterval
    deleted
    createdAt
    createdByID
    createdByName
    foodicsToken
    enableTableFoodics
    image
    logo
    album
    type
    rating
    location
    description
    areas
    seatTypes
    needAssistance
    spaBooking
    showerBooking
    cigarMenu
    precedence
    isPinRequired
    viewOnly
    openTime
    closeTime
    varifyForAmount
    varifyFirstOrder
    varifyNewAddress
    sliderImages
    receiveWaiters
    WaiterNotificationOption
    applyLoyalty
    rewardPoints
    moneySpent
    afterVat
    afterDeliveryFee
    beforePromoCode
    pointsCalcWithPromoCode
    isBusy
    allowOnlineOrdering
    minOrderVal
    vatPercentage
    serviceChargePercentage
    addDeliveryToVat
    categoryTagIDs
    parentConceptID
    containerColor
    avgOrderCompletionEstimate
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateConceptMutationVariables,
  APITypes.CreateConceptMutation
>;
export const updateConcept = /* GraphQL */ `mutation UpdateConcept(
  $input: UpdateConceptInput!
  $condition: ModelConceptConditionInput
) {
  updateConcept(input: $input, condition: $condition) {
    id
    accountID
    name
    kiosks
    merchantID
    merchantUsername
    merchantPassword
    merchantIntegrationNumber
    merchantAPIKey
    paymentSharePercentage
    simphonyServerBaseURL
    simphonyShortOrgName
    simphonyLocRef
    simphonyToken
    revenueCenterID
    employeeID
    tenderID
    tenders {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptTendersId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    orderType {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptOrderTypeId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    discountID
    useServiceCharge
    serviceChargeId
    specialRequestID
    addressID
    ratingCount
    averageRating
    feesback
    simphonyAuthClientId
    simphonyAuthUsername
    simphonyAuthPassword
    simphonyAuthBaseURL
    orderTypeDineIn
    orderTypeTakeAway
    orderTypeDelivery
    simphonyTakeAwayRvc
    simphonyDeliveryRvc
    exportInterval
    deleted
    createdAt
    createdByID
    createdByName
    foodicsToken
    enableTableFoodics
    image
    logo
    album
    type
    rating
    location
    description
    areas
    seatTypes
    needAssistance
    spaBooking
    showerBooking
    cigarMenu
    precedence
    isPinRequired
    viewOnly
    openTime
    closeTime
    varifyForAmount
    varifyFirstOrder
    varifyNewAddress
    sliderImages
    receiveWaiters
    WaiterNotificationOption
    applyLoyalty
    rewardPoints
    moneySpent
    afterVat
    afterDeliveryFee
    beforePromoCode
    pointsCalcWithPromoCode
    isBusy
    allowOnlineOrdering
    minOrderVal
    vatPercentage
    serviceChargePercentage
    addDeliveryToVat
    categoryTagIDs
    parentConceptID
    containerColor
    avgOrderCompletionEstimate
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateConceptMutationVariables,
  APITypes.UpdateConceptMutation
>;
export const deleteConcept = /* GraphQL */ `mutation DeleteConcept(
  $input: DeleteConceptInput!
  $condition: ModelConceptConditionInput
) {
  deleteConcept(input: $input, condition: $condition) {
    id
    accountID
    name
    kiosks
    merchantID
    merchantUsername
    merchantPassword
    merchantIntegrationNumber
    merchantAPIKey
    paymentSharePercentage
    simphonyServerBaseURL
    simphonyShortOrgName
    simphonyLocRef
    simphonyToken
    revenueCenterID
    employeeID
    tenderID
    tenders {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptTendersId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    orderType {
      items {
        id
        name
        simphonyID
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        conceptOrderTypeId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    discountID
    useServiceCharge
    serviceChargeId
    specialRequestID
    addressID
    ratingCount
    averageRating
    feesback
    simphonyAuthClientId
    simphonyAuthUsername
    simphonyAuthPassword
    simphonyAuthBaseURL
    orderTypeDineIn
    orderTypeTakeAway
    orderTypeDelivery
    simphonyTakeAwayRvc
    simphonyDeliveryRvc
    exportInterval
    deleted
    createdAt
    createdByID
    createdByName
    foodicsToken
    enableTableFoodics
    image
    logo
    album
    type
    rating
    location
    description
    areas
    seatTypes
    needAssistance
    spaBooking
    showerBooking
    cigarMenu
    precedence
    isPinRequired
    viewOnly
    openTime
    closeTime
    varifyForAmount
    varifyFirstOrder
    varifyNewAddress
    sliderImages
    receiveWaiters
    WaiterNotificationOption
    applyLoyalty
    rewardPoints
    moneySpent
    afterVat
    afterDeliveryFee
    beforePromoCode
    pointsCalcWithPromoCode
    isBusy
    allowOnlineOrdering
    minOrderVal
    vatPercentage
    serviceChargePercentage
    addDeliveryToVat
    categoryTagIDs
    parentConceptID
    containerColor
    avgOrderCompletionEstimate
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteConceptMutationVariables,
  APITypes.DeleteConceptMutation
>;
export const createCategoryTag = /* GraphQL */ `mutation CreateCategoryTag(
  $input: CreateCategoryTagInput!
  $condition: ModelCategoryTagConditionInput
) {
  createCategoryTag(input: $input, condition: $condition) {
    id
    parentConceptID
    name
    image
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCategoryTagMutationVariables,
  APITypes.CreateCategoryTagMutation
>;
export const updateCategoryTag = /* GraphQL */ `mutation UpdateCategoryTag(
  $input: UpdateCategoryTagInput!
  $condition: ModelCategoryTagConditionInput
) {
  updateCategoryTag(input: $input, condition: $condition) {
    id
    parentConceptID
    name
    image
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCategoryTagMutationVariables,
  APITypes.UpdateCategoryTagMutation
>;
export const deleteCategoryTag = /* GraphQL */ `mutation DeleteCategoryTag(
  $input: DeleteCategoryTagInput!
  $condition: ModelCategoryTagConditionInput
) {
  deleteCategoryTag(input: $input, condition: $condition) {
    id
    parentConceptID
    name
    image
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCategoryTagMutationVariables,
  APITypes.DeleteCategoryTagMutation
>;
export const createTender = /* GraphQL */ `mutation CreateTender(
  $input: CreateTenderInput!
  $condition: ModelTenderConditionInput
) {
  createTender(input: $input, condition: $condition) {
    id
    name
    simphonyID
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conceptTendersId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTenderMutationVariables,
  APITypes.CreateTenderMutation
>;
export const updateTender = /* GraphQL */ `mutation UpdateTender(
  $input: UpdateTenderInput!
  $condition: ModelTenderConditionInput
) {
  updateTender(input: $input, condition: $condition) {
    id
    name
    simphonyID
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conceptTendersId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTenderMutationVariables,
  APITypes.UpdateTenderMutation
>;
export const deleteTender = /* GraphQL */ `mutation DeleteTender(
  $input: DeleteTenderInput!
  $condition: ModelTenderConditionInput
) {
  deleteTender(input: $input, condition: $condition) {
    id
    name
    simphonyID
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conceptTendersId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTenderMutationVariables,
  APITypes.DeleteTenderMutation
>;
export const createOrderType = /* GraphQL */ `mutation CreateOrderType(
  $input: CreateOrderTypeInput!
  $condition: ModelOrderTypeConditionInput
) {
  createOrderType(input: $input, condition: $condition) {
    id
    name
    simphonyID
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conceptOrderTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderTypeMutationVariables,
  APITypes.CreateOrderTypeMutation
>;
export const updateOrderType = /* GraphQL */ `mutation UpdateOrderType(
  $input: UpdateOrderTypeInput!
  $condition: ModelOrderTypeConditionInput
) {
  updateOrderType(input: $input, condition: $condition) {
    id
    name
    simphonyID
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conceptOrderTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderTypeMutationVariables,
  APITypes.UpdateOrderTypeMutation
>;
export const deleteOrderType = /* GraphQL */ `mutation DeleteOrderType(
  $input: DeleteOrderTypeInput!
  $condition: ModelOrderTypeConditionInput
) {
  deleteOrderType(input: $input, condition: $condition) {
    id
    name
    simphonyID
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    conceptOrderTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderTypeMutationVariables,
  APITypes.DeleteOrderTypeMutation
>;
export const createParentConcept = /* GraphQL */ `mutation CreateParentConcept(
  $input: CreateParentConceptInput!
  $condition: ModelParentConceptConditionInput
) {
  createParentConcept(input: $input, condition: $condition) {
    id
    deleted
    createdAt
    createdByID
    createdByName
    image
    name
    logo
    type
    venues {
      items {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        paymentSharePercentage
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          nextToken
          startedAt
          __typename
        }
        orderType {
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        ratingCount
        averageRating
        feesback
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        categoryTagIDs
        parentConceptID
        containerColor
        avgOrderCompletionEstimate
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    index
    categoryTags {
      items {
        id
        parentConceptID
        name
        image
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateParentConceptMutationVariables,
  APITypes.CreateParentConceptMutation
>;
export const updateParentConcept = /* GraphQL */ `mutation UpdateParentConcept(
  $input: UpdateParentConceptInput!
  $condition: ModelParentConceptConditionInput
) {
  updateParentConcept(input: $input, condition: $condition) {
    id
    deleted
    createdAt
    createdByID
    createdByName
    image
    name
    logo
    type
    venues {
      items {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        paymentSharePercentage
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          nextToken
          startedAt
          __typename
        }
        orderType {
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        ratingCount
        averageRating
        feesback
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        categoryTagIDs
        parentConceptID
        containerColor
        avgOrderCompletionEstimate
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    index
    categoryTags {
      items {
        id
        parentConceptID
        name
        image
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateParentConceptMutationVariables,
  APITypes.UpdateParentConceptMutation
>;
export const deleteParentConcept = /* GraphQL */ `mutation DeleteParentConcept(
  $input: DeleteParentConceptInput!
  $condition: ModelParentConceptConditionInput
) {
  deleteParentConcept(input: $input, condition: $condition) {
    id
    deleted
    createdAt
    createdByID
    createdByName
    image
    name
    logo
    type
    venues {
      items {
        id
        accountID
        name
        kiosks
        merchantID
        merchantUsername
        merchantPassword
        merchantIntegrationNumber
        merchantAPIKey
        paymentSharePercentage
        simphonyServerBaseURL
        simphonyShortOrgName
        simphonyLocRef
        simphonyToken
        revenueCenterID
        employeeID
        tenderID
        tenders {
          nextToken
          startedAt
          __typename
        }
        orderType {
          nextToken
          startedAt
          __typename
        }
        discountID
        useServiceCharge
        serviceChargeId
        specialRequestID
        addressID
        ratingCount
        averageRating
        feesback
        simphonyAuthClientId
        simphonyAuthUsername
        simphonyAuthPassword
        simphonyAuthBaseURL
        orderTypeDineIn
        orderTypeTakeAway
        orderTypeDelivery
        simphonyTakeAwayRvc
        simphonyDeliveryRvc
        exportInterval
        deleted
        createdAt
        createdByID
        createdByName
        foodicsToken
        enableTableFoodics
        image
        logo
        album
        type
        rating
        location
        description
        areas
        seatTypes
        needAssistance
        spaBooking
        showerBooking
        cigarMenu
        precedence
        isPinRequired
        viewOnly
        openTime
        closeTime
        varifyForAmount
        varifyFirstOrder
        varifyNewAddress
        sliderImages
        receiveWaiters
        WaiterNotificationOption
        applyLoyalty
        rewardPoints
        moneySpent
        afterVat
        afterDeliveryFee
        beforePromoCode
        pointsCalcWithPromoCode
        isBusy
        allowOnlineOrdering
        minOrderVal
        vatPercentage
        serviceChargePercentage
        addDeliveryToVat
        categoryTagIDs
        parentConceptID
        containerColor
        avgOrderCompletionEstimate
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    index
    categoryTags {
      items {
        id
        parentConceptID
        name
        image
        precedence
        deleted
        createdAt
        createdByID
        createdByName
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteParentConceptMutationVariables,
  APITypes.DeleteParentConceptMutation
>;
export const createTimeline = /* GraphQL */ `mutation CreateTimeline(
  $input: CreateTimelineInput!
  $condition: ModelTimelineConditionInput
) {
  createTimeline(input: $input, condition: $condition) {
    id
    actionName
    oldStatus
    newStatus
    bookingId
    transactionId
    customerId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTimelineMutationVariables,
  APITypes.CreateTimelineMutation
>;
export const updateTimeline = /* GraphQL */ `mutation UpdateTimeline(
  $input: UpdateTimelineInput!
  $condition: ModelTimelineConditionInput
) {
  updateTimeline(input: $input, condition: $condition) {
    id
    actionName
    oldStatus
    newStatus
    bookingId
    transactionId
    customerId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTimelineMutationVariables,
  APITypes.UpdateTimelineMutation
>;
export const deleteTimeline = /* GraphQL */ `mutation DeleteTimeline(
  $input: DeleteTimelineInput!
  $condition: ModelTimelineConditionInput
) {
  deleteTimeline(input: $input, condition: $condition) {
    id
    actionName
    oldStatus
    newStatus
    bookingId
    transactionId
    customerId
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTimelineMutationVariables,
  APITypes.DeleteTimelineMutation
>;
export const createCall = /* GraphQL */ `mutation CreateCall(
  $input: CreateCallInput!
  $condition: ModelCallConditionInput
) {
  createCall(input: $input, condition: $condition) {
    id
    phone_number
    customer {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    createdByID
    createdByName
    endedAt
    duration
    date
    employeeId
    updatedAt
    _version
    _deleted
    _lastChangedAt
    callCustomerId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCallMutationVariables,
  APITypes.CreateCallMutation
>;
export const updateCall = /* GraphQL */ `mutation UpdateCall(
  $input: UpdateCallInput!
  $condition: ModelCallConditionInput
) {
  updateCall(input: $input, condition: $condition) {
    id
    phone_number
    customer {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    createdByID
    createdByName
    endedAt
    duration
    date
    employeeId
    updatedAt
    _version
    _deleted
    _lastChangedAt
    callCustomerId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCallMutationVariables,
  APITypes.UpdateCallMutation
>;
export const deleteCall = /* GraphQL */ `mutation DeleteCall(
  $input: DeleteCallInput!
  $condition: ModelCallConditionInput
) {
  deleteCall(input: $input, condition: $condition) {
    id
    phone_number
    customer {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    createdByID
    createdByName
    endedAt
    duration
    date
    employeeId
    updatedAt
    _version
    _deleted
    _lastChangedAt
    callCustomerId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCallMutationVariables,
  APITypes.DeleteCallMutation
>;
export const createAdminRole = /* GraphQL */ `mutation CreateAdminRole(
  $input: CreateAdminRoleInput!
  $condition: ModelAdminRoleConditionInput
) {
  createAdminRole(input: $input, condition: $condition) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdminRoleMutationVariables,
  APITypes.CreateAdminRoleMutation
>;
export const updateAdminRole = /* GraphQL */ `mutation UpdateAdminRole(
  $input: UpdateAdminRoleInput!
  $condition: ModelAdminRoleConditionInput
) {
  updateAdminRole(input: $input, condition: $condition) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdminRoleMutationVariables,
  APITypes.UpdateAdminRoleMutation
>;
export const deleteAdminRole = /* GraphQL */ `mutation DeleteAdminRole(
  $input: DeleteAdminRoleInput!
  $condition: ModelAdminRoleConditionInput
) {
  deleteAdminRole(input: $input, condition: $condition) {
    id
    name
    description
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdminRoleMutationVariables,
  APITypes.DeleteAdminRoleMutation
>;
export const createAdminGroup = /* GraphQL */ `mutation CreateAdminGroup(
  $input: CreateAdminGroupInput!
  $condition: ModelAdminGroupConditionInput
) {
  createAdminGroup(input: $input, condition: $condition) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdminGroupMutationVariables,
  APITypes.CreateAdminGroupMutation
>;
export const updateAdminGroup = /* GraphQL */ `mutation UpdateAdminGroup(
  $input: UpdateAdminGroupInput!
  $condition: ModelAdminGroupConditionInput
) {
  updateAdminGroup(input: $input, condition: $condition) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdminGroupMutationVariables,
  APITypes.UpdateAdminGroupMutation
>;
export const deleteAdminGroup = /* GraphQL */ `mutation DeleteAdminGroup(
  $input: DeleteAdminGroupInput!
  $condition: ModelAdminGroupConditionInput
) {
  deleteAdminGroup(input: $input, condition: $condition) {
    id
    name
    description
    roles
    users
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdminGroupMutationVariables,
  APITypes.DeleteAdminGroupMutation
>;
export const createUserConcepts = /* GraphQL */ `mutation CreateUserConcepts(
  $input: CreateUserConceptsInput!
  $condition: ModelUserConceptsConditionInput
) {
  createUserConcepts(input: $input, condition: $condition) {
    id
    defaultConcept
    concepts
    parentConcepts
    conceptsRoles
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserConceptsMutationVariables,
  APITypes.CreateUserConceptsMutation
>;
export const updateUserConcepts = /* GraphQL */ `mutation UpdateUserConcepts(
  $input: UpdateUserConceptsInput!
  $condition: ModelUserConceptsConditionInput
) {
  updateUserConcepts(input: $input, condition: $condition) {
    id
    defaultConcept
    concepts
    parentConcepts
    conceptsRoles
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserConceptsMutationVariables,
  APITypes.UpdateUserConceptsMutation
>;
export const deleteUserConcepts = /* GraphQL */ `mutation DeleteUserConcepts(
  $input: DeleteUserConceptsInput!
  $condition: ModelUserConceptsConditionInput
) {
  deleteUserConcepts(input: $input, condition: $condition) {
    id
    defaultConcept
    concepts
    parentConcepts
    conceptsRoles
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserConceptsMutationVariables,
  APITypes.DeleteUserConceptsMutation
>;
export const createTransaction = /* GraphQL */ `mutation CreateTransaction(
  $input: CreateTransactionInput!
  $condition: ModelTransactionConditionInput
) {
  createTransaction(input: $input, condition: $condition) {
    id
    transactionID
    date
    guestName
    guestPhone
    guestID
    guestGroup
    guestsNames
    bookingID
    bookingDate
    amount_cents
    status
    failureReason
    type
    timeSlots
    tables
    conceptID
    currency
    refund
    reference_orderID
    ownerID
    refunded_amount_cents
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTransactionMutationVariables,
  APITypes.CreateTransactionMutation
>;
export const updateTransaction = /* GraphQL */ `mutation UpdateTransaction(
  $input: UpdateTransactionInput!
  $condition: ModelTransactionConditionInput
) {
  updateTransaction(input: $input, condition: $condition) {
    id
    transactionID
    date
    guestName
    guestPhone
    guestID
    guestGroup
    guestsNames
    bookingID
    bookingDate
    amount_cents
    status
    failureReason
    type
    timeSlots
    tables
    conceptID
    currency
    refund
    reference_orderID
    ownerID
    refunded_amount_cents
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTransactionMutationVariables,
  APITypes.UpdateTransactionMutation
>;
export const deleteTransaction = /* GraphQL */ `mutation DeleteTransaction(
  $input: DeleteTransactionInput!
  $condition: ModelTransactionConditionInput
) {
  deleteTransaction(input: $input, condition: $condition) {
    id
    transactionID
    date
    guestName
    guestPhone
    guestID
    guestGroup
    guestsNames
    bookingID
    bookingDate
    amount_cents
    status
    failureReason
    type
    timeSlots
    tables
    conceptID
    currency
    refund
    reference_orderID
    ownerID
    refunded_amount_cents
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTransactionMutationVariables,
  APITypes.DeleteTransactionMutation
>;
export const createPaymentStat = /* GraphQL */ `mutation CreatePaymentStat(
  $input: CreatePaymentStatInput!
  $condition: ModelPaymentStatConditionInput
) {
  createPaymentStat(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    year
    month
    monthName
    earning
    refund
    earningTrxCount
    refundTrxCount
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePaymentStatMutationVariables,
  APITypes.CreatePaymentStatMutation
>;
export const updatePaymentStat = /* GraphQL */ `mutation UpdatePaymentStat(
  $input: UpdatePaymentStatInput!
  $condition: ModelPaymentStatConditionInput
) {
  updatePaymentStat(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    year
    month
    monthName
    earning
    refund
    earningTrxCount
    refundTrxCount
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePaymentStatMutationVariables,
  APITypes.UpdatePaymentStatMutation
>;
export const deletePaymentStat = /* GraphQL */ `mutation DeletePaymentStat(
  $input: DeletePaymentStatInput!
  $condition: ModelPaymentStatConditionInput
) {
  deletePaymentStat(input: $input, condition: $condition) {
    id
    accountID
    conceptID
    year
    month
    monthName
    earning
    refund
    earningTrxCount
    refundTrxCount
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePaymentStatMutationVariables,
  APITypes.DeletePaymentStatMutation
>;
export const createCategory = /* GraphQL */ `mutation CreateCategory(
  $input: CreateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  createCategory(input: $input, condition: $condition) {
    id
    conceptID
    parentConceptID
    preparationAreaID
    subCategory
    name
    guestView
    guestOrder
    staffOrder
    image
    darkImage
    menuItems {
      items {
        id
        conceptID
        kioskID
        name
        description
        image
        precedence
        categoryID
        categoryName
        outOfStock
        requiredPoints
        symphonyID
        comboItemID
        ratings
        prices
        choiceGroups
        pricesItems {
          id
          conceptID
          parentConceptID
          name
          multiLanguages
          price
          symphonyID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsItems {
          id
          conceptID
          parentConceptID
          name
          required
          maxNumberOfChoices
          minNumberOfChoices
          precedence
          type
          choices
          deleted
          createdAt
          createdByID
          createdByName
          multiLanguages
          simphonyID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsRules {
          id
          minNumberOfChoices
          maxNumberOfChoices
          __typename
        }
        ExtraChoiceGroups
        enabled
        hasLimitedQuantity
        quantity
        autoRestock
        restockQuantity
        deleted
        createdAt
        createdByID
        createdByName
        quantityOrdered
        performancePerDay
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    symphonyID
    isHidden
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCategoryMutationVariables,
  APITypes.CreateCategoryMutation
>;
export const updateCategory = /* GraphQL */ `mutation UpdateCategory(
  $input: UpdateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  updateCategory(input: $input, condition: $condition) {
    id
    conceptID
    parentConceptID
    preparationAreaID
    subCategory
    name
    guestView
    guestOrder
    staffOrder
    image
    darkImage
    menuItems {
      items {
        id
        conceptID
        kioskID
        name
        description
        image
        precedence
        categoryID
        categoryName
        outOfStock
        requiredPoints
        symphonyID
        comboItemID
        ratings
        prices
        choiceGroups
        pricesItems {
          id
          conceptID
          parentConceptID
          name
          multiLanguages
          price
          symphonyID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsItems {
          id
          conceptID
          parentConceptID
          name
          required
          maxNumberOfChoices
          minNumberOfChoices
          precedence
          type
          choices
          deleted
          createdAt
          createdByID
          createdByName
          multiLanguages
          simphonyID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsRules {
          id
          minNumberOfChoices
          maxNumberOfChoices
          __typename
        }
        ExtraChoiceGroups
        enabled
        hasLimitedQuantity
        quantity
        autoRestock
        restockQuantity
        deleted
        createdAt
        createdByID
        createdByName
        quantityOrdered
        performancePerDay
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    symphonyID
    isHidden
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCategoryMutationVariables,
  APITypes.UpdateCategoryMutation
>;
export const deleteCategory = /* GraphQL */ `mutation DeleteCategory(
  $input: DeleteCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  deleteCategory(input: $input, condition: $condition) {
    id
    conceptID
    parentConceptID
    preparationAreaID
    subCategory
    name
    guestView
    guestOrder
    staffOrder
    image
    darkImage
    menuItems {
      items {
        id
        conceptID
        kioskID
        name
        description
        image
        precedence
        categoryID
        categoryName
        outOfStock
        requiredPoints
        symphonyID
        comboItemID
        ratings
        prices
        choiceGroups
        pricesItems {
          id
          conceptID
          parentConceptID
          name
          multiLanguages
          price
          symphonyID
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsItems {
          id
          conceptID
          parentConceptID
          name
          required
          maxNumberOfChoices
          minNumberOfChoices
          precedence
          type
          choices
          deleted
          createdAt
          createdByID
          createdByName
          multiLanguages
          simphonyID
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        choiceGroupsRules {
          id
          minNumberOfChoices
          maxNumberOfChoices
          __typename
        }
        ExtraChoiceGroups
        enabled
        hasLimitedQuantity
        quantity
        autoRestock
        restockQuantity
        deleted
        createdAt
        createdByID
        createdByName
        quantityOrdered
        performancePerDay
        multiLanguages
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    precedence
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    symphonyID
    isHidden
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCategoryMutationVariables,
  APITypes.DeleteCategoryMutation
>;
export const createMenuItem = /* GraphQL */ `mutation CreateMenuItem(
  $input: CreateMenuItemInput!
  $condition: ModelMenuItemConditionInput
) {
  createMenuItem(input: $input, condition: $condition) {
    id
    conceptID
    kioskID
    name
    description
    image
    precedence
    categoryID
    categoryName
    outOfStock
    requiredPoints
    symphonyID
    comboItemID
    ratings
    prices
    choiceGroups
    pricesItems {
      id
      conceptID
      parentConceptID
      name
      multiLanguages
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    choiceGroupsItems {
      id
      conceptID
      parentConceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      choiceItems {
        items {
          id
          conceptID
          name
          price
          precedence
          choiceGroupID
          choiceGroupName
          deleted
          createdAt
          createdByID
          createdByName
          symphonyID
          multiLanguages
          definitionNumber
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      simphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    choiceGroupsRules {
      id
      minNumberOfChoices
      maxNumberOfChoices
      __typename
    }
    ExtraChoiceGroups
    enabled
    hasLimitedQuantity
    quantity
    autoRestock
    restockQuantity
    deleted
    createdAt
    createdByID
    createdByName
    quantityOrdered
    performancePerDay
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMenuItemMutationVariables,
  APITypes.CreateMenuItemMutation
>;
export const updateMenuItem = /* GraphQL */ `mutation UpdateMenuItem(
  $input: UpdateMenuItemInput!
  $condition: ModelMenuItemConditionInput
) {
  updateMenuItem(input: $input, condition: $condition) {
    id
    conceptID
    kioskID
    name
    description
    image
    precedence
    categoryID
    categoryName
    outOfStock
    requiredPoints
    symphonyID
    comboItemID
    ratings
    prices
    choiceGroups
    pricesItems {
      id
      conceptID
      parentConceptID
      name
      multiLanguages
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    choiceGroupsItems {
      id
      conceptID
      parentConceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      choiceItems {
        items {
          id
          conceptID
          name
          price
          precedence
          choiceGroupID
          choiceGroupName
          deleted
          createdAt
          createdByID
          createdByName
          symphonyID
          multiLanguages
          definitionNumber
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      simphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    choiceGroupsRules {
      id
      minNumberOfChoices
      maxNumberOfChoices
      __typename
    }
    ExtraChoiceGroups
    enabled
    hasLimitedQuantity
    quantity
    autoRestock
    restockQuantity
    deleted
    createdAt
    createdByID
    createdByName
    quantityOrdered
    performancePerDay
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMenuItemMutationVariables,
  APITypes.UpdateMenuItemMutation
>;
export const deleteMenuItem = /* GraphQL */ `mutation DeleteMenuItem(
  $input: DeleteMenuItemInput!
  $condition: ModelMenuItemConditionInput
) {
  deleteMenuItem(input: $input, condition: $condition) {
    id
    conceptID
    kioskID
    name
    description
    image
    precedence
    categoryID
    categoryName
    outOfStock
    requiredPoints
    symphonyID
    comboItemID
    ratings
    prices
    choiceGroups
    pricesItems {
      id
      conceptID
      parentConceptID
      name
      multiLanguages
      price
      symphonyID
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    choiceGroupsItems {
      id
      conceptID
      parentConceptID
      name
      required
      maxNumberOfChoices
      minNumberOfChoices
      precedence
      type
      choices
      choiceItems {
        items {
          id
          conceptID
          name
          price
          precedence
          choiceGroupID
          choiceGroupName
          deleted
          createdAt
          createdByID
          createdByName
          symphonyID
          multiLanguages
          definitionNumber
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      deleted
      createdAt
      createdByID
      createdByName
      multiLanguages
      simphonyID
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    choiceGroupsRules {
      id
      minNumberOfChoices
      maxNumberOfChoices
      __typename
    }
    ExtraChoiceGroups
    enabled
    hasLimitedQuantity
    quantity
    autoRestock
    restockQuantity
    deleted
    createdAt
    createdByID
    createdByName
    quantityOrdered
    performancePerDay
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMenuItemMutationVariables,
  APITypes.DeleteMenuItemMutation
>;
export const createPrice = /* GraphQL */ `mutation CreatePrice(
  $input: CreatePriceInput!
  $condition: ModelPriceConditionInput
) {
  createPrice(input: $input, condition: $condition) {
    id
    conceptID
    parentConceptID
    name
    multiLanguages
    price
    symphonyID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePriceMutationVariables,
  APITypes.CreatePriceMutation
>;
export const updatePrice = /* GraphQL */ `mutation UpdatePrice(
  $input: UpdatePriceInput!
  $condition: ModelPriceConditionInput
) {
  updatePrice(input: $input, condition: $condition) {
    id
    conceptID
    parentConceptID
    name
    multiLanguages
    price
    symphonyID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePriceMutationVariables,
  APITypes.UpdatePriceMutation
>;
export const deletePrice = /* GraphQL */ `mutation DeletePrice(
  $input: DeletePriceInput!
  $condition: ModelPriceConditionInput
) {
  deletePrice(input: $input, condition: $condition) {
    id
    conceptID
    parentConceptID
    name
    multiLanguages
    price
    symphonyID
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePriceMutationVariables,
  APITypes.DeletePriceMutation
>;
export const createChoiceGroups = /* GraphQL */ `mutation CreateChoiceGroups(
  $input: CreateChoiceGroupsInput!
  $condition: ModelChoiceGroupsConditionInput
) {
  createChoiceGroups(input: $input, condition: $condition) {
    id
    conceptID
    parentConceptID
    name
    required
    maxNumberOfChoices
    minNumberOfChoices
    precedence
    type
    choices
    choiceItems {
      items {
        id
        conceptID
        name
        price
        precedence
        choiceGroupID
        choiceGroupName
        deleted
        createdAt
        createdByID
        createdByName
        symphonyID
        multiLanguages
        definitionNumber
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    simphonyID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChoiceGroupsMutationVariables,
  APITypes.CreateChoiceGroupsMutation
>;
export const updateChoiceGroups = /* GraphQL */ `mutation UpdateChoiceGroups(
  $input: UpdateChoiceGroupsInput!
  $condition: ModelChoiceGroupsConditionInput
) {
  updateChoiceGroups(input: $input, condition: $condition) {
    id
    conceptID
    parentConceptID
    name
    required
    maxNumberOfChoices
    minNumberOfChoices
    precedence
    type
    choices
    choiceItems {
      items {
        id
        conceptID
        name
        price
        precedence
        choiceGroupID
        choiceGroupName
        deleted
        createdAt
        createdByID
        createdByName
        symphonyID
        multiLanguages
        definitionNumber
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    simphonyID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChoiceGroupsMutationVariables,
  APITypes.UpdateChoiceGroupsMutation
>;
export const deleteChoiceGroups = /* GraphQL */ `mutation DeleteChoiceGroups(
  $input: DeleteChoiceGroupsInput!
  $condition: ModelChoiceGroupsConditionInput
) {
  deleteChoiceGroups(input: $input, condition: $condition) {
    id
    conceptID
    parentConceptID
    name
    required
    maxNumberOfChoices
    minNumberOfChoices
    precedence
    type
    choices
    choiceItems {
      items {
        id
        conceptID
        name
        price
        precedence
        choiceGroupID
        choiceGroupName
        deleted
        createdAt
        createdByID
        createdByName
        symphonyID
        multiLanguages
        definitionNumber
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    simphonyID
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteChoiceGroupsMutationVariables,
  APITypes.DeleteChoiceGroupsMutation
>;
export const createChoice = /* GraphQL */ `mutation CreateChoice(
  $input: CreateChoiceInput!
  $condition: ModelChoiceConditionInput
) {
  createChoice(input: $input, condition: $condition) {
    id
    conceptID
    name
    price
    precedence
    choiceGroupID
    choiceGroupName
    deleted
    createdAt
    createdByID
    createdByName
    symphonyID
    multiLanguages
    definitionNumber
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChoiceMutationVariables,
  APITypes.CreateChoiceMutation
>;
export const updateChoice = /* GraphQL */ `mutation UpdateChoice(
  $input: UpdateChoiceInput!
  $condition: ModelChoiceConditionInput
) {
  updateChoice(input: $input, condition: $condition) {
    id
    conceptID
    name
    price
    precedence
    choiceGroupID
    choiceGroupName
    deleted
    createdAt
    createdByID
    createdByName
    symphonyID
    multiLanguages
    definitionNumber
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChoiceMutationVariables,
  APITypes.UpdateChoiceMutation
>;
export const deleteChoice = /* GraphQL */ `mutation DeleteChoice(
  $input: DeleteChoiceInput!
  $condition: ModelChoiceConditionInput
) {
  deleteChoice(input: $input, condition: $condition) {
    id
    conceptID
    name
    price
    precedence
    choiceGroupID
    choiceGroupName
    deleted
    createdAt
    createdByID
    createdByName
    symphonyID
    multiLanguages
    definitionNumber
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteChoiceMutationVariables,
  APITypes.DeleteChoiceMutation
>;
export const createCart = /* GraphQL */ `mutation CreateCart(
  $input: CreateCartInput!
  $condition: ModelCartConditionInput
) {
  createCart(input: $input, condition: $condition) {
    id
    conceptID
    userID
    orderedItems {
      menuItemID
      categoryID
      name
      quantity
      selectedChoiceGroups {
        id
        name
        selectedChoices {
          id
          name
          price
          __typename
        }
        precedence
        __typename
      }
      price
      priceName
      priceID
      priceMultiLanguages
      multiLanguages
      notes
      image
      type
      __typename
    }
    specialRequest
    deliveryFee
    subTotal
    subTaxTotal
    totalPrice
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCartMutationVariables,
  APITypes.CreateCartMutation
>;
export const updateCart = /* GraphQL */ `mutation UpdateCart(
  $input: UpdateCartInput!
  $condition: ModelCartConditionInput
) {
  updateCart(input: $input, condition: $condition) {
    id
    conceptID
    userID
    orderedItems {
      menuItemID
      categoryID
      name
      quantity
      selectedChoiceGroups {
        id
        name
        selectedChoices {
          id
          name
          price
          __typename
        }
        precedence
        __typename
      }
      price
      priceName
      priceID
      priceMultiLanguages
      multiLanguages
      notes
      image
      type
      __typename
    }
    specialRequest
    deliveryFee
    subTotal
    subTaxTotal
    totalPrice
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCartMutationVariables,
  APITypes.UpdateCartMutation
>;
export const deleteCart = /* GraphQL */ `mutation DeleteCart(
  $input: DeleteCartInput!
  $condition: ModelCartConditionInput
) {
  deleteCart(input: $input, condition: $condition) {
    id
    conceptID
    userID
    orderedItems {
      menuItemID
      categoryID
      name
      quantity
      selectedChoiceGroups {
        id
        name
        selectedChoices {
          id
          name
          price
          __typename
        }
        precedence
        __typename
      }
      price
      priceName
      priceID
      priceMultiLanguages
      multiLanguages
      notes
      image
      type
      __typename
    }
    specialRequest
    deliveryFee
    subTotal
    subTaxTotal
    totalPrice
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCartMutationVariables,
  APITypes.DeleteCartMutation
>;
export const createAdminStatus = /* GraphQL */ `mutation CreateAdminStatus(
  $input: CreateAdminStatusInput!
  $condition: ModelAdminStatusConditionInput
) {
  createAdminStatus(input: $input, condition: $condition) {
    id
    sub
    accountID
    name
    averageCallTime
    numberOfCalls
    idelDuration
    activeDuration
    awayDuration
    lastCallTime
    agentBarAverageCallTime
    agentBarNumberOfCalls
    type
    waiterStatus
    totalAverageRatio
    performancePerDay
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAdminStatusMutationVariables,
  APITypes.CreateAdminStatusMutation
>;
export const updateAdminStatus = /* GraphQL */ `mutation UpdateAdminStatus(
  $input: UpdateAdminStatusInput!
  $condition: ModelAdminStatusConditionInput
) {
  updateAdminStatus(input: $input, condition: $condition) {
    id
    sub
    accountID
    name
    averageCallTime
    numberOfCalls
    idelDuration
    activeDuration
    awayDuration
    lastCallTime
    agentBarAverageCallTime
    agentBarNumberOfCalls
    type
    waiterStatus
    totalAverageRatio
    performancePerDay
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdminStatusMutationVariables,
  APITypes.UpdateAdminStatusMutation
>;
export const deleteAdminStatus = /* GraphQL */ `mutation DeleteAdminStatus(
  $input: DeleteAdminStatusInput!
  $condition: ModelAdminStatusConditionInput
) {
  deleteAdminStatus(input: $input, condition: $condition) {
    id
    sub
    accountID
    name
    averageCallTime
    numberOfCalls
    idelDuration
    activeDuration
    awayDuration
    lastCallTime
    agentBarAverageCallTime
    agentBarNumberOfCalls
    type
    waiterStatus
    totalAverageRatio
    performancePerDay
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAdminStatusMutationVariables,
  APITypes.DeleteAdminStatusMutation
>;
export const createOnlineOrder = /* GraphQL */ `mutation CreateOnlineOrder(
  $input: CreateOnlineOrderInput!
  $condition: ModelOnlineOrderConditionInput
) {
  createOnlineOrder(input: $input, condition: $condition) {
    id
    conceptID
    statusID
    status
    reason
    orderType
    channel
    userID
    userName
    userPhoneNumber
    dispatcherID
    dispatcherName
    totalAmount
    discountedValue
    subTotal
    subTaxTotal
    tax
    deliveryFee
    orderTime
    executionArn
    specialRequest
    orderAddress
    orderedItems {
      menuItemID
      categoryID
      name
      quantity
      selectedChoiceGroups {
        id
        name
        selectedChoices {
          id
          name
          price
          __typename
        }
        precedence
        __typename
      }
      price
      priceName
      priceID
      priceMultiLanguages
      multiLanguages
      notes
      image
      type
      __typename
    }
    discountID
    amount_cents
    transactionID
    paymentStatus
    paymentType
    referenceId
    cardHolderNumber
    cardHolderName
    cardSchemeName
    deleted
    createdAt
    sentToKitchenAt
    readyAt
    outForDeliveryAt
    deliveredAt
    promoCodeID
    promoCodeName
    promoCodeAppliedTo
    rating
    isReview
    reviewID
    review {
      id
      conceptID
      conceptName
      accountID
      bookingID
      parentConceptID
      guestName
      guestId
      reason
      value
      userID
      orderID
      rate
      feedback
      satisfaction
      thoughts
      itemReviews {
        itemID
        rate
        satisfaction
        feedback
        multiLanguages
        __typename
      }
      photos
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      phoneUser
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdByID
    createdByName
    tmpField
    otp
    avgOrderCompletionEstimate
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOnlineOrderMutationVariables,
  APITypes.CreateOnlineOrderMutation
>;
export const updateOnlineOrder = /* GraphQL */ `mutation UpdateOnlineOrder(
  $input: UpdateOnlineOrderInput!
  $condition: ModelOnlineOrderConditionInput
) {
  updateOnlineOrder(input: $input, condition: $condition) {
    id
    conceptID
    statusID
    status
    reason
    orderType
    channel
    userID
    userName
    userPhoneNumber
    dispatcherID
    dispatcherName
    totalAmount
    discountedValue
    subTotal
    subTaxTotal
    tax
    deliveryFee
    orderTime
    executionArn
    specialRequest
    orderAddress
    orderedItems {
      menuItemID
      categoryID
      name
      quantity
      selectedChoiceGroups {
        id
        name
        selectedChoices {
          id
          name
          price
          __typename
        }
        precedence
        __typename
      }
      price
      priceName
      priceID
      priceMultiLanguages
      multiLanguages
      notes
      image
      type
      __typename
    }
    discountID
    amount_cents
    transactionID
    paymentStatus
    paymentType
    referenceId
    cardHolderNumber
    cardHolderName
    cardSchemeName
    deleted
    createdAt
    sentToKitchenAt
    readyAt
    outForDeliveryAt
    deliveredAt
    promoCodeID
    promoCodeName
    promoCodeAppliedTo
    rating
    isReview
    reviewID
    review {
      id
      conceptID
      conceptName
      accountID
      bookingID
      parentConceptID
      guestName
      guestId
      reason
      value
      userID
      orderID
      rate
      feedback
      satisfaction
      thoughts
      itemReviews {
        itemID
        rate
        satisfaction
        feedback
        multiLanguages
        __typename
      }
      photos
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      phoneUser
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdByID
    createdByName
    tmpField
    otp
    avgOrderCompletionEstimate
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOnlineOrderMutationVariables,
  APITypes.UpdateOnlineOrderMutation
>;
export const deleteOnlineOrder = /* GraphQL */ `mutation DeleteOnlineOrder(
  $input: DeleteOnlineOrderInput!
  $condition: ModelOnlineOrderConditionInput
) {
  deleteOnlineOrder(input: $input, condition: $condition) {
    id
    conceptID
    statusID
    status
    reason
    orderType
    channel
    userID
    userName
    userPhoneNumber
    dispatcherID
    dispatcherName
    totalAmount
    discountedValue
    subTotal
    subTaxTotal
    tax
    deliveryFee
    orderTime
    executionArn
    specialRequest
    orderAddress
    orderedItems {
      menuItemID
      categoryID
      name
      quantity
      selectedChoiceGroups {
        id
        name
        selectedChoices {
          id
          name
          price
          __typename
        }
        precedence
        __typename
      }
      price
      priceName
      priceID
      priceMultiLanguages
      multiLanguages
      notes
      image
      type
      __typename
    }
    discountID
    amount_cents
    transactionID
    paymentStatus
    paymentType
    referenceId
    cardHolderNumber
    cardHolderName
    cardSchemeName
    deleted
    createdAt
    sentToKitchenAt
    readyAt
    outForDeliveryAt
    deliveredAt
    promoCodeID
    promoCodeName
    promoCodeAppliedTo
    rating
    isReview
    reviewID
    review {
      id
      conceptID
      conceptName
      accountID
      bookingID
      parentConceptID
      guestName
      guestId
      reason
      value
      userID
      orderID
      rate
      feedback
      satisfaction
      thoughts
      itemReviews {
        itemID
        rate
        satisfaction
        feedback
        multiLanguages
        __typename
      }
      photos
      multiLanguages
      deleted
      createdAt
      createdByID
      createdByName
      phoneUser
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdByID
    createdByName
    tmpField
    otp
    avgOrderCompletionEstimate
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOnlineOrderMutationVariables,
  APITypes.DeleteOnlineOrderMutation
>;
export const createOrderStatus = /* GraphQL */ `mutation CreateOrderStatus(
  $input: CreateOrderStatusInput!
  $condition: ModelOrderStatusConditionInput
) {
  createOrderStatus(input: $input, condition: $condition) {
    id
    conceptID
    name
    color
    precedence
    accessibleStatus
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderStatusMutationVariables,
  APITypes.CreateOrderStatusMutation
>;
export const updateOrderStatus = /* GraphQL */ `mutation UpdateOrderStatus(
  $input: UpdateOrderStatusInput!
  $condition: ModelOrderStatusConditionInput
) {
  updateOrderStatus(input: $input, condition: $condition) {
    id
    conceptID
    name
    color
    precedence
    accessibleStatus
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderStatusMutationVariables,
  APITypes.UpdateOrderStatusMutation
>;
export const deleteOrderStatus = /* GraphQL */ `mutation DeleteOrderStatus(
  $input: DeleteOrderStatusInput!
  $condition: ModelOrderStatusConditionInput
) {
  deleteOrderStatus(input: $input, condition: $condition) {
    id
    conceptID
    name
    color
    precedence
    accessibleStatus
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderStatusMutationVariables,
  APITypes.DeleteOrderStatusMutation
>;
export const createDiscountCoupon = /* GraphQL */ `mutation CreateDiscountCoupon(
  $input: CreateDiscountCouponInput!
  $condition: ModelDiscountCouponConditionInput
) {
  createDiscountCoupon(input: $input, condition: $condition) {
    id
    discountPercent
    usedBy
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDiscountCouponMutationVariables,
  APITypes.CreateDiscountCouponMutation
>;
export const updateDiscountCoupon = /* GraphQL */ `mutation UpdateDiscountCoupon(
  $input: UpdateDiscountCouponInput!
  $condition: ModelDiscountCouponConditionInput
) {
  updateDiscountCoupon(input: $input, condition: $condition) {
    id
    discountPercent
    usedBy
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDiscountCouponMutationVariables,
  APITypes.UpdateDiscountCouponMutation
>;
export const deleteDiscountCoupon = /* GraphQL */ `mutation DeleteDiscountCoupon(
  $input: DeleteDiscountCouponInput!
  $condition: ModelDiscountCouponConditionInput
) {
  deleteDiscountCoupon(input: $input, condition: $condition) {
    id
    discountPercent
    usedBy
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDiscountCouponMutationVariables,
  APITypes.DeleteDiscountCouponMutation
>;
export const createZone = /* GraphQL */ `mutation CreateZone(
  $input: CreateZoneInput!
  $condition: ModelZoneConditionInput
) {
  createZone(input: $input, condition: $condition) {
    id
    conceptID
    location
    deliveryFee
    concepts {
      id
      deliveryFee
      __typename
    }
    polygon
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateZoneMutationVariables,
  APITypes.CreateZoneMutation
>;
export const updateZone = /* GraphQL */ `mutation UpdateZone(
  $input: UpdateZoneInput!
  $condition: ModelZoneConditionInput
) {
  updateZone(input: $input, condition: $condition) {
    id
    conceptID
    location
    deliveryFee
    concepts {
      id
      deliveryFee
      __typename
    }
    polygon
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateZoneMutationVariables,
  APITypes.UpdateZoneMutation
>;
export const deleteZone = /* GraphQL */ `mutation DeleteZone(
  $input: DeleteZoneInput!
  $condition: ModelZoneConditionInput
) {
  deleteZone(input: $input, condition: $condition) {
    id
    conceptID
    location
    deliveryFee
    concepts {
      id
      deliveryFee
      __typename
    }
    polygon
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteZoneMutationVariables,
  APITypes.DeleteZoneMutation
>;
export const createUserIP = /* GraphQL */ `mutation CreateUserIP(
  $input: CreateUserIPInput!
  $condition: ModelUserIPConditionInput
) {
  createUserIP(input: $input, condition: $condition) {
    id
    ip
    date
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserIPMutationVariables,
  APITypes.CreateUserIPMutation
>;
export const updateUserIP = /* GraphQL */ `mutation UpdateUserIP(
  $input: UpdateUserIPInput!
  $condition: ModelUserIPConditionInput
) {
  updateUserIP(input: $input, condition: $condition) {
    id
    ip
    date
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserIPMutationVariables,
  APITypes.UpdateUserIPMutation
>;
export const deleteUserIP = /* GraphQL */ `mutation DeleteUserIP(
  $input: DeleteUserIPInput!
  $condition: ModelUserIPConditionInput
) {
  deleteUserIP(input: $input, condition: $condition) {
    id
    ip
    date
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserIPMutationVariables,
  APITypes.DeleteUserIPMutation
>;
export const createMainCategory = /* GraphQL */ `mutation CreateMainCategory(
  $input: CreateMainCategoryInput!
  $condition: ModelMainCategoryConditionInput
) {
  createMainCategory(input: $input, condition: $condition) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    guestView
    guestOrder
    staffOrder
    image
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMainCategoryMutationVariables,
  APITypes.CreateMainCategoryMutation
>;
export const updateMainCategory = /* GraphQL */ `mutation UpdateMainCategory(
  $input: UpdateMainCategoryInput!
  $condition: ModelMainCategoryConditionInput
) {
  updateMainCategory(input: $input, condition: $condition) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    guestView
    guestOrder
    staffOrder
    image
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMainCategoryMutationVariables,
  APITypes.UpdateMainCategoryMutation
>;
export const deleteMainCategory = /* GraphQL */ `mutation DeleteMainCategory(
  $input: DeleteMainCategoryInput!
  $condition: ModelMainCategoryConditionInput
) {
  deleteMainCategory(input: $input, condition: $condition) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    guestView
    guestOrder
    staffOrder
    image
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMainCategoryMutationVariables,
  APITypes.DeleteMainCategoryMutation
>;
export const createSubCategory = /* GraphQL */ `mutation CreateSubCategory(
  $input: CreateSubCategoryInput!
  $condition: ModelSubCategoryConditionInput
) {
  createSubCategory(input: $input, condition: $condition) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    mainCategoryID
    guestView
    guestOrder
    staffOrder
    image
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSubCategoryMutationVariables,
  APITypes.CreateSubCategoryMutation
>;
export const updateSubCategory = /* GraphQL */ `mutation UpdateSubCategory(
  $input: UpdateSubCategoryInput!
  $condition: ModelSubCategoryConditionInput
) {
  updateSubCategory(input: $input, condition: $condition) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    mainCategoryID
    guestView
    guestOrder
    staffOrder
    image
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSubCategoryMutationVariables,
  APITypes.UpdateSubCategoryMutation
>;
export const deleteSubCategory = /* GraphQL */ `mutation DeleteSubCategory(
  $input: DeleteSubCategoryInput!
  $condition: ModelSubCategoryConditionInput
) {
  deleteSubCategory(input: $input, condition: $condition) {
    id
    conceptID
    name
    precedence
    openTime
    closeTime
    mainCategoryID
    guestView
    guestOrder
    staffOrder
    image
    menuPDF
    deleted
    createdAt
    createdByID
    createdByName
    multiLanguages
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteSubCategoryMutationVariables,
  APITypes.DeleteSubCategoryMutation
>;
export const createChannel = /* GraphQL */ `mutation CreateChannel(
  $input: CreateChannelInput!
  $condition: ModelChannelConditionInput
) {
  createChannel(input: $input, condition: $condition) {
    id
    name
    description
    numberOfOrders
    isActive
    multiLanguages
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateChannelMutationVariables,
  APITypes.CreateChannelMutation
>;
export const updateChannel = /* GraphQL */ `mutation UpdateChannel(
  $input: UpdateChannelInput!
  $condition: ModelChannelConditionInput
) {
  updateChannel(input: $input, condition: $condition) {
    id
    name
    description
    numberOfOrders
    isActive
    multiLanguages
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateChannelMutationVariables,
  APITypes.UpdateChannelMutation
>;
export const deleteChannel = /* GraphQL */ `mutation DeleteChannel(
  $input: DeleteChannelInput!
  $condition: ModelChannelConditionInput
) {
  deleteChannel(input: $input, condition: $condition) {
    id
    name
    description
    numberOfOrders
    isActive
    multiLanguages
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteChannelMutationVariables,
  APITypes.DeleteChannelMutation
>;
export const createPromocode = /* GraphQL */ `mutation CreatePromocode(
  $input: CreatePromocodeInput!
  $condition: ModelPromocodeConditionInput
) {
  createPromocode(input: $input, condition: $condition) {
    id
    name
    redemptions
    status
    startDate
    endDate
    appliesTo
    minOrderReq
    minOrderVal
    minOrderQty
    tenderReq
    discountVal
    discountLimit
    quota
    quotaUsed
    totalRevenue
    appliedDiscountedVal
    usePerCustomer
    channelReq
    customerEligibility
    customerReq
    spendingExceeds
    spendingBelow
    orderSinceDate
    orderFromDate
    orderToDate
    isDisplayed
    deleted
    createdAt
    createdByID
    createdByName
    parentConceptIDs
    conceptIDs
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePromocodeMutationVariables,
  APITypes.CreatePromocodeMutation
>;
export const updatePromocode = /* GraphQL */ `mutation UpdatePromocode(
  $input: UpdatePromocodeInput!
  $condition: ModelPromocodeConditionInput
) {
  updatePromocode(input: $input, condition: $condition) {
    id
    name
    redemptions
    status
    startDate
    endDate
    appliesTo
    minOrderReq
    minOrderVal
    minOrderQty
    tenderReq
    discountVal
    discountLimit
    quota
    quotaUsed
    totalRevenue
    appliedDiscountedVal
    usePerCustomer
    channelReq
    customerEligibility
    customerReq
    spendingExceeds
    spendingBelow
    orderSinceDate
    orderFromDate
    orderToDate
    isDisplayed
    deleted
    createdAt
    createdByID
    createdByName
    parentConceptIDs
    conceptIDs
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePromocodeMutationVariables,
  APITypes.UpdatePromocodeMutation
>;
export const deletePromocode = /* GraphQL */ `mutation DeletePromocode(
  $input: DeletePromocodeInput!
  $condition: ModelPromocodeConditionInput
) {
  deletePromocode(input: $input, condition: $condition) {
    id
    name
    redemptions
    status
    startDate
    endDate
    appliesTo
    minOrderReq
    minOrderVal
    minOrderQty
    tenderReq
    discountVal
    discountLimit
    quota
    quotaUsed
    totalRevenue
    appliedDiscountedVal
    usePerCustomer
    channelReq
    customerEligibility
    customerReq
    spendingExceeds
    spendingBelow
    orderSinceDate
    orderFromDate
    orderToDate
    isDisplayed
    deleted
    createdAt
    createdByID
    createdByName
    parentConceptIDs
    conceptIDs
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePromocodeMutationVariables,
  APITypes.DeletePromocodeMutation
>;
export const createPromocodeDashboard = /* GraphQL */ `mutation CreatePromocodeDashboard(
  $input: CreatePromocodeDashboardInput!
  $condition: ModelPromocodeDashboardConditionInput
) {
  createPromocodeDashboard(input: $input, condition: $condition) {
    id
    conceptID
    totalRevenue
    totalRedemptions
    totalDiscount
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePromocodeDashboardMutationVariables,
  APITypes.CreatePromocodeDashboardMutation
>;
export const updatePromocodeDashboard = /* GraphQL */ `mutation UpdatePromocodeDashboard(
  $input: UpdatePromocodeDashboardInput!
  $condition: ModelPromocodeDashboardConditionInput
) {
  updatePromocodeDashboard(input: $input, condition: $condition) {
    id
    conceptID
    totalRevenue
    totalRedemptions
    totalDiscount
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePromocodeDashboardMutationVariables,
  APITypes.UpdatePromocodeDashboardMutation
>;
export const deletePromocodeDashboard = /* GraphQL */ `mutation DeletePromocodeDashboard(
  $input: DeletePromocodeDashboardInput!
  $condition: ModelPromocodeDashboardConditionInput
) {
  deletePromocodeDashboard(input: $input, condition: $condition) {
    id
    conceptID
    totalRevenue
    totalRedemptions
    totalDiscount
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePromocodeDashboardMutationVariables,
  APITypes.DeletePromocodeDashboardMutation
>;
export const createConceptRevenue = /* GraphQL */ `mutation CreateConceptRevenue(
  $input: CreateConceptRevenueInput!
  $condition: ModelConceptRevenueConditionInput
) {
  createConceptRevenue(input: $input, condition: $condition) {
    id
    conceptID
    date
    driveThruRevenue
    pickupRevenue
    deliveryRevenue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateConceptRevenueMutationVariables,
  APITypes.CreateConceptRevenueMutation
>;
export const updateConceptRevenue = /* GraphQL */ `mutation UpdateConceptRevenue(
  $input: UpdateConceptRevenueInput!
  $condition: ModelConceptRevenueConditionInput
) {
  updateConceptRevenue(input: $input, condition: $condition) {
    id
    conceptID
    date
    driveThruRevenue
    pickupRevenue
    deliveryRevenue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateConceptRevenueMutationVariables,
  APITypes.UpdateConceptRevenueMutation
>;
export const deleteConceptRevenue = /* GraphQL */ `mutation DeleteConceptRevenue(
  $input: DeleteConceptRevenueInput!
  $condition: ModelConceptRevenueConditionInput
) {
  deleteConceptRevenue(input: $input, condition: $condition) {
    id
    conceptID
    date
    driveThruRevenue
    pickupRevenue
    deliveryRevenue
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteConceptRevenueMutationVariables,
  APITypes.DeleteConceptRevenueMutation
>;
export const createLoyaltyItem = /* GraphQL */ `mutation CreateLoyaltyItem(
  $input: CreateLoyaltyItemInput!
  $condition: ModelLoyaltyItemConditionInput
) {
  createLoyaltyItem(input: $input, condition: $condition) {
    id
    name
    conceptID
    parentConceptID
    description
    startDate
    endDate
    options
    points
    redemptionPerUser
    image
    simphonyID
    multiLanguages
    isActive
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLoyaltyItemMutationVariables,
  APITypes.CreateLoyaltyItemMutation
>;
export const updateLoyaltyItem = /* GraphQL */ `mutation UpdateLoyaltyItem(
  $input: UpdateLoyaltyItemInput!
  $condition: ModelLoyaltyItemConditionInput
) {
  updateLoyaltyItem(input: $input, condition: $condition) {
    id
    name
    conceptID
    parentConceptID
    description
    startDate
    endDate
    options
    points
    redemptionPerUser
    image
    simphonyID
    multiLanguages
    isActive
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLoyaltyItemMutationVariables,
  APITypes.UpdateLoyaltyItemMutation
>;
export const deleteLoyaltyItem = /* GraphQL */ `mutation DeleteLoyaltyItem(
  $input: DeleteLoyaltyItemInput!
  $condition: ModelLoyaltyItemConditionInput
) {
  deleteLoyaltyItem(input: $input, condition: $condition) {
    id
    name
    conceptID
    parentConceptID
    description
    startDate
    endDate
    options
    points
    redemptionPerUser
    image
    simphonyID
    multiLanguages
    isActive
    deleted
    createdAt
    createdByID
    createdByName
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLoyaltyItemMutationVariables,
  APITypes.DeleteLoyaltyItemMutation
>;
export const createOTP = /* GraphQL */ `mutation CreateOTP(
  $input: CreateOTPInput!
  $condition: ModelOTPConditionInput
) {
  createOTP(input: $input, condition: $condition) {
    id
    OTP
    redeemed
    deleted
    userID
    conceptID
    loyaltyItemID
    loyaltyItem {
      id
      name
      conceptID
      parentConceptID
      description
      startDate
      endDate
      options
      points
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    user {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    concept {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      paymentSharePercentage
      simphonyServerBaseURL
      simphonyShortOrgName
      simphonyLocRef
      simphonyToken
      revenueCenterID
      employeeID
      tenderID
      tenders {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptTendersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderType {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptOrderTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      discountID
      useServiceCharge
      serviceChargeId
      specialRequestID
      addressID
      ratingCount
      averageRating
      feesback
      simphonyAuthClientId
      simphonyAuthUsername
      simphonyAuthPassword
      simphonyAuthBaseURL
      orderTypeDineIn
      orderTypeTakeAway
      orderTypeDelivery
      simphonyTakeAwayRvc
      simphonyDeliveryRvc
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      openTime
      closeTime
      varifyForAmount
      varifyFirstOrder
      varifyNewAddress
      sliderImages
      receiveWaiters
      WaiterNotificationOption
      applyLoyalty
      rewardPoints
      moneySpent
      afterVat
      afterDeliveryFee
      beforePromoCode
      pointsCalcWithPromoCode
      isBusy
      allowOnlineOrdering
      minOrderVal
      vatPercentage
      serviceChargePercentage
      addDeliveryToVat
      categoryTagIDs
      parentConceptID
      containerColor
      avgOrderCompletionEstimate
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOTPMutationVariables,
  APITypes.CreateOTPMutation
>;
export const updateOTP = /* GraphQL */ `mutation UpdateOTP(
  $input: UpdateOTPInput!
  $condition: ModelOTPConditionInput
) {
  updateOTP(input: $input, condition: $condition) {
    id
    OTP
    redeemed
    deleted
    userID
    conceptID
    loyaltyItemID
    loyaltyItem {
      id
      name
      conceptID
      parentConceptID
      description
      startDate
      endDate
      options
      points
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    user {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    concept {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      paymentSharePercentage
      simphonyServerBaseURL
      simphonyShortOrgName
      simphonyLocRef
      simphonyToken
      revenueCenterID
      employeeID
      tenderID
      tenders {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptTendersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderType {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptOrderTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      discountID
      useServiceCharge
      serviceChargeId
      specialRequestID
      addressID
      ratingCount
      averageRating
      feesback
      simphonyAuthClientId
      simphonyAuthUsername
      simphonyAuthPassword
      simphonyAuthBaseURL
      orderTypeDineIn
      orderTypeTakeAway
      orderTypeDelivery
      simphonyTakeAwayRvc
      simphonyDeliveryRvc
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      openTime
      closeTime
      varifyForAmount
      varifyFirstOrder
      varifyNewAddress
      sliderImages
      receiveWaiters
      WaiterNotificationOption
      applyLoyalty
      rewardPoints
      moneySpent
      afterVat
      afterDeliveryFee
      beforePromoCode
      pointsCalcWithPromoCode
      isBusy
      allowOnlineOrdering
      minOrderVal
      vatPercentage
      serviceChargePercentage
      addDeliveryToVat
      categoryTagIDs
      parentConceptID
      containerColor
      avgOrderCompletionEstimate
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOTPMutationVariables,
  APITypes.UpdateOTPMutation
>;
export const deleteOTP = /* GraphQL */ `mutation DeleteOTP(
  $input: DeleteOTPInput!
  $condition: ModelOTPConditionInput
) {
  deleteOTP(input: $input, condition: $condition) {
    id
    OTP
    redeemed
    deleted
    userID
    conceptID
    loyaltyItemID
    loyaltyItem {
      id
      name
      conceptID
      parentConceptID
      description
      startDate
      endDate
      options
      points
      redemptionPerUser
      image
      simphonyID
      multiLanguages
      isActive
      deleted
      createdAt
      createdByID
      createdByName
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    user {
      id
      accountID
      username
      preferred_username
      email
      email_verified
      phone_number
      phone_number_verified
      name
      nickname
      given_name
      middle_name
      family_name
      address
      birthdate
      gender
      locale
      picture
      pictureCrop
      website
      zoneinfo
      verification_code
      facebook
      instagram
      group
      phones
      flags
      flagsName
      interests
      interestsName
      status
      stats
      spending
      prevOrderDate
      lastOrderDate
      promocodes {
        name
        promocodeId
        canBeUsed
        discountVal
        discountLimit
        isDisplayed
        __typename
      }
      mostOrderedItems
      points
      deleted
      createdAt
      createdByID
      createdByName
      cognitoUsername
      cognitoSub
      autoGeneratedPassword
      updated
      sub
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    concept {
      id
      accountID
      name
      kiosks
      merchantID
      merchantUsername
      merchantPassword
      merchantIntegrationNumber
      merchantAPIKey
      paymentSharePercentage
      simphonyServerBaseURL
      simphonyShortOrgName
      simphonyLocRef
      simphonyToken
      revenueCenterID
      employeeID
      tenderID
      tenders {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptTendersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orderType {
        items {
          id
          name
          simphonyID
          precedence
          deleted
          createdAt
          createdByID
          createdByName
          updatedAt
          _version
          _deleted
          _lastChangedAt
          conceptOrderTypeId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      discountID
      useServiceCharge
      serviceChargeId
      specialRequestID
      addressID
      ratingCount
      averageRating
      feesback
      simphonyAuthClientId
      simphonyAuthUsername
      simphonyAuthPassword
      simphonyAuthBaseURL
      orderTypeDineIn
      orderTypeTakeAway
      orderTypeDelivery
      simphonyTakeAwayRvc
      simphonyDeliveryRvc
      exportInterval
      deleted
      createdAt
      createdByID
      createdByName
      foodicsToken
      enableTableFoodics
      image
      logo
      album
      type
      rating
      location
      description
      areas
      seatTypes
      needAssistance
      spaBooking
      showerBooking
      cigarMenu
      precedence
      isPinRequired
      viewOnly
      openTime
      closeTime
      varifyForAmount
      varifyFirstOrder
      varifyNewAddress
      sliderImages
      receiveWaiters
      WaiterNotificationOption
      applyLoyalty
      rewardPoints
      moneySpent
      afterVat
      afterDeliveryFee
      beforePromoCode
      pointsCalcWithPromoCode
      isBusy
      allowOnlineOrdering
      minOrderVal
      vatPercentage
      serviceChargePercentage
      addDeliveryToVat
      categoryTagIDs
      parentConceptID
      containerColor
      avgOrderCompletionEstimate
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOTPMutationVariables,
  APITypes.DeleteOTPMutation
>;
export const createMostOrderedItems = /* GraphQL */ `mutation CreateMostOrderedItems(
  $input: CreateMostOrderedItemsInput!
  $condition: ModelMostOrderedItemsConditionInput
) {
  createMostOrderedItems(input: $input, condition: $condition) {
    id
    conceptID
    menuItemID
    menuItemName
    menuItemImage
    quantity
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMostOrderedItemsMutationVariables,
  APITypes.CreateMostOrderedItemsMutation
>;
export const updateMostOrderedItems = /* GraphQL */ `mutation UpdateMostOrderedItems(
  $input: UpdateMostOrderedItemsInput!
  $condition: ModelMostOrderedItemsConditionInput
) {
  updateMostOrderedItems(input: $input, condition: $condition) {
    id
    conceptID
    menuItemID
    menuItemName
    menuItemImage
    quantity
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMostOrderedItemsMutationVariables,
  APITypes.UpdateMostOrderedItemsMutation
>;
export const deleteMostOrderedItems = /* GraphQL */ `mutation DeleteMostOrderedItems(
  $input: DeleteMostOrderedItemsInput!
  $condition: ModelMostOrderedItemsConditionInput
) {
  deleteMostOrderedItems(input: $input, condition: $condition) {
    id
    conceptID
    menuItemID
    menuItemName
    menuItemImage
    quantity
    deleted
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMostOrderedItemsMutationVariables,
  APITypes.DeleteMostOrderedItemsMutation
>;
