import { Auth, API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import {
  setDispatcherListing,
  setWaiterListing,
  setFilters,
  setListing,
  setSelected,
  setSelectedFilters,
  setPaginationList,
  setAdminType,
  setNextToken,
} from "../store/ducks/admins";
import { HeadCell } from "../models/dataTable";
import { AdminsApiPaths, AdminsConstants } from "../constants/enums";
import useApp from "./useApp";
import * as AWS from "aws-sdk";
import awsmobile from "../aws-exports";

const useResource = (listingName: string, singleName: string) => {
  const dispatch = useDispatch();
  const { showError, showConfirm } = useApp();
  const listing = useSelector(
    (state: any) => state[`${listingName}`]["listing"]
  );
  const searchText = useSelector(
    (state: any) => state[`${listingName}`]["searchText"]
  );
  const selected = useSelector(
    (state: any) => state[`${listingName}`]["selected"]
  );
  const account = useSelector((state: any) => state.accounts.selected);
  const paginationList = useSelector(
    (state: any) => state.admins.paginationList
  );
  const nextToken = useSelector((state: any) => state.admins.nextToken);
  const adminsList = useSelector((state: any) => state.admins.listing);
  const adminType = useSelector((state: any) => state.admins.adminType);

  const COGNITO_GROUPS = [
    { label: "admin", value: "admin" },
    // { label: "staff", value: "staff" },
  ];
  const DEVELOP_COGNITO_USER_POOL_ID = awsmobile.aws_user_pools_id;

  const DEVELOP_COGNITO_IDENTITY_POOL_ID =
    awsmobile.aws_cognito_identity_pool_id;

  // const DEVELOP_COGNITO_USER_POOL_ID = "us-east-2_l6TmJKRsb";
  // const PRODUCTION_COGNITO_USER_POOL_ID = "us-east-2_TaC1L1rt9";
  // const DEVELOP_COGNITO_IDENTITY_POOL_ID =
  //   "us-east-2:624fdbfa-b5c1-48cb-841a-25896c0880dd";
  // const PRODUCTION_COGNITO_IDENTITY_POOL_ID =
  //   "us-east-2:0a5dadf7-279a-4a80-80ef-55154eceb314";

  async function create(data: any) {
    try {
      // const attributes: any = {
      //   name: data.name,
      //   email: `${getDomainName(account)}_${data.email}`,
      // };

      const attributes: any = {
        name: data.name,
        email: data.email,
      };

      // Need to fix adding custom fields to cognito admin

      // if (data.simphEmpId) {
      //   attributes["custom:simphEmpId"] = data.simphEmpId;
      // } else {
      //   if (data.group && data.group === "admin") {
      //     throw "Invalid Simphony Employee ID";
      //   }
      // }

      // const user = await Auth.signUp({
      //   username: `${getDomainName(account)}_${data.email}`,
      //   password: data.password,
      //   attributes,
      // });
      const user = await Auth.signUp({
        username: data.email,
        password: data.password,
        attributes,
      });

      await addUserToGroup(
        user.user.getUsername(),
        data.group ? data.group : "admin"
      );
      return user;
    } catch (err: Error | any) {
      // console.log(err);
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  async function createMockAdmins() {
    try {
      for (let i = 31; i < 41; i++) {
        const name = `waiterFCP${i}`;
        const adminData = {
          name: name,
          password: "12345678",
          email: `${name}@anyware.software`,
          group: "staff",
        };

        // Register Admin
        await create(adminData);
      }
    } catch (err: Error | any) {
      // console.log(err);
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  async function adminsConfig(apiPath: string, queryString: any, body?: any) {
    let apiName = AdminsConstants.ADMINS_API;
    let path = apiPath;
    let myInit = {
      body: body,
      queryStringParameters: queryString,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };

    return {
      apiName,
      path,
      myInit,
    };
  }

  async function listGroupsForUser(username: string) {
    let groups: any[] = [];
    try {
      const { apiName, path, myInit } = await adminsConfig(
        AdminsApiPaths.LIST_GROUPS_FOR_USER,
        {
          username,
        }
      );
      const { Groups } = await API.get(apiName, path, myInit);
      for (let group of Groups) {
        groups.push(group["GroupName"]);
      }
      return groups;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  async function listAllGroups() {
    let groups: any[] = [];
    try {
      const { apiName, path, myInit } = await adminsConfig(
        AdminsApiPaths.LIST_GROUPS,
        { limit: 60 }
      );
      const { Groups } = await API.get(apiName, path, myInit);
      for (let group of Groups) {
        groups.push(group["GroupName"]);
      }
      return groups;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  async function addUserToGroup(username: string, groupname: string) {
    try {
      const { apiName, path, myInit } = await adminsConfig(
        AdminsApiPaths.ADD_USER_TO_GROUP,
        {},
        {
          username,
          groupname,
        }
      );
      const { ...result } = await API.post(apiName, path, myInit);
      showConfirm(result.message);
    } catch (err: Error | any) {
      // console.log(err);
      showError(err.message);
    }
  }

  async function removeUserFromGroup(username: string, groupname: string) {
    try {
      const { apiName, path, myInit } = await adminsConfig(
        AdminsApiPaths.REMOVE_USER_FROM_GROUP,
        {},
        {
          username,
          groupname,
        }
      );
      const { ...result } = await API.post(apiName, path, myInit);
      showConfirm(result.message);
    } catch (err: Error | any) {
      // console.log(err);
      showError(err.message);
    }
  }

  async function fetch(
    nextToken: string,
    searchText: string,
    limit: number,
    type: any
  ) {
    try {
      let listing: any[] = [];
      if (paginationList.length === 0 || type) {
        const { apiName, path, myInit } = await adminsConfig(
          AdminsApiPaths.LIST_USERS,
          {
            token: nextToken,
          }
        );
        const { NextToken, ...data } = await API.get(apiName, path, myInit);
        nextToken = NextToken === undefined ? "" : NextToken;
        let cognitoObj = data.Users;

        for (let user of cognitoObj) {
          let { Attributes, ...userData } = user;

          for (let attr of user["Attributes"]) {
            userData[attr["Name"]] = attr["Value"];
            if (userData["Username"]) {
              userData["id"] = userData["Username"];
            }
            if (userData["UserCreateDate"]) {
              userData["createdAt"] = userData["UserCreateDate"];
            }
            if (attr?.Name === "custom:simphEmpId") {
              userData.simphEmpId = attr?.Value;
            }
            // if (userData["email"]) {
            //   userData["email"] = userData["email"].split(
            //     `${getDomainName(account)}_`
            //   )[1];
            // }
          }
          // get user groups
          // if (userData["Username"] && userData["Groups"]!="dispatcher") {
          //   userData["Groups"] = await listGroupsForUser(userData["Username"]);
          // }
          // if(userData["Groups"]!=="dispatcher")
          // {
          //   listing.push(userData);
          // }
          listing.push(userData);
        }
        dispatch(setPaginationList(listing));
        // console.log(listing);
      } else {
        listing = paginationList;
      }

      if (searchText.length > 0) {
        let filteredData = listing.filter((item: any) => {
          return item.email.toLowerCase().includes(searchText.toLowerCase());
        });
        dispatch(setListing(filteredData));
      } else {
        dispatch(setListing(listing));
      }

      // listing = [...useData]
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }
  async function fetchAll(
    nextToken: string,
    searchText: string,
    limit: number,
    type: any
  ) {
    try {
      let listing: any[] = [];
      if (paginationList.length === 0 || type) {
        const { apiName, path, myInit } = await adminsConfig(
          AdminsApiPaths.LIST_USERS,
          {
            token: nextToken,
          }
        );
        const { NextToken, ...data } = await API.get(apiName, path, myInit);
        nextToken = NextToken === undefined ? "" : NextToken;
        let cognitoObj = data.Users;

        for (let user of cognitoObj) {
          let { Attributes, ...userData } = user;

          for (let attr of user["Attributes"]) {
            userData[attr["Name"]] = attr["Value"];
            if (userData["Username"]) {
              userData["id"] = userData["Username"];
            }
            if (userData["UserCreateDate"]) {
              userData["createdAt"] = userData["UserCreateDate"];
            }
            if (attr?.Name === "custom:simphEmpId") {
              userData.simphEmpId = attr?.Value;
            }
            // if (userData["email"]) {
            //   userData["email"] = userData["email"].split(
            //     `${getDomainName(account)}_`
            //   )[1];
            // }
          }
          listing.push(userData);
        }
        dispatch(setPaginationList(listing));
        // console.log(listing);
      } else {
        listing = paginationList;
      }

      if (searchText.length > 0) {
        let filteredData = listing.filter((item: any) => {
          return item.email.toLowerCase().includes(searchText.toLowerCase());
        });
        dispatch(setListing(filteredData));
      } else {
        dispatch(setListing(listing));
      }

      // listing = [...useData]
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  async function byGroup(
    nextTokenPublic: string,
    limit: number,
    groupName: string,
    searchText: string
  ) {
    try {
      let nextToken: any = null;
      let listing: any[] = [];
      let allListing: any[] = [];
      do {
        const { apiName, path, myInit } = await adminsConfig(
          AdminsApiPaths.LIST_USERS_IN_GROUP,
          {
            groupname: groupName,
            // limit: 1000,
            token: nextToken,
          }
        );

        const { NextToken, ...data } = await API.get(apiName, path, myInit);
        nextToken = NextToken;
        listing = data.Users;

        allListing = [...allListing, ...listing];
      } while (nextToken);

      let groupMembers = [];
      for (let user of allListing) {
        let { Attributes, ...userData } = user;

        for (let attr of user["Attributes"]) {
          userData[attr["Name"]] = attr["Value"];
          if (userData["Username"]) {
            userData["id"] = userData["Username"];
          }
          if (userData["UserCreateDate"]) {
            userData["createdAt"] = userData["UserCreateDate"];
          }
          if (attr?.Name === "custom:simphEmpId") {
            userData.simphEmpId = attr?.Value;
          }
          // if (userData["email"]) {
          //   userData["email"] = userData["email"].split(
          //     `${getDomainName(account)}_`
          //   )[1];
          // }
        }
        if (userData["Username"]) {
          groupMembers.push(userData);
        }
      }
      switch (groupName) {
        case "admin":
          dispatch(setListing(groupMembers));
          break;
        case "staff":
          dispatch(setWaiterListing(groupMembers));
          break;
      }

      if (searchText?.length > 0) {
        let filteredData = groupMembers.filter((item: any) => {
          return item.email.toLowerCase().includes(searchText.toLowerCase());
        });
        dispatch(setListing(filteredData));
      }

      return groupMembers;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  async function get(username: string) {
    try {
      const { apiName, path, myInit } = await adminsConfig(
        AdminsApiPaths.GET_USER,
        {
          username,
        }
      );

      const { ...single } = await API.get(apiName, path, myInit);
      let { UserAttributes, ...userData } = single;

      // console.log({ UserAttributes, userData });

      for (let attr of single["UserAttributes"]) {
        userData[attr["Name"]] = attr["Value"];
        if (userData["Username"]) {
          userData["id"] = userData["Username"];
        }
        if (userData["UserCreateDate"]) {
          userData["createdAt"] = userData["UserCreateDate"];
        }
        if (attr?.Name === "custom:simphEmpId") {
          userData.simphEmpId = attr?.Value;
        }
        // if (userData["email"]) {
        //   userData["email"] =
        //     userData["email"].split(`${getDomainName(account)}_`)[1] ??
        //     userData["email"];
        // }

        // get user groups
      }
      if (userData["Username"]) {
        userData["Groups"] = await listGroupsForUser(userData["Username"]);
      }
      return userData;
    } catch (err: Error | any) {
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  async function enableUser(username: string) {
    try {
      const { apiName, path, myInit } = await adminsConfig(
        AdminsApiPaths.ENABLE_USER,
        {},
        {
          username,
        }
      );
      const { ...result } = await API.post(apiName, path, myInit);
      // console.log(result.message);
      showConfirm("User enabled successfully.");
    } catch (err: Error | any) {
      // console.log(err);
      showError(err.message);
    }
  }

  async function disableUser(username: string) {
    try {
      const { apiName, path, myInit } = await adminsConfig(
        AdminsApiPaths.DISABLE_USER,
        {},
        {
          username,
        }
      );
      const { ...result } = await API.post(apiName, path, myInit);
      // console.log(result.message);
      showConfirm("User disabled successfully.");
    } catch (err: Error | any) {
      // console.log(err);
      showError(err.message);
    }
  }

  async function deleteUser(username: any) {
    const credentials = await Auth.currentCredentials();
    AWS.config.credentials = credentials;
    AWS.config.region = "us-east-2";
    new AWS.CognitoIdentityCredentials(
      {
        IdentityPoolId: DEVELOP_COGNITO_IDENTITY_POOL_ID,
      },
      { credentials: credentials }
    );
    const cognitoidentityserviceprovider =
      new AWS.CognitoIdentityServiceProvider();
    cognitoidentityserviceprovider.adminDeleteUser(
      {
        UserPoolId: DEVELOP_COGNITO_USER_POOL_ID,
        Username: username.id,
      },
      function (err, data) {
        if (data) {
          let newList = paginationList.filter((admin: any) => {
            return admin.sub !== username.id;
          });
          dispatch(setPaginationList(newList));
          dispatch(setListing(newList));
          showConfirm("User deleted successfully.");
        }
        if (err) {
          // console.log("error deleting user", { err });
          showError(
            typeof err.message === "string"
              ? err.message
              : "Error occurred while deleting the user"
          );
        }
      }
    );
  }

  async function adminChangeUserPassword(username: string, password: string) {
    const credentials = await Auth.currentCredentials();
    AWS.config.credentials = credentials;
    AWS.config.region = "us-east-2";
    new AWS.CognitoIdentityCredentials(
      {
        IdentityPoolId: DEVELOP_COGNITO_IDENTITY_POOL_ID,
      },
      { credentials: credentials }
    );
    const cognitoidentityserviceprovider =
      new AWS.CognitoIdentityServiceProvider();
    cognitoidentityserviceprovider.adminSetUserPassword(
      {
        UserPoolId: DEVELOP_COGNITO_USER_POOL_ID,
        Username: username,
        Password: password,
        Permanent: true,
      },
      function (err, data) {
        if (data) {
          showConfirm("User password has changed successfully.");
        }
        if (err) {
          showError("Unauthorized Action !");
          // console.log("error changing user password", { err });
          // showError(
          //   typeof err.message === "string"
          //     ? err.message
          //     : "Error occurred while changing user password"
          // );
        }
      }
    );
  }

  async function adminUpdateUserAttributes(
    username: string,
    email: string,
    name: string,
    simphEmpId: string
  ) {
    // console.log({ simphEmpId });

    const userAttrs: any[] = [];
    if (name) userAttrs.push({ Name: "name", Value: name });
    // if (email)
    //   userAttrs.push({
    //     Name: "email",
    //     Value: `${getDomainName(account)}_${email}`,
    //   });
    if (email)
      userAttrs.push({
        Name: "email",
        Value: email,
      });
    if (simphEmpId) {
      userAttrs.push({
        Name: "custom:simphEmpId",
        Value: simphEmpId,
      });
    } else {
      userAttrs.push({
        Name: "custom:simphEmpId",
        Value: "",
      });
    }
    const credentials = await Auth.currentCredentials();
    AWS.config.credentials = credentials;
    AWS.config.region = "us-east-2";
    new AWS.CognitoIdentityCredentials(
      {
        IdentityPoolId: DEVELOP_COGNITO_IDENTITY_POOL_ID,
      },
      { credentials: credentials }
    );
    const cognitoidentityserviceprovider =
      new AWS.CognitoIdentityServiceProvider();
    cognitoidentityserviceprovider.adminUpdateUserAttributes(
      {
        UserPoolId: DEVELOP_COGNITO_USER_POOL_ID,
        Username: username,
        UserAttributes: userAttrs,
      },
      function (err, data) {
        if (data) {
          showConfirm("User data has changed successfully.");
        }
        if (err) {
          showError(
            typeof err.message === "string"
              ? err.message
              : "Error occurred while changing user data"
          );
        }
      }
    );
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const result = await Auth.changePassword(user, oldPassword, newPassword);
      showConfirm(result);
    } catch (err: Error | any) {
      console.log(err);
      showError(
        typeof err.message === "string" ? err.message : "Error occurred"
      );
    }
  }

  const headCells: readonly HeadCell[] = [
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
    },
    // {
    //   id: "simphEmpId",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Simphony Employee ID",
    // },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "Date",
    },
    {
      id: "actions",
      numeric: true,
      disablePadding: false,
      label: "",
    },
  ];

  const dataCells: readonly string[] = ["email", "name"];

  function isAdmin(session: any) {
    if (session && session["cognito:groups"]) {
      return (
        session["cognito:groups"].find((group: any) => group === "admin") !==
        undefined
      );
    }
  }

  async function isCurrentAuthUser(resourceUsername: string): Promise<boolean> {
    const { username } = await Auth.currentAuthenticatedUser();
    return username === resourceUsername;
  }

  const options: any[] = [];

  for (let option of listing) {
    options.push({ label: option.name, value: option.sub });
  }
  const api: any = {};

  api[`${listingName}Listing`] = listing;
  api[`${listingName}Options`] = options;
  api[`${listingName}SearchText`] = searchText;
  api[`${listingName}HeadCells`] = headCells;

  api[`${listingName}DataCells`] = dataCells;
  api[`${listingName}Selected`] = selected;
  api[`${listingName}Fetch`] = fetch;
  api[`${listingName}Fetch`] = fetchAll;
  api[`${listingName}FetchByGroup`] = byGroup;
  api[`${listingName}Get`] = get;
  api[`${listingName}Create`] = create;
  api[`${listingName}CreateMockAdmins`] = createMockAdmins;
  api[`${listingName}Update`] = () => {};
  api[`${listingName}Trash`] = () => {};
  api[`${listingName}BulkTrash`] = () => {};
  api[`${listingName}Delete`] = deleteUser;
  api[`${listingName}UpdateUserAttrs`] = adminUpdateUserAttributes;
  api[`${listingName}ChangeUserPassword`] = adminChangeUserPassword;
  api[`${listingName}ChangeListing`] = (listing: any[]) =>
    dispatch(setListing(listing));
  api[`${listingName}ChangeSelected`] = (conceptID: string) =>
    dispatch(setSelected(conceptID));
  api["isAdmin"] = isAdmin;
  api["isCurrentAuthUser"] = isCurrentAuthUser;
  api["changePassword"] = changePassword;
  api[`${listingName}ListGroupsForUser`] = listGroupsForUser;
  api[`${listingName}ListAllGroups`] = listAllGroups;
  api[`addUserToGroup`] = addUserToGroup;
  api[`removeUserFromGroup`] = removeUserFromGroup;
  api[`${listingName}DisableUser`] = disableUser;
  api[`${listingName}EnableUser`] = enableUser;
  api[`${listingName}CognitoGroups`] = COGNITO_GROUPS;
  api[`${listingName}ChangeListingForGroup`] = (listing: any[]) => {
    dispatch(setDispatcherListing(listing));
    dispatch(
      setFilters(
        listing.map((model: any) =>
          model.Attributes[2] ? model.Attributes[2].Value : model.Username
        )
      )
    );
  };
  api[`${listingName}ChangeSelectedFilters`] = (filters: any) =>
    dispatch(setSelectedFilters(filters));
  api[`${listingName}ChangeFilters`] = (filters: string[]) =>
    dispatch(setFilters(filters));
  api[`${listingName}ChangePagination`] = (listing: any[]) =>
    dispatch(setPaginationList(listing));
  api[`${listingName}ChangeType`] = (AdminType: any) =>
    dispatch(setAdminType(AdminType));

  api[`${listingName}NextToken`] = nextToken;
  api[`${listingName}Listing`] = adminsList;
  api[`${listingName}ClearListing`] = () => dispatch(setListing([]));
  api[`${listingName}ClearNextToken`] = () => dispatch(setNextToken(null));
  return api;
};

export default useResource;
